// import {Html5QrcodeError, Html5QrcodeResult} from "html5-qrcode/src/core";
import {useValidateEventTicketOrganizersOrganizerIdEventsValidationsPostMutation} from "@ef-org/api";
import Cookies from "js-cookie";

import {useEffect, useState} from "react";
import {QrReader} from "react-qr-reader";
import {useDispatch} from "react-redux";

import {useRouter} from "next/router";

import {Center, Spinner} from "@chakra-ui/react";
import {Box} from "@chakra-ui/react";

import {setValidationResult} from "../../store/validation.slice";

const ViewFinder = (props) => {
  return (
    <Box
      sx={{
        top: "0px",
        left: "0px",
        zIndex: 1,
        boxSizing: "border-box",
        border: "50px solid rgba(0, 0, 0, 0.3)",
        boxShadow: "#fff 0px 0px 0px 5px inset",
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    ></Box>
  );
};

const QrScanner = () => {
  const [scanResult, setScanResult] = useState();

  const dispatch = useDispatch();
  const organizerId = Cookies.get("organizerId");
  const {push} = useRouter();
  const [validate, res] =
    useValidateEventTicketOrganizersOrganizerIdEventsValidationsPostMutation();

  const onResult = (result) => {
    if (result) {
      setScanResult(result);
      validate({
        organizerId,
        validateEventTicket: {
          ticket_data: result.text,
          source: "eventsFrame",
        },
      });
    }
  };

  useEffect(() => {
    if (res.error) {
      // @ts-ignore // introduced by data on error object
      dispatch(setValidationResult({id: res.requestId, result: res.error?.data?.ticket}));
      push(`/qr-scanner/failure/?id=${res.requestId}`);
    } else if (res.data?.ticket) {
      dispatch(setValidationResult({id: res.requestId, result: res.data.ticket}));
      push(`/qr-scanner/success/?id=${res.requestId}`);
    }
  }, [res]);

  return (
    <>
      {!scanResult && (
        <Box background="#000" height="100vh" display="flex" alignItems="center">
          <QrReader
            constraints={{facingMode: "environment"}}
            scanDelay={500}
            onResult={onResult}
            // chooseDeviceId={()=>selected}
            containerStyle={{width: "100%"}}
            videoContainerStyle={{
              width: "100%",
              // paddingTop: "0",
            }}
            videoStyle={{}}
            ViewFinder={() => <ViewFinder />}
          />
        </Box>
      )}
      {scanResult && (
        <Center
          sx={{
            height: "100%",
            display: "flex",
            position: "fixed",
            width: "100%",
          }}
        >
          <Spinner />
        </Center>
      )}
    </>
  );
};

export default QrScanner;
