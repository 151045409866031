import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconTicket: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    cursor="pointer"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M9.54854 1.54785C9.34612 1.34543 9.01791 1.34543 8.81548 1.54785L1.98828 8.37506C1.78586 8.57748 1.78586 8.90569 1.98828 9.10811L2.72653 9.84636C3.00106 9.69286 3.31756 9.6055 3.65304 9.6055C4.70268 9.6055 5.55359 10.4564 5.55359 11.5061C5.55359 11.8416 5.46622 12.158 5.31274 12.4326L6.05108 13.1709C6.25351 13.3734 6.58168 13.3734 6.78411 13.1709L13.6114 6.34368C13.8138 6.14126 13.8138 5.81308 13.6114 5.61066L12.873 4.87236C12.5985 5.02591 12.2819 5.11333 11.9463 5.11333C10.8967 5.11333 10.0458 4.26242 10.0458 3.21278C10.0458 2.87721 10.1332 2.56063 10.2868 2.28606L9.54854 1.54785ZM8.08249 0.814822C8.68977 0.207559 9.67432 0.207559 10.2816 0.814822L11.0912 1.62444C11.4442 1.97742 11.3809 2.47293 11.2029 2.77229C11.1265 2.90087 11.0825 3.05089 11.0825 3.21278C11.0825 3.68989 11.4692 4.07666 11.9463 4.07666C12.1082 4.07666 12.2582 4.03263 12.3868 3.95618C12.6862 3.77819 13.1817 3.71493 13.5346 4.06792L14.3443 4.87762C14.9516 5.48489 14.9516 6.46947 14.3443 7.07674L7.51717 13.9039C6.90989 14.5112 5.92531 14.5112 5.31805 13.9039L4.50831 13.0942C4.15536 12.7413 4.21855 12.2458 4.39649 11.9465C4.4729 11.8179 4.51692 11.6679 4.51692 11.5061C4.51692 11.029 4.13015 10.6422 3.65304 10.6422C3.49119 10.6422 3.3412 10.6862 3.21265 10.7626C2.91329 10.9406 2.41785 11.0038 2.06491 10.6508L1.25525 9.84117C0.647986 9.2339 0.647993 8.24934 1.25525 7.64207L8.08249 0.814822Z"
      fill="#162184"
    />
  </Icon>
);

export default IconTicket;
