import React from "react";

import Link from "next/link";
import {useRouter} from "next/router";

import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {isUserAuthorized, logout} from "../../helpers/aws/auth";
import useCookie from "../../hooks/useCookie";
import ItemRow from "./ItemRow";

type Props = {fullMenuWidth: boolean};
const MenuBottomSection = ({fullMenuWidth}: Props) => {
  const {asPath} = useRouter();
  const {isOpen, onToggle, onClose} = useDisclosure();
  const {cookieValue: userName} = useCookie<string>("userName");
  return (
    <Box overflow="hidden">
      <a target="_blank" rel="noopener noreferrer" href="https://support.eventsframe.com/">
        <ItemRow
          fullMenuWidth={fullMenuWidth}
          icon={
            <svg
              width="24px"
              height="25px"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4903 14.5482C11.212 14.5482 10.9736 14.319 11.0167 14.0441C11.0381 13.9082 11.0699 13.8142 11.1123 13.689C11.1276 13.6439 11.1442 13.5947 11.1622 13.5381C11.2773 13.1865 11.4403 12.9021 11.6512 12.6848C11.8622 12.4674 12.1163 12.2693 12.4135 12.0903C12.6052 11.9688 12.7778 11.833 12.9312 11.6828C13.0847 11.5326 13.2061 11.36 13.2956 11.165C13.3851 10.9701 13.4298 10.7543 13.4298 10.5178C13.4298 10.2334 13.3627 9.98726 13.2285 9.77952C13.0942 9.57178 12.9153 9.41198 12.6915 9.30012C12.471 9.18506 12.2249 9.12753 11.9533 9.12753C11.7072 9.12753 11.4723 9.17867 11.2485 9.28094C11.0248 9.38321 10.8394 9.54301 10.6924 9.76034C10.6187 9.86774 10.5606 9.99081 10.5183 10.1296C10.4413 10.3814 10.2336 10.5897 9.97027 10.5897H9.50078C9.21702 10.5897 8.985 10.3522 9.03856 10.0736C9.10695 9.71776 9.23317 9.40402 9.41722 9.13232C9.68568 8.73282 10.0404 8.4308 10.4815 8.22625C10.9257 8.02171 11.4163 7.91943 11.9533 7.91943C12.5413 7.91943 13.0559 8.0297 13.4969 8.25022C13.938 8.46755 14.28 8.77277 14.5229 9.16588C14.769 9.5558 14.892 10.0112 14.892 10.5322C14.892 10.8901 14.8361 11.213 14.7242 11.5006C14.6124 11.7851 14.4526 12.0392 14.2448 12.2629C14.0403 12.4866 13.7942 12.6848 13.5065 12.8573C13.2349 13.0267 13.0143 13.2025 12.845 13.3847C12.6788 13.5669 12.5573 13.7826 12.4806 14.0319C12.4695 14.068 12.459 14.1046 12.4491 14.1406C12.3852 14.3741 12.1798 14.5482 11.9377 14.5482H11.4903Z"
                fill="#727CD4"
              />
              <path
                d="M11.7088 17.963C11.4467 17.963 11.2214 17.8703 11.0328 17.6849C10.8442 17.4964 10.75 17.2695 10.75 17.0042C10.75 16.7421 10.8442 16.5184 11.0328 16.333C11.2214 16.1445 11.4467 16.0502 11.7088 16.0502C11.9676 16.0502 12.1914 16.1445 12.3799 16.333C12.5717 16.5184 12.6676 16.7421 12.6676 17.0042C12.6676 17.18 12.6228 17.3414 12.5333 17.4884C12.447 17.6322 12.332 17.7473 12.1882 17.8335C12.0443 17.9198 11.8845 17.963 11.7088 17.963Z"
                fill="#727CD4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12.9219C22 18.4447 17.5228 22.9219 12 22.9219C6.47715 22.9219 2 18.4447 2 12.9219C2 7.39902 6.47715 2.92188 12 2.92188C17.5228 2.92188 22 7.39902 22 12.9219ZM20.5 12.9219C20.5 17.6163 16.6944 21.4219 12 21.4219C7.30558 21.4219 3.5 17.6163 3.5 12.9219C3.5 8.22746 7.30558 4.42188 12 4.42188C16.6944 4.42188 20.5 8.22746 20.5 12.9219Z"
                fill="#727CD4"
              />
            </svg>
          }
          name="Help"
        />
      </a>

      {/* <ItemRow
        fullMenuWidth={fullMenuWidth}
        icon={
          <svg
            width="24px"
            height="25px"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9954 2.92188C16.0543 2.92188 19.3684 6.19765 19.5078 10.3548L19.512 10.6121V14.813L20.8951 18.049C20.9642 18.211 21 18.3858 21 18.5625C21 19.2704 20.4391 19.8443 19.7472 19.8443L15.002 19.8458C15.002 21.5446 13.6558 22.9219 11.9954 22.9219C10.3941 22.9219 9.08526 21.6412 8.99381 20.0265L8.98826 19.8434L4.25309 19.8443C4.08137 19.8443 3.91149 19.8081 3.75398 19.7381C3.11938 19.456 2.82838 18.7012 3.10403 18.0519L4.47873 14.8139V10.612C4.47933 6.35182 7.83825 2.92188 11.9954 2.92188ZM13.4982 19.8434L10.492 19.8458C10.492 20.6952 11.1651 21.3838 11.9954 21.3838C12.7768 21.3838 13.4189 20.7738 13.4918 19.9939L13.4982 19.8434ZM11.9954 4.45992C8.66784 4.45992 5.98254 7.202 5.98205 10.6121V15.1335L4.63509 18.3062H19.3642L18.0087 15.1346L18.0088 10.6253L18.0051 10.3944C17.8937 7.07888 15.2442 4.45992 11.9954 4.45992Z"
              fill="#727CD4"
            />
          </svg>
        }
        name="Notifications"
        rightElement={
          <Text
            as="span"
            bg="#D76464"
            color="white"
            lineHeight="30px"
            boxSize="30px"
            borderRadius="99px"
            textAlign="center"
          >
            10
          </Text>
        }
      /> */}

      <Popover isOpen={isOpen} onClose={onClose} placement="top-start">
        <PopoverTrigger>
          <Stack
            mt={["1rem", "1rem", "2rem"]}
            direction="row"
            align="center"
            border="1px solid"
            borderColor="#2A429C"
            _hover={{bg: "#2A429C"}}
            pl="1rem"
            borderRadius="16px"
            h="40px"
            cursor="pointer"
            onClick={onToggle}
            spacing="1rem"
          >
            <svg
              width="16px"
              height="21px"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7506 12.9191C14.9926 12.9191 15.9995 13.9259 15.9995 15.168V15.7434C15.9995 16.6377 15.6799 17.5025 15.0984 18.1819C13.529 20.0154 11.1418 20.9202 7.99644 20.9202C4.8506 20.9202 2.46459 20.0151 0.89828 18.1808C0.31852 17.5019 0 16.6384 0 15.7456V15.168C0 13.9259 1.00686 12.9191 2.24888 12.9191H13.7506ZM13.7506 14.4191H2.24888C1.83529 14.4191 1.5 14.7544 1.5 15.168V15.7456C1.5 16.2813 1.69111 16.7994 2.03897 17.2068C3.29228 18.6744 5.25816 19.4202 7.99644 19.4202C10.7347 19.4202 12.7023 18.6744 13.9588 17.2065C14.3077 16.7989 14.4995 16.2799 14.4995 15.7434V15.168C14.4995 14.7544 14.1642 14.4191 13.7506 14.4191ZM7.99644 0.923828C10.7578 0.923828 12.9964 3.16241 12.9964 5.92383C12.9964 8.68526 10.7578 10.9238 7.99644 10.9238C5.23504 10.9238 2.99646 8.68526 2.99646 5.92383C2.99646 3.16241 5.23504 0.923828 7.99644 0.923828ZM7.99644 2.42383C6.06344 2.42383 4.49646 3.99084 4.49646 5.92383C4.49646 7.85683 6.06344 9.42384 7.99644 9.42384C9.92944 9.42384 11.4964 7.85683 11.4964 5.92383C11.4964 3.99084 9.92944 2.42383 7.99644 2.42383Z"
                fill="#727CD4"
              />
            </svg>

            {fullMenuWidth && (
              <Stack direction="row" w="100%" align="center" justify="space-between" pr="1rem">
                <Text fontWeight="600" color="#E9EAF8" fontSize="12px">
                  {userName}
                </Text>

                {isOpen ? (
                  <ChevronDownIcon boxSize="20px" color="#727CD4" />
                ) : (
                  <ChevronUpIcon boxSize="20px" color="#727CD4" />
                )}
              </Stack>
            )}
          </Stack>
        </PopoverTrigger>
        <PopoverContent border="0" bg="transparent" w="204px">
          <PopoverBody
            _focus={{boxShadow: "0"}}
            bg="#162184"
            border="1px solid"
            borderColor="#727CD4"
            borderRadius="24px"
          >
            <Stack py="0.4rem" spacing="0.3rem">
              {/* <ItemRow fullMenuWidth={fullMenuWidth} name="My Wallet" href="/my-wallet" /> */}
              {isUserAuthorized("admin") && (
                <ItemRow
                  fullMenuWidth={fullMenuWidth}
                  name="User Settings"
                  href="/settings"
                  isActive={asPath === "/settings"}
                />
              )}

              <Stack
                onClick={() => {
                  logout();
                  onClose();
                }}
                px="12px"
                py="0.6rem"
                direction="row"
                align="center"
                justify="space-between"
                spacing="1rem"
                borderRadius="16px"
                cursor="pointer"
                transition="all 0.3s"
                minW="180px"
                _hover={{
                  bg: "linear-gradient(95.74deg, #4754C6 27.06%, #2F3BA0 87.33%)",
                  p: {
                    background: "linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  },
                  __css: {
                    //@ts-ignore
                    path: {fill: "#A5FFB9"},
                  },
                  //@ts-ignore
                  path: {fill: "#A5FFB9"},
                }}
              >
                <Stack direction="row" align="center" spacing="16px">
                  <Text
                    noOfLines={1}
                    fontWeight="500"
                    letterSpacing="wider"
                    fontSize="15px"
                    color="#E9EAF8"
                  >
                    Log out
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default MenuBottomSection;
