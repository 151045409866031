import {useGetFeaturedEventPhotosSelector} from "@ef-org/hooks";
import {
  useRemoveEventImageEventsEventIdImagesImageIdDeleteMutation,
  useUploadEventImageEventsEventIdImagesPostMutation,
} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";

import React from "react";
import {useDropzone} from "react-dropzone";

import {useRouter} from "next/router";

import {Box, Button, Grid, Heading, Stack, Image, AspectRatio} from "@chakra-ui/react";

import {DescriptionStepDataType, useSubscribeToDescriptionStep} from "../newEventStore";

const Title: React.FC<{text?: string}> = ({text}) => (
  <Heading fontWeight="bold" fontSize="18px" pt="1.5rem" pb="0.5rem">
    {text}
  </Heading>
);

const EventAssets: React.FC = () => {
  const {query} = useRouter();
  const [assets, setAssets] =
    useSubscribeToDescriptionStep<DescriptionStepDataType["assets"]>("assets");
  const assetsPhotos = useGetFeaturedEventPhotosSelector();
  const [uploadPhoto] = useUploadEventImageEventsEventIdImagesPostMutation();
  const [removeImage] = useRemoveEventImageEventsEventIdImagesImageIdDeleteMutation();

  const {open, getRootProps, getInputProps} = useDropzone({
    maxSize: 12000000, // 1 megabyte = 1 000 000 bytes we need 3mb
    accept: {"image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "image/gif": [".gif"]},
    onDrop: async (acceptedFiles) => {
      setAssets((p) => ({
        ...p,
        value: [
          ...p.value,
          ...acceptedFiles.map((x) =>
            Object.assign(x, {
              preview: URL.createObjectURL(x),
            })
          ),
        ],
      }));

      if (acceptedFiles.length > 0) {
        for (let i = 0; i < acceptedFiles.length; i++) {
          const file = acceptedFiles[i];

          const form = new FormData();

          form.append("file", file);
          //@ts-ignore
          form.append("is_featured", false);

          await uploadPhoto({
            eventId: query?.eventId as string,
            //@ts-ignore
            bodyUploadEventImageEventsEventIdImagesPost: form,
          });
        }
      }
    },
  });

  return (
    <Box>
      <Stack
        direction={["column", "row", "row"]}
        align={["start", "center", "center"]}
        justify="space-between"
        mb="1rem"
      >
        <Title text="Add photos" />

        <Button
          w={["100%", "fit-content", "fit-content"]}
          size="md"
          variant="outline"
          onClick={open}
          leftIcon={
            <svg
              width="16px"
              height="17px"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.22222 8.77778H1.77784C1.77782 8.77778 1.7778 8.77778 1.77778 8.77778C1.70976 8.77775 1.64412 8.75278 1.5933 8.70758C1.54514 8.66476 1.51345 8.60661 1.50348 8.54316L1.50023 8.48886C1.50282 8.42485 1.52748 8.36356 1.5702 8.31552C1.61302 8.26736 1.67117 8.23568 1.73461 8.2257L1.79272 8.22222H7.22222H7.72222V7.72222V2.27784C7.72222 2.27782 7.72222 2.2778 7.72222 2.27778C7.72225 2.20976 7.74723 2.14412 7.79242 2.0933C7.83524 2.04514 7.8934 2.01345 7.95684 2.00348L8.01114 2.00023C8.07515 2.00282 8.13644 2.02748 8.18448 2.0702L8.5152 1.69828L8.18448 2.0702C8.23264 2.11302 8.26432 2.17117 8.2743 2.23461L8.27778 2.29272V7.72222V8.22222H8.77778H14.2222C14.2222 8.22222 14.2222 8.22222 14.2222 8.22222C14.2902 8.22225 14.3559 8.24723 14.4067 8.29242C14.4549 8.33524 14.4865 8.3934 14.4965 8.45683L14.4998 8.51114C14.4972 8.57515 14.4725 8.63644 14.4298 8.68448L14.8034 9.01673L14.4298 8.68448C14.387 8.73264 14.3288 8.76432 14.2654 8.7743L14.2073 8.77778H8.77778H8.27778V9.27778V14.7222C8.27778 14.7222 8.27778 14.7222 8.27778 14.7222C8.27775 14.7902 8.25278 14.8559 8.20758 14.9067L8.58122 15.239L8.20758 14.9067C8.16476 14.9549 8.1066 14.9865 8.04317 14.9965L7.98886 14.9998C7.92485 14.9972 7.86356 14.9725 7.81552 14.9298C7.76736 14.887 7.73568 14.8288 7.7257 14.7654L7.72222 14.7073V9.27778V8.77778H7.22222Z"
                fill="white"
                stroke="#4754C6"
              />
            </svg>
          }
        >
          Upload
        </Button>
      </Stack>

      <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} gap="1rem">
        {assetsPhotos.map((a) => (
          <AspectRatio key={a.id} ratio={1 / 1}>
            <Box w="100%" h="100%" _hover={{".rat-img-delete": {display: "block"}}}>
              <Image
                w="100%"
                h="100%"
                objectFit="cover"
                border="1px"
                borderColor="gray.50"
                fallback={
                  <Stack
                    w="100%"
                    h="100%"
                    bg="gray.50"
                    borderRadius="24px"
                    align="center"
                    justify="center"
                  >
                    <p>Unable to load image</p>
                  </Stack>
                }
                src={a.url}
                borderRadius="24px"
                alt="img-preview"
              />
              <Box
                className="rat-img-delete"
                display="none"
                position="absolute"
                top="1rem"
                right="1rem"
                cursor="pointer"
                bg="gray.400"
                border="1px"
                borderColor="gray.50"
                textAlign="center"
                borderRadius="12px"
                p="0.4rem"
                color="white"
                _hover={{bg: "gray.500"}}
                onClick={async () => {
                  await removeImage({imageId: a.id, eventId: query?.eventId as string});
                }}
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.875C13.733 1.875 15.1492 3.23145 15.2449 4.94058L15.25 5.125H20.5C20.9142 5.125 21.25 5.46079 21.25 5.875C21.25 6.2547 20.9678 6.56849 20.6018 6.61815L20.5 6.625H19.704L18.4239 19.6442C18.2912 20.9933 17.1984 22.035 15.8626 22.1195L15.6871 22.125H8.31293C6.95734 22.125 5.81365 21.1395 5.59883 19.8184L5.57614 19.6442L4.295 6.625H3.5C3.1203 6.625 2.80651 6.34285 2.75685 5.97677L2.75 5.875C2.75 5.4953 3.03215 5.18151 3.39823 5.13185L3.5 5.125H8.75C8.75 3.33007 10.2051 1.875 12 1.875ZM18.197 6.625H5.802L7.06893 19.4974C7.12768 20.0946 7.60033 20.5593 8.18585 20.6186L8.31293 20.625H15.6871C16.2872 20.625 16.7959 20.2001 16.9123 19.6232L16.9311 19.4974L18.197 6.625ZM13.75 9.375C14.1297 9.375 14.4435 9.65715 14.4932 10.0232L14.5 10.125V17.125C14.5 17.5392 14.1642 17.875 13.75 17.875C13.3703 17.875 13.0565 17.5928 13.0068 17.2268L13 17.125V10.125C13 9.71079 13.3358 9.375 13.75 9.375ZM10.25 9.375C10.6297 9.375 10.9435 9.65715 10.9932 10.0232L11 10.125V17.125C11 17.5392 10.6642 17.875 10.25 17.875C9.8703 17.875 9.55651 17.5928 9.50685 17.2268L9.5 17.125V10.125C9.5 9.71079 9.83579 9.375 10.25 9.375ZM12 3.375C11.0818 3.375 10.3288 4.08211 10.2558 4.98147L10.25 5.125H13.75C13.75 4.1585 12.9665 3.375 12 3.375Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Box>
          </AspectRatio>
        ))}
        {assets.value.map((a) => (
          <AspectRatio key={a.preview} ratio={1 / 1}>
            <Box w="100%" h="100%">
              <Image
                w="100%"
                h="100%"
                objectFit="cover"
                border="1px"
                borderColor="gray.100"
                src={a.preview}
                borderRadius="24px"
                alt="img-preview"
              />
              <Box
                position="absolute"
                top="1rem"
                right="1rem"
                cursor="pointer"
                bg="#FFFFFF"
                border="1px"
                borderColor="gray.50"
                textAlign="center"
                borderRadius="12px"
                p="0.4rem"
                color="black"
                _hover={{bg: "#F8F8F8"}}
              >
                Uploading
              </Box>
            </Box>
          </AspectRatio>
        ))}
        <AspectRatio ratio={1 / 1}>
          <Stack
            cursor="pointer"
            onClick={open}
            {...getRootProps({id: "PHOTOS_UPLOAD_SECTION"})}
            w="100%"
            h="100%"
            spacing={0}
            border="2px dashed"
            borderRadius="24px"
            borderColor="#f0f0f0"
            color="gray.400"
          >
            <input {...getInputProps()} />
            <p>Drag and drop</p>
            <p>your photos here</p>
          </Stack>
        </AspectRatio>
      </Grid>
    </Box>
  );
};

export default EventAssets;
