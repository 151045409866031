import * as React from "react";

import {IconProps} from "@chakra-ui/react";

const IQuestionMark: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9903 12.306C9.712 12.306 9.4736 12.0768 9.5167 11.8019C9.5381 11.666 9.5699 11.572 9.6123 11.4468C9.6276 11.4017 9.6442 11.3525 9.6622 11.2959C9.7773 10.9443 9.9403 10.6599 10.1512 10.4426C10.3622 10.2252 10.6163 10.0271 10.9135 9.84807C11.1052 9.72657 11.2778 9.59076 11.4312 9.44057C11.5847 9.29037 11.7061 9.11777 11.7956 8.92277C11.8851 8.72787 11.9298 8.51213 11.9298 8.27562C11.9298 7.99117 11.8627 7.74508 11.7285 7.53734C11.5942 7.3296 11.4153 7.1698 11.1915 7.05794C10.971 6.94288 10.7249 6.88535 10.4533 6.88535C10.2072 6.88535 9.9723 6.93649 9.7485 7.03876C9.5248 7.14103 9.3394 7.30083 9.1924 7.51816C9.1187 7.62556 9.0606 7.74863 9.0183 7.88737C8.9413 8.13924 8.7336 8.34753 8.47027 8.34753H8.00078C7.71702 8.34753 7.485 8.11005 7.53856 7.83139C7.60695 7.47558 7.73317 7.16184 7.91722 6.89014C8.18568 6.49064 8.5404 6.18862 8.9815 5.98407C9.4257 5.77953 9.9163 5.67725 10.4533 5.67725C11.0413 5.67725 11.5559 5.78752 11.9969 6.00804C12.438 6.22537 12.78 6.53059 13.0229 6.9237C13.269 7.31362 13.392 7.76905 13.392 8.29C13.392 8.64796 13.3361 8.97077 13.2242 9.25837C13.1124 9.54287 12.9526 9.79697 12.7448 10.0207C12.5403 10.2444 12.2942 10.4426 12.0065 10.6151C11.7349 10.7845 11.5143 10.9603 11.345 11.1425C11.1788 11.3247 11.0573 11.5404 10.9806 11.7897C10.9695 11.8258 10.959 11.8624 10.9491 11.8984C10.8852 12.1319 10.6798 12.306 10.4377 12.306H9.9903Z"
      fill="#727CD4"
    />
    <path
      d="M10.2088 15.7208C9.9467 15.7208 9.7214 15.6281 9.5328 15.4427C9.3442 15.2542 9.25 15.0273 9.25 14.762C9.25 14.4999 9.3442 14.2762 9.5328 14.0908C9.7214 13.9023 9.9467 13.808 10.2088 13.808C10.4676 13.808 10.6914 13.9023 10.8799 14.0908C11.0717 14.2762 11.1676 14.4999 11.1676 14.762C11.1676 14.9378 11.1228 15.0992 11.0333 15.2462C10.947 15.39 10.832 15.5051 10.6882 15.5913C10.5443 15.6776 10.3845 15.7208 10.2088 15.7208Z"
      fill="#727CD4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 10.6797C20.5 16.2025 16.0228 20.6797 10.5 20.6797C4.97715 20.6797 0.5 16.2025 0.5 10.6797C0.5 5.15684 4.97715 0.679688 10.5 0.679688C16.0228 0.679688 20.5 5.15684 20.5 10.6797ZM19 10.6797C19 15.3741 15.1944 19.1797 10.5 19.1797C5.80558 19.1797 2 15.3741 2 10.6797C2 5.98527 5.80558 2.17969 10.5 2.17969C15.1944 2.17969 19 5.98527 19 10.6797Z"
      fill="#727CD4"
    />
  </svg>
);

export default IQuestionMark;
