import {useDeleteIntegration} from "@ef-org/hooks";
import {useCreateIntegrationModal} from "apps/ef-org/src/modals/useCreateEmailIntegrationModal";

import {Button} from "@chakra-ui/react";

export const MailchimpContent: React.FC<{isAuthorized: boolean; id: string}> = ({
  isAuthorized,
  id,
}) => {
  const {openModal: openCreateIntegrationModal} = useCreateIntegrationModal();
  const deleteIntegration = useDeleteIntegration();

  return !isAuthorized ? (
    <Button
      w="100%"
      variant="outline"
      onClick={() => openCreateIntegrationModal({type: "mail_chimp"})}
    >
      Authorize
    </Button>
  ) : (
    <Button
      w="100%"
      variant="outline"
      borderColor={!isAuthorized ? undefined : "#D85639"}
      color={!isAuthorized ? undefined : "#D85639"}
      onClick={() => deleteIntegration(id)}
    >
      Unathorize
    </Button>
  );
};
