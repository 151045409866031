import {useGetUserUsersUserIdGetQuery} from "@ef-org/api";
import {Input, Link} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import {getCurrentUser, authenticateUser} from "apps/ef-org/src/helpers/aws/auth";
import Joi from "joi";
import Cookies from "js-cookie";

import {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Heading, Stack, useToast, Text} from "@chakra-ui/react";

const SectionLoginSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  password: Joi.string().min(6).required(),
});

type SignupTypes = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const toast = useToast();
  const {push, query} = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [userId, setUserId] = useState("");

  const {registerWithError, handleSubmit, setValue} = useForm<SignupTypes>(SectionLoginSchema, {
    keepDataOnSuccess: true,
    defaultValues: {email: "", password: ""},
  });

  useEffect(() => {
    if (query?.email) {
      setValue("email", query?.email?.toString() || "");
    }
  }, []);
  const handleFormSubmit: OnFormSubmitType<SignupTypes> = async (data) => {
    setLoading(true);
    const currentUser = getCurrentUser();

    if (currentUser) {
      currentUser.signOut();
    }

    try {
      const awsNewUserStatus = await authenticateUser(data.email, data.password);
      setIsFirstLogin(!awsNewUserStatus.userData["custom:last_login_datetime"]);
      setUserId(awsNewUserStatus.id);
    } catch (error) {
      toast({
        title: "Ops!",
        description: `${error}` || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }
  };

  const user = useGetUserUsersUserIdGetQuery({userId: userId}, {skip: !userId});

  useEffect(() => {
    if (!user.data) {
      return;
    }
    Cookies.set("organizerId", user.data.organizer?.id || user.data.team_owner_id, {expires: 12});
    setLoading(false);
    push(isFirstLogin ? "/questionaire" : "/dashboard");
  }, [user]);

  return (
    <Box w="100%">
      {query["showSuccess"] && (
        <Text
          bg="green.200"
          color="black"
          w="100%"
          textAlign="center"
          borderRadius="lg"
          p="10px"
          mb="5vh"
        >
          Your email is verified. Please login to access your account
        </Text>
      )}
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="2rem">
        Sign In
      </Heading>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            w="100%"
            label="Email*"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                  fill="#727CD4"
                />
              </svg>
            }
            labelColor="primary-2"
            placeholder="Enter your email"
            {...registerWithError("email")}
            noBorder
          />

          <Input
            w="100%"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2C12.2091 2 14 3.79086 14 6V8H15.75C16.9926 8 18 9.00736 18 10.25L18.0004 11C17.6815 11 17.3626 11.1096 17.1038 11.3286L16.9966 11.4293C16.8328 11.5998 16.6678 11.7519 16.5009 11.886L16.5 10.25C16.5 9.83579 16.1642 9.5 15.75 9.5H4.25C3.83579 9.5 3.5 9.83579 3.5 10.25V19.75C3.5 20.1642 3.83579 20.5 4.25 20.5L14.1384 20.5008C14.546 21.0774 15.0606 21.579 15.6783 22.0005L4.25 22C3.00736 22 2 20.9926 2 19.75V10.25C2 9.00736 3.00736 8 4.25 8H6V6C6 3.79086 7.79086 2 10 2ZM18.2837 12.1223C19.2758 13.1576 20.3751 13.6667 21.6 13.6667C21.7933 13.6667 21.9546 13.8095 21.9919 13.9994L22 14.0833V16.5844C22 19.2663 20.687 21.0896 18.1265 21.9786C18.0444 22.0071 17.9556 22.0071 17.8735 21.9786C15.3984 21.1192 14.0889 19.3868 14.0044 16.8497L14 16.5844V14.0833C14 13.8532 14.1791 13.6667 14.4 13.6667C15.6234 13.6667 16.7233 13.1575 17.7178 12.122C17.8741 11.9592 18.1276 11.9594 18.2837 12.1223ZM10.0001 13.5C10.8286 13.5 11.5001 14.1716 11.5001 15C11.5001 15.8284 10.8286 16.5 10.0001 16.5C9.1717 16.5 8.50012 15.8284 8.50012 15C8.50012 14.1716 9.1717 13.5 10.0001 13.5ZM10 3.5C8.61929 3.5 7.5 4.61929 7.5 6V8H12.5V6C12.5 4.61929 11.3807 3.5 10 3.5Z"
                  fill="#727CD4"
                />
              </svg>
            }
            type="password"
            label="Password*"
            labelColor="primary-2"
            placeholder="Enter your Password"
            {...registerWithError("password")}
            noBorder
          />

          <Box py="1rem">
            <Button type="submit" w="100%" isLoading={isLoading}>
              Sign In
            </Button>
          </Box>

          <Text color="black" w="100%" textAlign="center">
            <Link color="inherit" href="/reset-password" _hover={{textDecoration: "underline"}}>
              Forgot your password?
            </Link>
          </Text>
          <Text color="black" w="100%" textAlign="center">
            Don&apos;t have an account ?{" "}
            <Link color="inherit" _hover={{textDecoration: "underline"}} href="/register">
              Sign up.
            </Link>
          </Text>
        </Stack>
      </form>
    </Box>
  );
};

export default LoginForm;
