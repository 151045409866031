import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";
import Joi from "joi";
import Cookies from "js-cookie";

import React, {useState} from "react";

import {Box, Button, Heading, Stack, Textarea, useToast} from "@chakra-ui/react";

import {useSendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostMutation} from "../api/__generated__/beOrgApi.extended";
import {CommunityMessagePayload} from "../api/__generated__/beOrgApi.generated";
import Input from "../components/Input";
import useForm, {OnFormSubmitType} from "../hooks/useForm";

type Props = {
  userEmails: string[];
};

const FormSchema = Joi.object({
  subject: Joi.string().required(),
  message: Joi.string().required(),
});

type FormType = {
  subject: string;
  message: string;
};

const SendCommunityMessage: React.FC<Props> = ({userEmails}) => {
  const {closeModal} = useModal("SendCommunityMessageModal");

  const toast = useToast();
  const sendMessage = useSendCommunityMessage();

  const [isLoading, setLoading] = useState(false);

  const {registerWithError, handleSubmit} = useForm<FormType>(FormSchema, {
    keepDataOnSuccess: true,
    defaultValues: {subject: "", message: ""},
  });

  const handleFormSubmit: OnFormSubmitType<FormType> = async (data) => {
    setLoading(true);

    const res = await sendMessage({
      emails: userEmails,
      subject: data.subject,
      message: data.message,
    });

    if (!("data" in res)) {
      toast({
        title: "Ohh no!",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    toast({
      title: `Nice work! Your message was successfully sent to ${userEmails.length} ${
        userEmails.length > 1 ? "attendees" : "attendee"
      }`,
      status: "info",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
    setLoading(false);
    closeModal();
  };

  return (
    <Box p="1.5rem" w="100%">
      <Heading fontSize="large" w="100%" textAlign="center" as="h5" color="#141A4E" mb="2rem">
        Send a message to {userEmails.length} attendees
      </Heading>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            w="100%"
            label="Subject*"
            labelColor="primary-2"
            placeholder="Enter a subject for your message"
            {...registerWithError("subject")}
            noBorder
          />
          <Input
            as={Textarea}
            w="100%"
            minH="150px"
            label="Message*"
            labelColor="primary-2"
            placeholder="Enter a body for your message"
            {...registerWithError("message")}
            noBorder
          />

          <Box py="1rem">
            <Button type="submit" w="100%" isLoading={isLoading}>
              Send message
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export const useSendCommunityMessageModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("SendCommunityMessageModal", SendCommunityMessage, {
    closeOnEsc: true,
    closeOnOverlayClick: true,
    hideCloseButton: false,
    size: "lg",
  });

export const useSendCommunityMessage = () => {
  const [create] =
    useSendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostMutation();
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId") ?? "";

  return async (payload: CommunityMessagePayload) => {
    return await create({
      organizerId,
      userId,
      communityMessagePayload: payload,
    });
  };
};
