import {CopyIcon} from "@chakra-ui/icons";
import {useModal, useToast, Stack, IconButton, Text, Box, Heading} from "@chakra-ui/react";

import Link from "../components/Link";
import {InitialUseCreateModalType, useCreateModal} from "./ModalsProvider";

type Props = {
  organizerId: string;
  apiKey: string;
};

const ZapierIntegrationModal: React.FC<Props> = ({organizerId, apiKey}) => {
  const toast = useToast();

  return (
    <Box p="1.5rem" w="100%">
      <Heading fontSize="large" w="100%" textAlign="center" as="h5" color="#141A4E" mb="2rem">
        Configure your Zapier integration
      </Heading>

      <Stack display="flex" gap="1rem">
        <Link href="https://zapier.com/developer/public-invite/196600/bf46c7993e967a364674b33c73e398d3/">
          Click here to access our zapier integration
        </Link>
        <Stack direction="row" align="center">
          <Text>Copy your API Key (required for authentication):</Text>
          <IconButton
            variant={"link"}
            width="40px"
            height="40px"
            bg="white"
            icon={<CopyIcon />}
            aria-label="copy"
            onClick={() => {
              toast({description: "Copied", colorScheme: "green"});
              navigator.clipboard.writeText(apiKey);
            }}
          />
        </Stack>
        <Stack direction="row" align="center">
          <Text>Copy your ID (required for authentication):</Text>
          <IconButton
            variant={"link"}
            width="40px"
            height="40px"
            bg="white"
            icon={<CopyIcon />}
            aria-label="copy"
            onClick={() => {
              toast({description: "Copied", colorScheme: "green"});
              navigator.clipboard.writeText(organizerId);
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export const useZapierIntegrationModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("useZapierIntegrationModal", ZapierIntegrationModal);
