import * as echarts from "echarts";

import React, {useEffect, useRef, useState} from "react";

interface LineChartProps {
  data: number[];
  legend: string[];
  fillColor: string;
  lineColor: string;
}

const LineChart: React.FC<LineChartProps> = ({data, fillColor, lineColor, legend}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [chart, setChart] = useState<echarts.ECharts | null>(null);
  useEffect(() => {
    if (chartRef.current && !chart) {
      const dchart = echarts.init(chartRef.current);
      setChart(dchart);
      const options: echarts.EChartsOption = {
        color: [lineColor],
        tooltip: {
          backgroundColor: lineColor,
          trigger: "axis",
          type: "none",
          textStyle: {
            color: "white",
          },
          axisPointer: {
            type: "none",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: legend,
            axisLine: {
              lineStyle: {
                color: lineColor,
              },
              show: false,
            },
            axisTick: {
              show: false, // Hide the x-axis tick marks
            },
            axisLabel: {
              show: true, // Show the x-axis labels
            },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              show: false,
            },
            type: "value",
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 1,
              color: lineColor,
            },
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: fillColor,
                },
                {
                  offset: 1,
                  color: "white",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: data,
          },
        ],
      };

      dchart.setOption(options);
      const resizeChart = function () {
        dchart.resize({width: "auto", height: "auto"});
      };
      resizeChart();
      return () => {
        resizeChart();
        window.addEventListener("resize", resizeChart);
      };
    }
  }, [data]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        maxWidth: "385px",
        maxHeight: "200px",
        minWidth: "160px",
        minHeight: "130px",
        flexShrink: 2,
      }}
    />
  );
};

export default LineChart;
