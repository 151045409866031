import {OrganizerReadPaymentMethods} from "@ef-org/api";
import {Input} from "@ef-org/components";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";

import React from "react";

import {Box, Button, Heading, Stack, Text} from "@chakra-ui/react";

import {supportedPaymentOptionsMap} from "../scenes/Finance/FinanceSubscription";

type Props = {
  method: string;
  details: OrganizerReadPaymentMethods;
};

const getLabel = (method: string) => {
  switch (method) {
    case "stripe":
      return "Client secret";
    case "wire_transfer":
      return "IBAN";
    case "ryft":
      return "Email";
  }
};

const getValue = (method: string, detail: OrganizerReadPaymentMethods) => {
  switch (method) {
    case "stripe":
      return detail.stripe.client_secret;
    case "wire_transfer":
      return detail.wire_transfer.bank_account_number;
    case "ryft":
      return detail.ryft.email;
  }
};

const PaymentOptionDetailModal: React.FC<Props> = ({method, details}) => {
  const {closeModal, closeAllModals} = useModal("showPaymentOptionDetail");

  return (
    <Box p="1.5rem" height="auto">
      <Heading fontWeight="600" fontSize="20px" color="#1F2FBA">
        {supportedPaymentOptionsMap[method]} details
      </Heading>
      <button style={{marginTop: "10px"}} onClick={closeModal}></button>
      <Stack direction="row" flex="0 0 auto" spacing="2rem" height="100%">
        <Input
          isDisabled={true}
          sx={{color: "#C2C3D3"}}
          leftContent={
            method === "wire_transfer" ? (
              <svg width="26" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.227 2.199H4.828a.99.99 0 0 0-.994.994v.773h-1.73C1.515 3.966 1 4.444 1 5.07v13.95c0 .59.479 1.104 1.104 1.104h21.792c.589 0 1.104-.478 1.104-1.104V5.07c0-.59-.479-1.104-1.104-1.104h-1.73v-.773a.99.99 0 0 0-.994-.994h-1.4a.99.99 0 0 0-.993.994v.773h-2.024V2.75c0-1.141-.92-2.062-2.062-2.062h-3.35c-1.14 0-2.06.92-2.06 2.062l-.001 1.215H7.258v-.773c-.037-.553-.48-.994-1.031-.994zm-1.656.994c0-.148.11-.258.257-.258h1.4c.146 0 .257.11.257.258v.773H4.57v-.773zM1.736 19.02V5.07a.37.37 0 0 1 .368-.369H5.16V19.39H2.104a.369.369 0 0 1-.368-.368zM23.896 4.7a.37.37 0 0 1 .368.369V19.02a.369.369 0 0 1-.368.368H20.84V4.702h3.056zm-4.38-1.508c0-.148.11-.258.257-.258h1.399c.147 0 .258.11.258.258v.773h-1.915v-.773zM9.98 2.75c0-.736.59-1.325 1.326-1.325h3.35a1.32 1.32 0 0 1 1.325 1.325v1.215h-6V2.75zM20.104 4.7v14.69H5.896V4.702h14.208z"
                  fill="#727CD4"
                  stroke="#727CD4"
                  strokeWidth=".2"
                />
                <path
                  d="M13.257 14.789a1.32 1.32 0 0 1-1.325-1.326c0-.22-.147-.368-.368-.368-.22 0-.368.147-.368.368 0 .957.7 1.804 1.583 1.988v.92c0 .221.147.369.368.369.22 0 .368-.148.368-.369v-.883c.994-.147 1.767-.994 1.767-2.025 0-1.14-.92-2.06-2.061-2.06a1.32 1.32 0 0 1-1.325-1.326c0-.7.588-1.288 1.288-1.288h.074c.699 0 1.288.589 1.288 1.288 0 .22.147.368.368.368.22 0 .368-.147.368-.368 0-.994-.736-1.84-1.693-2.025V7.17c0-.22-.147-.368-.368-.368s-.369.147-.369.368v.883c-.957.184-1.693.994-1.693 2.025 0 1.141.92 2.061 2.061 2.061.737 0 1.326.59 1.326 1.325 0 .737-.59 1.326-1.289 1.326z"
                  fill="#727CD4"
                  stroke="#727CD4"
                  strokeWidth=".2"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  d="M13.7509 12.177C14.9929 12.177 15.9998 13.1838 15.9998 14.4259V15.0013C15.9998 15.8955 15.6802 16.7604 15.0987 17.4398C13.5293 19.2733 11.1421 20.178 7.99668 20.178C4.85084 20.178 2.46483 19.2729 0.898524 17.4387C0.318764 16.7598 0.000244141 15.8963 0.000244141 15.0035V14.4259C0.000244141 13.1838 1.0071 12.177 2.24912 12.177H13.7509ZM13.7509 13.677H2.24912C1.83553 13.677 1.50024 14.0123 1.50024 14.4259V15.0035C1.50024 15.5392 1.69135 16.0572 2.03921 16.4646C3.29252 17.9322 5.2584 18.678 7.99668 18.678C10.735 18.678 12.7026 17.9322 13.9591 16.4643C14.308 16.0567 14.4998 15.5378 14.4998 15.0013V14.4259C14.4998 14.0123 14.1645 13.677 13.7509 13.677ZM7.99668 0.181641C10.7581 0.181641 12.9967 2.42022 12.9967 5.18164C12.9967 7.94307 10.7581 10.1817 7.99668 10.1817C5.23528 10.1817 2.9967 7.94307 2.9967 5.18164C2.9967 2.42022 5.23528 0.181641 7.99668 0.181641ZM7.99668 1.68164C6.06368 1.68164 4.4967 3.24865 4.4967 5.18164C4.4967 7.11464 6.06368 8.68165 7.99668 8.68165C9.92968 8.68165 11.4967 7.11464 11.4967 5.18164C11.4967 3.24865 9.92968 1.68164 7.99668 1.68164Z"
                  fill="#727CD4"
                />
              </svg>
            )
          }
          maxW="100%"
          label={<Text color="#727CD4">{getLabel(method)}</Text>}
          noBorder
          value={getValue(method, details)}
        />
      </Stack>
      <Stack display="flex" alignItems="end" pt="15px">
        <Button fontSize="14px" w="135px" maxWidth="335px" onClick={closeAllModals}>
          OK
        </Button>
      </Stack>
    </Box>
  );
};

export const useShowPaymentOptionDetail: InitialUseCreateModalType<Props> = () =>
  useCreateModal("paymentOptionDetail", PaymentOptionDetailModal, {size: "sm"});
