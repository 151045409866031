import {Link} from "@ef-org/components";

import React from "react";

import {useRouter} from "next/router";

import {Button, Stack} from "@chakra-ui/react";

const TabHandler: React.FC = () => {
  const {asPath} = useRouter();

  return (
    <Stack
      direction="row"
      align="center"
      justify="space-between"
      mb="2rem"
      overflowX="auto"
      p="3px"
    >
      <Stack direction="row" align="center">
        <Link href="/finance/subscription">
          <Button
            fontWeight="500"
            variant="neutral"
            bg={asPath.includes("subscription") ? undefined : "transparent"}
          >
            Subscription & payments
          </Button>
        </Link>

        <Link href="/finance/invoices">
          <Button
            fontWeight="500"
            variant="neutral"
            bg={asPath.includes("invoices") ? undefined : "transparent"}
          >
            Invoices
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default TabHandler;
