import {Input} from "@ef-org/components";

import React from "react";
import {useFormContext, UseFormRegister} from "react-hook-form";

import {Box, Heading, Stack, Text} from "@chakra-ui/react";

import {OrganizerFormData} from "../SettingsOrganiser";

export interface OrganiserInformationProps {
  registerWithError: UseFormRegister<OrganizerFormData>;
}

const OrganiserInformation: React.FC<OrganiserInformationProps> = ({registerWithError}) => {
  return (
    <Box>
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Organiser Information
      </Heading>

      <Stack
        direction={["column", "row", "row"]}
        spacing="1rem"
        bg="white"
        borderRadius="22px"
        p="1rem"
        w="100%"
      >
        <Stack spacing="1rem" w="100%">
          <Input
            maxW="100%"
            label={<Text color="#727CD4">Your organization name</Text>}
            placeholder="Organization name"
            {...registerWithError("name")}
            noBorder
          />

          <Input
            leftContent={
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 4.40771H18.75C20.483 4.40771 21.8992 5.76416 21.9949 7.47329L22 7.65771V17.1577C22 18.8907 20.6435 20.3069 18.9344 20.4026L18.75 20.4077H5.25C3.51697 20.4077 2.10075 19.0512 2.00514 17.3421L2 17.1577V7.65771C2 5.92468 3.35645 4.50846 5.06558 4.41285L5.25 4.40771ZM20.5 9.78071L12.3493 14.0714C12.1619 14.17 11.9431 14.1841 11.7468 14.1137L11.6507 14.0714L3.5 9.78171V17.1577C3.5 18.0759 4.20711 18.8289 5.10647 18.9019L5.25 18.9077H18.75C19.6682 18.9077 20.4212 18.2006 20.4942 17.3012L20.5 17.1577V9.78071ZM18.75 5.90771H5.25C4.33183 5.90771 3.57881 6.61483 3.5058 7.51418L3.5 7.65771V8.08671L12 12.5602L20.5 8.08571V7.65771C20.5 6.73954 19.7929 5.98652 18.8935 5.91351L18.75 5.90771Z"
                  fill="#727CD4"
                />
              </svg>
            }
            maxW="100%"
            label={<Text color="#727CD4">Event support email</Text>}
            placeholder="Support email"
            {...registerWithError("support_email")}
            noBorder
          />
        </Stack>

        <Input
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7506 14.177C18.9926 14.177 19.9995 15.1838 19.9995 16.4259V17.0013C19.9995 17.8955 19.6799 18.7604 19.0984 19.4398C17.529 21.2733 15.1418 22.178 11.9964 22.178C8.8506 22.178 6.46459 21.2729 4.89828 19.4387C4.31852 18.7598 4 17.8963 4 17.0035V16.4259C4 15.1838 5.00686 14.177 6.24888 14.177H17.7506ZM17.7506 15.677H6.24888C5.83529 15.677 5.5 16.0123 5.5 16.4259V17.0035C5.5 17.5392 5.69111 18.0572 6.03897 18.4646C7.29228 19.9322 9.25816 20.678 11.9964 20.678C14.7347 20.678 16.7023 19.9322 17.9588 18.4643C18.3077 18.0567 18.4995 17.5378 18.4995 17.0013V16.4259C18.4995 16.0123 18.1642 15.677 17.7506 15.677ZM11.9964 2.18164C14.7578 2.18164 16.9964 4.42022 16.9964 7.18164C16.9964 9.94307 14.7578 12.1817 11.9964 12.1817C9.23504 12.1817 6.99646 9.94307 6.99646 7.18164C6.99646 4.42022 9.23504 2.18164 11.9964 2.18164ZM11.9964 3.68164C10.0634 3.68164 8.49646 5.24865 8.49646 7.18164C8.49646 9.11464 10.0634 10.6817 11.9964 10.6817C13.9294 10.6817 15.4964 9.11464 15.4964 7.18164C15.4964 5.24865 13.9294 3.68164 11.9964 3.68164Z"
                fill="#727CD4"
              />
            </svg>
          }
          maxW="100%"
          label={<Text color="#727CD4">Organization description</Text>}
          placeholder="Description of your organization"
          {...registerWithError("bio")}
          noBorder
        />
      </Stack>
    </Box>
  );
};

export default OrganiserInformation;
