import {
  CustomAttendeeFieldConfigCreate,
  DiscountType as DiscountTypeEnum,
  LocationCreate,
  TicketState,
  TicketTypeStatus,
} from "@ef-org/api";
import {parse} from "date-fns";
import Joi from "joi";
import {mergeDeepRight} from "ramda";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

import {Dispatch} from "react";

export enum StepVariant {
  "INIT" = "INIT",
  "BASIC" = "BASIC",
  "DESCRIPTION" = "DESCRIPTION",
  "TICKETS" = "TICKETS",
  "MERCH" = "MERCH",
  "SETTINGS" = "SETTINGS",
  "DONE" = "DONE",
}

type StepStructure<T = unknown> = {
  name: string;
  hidden?: boolean;
  data: T;
  validator: (props: {stepValues: T; store: StoreType}) => boolean;
  onSuccess?: (props: {
    stepValues: T;
    store: StoreType;
    dispatch?: Dispatch<any>;
  }) => void | Promise<void>;
  onError?: (props: {
    stepValues: T;
    store: StoreType;
    dispatch?: Dispatch<any>;
  }) => void | Promise<void>;
};

export type FileWithPreview = File & {preview: string};

export type ValueType<T> = {value: T; error?: any};

export type BasicStepDataType = {
  eventImage: ValueType<null | FileWithPreview>;
  eventName: ValueType<string>;
  eventType: ValueType<"In-person" | "Online event" | "Hybrid">;
  eventLocation: ValueType<undefined | LocationCreate>;
  eventLink: ValueType<string>;
  use_24_hour_time_format: ValueType<boolean>;
  eventDurationStart: ValueType<string>;
  eventDurationEnd: ValueType<string>;
  eventDurationStartTime: ValueType<string>;
  eventDurationEndTime: ValueType<string>;
  eventRegistrationStart: ValueType<string>;
  eventRegistrationStartTime: ValueType<string>;
  eventRegistrationEnd: ValueType<string>;
  eventRegistrationEndTime: ValueType<string>;
  eventCurrency: ValueType<string>;
  timeZone: ValueType<string>;
};

export const BASIC_STEP_SCHEMA = Joi.object({
  eventCurrency: Joi.object({
    value: Joi.string().required().label("Currency"),
    error: Joi.string(),
  }),
  eventName: Joi.object({value: Joi.string().min(3).required().label("Name"), error: Joi.string()}),
  eventLocation: Joi.alternatives().conditional("eventType", {
    is: Joi.object({value: Joi.any().valid("In-person", "Hybrid")}).unknown(true),
    then: Joi.object({value: Joi.object().required().label("Location")})
      .label("Location")
      .unknown(true),
    otherwise: Joi.any(),
  }),
  eventLink: Joi.alternatives().conditional("eventType", {
    is: Joi.object({value: Joi.any().valid("Online event", "Hybrid")}).unknown(true),
    then: Joi.object({value: Joi.string().uri().required().label("Link"), error: Joi.string()}),
    otherwise: Joi.any(),
  }),
  eventDurationStart: Joi.object({
    value: Joi.string().required().label("Start"),
    error: Joi.string(),
  }),
  eventDurationStartTime: Joi.object({
    value: Joi.string().required().label("Start time"),
    error: Joi.string(),
  }),
  eventDurationEnd: Joi.object({
    value: Joi.string()
      .required()
      .required()
      .custom((value, helper) => {
        const endDate = parse(
          `${value} ${helper.state.ancestors[1].eventDurationEndTime.value || "00:00"}`,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
        const startDate = parse(
          `${helper.state.ancestors[1].eventDurationStart.value} ${
            helper.state.ancestors[1].eventDurationStartTime.value || "00:00"
          }`,
          "yyyy-MM-dd HH:mm",
          new Date()
        );
        if (endDate < startDate) {
          return helper.message({messages: "custom"});
        }
      })
      .label("End")
      .messages({custom: "End must be after Start"}),
    error: Joi.string(),
  }),
  eventDurationEndTime: Joi.object({
    value: Joi.string().required().label("End time"),
    error: Joi.string(),
  }),
  eventRegistrationStart: Joi.object({
    value: Joi.string().required().label("Start"),
    error: Joi.string(),
  }),
}).unknown(true); //todo: this is here just for now till we validate all fields

const DEFAULT_BASIC_STEP: BasicStepDataType = {
  eventImage: {value: null},
  eventName: {value: ""},
  eventType: {value: "In-person"},
  timeZone: {value: ""},
  eventCurrency: {value: undefined},
  eventLink: {value: ""},
  use_24_hour_time_format: {value: true},
  eventLocation: {value: undefined},
  eventDurationStart: {value: ""},
  eventDurationStartTime: {value: ""},
  eventDurationEnd: {value: ""},
  eventDurationEndTime: {value: ""},
  eventRegistrationStart: {value: ""},
  eventRegistrationStartTime: {value: ""},
  eventRegistrationEnd: {value: ""},
  eventRegistrationEndTime: {value: ""},
};

export type DescriptionStepDataType = {
  descriptionHTML: ValueType<string>;
  assets: ValueType<Array<FileWithPreview>>;
};

const DEFAULT_DESCRIPTION_STEP: DescriptionStepDataType = {
  descriptionHTML: {value: null},
  assets: {value: []},
};

export type TicketType = {
  _id?: string;
  ticketName: string;
  isPaidTicket: boolean;
  price: number;
  currency: string;
  ticketAmount: number;
  minimumPerOrder: number;
  maximumPerOrder: number;
  ticketAmountUnlimited: boolean;
  smartTicket: boolean;
  description: string;
  state: TicketTypeStatus;
  isDescriptionVisible: boolean;
};

export type MerchType = {
  _id?: string;
  name: string;
  isPaid: boolean;
  price: number;
  amount: number;
  minimumPerOrder: number;
  maximumPerOrder: number;
  unlimitedAmount: boolean;
  isDescriptionVisible: boolean;
  description: string;
};

export type DiscountType = {
  _id?: string;
  name: string;
  type: DiscountTypeEnum;
  startDate: string;
  ticketTypeId: string;
  volume: number;
  startTime: string;
  endDate: string;
  endTime: string;
  amount: number;
  required: boolean;
  isExclusive: boolean;
  couponCode?: string;
};

export type TicketsStepDataType = {
  isActiveSubscription: ValueType<boolean>;
  addingNewTicket?: ValueType<boolean>;
  editingTicket?: ValueType<TicketType>;
  tickets: ValueType<Array<TicketType>>;
  addingNewDiscount?: ValueType<boolean>;
  editingDiscountId?: ValueType<string>;
  currency?: ValueType<string>;
  discounts: ValueType<Array<DiscountType>>;
  payment_options: {
    methods: {
      offlinePayment: {
        isConfigured: boolean;
        _name: "Wire Transfer";
        isActive: boolean;
      };
      ryft: {
        isConfigured: boolean;
        _name: "Ryft";
        clientId?: string;
        clientSecret?: string;
        isActive: boolean;
      };
      stripe: {
        isConfigured: boolean;
        _name: "Stripe";
        clientId?: string;
        clientSecret?: string;
        isActive: boolean;
      };
    };
    vat: {isEnabled: boolean; value: number};
    collectBillingDetails: boolean;
    allowCancelOrder: boolean;
  };
};

export type MerchStepDataType = {
  addingNewMerch?: ValueType<boolean>;
  editingMerchId?: ValueType<string>;
  merch: ValueType<Array<MerchType>>;
};

export type SettingsStepDataType = {
  // merch: ValueType<Array<MerchType>>;
  customAttendeeFields: ValueType<Array<CustomAttendeeFieldConfigCreate>>;
  addingNewAttendeeField: boolean;
  emailNote: ValueType<string>;
  termsLink: ValueType<string>;
};

const DEFAULT_TICKETS_STEP: TicketsStepDataType = {
  isActiveSubscription: {value: false},
  addingNewTicket: {value: true},
  tickets: {
    value: [],
  },
  discounts: {value: []},
  editingTicket: {value: undefined},
  payment_options: {
    allowCancelOrder: false,
    collectBillingDetails: true,
    vat: {isEnabled: false, value: 0},
    methods: {
      offlinePayment: {isConfigured: false, isActive: false, _name: "Wire Transfer"},
      ryft: {
        isConfigured: false,
        _name: "Ryft",
        clientId: undefined,
        clientSecret: undefined,
        isActive: false,
      },
      stripe: {
        isConfigured: false,
        _name: "Stripe",
        clientId: undefined,
        clientSecret: undefined,
        isActive: false,
      },
    },
  },
};

const DEFAULT_MERCH_STEP: MerchStepDataType = {
  addingNewMerch: {value: true},
  editingMerchId: {value: undefined},
  merch: {
    value: [],
  },
};

export type StoreType = {
  errorMessage?: string;
  currentStep: StepVariant;
  allowedStepsBack: Array<StepVariant>;
  data: {
    [StepVariant.BASIC]: StepStructure<BasicStepDataType>;
    [StepVariant.DESCRIPTION]: StepStructure<DescriptionStepDataType>;
    [StepVariant.TICKETS]: StepStructure<TicketsStepDataType>;
    // [StepVariant.MERCH]: StepStructure<MerchStepDataType>;
    [StepVariant.SETTINGS]: StepStructure<SettingsStepDataType>;
    [StepVariant.DONE]: StepStructure;
  };
  actions: {
    setNewStep: (step: StepVariant) => void;
    goToStep: (step: StepVariant) => void;
    goBack: () => void;
    goNext: (props: {dispatch?: Dispatch<any>}) => void;
    setValue: <T = unknown>(step: `${StepVariant}`, key: keyof T, newValue: T[keyof T]) => void;
  };
};

const preparePatchPayload = (data: Partial<any>): any => {
  //@ts-ignore
  return data;
};

const useNewEventStore = create(
  subscribeWithSelector<StoreType>((set) => ({
    errorMessage: undefined,
    currentStep: StepVariant.BASIC,
    allowedStepsBack: [StepVariant.BASIC],
    data: {
      [StepVariant.BASIC]: {
        name: "Basic",
        validator: (param) => {
          if (!param) {
            return true;
          }
          const {stepValues, store} = param;
          const res = BASIC_STEP_SCHEMA.validate(stepValues, {abortEarly: false});
          Object.keys(stepValues).forEach((key) => {
            const valWithError = {
              ...stepValues[key],
              error: res.error?.details.find((ed) => ed.path[0] === key)?.message,
            };
            set((store) =>
              mergeDeepRight(store, {
                data: {[StepVariant.BASIC]: {data: {[key]: valWithError}}},
              })
            );
          });
          return !res.error;
        },
        data: DEFAULT_BASIC_STEP,
      },
      [StepVariant.DESCRIPTION]: {
        name: "Description",
        validator: () => true,
        data: DEFAULT_DESCRIPTION_STEP,
      },
      [StepVariant.TICKETS]: {
        name: "Tickets",
        validator: (param) => {
          const {stepValues, store} = param;
          const paidTicket = stepValues.tickets.value.some((ticket) => ticket.isPaidTicket);
          const paymentSelected = Object.values(stepValues.payment_options.methods).some(
            (method) => method.isActive
          );
          return !paidTicket || paymentSelected;
        },
        data: DEFAULT_TICKETS_STEP,
      },
      // [StepVariant.MERCH]: {
      //   name: "Merch",
      //   validator: () => true,
      //   data: DEFAULT_MERCH_STEP,
      // },
      [StepVariant.SETTINGS]: {
        name: "Settings",
        validator: () => true,
        data: {
          customAttendeeFields: {value: []},
          addingNewAttendeeField: false,
          emailNote: {value: ""},
          termsLink: {value: ""},
        },
      },
      [StepVariant.DONE]: {
        name: "Done",
        hidden: true,
        validator: () => true,
        data: {},
      },
    },
    actions: {
      setNewStep: (newStep) => set(() => ({currentStep: newStep})),
      goBack: () =>
        set((state) => {
          const i = state.allowedStepsBack.findIndex((x) => x === state.currentStep);
          return {currentStep: i <= 0 ? StepVariant.BASIC : state.allowedStepsBack[i - 1]};
        }),
      goToStep: (newStep) => {
        set((state) => {
          const allSteps = Object.keys(state.data) as Array<StepVariant>;
          const currentIndex = allSteps.findIndex((x) => x === newStep);
          const nextStep = allSteps[currentIndex];
          if (nextStep && allSteps.includes(nextStep)) {
            const stepState = {
              currentStep: nextStep,
              allowedStepsBack: allSteps.slice(0, currentIndex + 1),
            };
            return stepState;
          }
        });
      },
      goNext: ({dispatch}) => {
        set((state) => {
          const currentStep = state.data[state.currentStep] as StepStructure;

          const isValid = currentStep.validator({stepValues: currentStep.data, store: state});

          if (isValid) {
            currentStep?.onSuccess &&
              currentStep?.onSuccess({stepValues: currentStep.data, store: state, dispatch});
            const allSteps = Object.keys(state.data) as Array<StepVariant>;

            const currentIndex = allSteps.findIndex((x) => x === state.currentStep);
            const nextStep = allSteps[currentIndex + 1];

            if (nextStep && allSteps.includes(nextStep)) {
              state.actions.goToStep(nextStep);
            }
          } else {
            currentStep?.onError &&
              currentStep?.onError({stepValues: currentStep.data, store: state, dispatch});
          }
          return {};
        });
      },
      setValue(step, key, newValue) {
        //@ts-ignore
        set((store) =>
          mergeDeepRight(store, {
            data: {[step]: {data: {[key]: {...store.data[step]["data"][key], ...newValue}}}},
          })
        );
      },
    },
  }))
);

export const useSubscribeToKey = <T, K>(
  step: `${StepVariant}`,
  key: keyof T
): [K, (newValue: K | ((prev: K) => K)) => void] => {
  const prop = useNewEventStore((p) => p.data[step]["data"][key]) as K;
  const setValue = useNewEventStore((p) => p.actions.setValue);

  return [
    prop,
    (newValue) => {
      if (typeof newValue === "function") {
        //@ts-ignore
        setValue<K>(step, key, newValue(prop));
      } else {
        //@ts-ignore
        setValue<K>(step, key, newValue);
      }
    },
  ];
};

export const useSubscribeToBasicStep = <T>(step: keyof BasicStepDataType) => {
  return useSubscribeToKey<BasicStepDataType, T>(StepVariant.BASIC, step);
};

export const useSubscribeToDescriptionStep = <T>(step: keyof DescriptionStepDataType) => {
  return useSubscribeToKey<DescriptionStepDataType, T>(StepVariant.DESCRIPTION, step);
};

export const useSubscribeToTicketsStep = <T>(step: keyof TicketsStepDataType) => {
  return useSubscribeToKey<TicketsStepDataType, T>(StepVariant.TICKETS, step);
};

export const useSubscribeToSettingsStep = <T>(step: keyof SettingsStepDataType) => {
  return useSubscribeToKey<SettingsStepDataType, T>(StepVariant.SETTINGS, step);
};

export const useSubscribeToMerchStep = <T>(step: keyof MerchStepDataType) => {
  return useSubscribeToKey<MerchStepDataType, T>(StepVariant.MERCH, step);
};

export const useEventLogger = () => {
  const p = useNewEventStore((p) => p);
  // eslint-disable-next-line no-console
  // console.log("  NEW EVENT STORE", p.data.TICKETS.data);
};

const useNewEventStepSubscriber = () => {
  const steps = useNewEventStore((p) =>
    Object.entries(p.data)
      .filter(
        ([stepName, stepData]) =>
          (stepName as unknown as StepVariant) !== StepVariant.INIT && !stepData.hidden
      )
      .map(([stepName, stepData]) => ({
        id: stepName as unknown as StepVariant,
        //@ts-ignore
        name: stepData.name,
      }))
  );

  const allowedStepsBack = useNewEventStore((p) => p.allowedStepsBack);
  const currentStep = useNewEventStore((p) => p.currentStep);

  return {
    steps,
    currentStep,
    allowedStepsBack,
    currentIndex: allowedStepsBack.findIndex((x) => x === currentStep),
  };
};

export {useNewEventStore, useNewEventStepSubscriber};
