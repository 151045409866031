import {useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery} from "@ef-org/api";
import {useUpdateOrganiser} from "@ef-org/hooks";
import IQuestionMark from "apps/ef-org/src/components/Icons/IQuestionMatk";
import IconTrash from "apps/ef-org/src/components/Icons/Trash";
import {useAddPaymentOptionModal} from "apps/ef-org/src/modals/useAddPaymentOptionModal";
import {useShowPaymentOptionDetail} from "apps/ef-org/src/modals/useShowPaymentOptionDetail";
import Cookies from "js-cookie";

import React, {FC, useCallback, useEffect} from "react";

import {useRouter} from "next/router";

import {
  Box,
  Button,
  Heading,
  Stack,
  Switch,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";

import {supportedPaymentOptionsMap} from "../FinanceSubscription";

const PaymentOverview: FC = () => {
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId");
  const {query} = useRouter();
  const isMobile = useBreakpointValue([true, true, false]);

  const {openModal} = useAddPaymentOptionModal();
  const {openModal: openInfoModal} = useShowPaymentOptionDetail();

  const {data: organiserInfo} = useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery({
    organizerId,
    userId,
  });

  const updateOrganiser = useUpdateOrganiser();

  const removeMethod = useCallback((method) => {
    void updateOrganiser({
      payment_methods: {
        [method]:
          method === "wire_transfer"
            ? {is_active: false}
            : {is_active: false, client_id: "", client_secret: ""},
      },
    });
  }, []);

  const onFeesChange = useCallback(() => {
    void updateOrganiser({
      pass_on_fees: !organiserInfo?.pass_on_fees,
    });
  }, [organiserInfo]);

  const openDetails = useCallback(
    (method: string) => {
      openInfoModal({details: organiserInfo.payment_methods, method});
    },
    [organiserInfo]
  );

  useEffect(() => {
    if (query?.addModal === "ryft") {
      openModal();
    }
  }, [query]);

  return (
    <Box pt="2rem">
      <Stack flexDirection="row" justifyContent="space-between" paddingBottom="16px">
        <Heading
          marginBottom="0"
          lineHeight="2"
          fontWeight="600"
          fontSize={isMobile ? "16px" : "20px"}
          color="#1F2FBA"
        >
          Connected payment options
        </Heading>
        <Button
          fontSize="14px"
          height="40px"
          onClick={() => openModal()}
          leftIcon={
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M0.66788 10.2721C0.579335 10.1933 0.521727 10.0858 0.505101 9.96876L0.500156 9.88609C0.503099 9.76807 0.547731 9.65474 0.626358 9.56632C0.705095 9.47777 0.812624 9.42016 0.929681 9.40354L1.01494 9.39844H8H8.5V8.89844V1.8985C8.5 1.89848 8.5 1.89846 8.5 1.89844C8.50003 1.77599 8.54499 1.65782 8.62636 1.56632C8.70509 1.47777 8.81262 1.42016 8.92968 1.40354L9.01234 1.39859C9.13037 1.40154 9.2437 1.44617 9.33212 1.5248C9.42067 1.60353 9.47827 1.71106 9.4949 1.82812L9.5 1.91338V8.89844V9.39844H10H16.9999C17 9.39844 17 9.39844 17 9.39844C17.1224 9.39847 17.2406 9.44343 17.3321 9.5248C17.4207 9.60353 17.4783 9.71106 17.4949 9.82811L17.4998 9.91078C17.4969 10.0288 17.4523 10.1421 17.3736 10.2306C17.2949 10.3191 17.1874 10.3767 17.0703 10.3933L16.9851 10.3984H10H9.5V10.8984V17.8984C9.5 17.8984 9.5 17.8984 9.5 17.8984C9.49997 18.0209 9.45501 18.1391 9.37364 18.2306C9.29491 18.3191 9.18738 18.3767 9.07033 18.3933L8.98766 18.3983C8.86964 18.3953 8.7563 18.3507 8.66788 18.2721C8.57933 18.1933 8.52173 18.0858 8.5051 17.9688L8.5 17.8835V10.8984V10.3984H8H1.00007C1.00004 10.3984 1.00002 10.3984 1 10.3984C0.877557 10.3984 0.759381 10.3534 0.66788 10.2721Z"
                fill="white"
                stroke="white"
              />
            </svg>
          }
        >
          Add payment option
        </Button>
      </Stack>
      <Stack
        justifyContent="center"
        direction={["column", "row", "row"]}
        spacing="1rem"
        bg="white"
        borderRadius="22px"
        p="1.5rem"
        w="100%"
      >
        {organiserInfo &&
        Object.keys(organiserInfo?.payment_methods).every(
          (method) => !organiserInfo?.payment_methods[method].is_active
        ) ? (
          <Text textAlign="center" fontSize="18px" color="#727CD4">
            No payment options have been connected yet
          </Text>
        ) : (
          <Stack spacing="16px" justifyContent="left" width="100%">
            {organiserInfo &&
              Object.keys(organiserInfo?.payment_methods)
                .filter((key) => organiserInfo.payment_methods[key].is_active)
                .map((method) => (
                  <Stack
                    key={method}
                    width="300px"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="16px"
                    backgroundColor="#F8F8F8"
                    flexDirection="row"
                    borderRadius="12px"
                    fontSize="16px"
                    fontWeight="500"
                  >
                    <>
                      <Text fontSize="16px" fontWeight="500">
                        {supportedPaymentOptionsMap[method]}
                      </Text>
                    </>
                    <Stack flexDirection="row" alignItems="center">
                      {method !== "wire_transfer" && (
                        <Text
                          cursor="pointer"
                          fontSize="16px"
                          fontWeight="400"
                          onClick={() => openDetails(method)}
                        >
                          Details
                        </Text>
                      )}
                      <IconTrash onClick={() => removeMethod(method)} width="20px" height="20px" />
                    </Stack>
                  </Stack>
                ))}
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <Switch width="unset" isChecked={organiserInfo?.pass_on_fees} onChange={onFeesChange}>
                Pass on Fees
              </Switch>
              <Tooltip
                marginLeft="10px"
                maxW="500px"
                bg="white"
                borderRadius="6px"
                p="1rem"
                label={
                  <Box>
                    <Text>
                      Pass EventsFrame service fees onto your customers. Turning this on increases
                      your ticket prices by EventsFrame service fees.
                    </Text>
                  </Box>
                }
              >
                <Text marginLeft="10px">
                  <IQuestionMark />
                </Text>
              </Tooltip>
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default PaymentOverview;
