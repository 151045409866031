import {UserRole} from "@ef-org/api";
import {CognitoUser} from "amazon-cognito-identity-js";

import {FC, PropsWithChildren, useEffect, useState} from "react";

import {useRouter} from "next/router";

import {getCurrentUser, isUserAuthorized} from "../helpers/aws/auth";

export type AuthProps = {
  requiredPermissions: UserRole;
};

const AuthGuard: FC<PropsWithChildren & AuthProps> = ({children, requiredPermissions}) => {
  const [user, setUser] = useState<CognitoUser | undefined>();
  const {push} = useRouter();

  useEffect(() => {
    const currentUser = getCurrentUser();
    setUser(currentUser);
    if (!currentUser) {
      push("/");
      return;
    }
    const hasPermission = isUserAuthorized(requiredPermissions);
    if (!hasPermission) {
      push("/dashboard");
    }
  }, []);

  return <>{user && children}</>;
};

export default AuthGuard;
