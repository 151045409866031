import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";

import React, {useCallback} from "react";

import PaymentAddOption from "../scenes/Finance/components/PaymentAddOption";

const AddPaymentOptionModal: React.FC = () => {
  const {closeModal, closeAllModals} = useModal("addPaymentOptionModal");

  const onSuccess = useCallback(() => {
    closeAllModals();
  }, []);

  return <PaymentAddOption success={onSuccess} />;
};

export const useAddPaymentOptionModal: InitialUseCreateModalType = () =>
  useCreateModal("addPaymentOptionModal", AddPaymentOptionModal, {
    size: "lg",
    borderRadius: "24px",
  });
