// import React from "react";
// import {useRouter} from "next/router";
import {
  useGetNftappApiAccountByAccountIdTemplateQuery,
  useGetNftappApiEnumsQuery,
  useGetNftappApiStyleQuery, // useGetPy3ApiUserRefreshMutation,
} from "@ef-org/api";
import {NFTLayout} from "@ef-org/layouts";

// import {useStrictAuth} from "@ef/providers";
import {Box, Grid, Stack} from "@chakra-ui/react";

import {NftBuilderMainSection} from "./components/NftBuilderSection";
import NftPreview from "./components/NftPreview";
import useHandleNftStateWithUrl from "./components/useHandleNftStateWithUrl";

const NFTBuilderPage = () => {
  // const {push} = useRouter();
  // const {user, setUserManually} = useStrictAuth();
  // const [call] = useGetPy3ApiUserRefreshMutation();
  // const [canContinue, setCanContinue] = useState(Boolean(user.last_account_id));

  useGetNftappApiStyleQuery();
  useHandleNftStateWithUrl();

  // Init templates
  useGetNftappApiAccountByAccountIdTemplateQuery({
    accountId: "0",
  });

  useGetNftappApiEnumsQuery();

  // TODO @RadovanPelka: uncomment this when we have a new way to refresh user
  // React.useEffect(() => {
  //   call()
  //     .then((x) => {
  //       if ("data" in x && x?.data?.user && x.data.user.last_account_id) {
  //         setUserManually(x.data.user);
  //         setCanContinue(true);
  //         return;
  //       }
  //       push("/my-wallet/");
  //     })
  //     .catch(() => push("/my-wallet/"));
  // }, []);

  // if (!canContinue) {
  //   return null;
  // }

  return (
    <NFTLayout isDetail>
      <Grid templateColumns={["1fr", "1fr", "calc(100% - 450px) 450px"]} h="inherit">
        <Stack id="nft-layout-left-section" align="center" justify="center" overflowX="auto">
          <NftPreview />
        </Stack>
        <Box
          borderLeft={["0", "0", "1px"]}
          h="auto"
          p="1.5rem"
          borderLeftColor={["ef-white", "ef-white", "ef-divider"]}
          overflowX="auto"
        >
          <NftBuilderMainSection />
        </Box>
      </Grid>
    </NFTLayout>
  );
};

export default NFTBuilderPage;
