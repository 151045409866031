import {
  PaymentGateway,
  SubscriptionPlanRead,
  useGetAvailablePlansSubscriptionsPlansGetQuery,
} from "@ef-org/api";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";

import React, {useCallback} from "react";

import {Box, Stack, Text, useBreakpointValue} from "@chakra-ui/react";

import SubscriptionActivateRyft from "../scenes/Finance/components/SubscriptionActivateRyft";
import SubscriptionActivateStripe from "../scenes/Finance/components/SubscriptionActivateStripe";
import {WalletImage} from "../scenes/Finance/components/WalletImage";

type Props = {
  onSuccess(): void;
  plan: SubscriptionPlanRead;
};

const ActivateSubscriptionModal: React.FC<Props> = ({onSuccess, plan}) => {
  const isMobile = useBreakpointValue([true, true, false]);
  const {closeModal, closeAllModals} = useModal("activateSubscriptionModal");

  const onSetupComplete = useCallback(() => {
    onSuccess();
    closeModal();
  }, []);

  return (
    <Box p="1rem">
      <Stack direction="row" flex="0 0 auto" spacing="2rem" height="100%">
        {!isMobile && (
          <Stack
            padding={isMobile ? "1rem" : "2rem"}
            width="40%"
            background="linear-gradient(95.74deg, #4754C6 27.06%, #2F3BA0 87.33%)"
            borderRadius="24px"
            spacing="3rem"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <svg
              width="31"
              height="23"
              viewBox="0 0 31 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47997 0H0.0678711V4.6043V9.20861V13.7914V18.3957V23H4.47997H8.89206H13.2835V18.3957H8.89206H4.47997V13.7914V9.20861V4.6043H8.89206H13.2835V0H8.89206H4.47997Z"
                fill="#E9EAF8"
              />
              <path
                d="M26.52 0H22.1079H17.6958V4.6043H22.1079H26.52V9.20861V13.7914V18.3957V23H30.9321V18.3957V13.7914V9.20861V4.6043V0H26.52Z"
                fill="#E9EAF8"
              />
              <path d="M13.3042 9.20898H8.89209V13.8133H13.3042V9.20898Z" fill="#E9EAF8" />
              <path d="M22.1079 9.20898H17.6958V13.8133H22.1079V9.20898Z" fill="#E9EAF8" />
            </svg>
            <Text color="#E9EAF8" size="18px" fontWeight="600" textAlign="center">
              Monetise your events with Eventsframe
            </Text>
            <WalletImage />
          </Stack>
        )}
        <Box width={isMobile ? "100%" : "60%"} pt="1rem">
          {plan.payment_gateway === "ryft" ? (
            <SubscriptionActivateRyft setupComplete={onSetupComplete} plan={plan} />
          ) : (
            <SubscriptionActivateStripe setupComplete={onSetupComplete} />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export const useActivateSubscriptionModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("activateSubscriptionModal", ActivateSubscriptionModal, {
    size: "2xl",
    borderRadius: "24px",
  });
