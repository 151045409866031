import {
  useCreateUserUsersUserIdPutMutation,
  useGetOrganizersOrganizerIdInvitationsGetQuery,
} from "@ef-org/api";
import {Checkbox, Input, Link} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import {getCurrentUser, registerUser} from "apps/ef-org/src/helpers/aws/auth";
import useRecaptcha from "apps/ef-org/src/hooks/useRecaptcha";
import Joi from "joi";
import Cookies from "js-cookie";

import {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Flex, Heading, Stack, Text, useToast} from "@chakra-ui/react";

const SectionLoginSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  password: Joi.string().min(6).required(),
  name: Joi.string().min(2).required(),
  surname: Joi.string().min(2).required(),
  terms: Joi.boolean().valid(true).required(),
});

type SignupTypes = {
  email: string;
  password: string;
  name: string;
  surname: string;
  terms: boolean;
};

const RegisterForm = () => {
  const toast = useToast();
  const [createEFUser] = useCreateUserUsersUserIdPutMutation();
  const {push, query} = useRouter();
  const [isLoading, setLoading] = useState(false);
  const organizerId = Cookies.get("organizerId");
  const handleReCaptchaVerify = useRecaptcha();

  const invitation = useGetOrganizersOrganizerIdInvitationsGetQuery(
    {id: query?.invitationId?.toString(), organizerId},
    {skip: !query?.invitationId}
  );

  useEffect(() => {
    if (invitation?.data) {
      setValue("email", invitation?.data?.email);
    }
  }, [invitation]);

  const {registerWithError, handleSubmit, setValue} = useForm<SignupTypes>(SectionLoginSchema, {
    keepDataOnSuccess: true,
    defaultValues: {email: "", password: "", name: "", surname: "", terms: false},
  });

  const handleFormSubmit: OnFormSubmitType<SignupTypes> = async (data) => {
    setLoading(true);
    const captchaResult = await handleReCaptchaVerify();
    if (!captchaResult) {
      toast({
        title: "Ops!",
        description: "We could not verify you are not a robot. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }
    const currentUser = getCurrentUser();

    if (currentUser) {
      currentUser.signOut();
    }

    const role = invitation?.data?.role || "owner";
    const owner = invitation?.data?.organizer_id || null;

    const awsNewUserStatus = await registerUser(
      data.email,
      data.password,
      {
        given_name: data.name,
        family_name: data.surname,
      },
      role,
      owner
    );

    if (awsNewUserStatus.errorMessage || !awsNewUserStatus.isCreated) {
      toast({
        title: "Ops!",
        description: awsNewUserStatus.errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    const res = await createEFUser({
      userId: awsNewUserStatus?.userSub || "",
      userCreate: {
        email: data.email,
        first_name: data.name,
        last_name: data.surname,
        role,
        team_owner_id: owner,
      },
    });

    if (!("data" in res && res.data?.id)) {
      toast({
        title: "Ohh no!",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    push("/register/confirmation?email=" + encodeURIComponent(data.email));
  };

  return (
    <Box w="100%">
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="2rem">
        Create your account
      </Heading>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            w="100%"
            label="Email*"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                  fill="#727CD4"
                />
              </svg>
            }
            labelColor="primary-2"
            placeholder="Enter your email"
            {...registerWithError("email")}
            noBorder
            isDisabled={!!invitation?.data?.email}
          />

          <Input
            w="100%"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2C12.2091 2 14 3.79086 14 6V8H15.75C16.9926 8 18 9.00736 18 10.25L18.0004 11C17.6815 11 17.3626 11.1096 17.1038 11.3286L16.9966 11.4293C16.8328 11.5998 16.6678 11.7519 16.5009 11.886L16.5 10.25C16.5 9.83579 16.1642 9.5 15.75 9.5H4.25C3.83579 9.5 3.5 9.83579 3.5 10.25V19.75C3.5 20.1642 3.83579 20.5 4.25 20.5L14.1384 20.5008C14.546 21.0774 15.0606 21.579 15.6783 22.0005L4.25 22C3.00736 22 2 20.9926 2 19.75V10.25C2 9.00736 3.00736 8 4.25 8H6V6C6 3.79086 7.79086 2 10 2ZM18.2837 12.1223C19.2758 13.1576 20.3751 13.6667 21.6 13.6667C21.7933 13.6667 21.9546 13.8095 21.9919 13.9994L22 14.0833V16.5844C22 19.2663 20.687 21.0896 18.1265 21.9786C18.0444 22.0071 17.9556 22.0071 17.8735 21.9786C15.3984 21.1192 14.0889 19.3868 14.0044 16.8497L14 16.5844V14.0833C14 13.8532 14.1791 13.6667 14.4 13.6667C15.6234 13.6667 16.7233 13.1575 17.7178 12.122C17.8741 11.9592 18.1276 11.9594 18.2837 12.1223ZM10.0001 13.5C10.8286 13.5 11.5001 14.1716 11.5001 15C11.5001 15.8284 10.8286 16.5 10.0001 16.5C9.1717 16.5 8.50012 15.8284 8.50012 15C8.50012 14.1716 9.1717 13.5 10.0001 13.5ZM10 3.5C8.61929 3.5 7.5 4.61929 7.5 6V8H12.5V6C12.5 4.61929 11.3807 3.5 10 3.5Z"
                  fill="#727CD4"
                />
              </svg>
            }
            type="password"
            label="Password*"
            labelColor="primary-2"
            placeholder="Enter your Password"
            {...registerWithError("password")}
            noBorder
          />

          <Flex pt="1rem">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M11.4903 14.0625C11.212 14.0625 10.9736 13.8333 11.0167 13.5584C11.0381 13.4225 11.0699 13.3285 11.1123 13.2033C11.1276 13.1582 11.1442 13.109 11.1622 13.0524C11.2773 12.7008 11.4403 12.4164 11.6512 12.1991C11.8622 11.9817 12.1163 11.7836 12.4135 11.6046C12.6052 11.4831 12.7778 11.3473 12.9312 11.1971C13.0847 11.0469 13.2061 10.8743 13.2956 10.6793C13.3851 10.4844 13.4298 10.2687 13.4298 10.0321C13.4298 9.7477 13.3627 9.50161 13.2285 9.29387C13.0942 9.08613 12.9153 8.92633 12.6915 8.81447C12.471 8.69941 12.2249 8.64188 11.9533 8.64188C11.7072 8.64188 11.4723 8.69302 11.2485 8.79529C11.0248 8.89756 10.8394 9.05736 10.6924 9.27469C10.6187 9.38209 10.5606 9.50516 10.5183 9.6439C10.4413 9.89577 10.2336 10.1041 9.97027 10.1041H9.50078C9.21702 10.1041 8.985 9.86658 9.03856 9.58792C9.10695 9.23211 9.23317 8.91837 9.41722 8.64667C9.68568 8.24717 10.0404 7.94515 10.4815 7.7406C10.9257 7.53606 11.4163 7.43378 11.9533 7.43378C12.5413 7.43378 13.0559 7.54405 13.4969 7.76457C13.938 7.9819 14.28 8.28712 14.5229 8.68023C14.769 9.07015 14.892 9.52558 14.892 10.0465C14.892 10.4045 14.8361 10.7273 14.7242 11.0149C14.6124 11.2994 14.4526 11.5535 14.2448 11.7772C14.0403 12.0009 13.7942 12.1991 13.5065 12.3716C13.2349 12.541 13.0143 12.7168 12.845 12.899C12.6788 13.0812 12.5573 13.2969 12.4806 13.5462C12.4695 13.5823 12.459 13.6189 12.4491 13.6549C12.3852 13.8884 12.1798 14.0625 11.9377 14.0625H11.4903Z"
                fill="#727CD4"
              />
              <path
                d="M11.7088 17.4773C11.4467 17.4773 11.2214 17.3846 11.0328 17.1992C10.8442 17.0107 10.75 16.7838 10.75 16.5185C10.75 16.2564 10.8442 16.0327 11.0328 15.8473C11.2214 15.6588 11.4467 15.5645 11.7088 15.5645C11.9676 15.5645 12.1914 15.6588 12.3799 15.8473C12.5717 16.0327 12.6676 16.2564 12.6676 16.5185C12.6676 16.6943 12.6228 16.8557 12.5333 17.0027C12.447 17.1465 12.332 17.2616 12.1882 17.3478C12.0443 17.4341 11.8845 17.4773 11.7088 17.4773Z"
                fill="#727CD4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12.4362C22 17.959 17.5228 22.4362 12 22.4362C6.47715 22.4362 2 17.959 2 12.4362C2 6.91337 6.47715 2.43622 12 2.43622C17.5228 2.43622 22 6.91337 22 12.4362ZM20.5 12.4362C20.5 17.1306 16.6944 20.9362 12 20.9362C7.30558 20.9362 3.5 17.1306 3.5 12.4362C3.5 7.7418 7.30558 3.93622 12 3.93622C16.6944 3.93622 20.5 7.7418 20.5 12.4362Z"
                fill="#727CD4"
              />
            </svg>
            <Text color="primary-4" px="4px" fontSize="16px">
              Your password must contain at least one uppercase letter, one lowercase letter and one
              number
            </Text>
          </Flex>

          <Input
            w="100%"
            label="First name*"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7506 13.9978C18.9926 13.9978 19.9995 15.0046 19.9995 16.2467V16.8221C19.9995 17.7164 19.6799 18.5812 19.0984 19.2606C17.529 21.0941 15.1418 21.9988 11.9964 21.9988C8.8506 21.9988 6.46459 21.0938 4.89828 19.2595C4.31852 18.5806 4 17.7171 4 16.8243V16.2467C4 15.0046 5.00686 13.9978 6.24888 13.9978H17.7506ZM17.7506 15.4978H6.24888C5.83529 15.4978 5.5 15.8331 5.5 16.2467V16.8243C5.5 17.36 5.69111 17.878 6.03897 18.2854C7.29228 19.753 9.25816 20.4988 11.9964 20.4988C14.7347 20.4988 16.7023 19.753 17.9588 18.2852C18.3077 17.8776 18.4995 17.3586 18.4995 16.8221V16.2467C18.4995 15.8331 18.1642 15.4978 17.7506 15.4978ZM11.9964 2.00244C14.7578 2.00244 16.9964 4.24102 16.9964 7.00244C16.9964 9.76387 14.7578 12.0025 11.9964 12.0025C9.23504 12.0025 6.99646 9.76387 6.99646 7.00244C6.99646 4.24102 9.23504 2.00244 11.9964 2.00244ZM11.9964 3.50244C10.0634 3.50244 8.49646 5.06945 8.49646 7.00244C8.49646 8.93544 10.0634 10.5025 11.9964 10.5025C13.9294 10.5025 15.4964 8.93544 15.4964 7.00244C15.4964 5.06945 13.9294 3.50244 11.9964 3.50244Z"
                  fill="#727CD4"
                />
              </svg>
            }
            labelColor="primary-2"
            placeholder="Enter your first name"
            {...registerWithError("name")}
            noBorder
          />

          <Input
            w="100%"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7506 13.9978C18.9926 13.9978 19.9995 15.0046 19.9995 16.2467V16.8221C19.9995 17.7164 19.6799 18.5812 19.0984 19.2606C17.529 21.0941 15.1418 21.9988 11.9964 21.9988C8.8506 21.9988 6.46459 21.0938 4.89828 19.2595C4.31852 18.5806 4 17.7171 4 16.8243V16.2467C4 15.0046 5.00686 13.9978 6.24888 13.9978H17.7506ZM17.7506 15.4978H6.24888C5.83529 15.4978 5.5 15.8331 5.5 16.2467V16.8243C5.5 17.36 5.69111 17.878 6.03897 18.2854C7.29228 19.753 9.25816 20.4988 11.9964 20.4988C14.7347 20.4988 16.7023 19.753 17.9588 18.2852C18.3077 17.8776 18.4995 17.3586 18.4995 16.8221V16.2467C18.4995 15.8331 18.1642 15.4978 17.7506 15.4978ZM11.9964 2.00244C14.7578 2.00244 16.9964 4.24102 16.9964 7.00244C16.9964 9.76387 14.7578 12.0025 11.9964 12.0025C9.23504 12.0025 6.99646 9.76387 6.99646 7.00244C6.99646 4.24102 9.23504 2.00244 11.9964 2.00244ZM11.9964 3.50244C10.0634 3.50244 8.49646 5.06945 8.49646 7.00244C8.49646 8.93544 10.0634 10.5025 11.9964 10.5025C13.9294 10.5025 15.4964 8.93544 15.4964 7.00244C15.4964 5.06945 13.9294 3.50244 11.9964 3.50244Z"
                  fill="#727CD4"
                />
              </svg>
            }
            label="Last name*"
            labelColor="primary-2"
            placeholder="Enter your last name"
            {...registerWithError("surname")}
            noBorder
          />

          <Checkbox
            w="100%"
            variant="onboarding"
            {...registerWithError("terms")}
            customError="Required"
          >
            I agree with the{" "}
            <Link
              fontSize="15px"
              variant="black"
              href="https://www.eventsframe.com/terms"
              isExternal
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              EventsFrame Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              fontSize="15px"
              variant="black"
              href="https://www.eventsframe.com/privacy-policy"
              isExternal
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Privacy Policy
            </Link>
          </Checkbox>

          <Box py="1rem">
            <Button type="submit" w="100%" isLoading={isLoading}>
              Sign up
            </Button>
          </Box>

          <Text color="black" w="100%" textAlign="center">
            Already have an account ?{" "}
            <Link color="inherit" href="/login">
              Log in
            </Link>
          </Text>
        </Stack>
      </form>
    </Box>
  );
};

export default RegisterForm;
