import {Stack} from "@chakra-ui/react";

import ChangePassword from "./components/ChangePassword";
import UserDetails from "./components/UserDetails";

const Settings = () => {
  return (
    <Stack spacing="2rem">
      <UserDetails />
      <ChangePassword />
    </Stack>
  );
};

export default Settings;
