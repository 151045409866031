import {DiscountCreate, DiscountType, DiscountUpdate} from "@ef-org/api";
import {Checkbox, Select, TypingSelect} from "@ef-org/components";
import {useUpdateEvent} from "@ef-org/hooks";
import {formatISO, parse} from "date-fns";
import Joi from "joi";

import React, {useEffect, useMemo, useState} from "react";

import {useRouter} from "next/router";

import {
  Box,
  Button,
  Heading,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Input as ChakraInput,
} from "@chakra-ui/react";

import Input, {ErrorMessage} from "../components/Input";
import useForm, {OnFormSubmitType} from "../hooks/useForm";
import {TicketsStepDataType, useSubscribeToTicketsStep} from "../scenes/NewEvent/newEventStore";
import {JoiDateValidator, JoiTimeValidator, parseDateFromInput} from "../utils/joiValudators";
import {InitialUseCreateModalType, useCreateModal, useModal} from "./ModalsProvider";

const formInputsSchema = Joi.object({
  name: Joi.string().min(1).max(100).required(),
  type: Joi.string().min(1).max(100).required(),
  amount: Joi.number().min(1).required(),
  volume: Joi.any().when("type", {
    is: "volume_discount",
    then: Joi.number().min(1).required(),
    otherwise: Joi.any(),
  }),
  ticketTypeId: Joi.any().when("type", {
    is: "type_discount",
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  startDate: JoiDateValidator.required(),
  startTime: JoiTimeValidator.required(),
  endDate: Joi.any().when("withEndDate", {
    is: true,
    then: JoiDateValidator.required(),
    otherwise: Joi.any(),
  }),
  endTime: Joi.any().when("withEndDate", {
    is: true,
    then: JoiTimeValidator.required(),
    otherwise: Joi.any(),
  }),
  withEndDate: Joi.boolean(),
  isExclusive: Joi.boolean(),
  couponCode: Joi.string().min(4).max(15).allow(""),
});

type FormInputsTypes = {
  name: string;
  type: string;
  ticketTypeId: string;
  volume: number;
  amount: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isExclusive: boolean;
  withEndDate: boolean;
  couponCode: string;
};

const EventCreationDiscountsModal: React.FC = () => {
  const {closeModal} = useModal("useEventCreationDiscountsModal");

  const [discounts] = useSubscribeToTicketsStep<TicketsStepDataType["discounts"]>("discounts");
  const [, setAddingNewDiscount] =
    useSubscribeToTicketsStep<TicketsStepDataType["addingNewDiscount"]>("addingNewDiscount");
  const [editingDiscountId, setEditingDiscountId] =
    useSubscribeToTicketsStep<TicketsStepDataType["editingDiscountId"]>("editingDiscountId");
  const [tickets, _] = useSubscribeToTicketsStep<TicketsStepDataType["tickets"]>("tickets");
  const updateEvent = useUpdateEvent();

  const {registerWithError, watch, trigger, errors, setValue, handleSubmit, setError} =
    useForm<FormInputsTypes>(formInputsSchema, {
      defaultValues: {type: "Order discount"},
      keepDataOnSuccess: true,
    });

  useEffect(() => {
    const found = discounts?.value?.find((x) => x._id === editingDiscountId?.value);
    if (found) {
      //@ts-ignore
      Object.keys(found).forEach((key) => setValue(key, found[key]));
      setValue("withEndDate", found.endDate ? true : false);
      if (found.couponCode) {
        setRequireCoupon(true);
      }
      trigger();
    }
  }, [editingDiscountId, discounts]);

  const {pathname, query, replace} = useRouter();

  const completeClose = () => {
    closeModal();
    setAddingNewDiscount({value: false});
    setEditingDiscountId({value: null});
    delete query.discountId;
    replace({pathname, query});
  };

  const handleAdd: OnFormSubmitType<FormInputsTypes> = async (data) => {
    const startDate = parseDateFromInput(data.startDate);
    const endDate = parseDateFromInput(data.endDate);

    if (startDate.getTime() >= endDate.getTime()) {
      return setError("endDate", {message: "End date must be after start date"});
    }

    if (requireCoupon && !data.couponCode) {
      return setError("couponCode", {message: "Please enter coupon code"});
    }

    const start_datetime = !data.startDate
      ? formatISO(new Date())
      : formatISO(
          parse(`${data.startDate} ${data.startTime || "00:00"}`, "yyyy-MM-dd HH:mm", new Date())
        );

    const end_datetime =
      !data.endDate || !data.withEndDate
        ? undefined
        : formatISO(
            parse(`${data.endDate} ${data.endTime || "00:00"}`, "yyyy-MM-dd HH:mm", new Date())
          );

    if (Boolean(editingDiscountId?.value)) {
      const updatedTicket: DiscountUpdate = {
        id: editingDiscountId.value,
        name: data.name,
        type: foundType,
        percentage: data.amount,
        ticket_type_id: data.ticketTypeId,
        minimum_order_quantity: data.volume,
        is_exclusive_discount: data.isExclusive ?? false,
        coupon_code: data.couponCode,
        start_datetime,
        end_datetime,
      };

      await updateEvent({discounts: [updatedTicket]});
    } else {
      const newTicket: DiscountCreate = {
        name: data.name,
        type: foundType,
        percentage: data.amount,
        ticket_type_id: data.ticketTypeId,
        minimum_order_quantity: data.volume,
        is_exclusive_discount: data.isExclusive ?? false,
        coupon_code: data.couponCode,
        start_datetime,
        end_datetime,
      };

      await updateEvent({discounts: [newTicket]});
    }
    completeClose();
  };

  const type = watch("type");
  const isExclusive = watch("isExclusive");
  const ticketTypeId = watch("ticketTypeId");
  const withEndDate = watch("withEndDate");

  const options = [
    {name: "Order discount", value: "order_discount"},
    {name: "Ticket discount", value: "type_discount"},
    {name: "Volume discount", value: "volume_discount"},
  ];

  const foundType = useMemo(
    () => options.find((o) => o.name == type || o.value == type).value as DiscountType,
    [type]
  );

  const [requireCoupon, setRequireCoupon] = useState(false);

  return (
    <Box p="1.5rem">
      <Heading mb="2rem" fontSize="20px">
        Discount details
      </Heading>

      <form onSubmit={handleSubmit(handleAdd)}>
        <Heading mb="1rem" fontSize="20px">
          Discount type
        </Heading>
        <TypingSelect
          bg="white"
          hideTyping
          border="1px solid"
          borderColor="gray.200"
          size="lg"
          defaultValue={options.find((opt) => opt.value === type)?.name}
          placeholder="Select the type"
          onSelected={(val) => setValue("type", val)}
          options={options}
        />

        <Input {...registerWithError("name")} mb="1rem" label="Name" noBorder placeholder="Name" />
        <Heading my="1rem" fontSize="20px">
          Discount Amount
        </Heading>

        <Stack
          direction="row"
          align="center"
          maxW="200px"
          bg="#F8F8F8"
          borderRadius="12px"
          pr="1rem"
        >
          <Input
            leftContent={<Text as="span">%</Text>}
            placeholder="0.00"
            type="number"
            {...registerWithError("amount")}
            customError="Required"
            noBorder
            hideError
          />
        </Stack>

        <ErrorMessage error={errors.amount} />

        {foundType === "volume_discount" && (
          <>
            <Heading my="1rem" fontSize="20px">
              Minimum volume
            </Heading>
            <Input
              placeholder="0"
              type="number"
              {...registerWithError("volume")}
              customError="Required"
              noBorder
              hideError
            />
          </>
        )}

        <ErrorMessage error={errors.volume} />

        {foundType === "type_discount" && (
          <>
            <Heading my="1rem" fontSize="20px">
              Ticket type
            </Heading>
            <Box maxW="50%">
              <TypingSelect
                bg="white"
                hideTyping
                border="1px solid"
                borderColor="gray.200"
                size="lg"
                defaultValue={
                  tickets.value.find((ticket) => ticket._id == ticketTypeId)?.ticketName
                }
                placeholder="Select the type"
                onSelected={(val) => setValue("ticketTypeId", val)}
                options={tickets.value.map((ticket) => ({
                  name: ticket.ticketName,
                  value: ticket._id,
                }))}
              />
              <ErrorMessage error={errors.ticketTypeId} customError="Required" />
            </Box>
          </>
        )}

        <Heading my="1.5rem" fontSize="20px">
          Date & Time
        </Heading>

        <Text>Start</Text>

        <Stack mb="1rem" direction="row" spacing="1rem">
          <Stack w="100%">
            <InputGroup>
              <InputLeftElement>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5H6.25V3.75C6.25 3.33579 6.58579 3 7 3C7.41421 3 7.75 3.33579 7.75 3.75V5H16.25V3.75C16.25 3.33579 16.5858 3 17 3C17.4142 3 17.75 3.33579 17.75 3.75V5H20C21.1046 5 22 5.89543 22 7V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V7C2 5.89543 2.89543 5 4 5ZM20 6.5C20.2761 6.5 20.5 6.72386 20.5 7V9H3.5V7C3.5 6.72386 3.72386 6.5 4 6.5H20ZM4 19.5C3.72386 19.5 3.5 19.2761 3.5 19V10.5H20.5V19C20.5 19.2761 20.2761 19.5 20 19.5H4Z"
                    fill="#727CD4"
                  />
                </svg>
              </InputLeftElement>
              <ChakraInput
                bg="#F8F8F8"
                border="0"
                borderRadius="8px"
                type="date"
                {...registerWithError("startDate")}
                // value={eventDurationStart.value}
                // onChange={(e) => setEventDurationStart({value: e.target.value})}
              />
            </InputGroup>
            <ErrorMessage error={errors.startDate} />
          </Stack>

          <Stack w="120px">
            <InputGroup>
              <InputLeftElement>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25V12C11.25 12.268 11.393 12.5155 11.625 12.6495L15.3056 14.7745C15.6643 14.9816 16.123 14.8587 16.3301 14.5C16.5372 14.1413 16.4143 13.6826 16.0556 13.4755L12.75 11.567V8.25C12.75 7.83579 12.4142 7.5 12 7.5Z"
                    fill="#727CD4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z"
                    fill="#727CD4"
                  />
                </svg>
              </InputLeftElement>
              <ChakraInput
                {...registerWithError("startTime")}
                bg="#F8F8F8"
                border="0"
                borderRadius="8px"
                type="time"
              />
            </InputGroup>
            <ErrorMessage error={errors.startTime} />
          </Stack>

          {!withEndDate && (
            <Button
              w="fit-content"
              px="3rem"
              size="lg"
              onClick={() => {
                setValue("withEndDate", true);
              }}
              variant="neutral"
            >
              Add discount end
            </Button>
          )}
        </Stack>

        {withEndDate && (
          <Box mb="1.5rem">
            <Text>End</Text>
            <Stack direction="row" spacing="1rem">
              <Stack w="100%">
                <InputGroup>
                  <InputLeftElement>
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 5H6.25V3.75C6.25 3.33579 6.58579 3 7 3C7.41421 3 7.75 3.33579 7.75 3.75V5H16.25V3.75C16.25 3.33579 16.5858 3 17 3C17.4142 3 17.75 3.33579 17.75 3.75V5H20C21.1046 5 22 5.89543 22 7V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V7C2 5.89543 2.89543 5 4 5ZM20 6.5C20.2761 6.5 20.5 6.72386 20.5 7V9H3.5V7C3.5 6.72386 3.72386 6.5 4 6.5H20ZM4 19.5C3.72386 19.5 3.5 19.2761 3.5 19V10.5H20.5V19C20.5 19.2761 20.2761 19.5 20 19.5H4Z"
                        fill="#727CD4"
                      />
                    </svg>
                  </InputLeftElement>
                  <ChakraInput
                    {...registerWithError("endDate")}
                    bg="#F8F8F8"
                    border="0"
                    borderRadius="8px"
                    type="date"
                    // value={eventDurationStart.value}
                    // onChange={(e) => setEventDurationStart({value: e.target.value})}
                  />
                </InputGroup>
                <ErrorMessage error={errors.endDate} />
              </Stack>

              <Stack w="120px">
                <InputGroup>
                  <InputLeftElement>
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25V12C11.25 12.268 11.393 12.5155 11.625 12.6495L15.3056 14.7745C15.6643 14.9816 16.123 14.8587 16.3301 14.5C16.5372 14.1413 16.4143 13.6826 16.0556 13.4755L12.75 11.567V8.25C12.75 7.83579 12.4142 7.5 12 7.5Z"
                        fill="#727CD4"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z"
                        fill="#727CD4"
                      />
                    </svg>
                  </InputLeftElement>
                  <ChakraInput
                    {...registerWithError("endTime")}
                    bg="#F8F8F8"
                    border="0"
                    borderRadius="8px"
                    type="time"
                  />
                </InputGroup>
                <ErrorMessage error={errors.endTime} />
              </Stack>

              <Button
                w="fit-content"
                size="lg"
                onClick={() => {
                  setValue("withEndDate", false);
                }}
                variant="neutral"
              >
                X
              </Button>
            </Stack>
          </Box>
        )}

        <Stack spacing="1rem" mb="1.5rem" mt="1.5rem">
          <Checkbox
            isChecked={requireCoupon}
            onChange={() => {
              setRequireCoupon((x) => !x);
            }}
          >
            Require coupon code
          </Checkbox>
        </Stack>

        {requireCoupon && (
          <>
            <Text>Coupon code</Text>
            <Input
              placeholder="######"
              {...registerWithError("couponCode")}
              customError="couponCode"
              noBorder
              hideError
            />
          </>
        )}

        <ErrorMessage error={errors.couponCode} />

        <Stack spacing="1rem" mb="1.5rem" mt="1.5rem">
          <Checkbox {...registerWithError("isExclusive")} isChecked={isExclusive}>
            Can't combine with other discounts
          </Checkbox>
        </Stack>

        <Stack align="flex-end">
          <Button type="submit">
            {editingDiscountId?.value ? "Edit discount" : "Add discount"}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export const useEventCreationDiscountsModal: InitialUseCreateModalType = () =>
  useCreateModal("useEventCreationDiscountsModal", EventCreationDiscountsModal, {
    size: "2xl",
  });
