import {
  BillingMethodRead,
  useDeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteMutation,
  useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery,
} from "@ef-org/api";
import IQuestionMark from "apps/ef-org/src/components/Icons/IQuestionMatk";
import usePlansAndSubscriptions from "apps/ef-org/src/hooks/usePlansAndSubscriptions";
import {format, parseISO} from "date-fns";
import Cookies from "js-cookie";

import React, {useCallback, useEffect, useState} from "react";

import {Box, Button, Heading, Stack, Text, Tooltip, useBreakpointValue} from "@chakra-ui/react";

const BillingOverview: React.FC = () => {
  const isMobile = useBreakpointValue([true, true, false]);
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId");

  const [activeBillingMethod, setActiveBillingMethod] = useState<BillingMethodRead | undefined>();
  const [dueDate, setDueDate] = useState<Date>();
  const {activePlan} = usePlansAndSubscriptions();

  const {data: organiserInfo, isLoading: isOrganiserLoading} =
    useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery({
      organizerId,
      userId,
    });

  // cards and payments are empty
  const {
    data: billingMethods,
    refetch: refetchBilling,
    isLoading: isBillingLoading,
  } = useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery(
    {
      organizerId,
    },
    {pollingInterval: activeBillingMethod ? 0 : 5000}
  );

  const [remove] =
    useDeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteMutation();

  useEffect(() => {
    if (!billingMethods || billingMethods?.items.length === 0 || !activePlan) {
      return;
    }
    const activeMethod = billingMethods?.items.find(
      (item) => item.state === "active" && item.payment_gateway === activePlan.payment_gateway
    );
    setActiveBillingMethod(activeMethod);
  }, [billingMethods]);

  useEffect(() => {
    if (!organiserInfo?.billing_details) {
      return;
    }
    setDueDate(parseISO(organiserInfo.billing_details.next_billing_on));
  }, [organiserInfo, activePlan]);

  const removeBillingMethod = useCallback(() => {
    if (billingMethods?.items.length) {
      remove({organizerId, billingMethodId: activeBillingMethod.id}).then((response) => {
        refetchBilling();
        if ("error" in response) {
          // @ts-ignore
          setErrorMessage(response.error.data.error.message);
        }
      });
    }
  }, [billingMethods]);

  return (
    <Box pt="2rem">
      <Heading
        marginBottom="0"
        lineHeight="2"
        fontWeight="600"
        fontSize={isMobile ? "16px" : "20px"}
        color="#1F2FBA"
      >
        Billing
      </Heading>
      <Stack padding="1rem" gap="1rem" alignItems="start" bg="white" borderRadius="12px">
        {activeBillingMethod && organiserInfo && (
          <Stack>
            <Stack
              justifyContent="left"
              direction={["column", "column", "row"]}
              spacing="4rem"
              bg="white"
              borderRadius="22px"
              p="1.5rem"
              w="100%"
            >
              <Box>
                <Text fontSize="16px" color="#727CD4" fontWeight="500" mb="12px">
                  Billing amount
                </Text>
                <Text fontSize="32px" fontWeight="500">
                  $ {organiserInfo?.billing_details.outstanding_balance}
                </Text>
              </Box>
              <Box>
                <Text fontSize="16px" color="#727CD4" fontWeight="500" mb="12px">
                  Next billing
                </Text>
                {dueDate && <Text>{format(dueDate, "dd.MM.yyyy")}</Text>}
              </Box>
              <Box minWidth="243px">
                <Text fontSize="16px" color="#727CD4" fontWeight="500" mb="12px">
                  Billing method
                </Text>
                <Box background="#FAFAFD" p="14px" borderRadius="16px">
                  <Text fontSize="32px" fontWeight="500">
                    {activeBillingMethod.payment_gateway}
                  </Text>
                  {/* <Image src="/images/illustrations/stripe.png" /> */}
                  <Text
                    color="#656592"
                    mt="12px"
                    fontSize="16px
"
                  >
                    **** **** **** {activeBillingMethod.card?.last4}
                  </Text>
                  <Stack direction="row" mt="12px" alignItems="center">
                    <Tooltip
                      marginLeft="10px"
                      maxW="500px"
                      bg="white"
                      borderRadius="6px"
                      p="1rem"
                      label={
                        <Box>
                          <Text>
                            Please contact us at support@eventsframe.com for deletion of this
                            billing method
                          </Text>
                        </Box>
                      }
                    >
                      <Text marginLeft="10px" display="flex" flexDirection="row">
                        <IQuestionMark />
                      </Text>
                    </Tooltip>
                    <Button
                      p={0}
                      border="none"
                      fontSize="14px"
                      fontWeight="500"
                      height="40px"
                      isDisabled={true}
                      variant="outline"
                      color="#D85639"
                      background="none"
                      alignItems="center"
                      onClick={() => removeBillingMethod()}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default BillingOverview;
