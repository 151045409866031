import React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const QrIcon: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    boxSize="26px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    display={["block", "block", "none"]}
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M6.25 3C4.45507 3 3 4.45507 3 6.25V8.25C3 8.66421 3.33579 9 3.75 9C4.16421 9 4.5 8.66421 4.5 8.25V6.25C4.5 5.2835 5.2835 4.5 6.25 4.5H8.25C8.66421 4.5 9 4.16421 9 3.75C9 3.33579 8.66421 3 8.25 3H6.25ZM15.75 3C15.3358 3 15 3.33579 15 3.75C15 4.16421 15.3358 4.5 15.75 4.5H17.75C18.7165 4.5 19.5 5.2835 19.5 6.25V8.25C19.5 8.66421 19.8358 9 20.25 9C20.6642 9 21 8.66421 21 8.25V6.25C21 4.45507 19.5449 3 17.75 3H15.75ZM4.5 15.75C4.5 15.3358 4.16421 15 3.75 15C3.33579 15 3 15.3358 3 15.75V17.75C3 19.5449 4.45507 21 6.25 21H8.25C8.66421 21 9 20.6642 9 20.25C9 19.8358 8.66421 19.5 8.25 19.5H6.25C5.2835 19.5 4.5 18.7165 4.5 17.75V15.75ZM21 15.75C21 15.3358 20.6642 15 20.25 15C19.8358 15 19.5 15.3358 19.5 15.75V17.75C19.5 18.7165 18.7165 19.5 17.75 19.5H15.75C15.3358 19.5 15 19.8358 15 20.25C15 20.6642 15.3358 21 15.75 21H17.75C19.5449 21 21 19.5449 21 17.75V15.75ZM7.5 11.25C7.08579 11.25 6.75 11.5858 6.75 12C6.75 12.4142 7.08579 12.75 7.5 12.75H16.5C16.9142 12.75 17.25 12.4142 17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25H7.5Z"
        fill="#C9CDEE"
      />
    </g>
  </Icon>
);

export default QrIcon;
