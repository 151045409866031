import {Input} from "@ef-org/components";
import {useNFTBuilder} from "apps/ef-org/src/scenes/NFTBuilderPage/hooks/useNFTBuilder";

import React from "react";

import {CheckIcon, CloseIcon, EditIcon} from "@chakra-ui/icons";
import {Stack, Text, useBoolean} from "@chakra-ui/react";

const NFTEditableName: React.FC = () => {
  const [isEditing, {on, off}] = useBoolean(false);
  const {nftName, setNFTName} = useNFTBuilder(({nftName, actions: {setNFTName}}) => ({
    nftName,
    setNFTName,
  }));

  const handleChangeInput = () => {
    off();
  };

  return (
    <Stack direction="row" align="center">
      {!isEditing && (
        <Stack direction="row" align="center" spacing="1rem">
          <Text fontWeight="bold" color="#141A4E">
            {nftName}
          </Text>
          <EditIcon cursor="pointer" onClick={on} />
        </Stack>
      )}

      {isEditing && (
        <Input
          value={nftName}
          onChange={(e) => setNFTName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleChangeInput();
            }
          }}
          placeholder="NFT Name"
          bg="#F2F2F2"
          borderColor="#F2F2F2"
          rightElement={
            <Stack direction="row" align="center" h="14px" pr="10px" cursor="pointer">
              <CloseIcon h="inherit" onClick={off} cursor="inherit" />
              <CheckIcon h="inherit" onClick={handleChangeInput} cursor="inherit" />
            </Stack>
          }
        />
      )}
      <Text color="#A4A4A4" pl="1rem" fontSize="14px" whiteSpace="nowrap">
        Not published yet
      </Text>
    </Stack>
  );
};

export default NFTEditableName;
