/* eslint-disable @typescript-eslint/no-explicit-any */
import {GetNftappApiAccountByAccountIdStyleApiResponse} from "@ef-org/api";
import {nanoid} from "nanoid";
import {pick} from "ramda";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";
import {shallow} from "zustand/shallow";

export type BackgroundVariants = "image" | "solidColor" | "gradientColor";
export type TextAlignVariants = "easy" | "custom";

export type FileWithPreview = File & {preview: string};

type StoreType = {
  nftName: string;
  nftBackgroundSolidColor: string;
  nftBackgroundImage: null | FileWithPreview;
  nftBackgroundGradient: {top: string; bottom: string; rotation: number};
  nftBackgroundTemplateImage: string;
  backgroundActiveVariant: BackgroundVariants;
  nftContentImage: null | FileWithPreview;
  nftContentText: string;
  nftContentTextColor: string;
  nftContentTextPosition: number;
  nftContentTextBackgroundColor: string;
  actions: {
    setNFTName: (nftName: string) => void;
    setSolidColor: (color: string) => void;
    setBackgroundVariant: (backgroundActiveVariant: BackgroundVariants) => void;
    setBackgroundImage: (nftBackgroundImage: FileWithPreview | null) => void;
    setContentImage: (nftBackgroundImage: FileWithPreview | null) => void;
    setContentText: (nftContentText: string) => void;
    setContentTextColor: (nftContentTextColor: string) => void;
    setContentTextBackgroundColor: (nftContentTextBackgroundColor: string) => void;
    setBackgroundTemplateImage: (nftBackgroundTemplateImage: string) => void;
    setContentTextPosition: (nftContentTextPosition: number) => void;
    setGradientTopColor: (top: string) => void;
    setGradientBottomColor: (bottom: string) => void;
    setGradientRotationColor: (rotation: number) => void;
  };
};

const useNFTBuilder = create(
  subscribeWithSelector<StoreType>((set) => ({
    nftName: "NFT Name",
    backgroundActiveVariant: "image",
    nftBackgroundSolidColor: "",
    nftBackgroundGradient: {top: "#5f79e5", bottom: "#363784", rotation: 45},
    nftBackgroundTemplateImage: "",
    nftBackgroundImage: null,
    nftContentImage: null,
    nftContentText: "",
    nftContentTextColor: "#000000",
    nftContentTextPosition: 50,
    nftContentTextBackgroundColor: "",
    actions: {
      setNFTName: (nftName) => set(() => ({nftName})),
      setSolidColor: (nftBackgroundSolidColor) => set(() => ({nftBackgroundSolidColor})),
      setBackgroundVariant: (backgroundActiveVariant) => set(() => ({backgroundActiveVariant})),
      setBackgroundImage: (nftBackgroundImage) => set(() => ({nftBackgroundImage})),
      setContentImage: (nftContentImage) => set(() => ({nftContentImage})),
      setContentText: (nftContentText) => set(() => ({nftContentText})),
      setContentTextColor: (nftContentTextColor) => set(() => ({nftContentTextColor})),
      setContentTextBackgroundColor: (nftContentTextBackgroundColor) =>
        set(() => ({nftContentTextBackgroundColor})),
      setBackgroundTemplateImage: (nftBackgroundTemplateImage) =>
        set(() => ({nftBackgroundTemplateImage})),
      setContentTextPosition: (nftContentTextPosition) => set(() => ({nftContentTextPosition})),
      setGradientTopColor: (top) =>
        set(({nftBackgroundGradient}) => ({
          nftBackgroundGradient: {...nftBackgroundGradient, top},
        })),
      setGradientBottomColor: (bottom) =>
        set(({nftBackgroundGradient}) => ({
          nftBackgroundGradient: {...nftBackgroundGradient, bottom},
        })),
      setGradientRotationColor: (rotation) =>
        set(({nftBackgroundGradient}) => ({
          nftBackgroundGradient: {...nftBackgroundGradient, rotation},
        })),
    },
  }))
);

export type WorkspaceType = "2d" | "3d" | null;
type ImageType = "custom-image" | "from-template";
export type StyleType = Omit<
  GetNftappApiAccountByAccountIdStyleApiResponse["items"][0],
  "fragments"
> & {
  fragments: Array<
    Omit<
      GetNftappApiAccountByAccountIdStyleApiResponse["items"][0]["fragments"][0],
      "properties"
    > & {
      id: string;
      __orgValue: GetNftappApiAccountByAccountIdStyleApiResponse["items"][0]["fragments"][0]["value"];
      __templateUploadedFile: FileWithPreview | null;
      __crop: {isActive: boolean; croppedImage: FileWithPreview | null};
      __orgProps: any;
      properties?: {
        color?: string;
        color_outline?: string;
        size?: number;
        font?: string;
        anchor?: string;
        is_readonly?: boolean;
      };
    }
  >;
};

type Custom2dOwnImageStylesType = {isCropActive: boolean; croppedImage: FileWithPreview | null};

type NewStoreType = {
  nftName: string;
  selectedWorkspace: WorkspaceType;
  selectedImageType: ImageType;
  selected2dOwnImageFile: null | FileWithPreview;
  custom2dOwnImageStyles: Custom2dOwnImageStylesType;
  selected3dOwnImageSpace: {file?: FileWithPreview};
  selectedStyle: StyleType | null;
  selectedFragmentId: string | null;
  isStyleChanged: boolean;
  fragmentHoverId: string | null;

  setNFTName: (nftName: string) => void;
  setSelectWorkspace: (wsp: WorkspaceType) => void;
  setIsStyleChanged: (isStyleChanged: boolean) => void;
  setSelectImageType: (imageType: ImageType) => void;
  setSelectStyle: (setSelectNewStyle: StyleType | ((prev: StyleType) => StyleType)) => void;
  setSelect2dOwnImageFile: (file: FileWithPreview | null) => void;
  setCustom2dOwnImageStyles: (
    input:
      | Partial<Custom2dOwnImageStylesType>
      | ((prev: Custom2dOwnImageStylesType) => Custom2dOwnImageStylesType)
  ) => void;
  setSelect3dOwnImageSpace: (props: Partial<NewStoreType["selected3dOwnImageSpace"]>) => void;
  setSelectFragmentId: (selectedFragmentId: string | null) => void;
  setEditTextFragment: (fragmentId: string, value: string | null) => void;
  setEditTemplateFragment: (fragmentId: string, value: FileWithPreview | null) => void;
  setActivateCropForTemplateFragment: (fragmentId: string, newValue: boolean) => void;
  setCropedImageForTemplateFragment: (fragmentId: string, file: FileWithPreview | null) => void;
  setSelectEditFragment: (fragmentId: string, newValue: {value?: any; properties?: any}) => void;
  setHoverFragment: (fragmentId: string | null) => void;
};

const useNewNFTBuilder = create(
  subscribeWithSelector<NewStoreType>((set) => ({
    nftName: "NFT Name",
    selectedWorkspace: null,
    selectedImageType: "custom-image",
    selected2dOwnImageFile: null,
    custom2dOwnImageStyles: {isCropActive: false, croppedImage: null},
    selected3dOwnImageSpace: {file: null},
    selectedStyle: null,
    selectedFragmentId: null,
    isStyleChanged: false,

    fragmentHoverId: null,

    setNFTName: (nftName) => set(() => ({nftName})),
    setSelectWorkspace: (selectedWorkspace) => set(() => ({selectedWorkspace})),
    setIsStyleChanged: (isStyleChanged) => set(() => ({isStyleChanged})),
    setSelectImageType: (selectedImageType) => set(() => ({selectedImageType})),
    setSelect2dOwnImageFile: (selected2dOwnImageFile) =>
      set(() => ({selected2dOwnImageFile, isStyleChanged: true})),
    setSelect3dOwnImageSpace: (props) =>
      set(({selected3dOwnImageSpace}) => ({
        selected3dOwnImageSpace: {...selected3dOwnImageSpace, ...props},
      })),
    setSelectFragmentId: (newFragment) =>
      set(({selectedFragmentId, selectedStyle}) => ({
        selectedFragmentId: newFragment === selectedFragmentId ? null : newFragment,
        selectedStyle: selectedStyle
          ? {
              ...selectedStyle,
              fragments: selectedStyle.fragments.map((x) => ({
                ...x,
                __crop: {...x.__crop, isActive: false},
              })),
            }
          : null,
      })),
    setSelectStyle: (style) => {
      const reworkStyle = (style: StyleType | null): StyleType | null => {
        if (!style) return style;

        const newStyle: StyleType = {
          ...style,
          size: style.size || [0, 0],
          fragments: (style?.fragments || []).map((x) => ({
            ...x,
            id: nanoid(),
            __orgValue: x.value,
            __templateUploadedFile: null,
            __orgProps: x.properties || {},
            __crop: {isActive: false, croppedImage: null},
          })),
        };

        return newStyle;
      };

      if (typeof style === "function") {
        // is Function
        set(({selectedStyle: prev}) => ({selectedStyle: reworkStyle(style(prev))}));
        return;
      }

      set(() => ({selectedStyle: reworkStyle(style) as StyleType}));
    },
    setCustom2dOwnImageStyles: (input) => {
      if (typeof input === "function") {
        set((store) => ({
          custom2dOwnImageStyles: {
            ...store.custom2dOwnImageStyles,
            ...input(store.custom2dOwnImageStyles),
          },
        }));
        return;
      }

      set((store) => ({
        custom2dOwnImageStyles: {
          ...store.custom2dOwnImageStyles,
          ...input,
        },
      }));
    },
    setEditTextFragment: (fragmentId, value) => {
      set((store) => ({
        isStyleChanged: true,
        selectedStyle: {
          ...store.selectedStyle,
          fragments: store.selectedStyle.fragments.map((x) => {
            if (x.id === fragmentId)
              return {
                ...x,
                value,
              };
            return x;
          }),
        },
      }));
    },
    setEditTemplateFragment: (fragmentId, value) => {
      set((store) => ({
        isStyleChanged: true,
        selectedStyle: {
          ...store.selectedStyle,
          fragments: store.selectedStyle.fragments.map<StyleType["fragments"][0]>((x) => {
            if (x.id === fragmentId)
              return {
                ...x,
                __templateUploadedFile: value,
              };
            return x;
          }),
        },
      }));
    },
    setActivateCropForTemplateFragment: (fragmentId, newValue) => {
      set((store) => ({
        selectedStyle: {
          ...store.selectedStyle,
          fragments: store.selectedStyle.fragments.map<StyleType["fragments"][0]>((x) => {
            if (x.id === fragmentId)
              return {
                ...x,
                __crop: {...x.__crop, isActive: newValue},
              };
            return x;
          }),
        },
      }));
    },
    setCropedImageForTemplateFragment: (fragmentId, newFile) => {
      set((store) => ({
        selectedStyle: {
          ...store.selectedStyle,
          fragments: store.selectedStyle.fragments.map<StyleType["fragments"][0]>((x) => {
            if (x.id === fragmentId)
              return {
                ...x,
                __crop: {...x.__crop, croppedImage: newFile},
              };
            return x;
          }),
        },
      }));
    },
    setSelectEditFragment: (fragmentId, newValues) => {
      set((store) => ({
        selectedStyle: {
          ...store.selectedStyle,
          fragments: store.selectedStyle.fragments.map((x) => {
            if (x.id === fragmentId)
              return {
                ...x,
                ...(newValues?.value && {value: newValues.value}),
                ...(newValues?.properties && {
                  properties: {...x.properties, ...newValues?.properties},
                }),
              };
            return x;
          }),
        },
      }));
    },
    setHoverFragment: (fragmentHoverId) => {
      set(() => ({fragmentHoverId}));
    },
  }))
);

const useNFTBuilderSelector = <T extends Array<keyof NewStoreType>>(...fields: T) => {
  const fieldsToPick = Array.isArray(fields) ? fields : [fields];

  return useNewNFTBuilder((store) => pick(fieldsToPick, store), shallow);
};

export {useNFTBuilder, useNewNFTBuilder, useNFTBuilderSelector};
