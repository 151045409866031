import {useForm, useGetOrganiserDetails, useUpdateOrganiser} from "@ef-org/hooks";
import Joi from "joi";

import {useEffect, useState} from "react";

import {Button, Stack, Text} from "@chakra-ui/react";

import OrganiserInformation from "./components/OrganiserInformation";
import TabHandler from "./components/TabHandler";

export interface OrganizerFormData {
  name: string;
  support_email: string;
  bio: string;
}

const validationSchema = Joi.object({
  name: Joi.string().required().label("Name"),
  support_email: Joi.string().email({tlds: false}).required().label("Email"),
  bio: Joi.string().required().label("Description"),
});

const SettingsOrganiser = () => {
  const updateOrg = useUpdateOrganiser();
  const [showSuccess, setShowSuccess] = useState(false);
  const {handleSubmit, registerWithError, reset} = useForm<OrganizerFormData>(validationSchema);

  const onSubmit = async (data: OrganizerFormData) => {
    setShowSuccess(false);
    updateOrg(data).then(() => setShowSuccess(true));
  };

  const {data: organiserInfo} = useGetOrganiserDetails();

  useEffect(() => {
    reset(organiserInfo);
  }, [organiserInfo]);

  return (
    <>
      {showSuccess && (
        <Text
          bg="green.200"
          color="black"
          w="100%"
          textAlign="center"
          borderRadius="lg"
          p="10px"
          mb="5vh"
        >
          Saved!
        </Text>
      )}
      <TabHandler
        hideUserSetting
        rightElement={
          <Button size="md" w={["100%", "100%", "fit-content"]} onClick={handleSubmit(onSubmit)}>
            Save changes
          </Button>
        }
      />
      <Stack spacing="2rem">
        <OrganiserInformation registerWithError={registerWithError} />
        {/* <TrackingInformation register={register} /> */}
        {/* <PublicPageDesign register={register} /> */}
      </Stack>
    </>
  );
};

export default SettingsOrganiser;
