import {Link} from "@ef-org/components";

import React from "react";

import {Stack, Text} from "@chakra-ui/react";

const TicketProvider = () => {
  return (
    <Stack spacing="1rem" align="center" pt="2rem">
      <Text color="#666666">
        Ticketing provided by EventsFrame.{" "}
        <Link
          href="https://www.eventsframe.com/event-terms-conditions-privacy-policy"
          color="inherit"
        >
          Event Terms and Conditions
        </Link>
        .
      </Text>

      <Text color="#666666">
        Please contact the <Link color="inherit">event organizer</Link> for all support.
      </Text>
    </Stack>
  );
};

export default TicketProvider;
