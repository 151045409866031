import {EventStatusLabel, Link, Skeleton} from "@ef-org/components";
import {isUserAuthorized} from "apps/ef-org/src/helpers/aws/auth";
import {useDeleteEventModal} from "apps/ef-org/src/modals/useDeleteEventModal";

import React from "react";

import {useRouter} from "next/router";

import {Center, Grid, Icon, Stack, Text} from "@chakra-ui/react";

const EventListItem: React.FC<{
  asSkeleton?: boolean;
  eventId: string;
  eventName: string;
  soldTickets: number;
  tickets_total_count: number;
  soldMerch: {sold: number; total: number};
  revenue: number;
  isPublished?: boolean;
}> = ({
  asSkeleton,
  isPublished,
  eventId,
  eventName,
  revenue,
  soldMerch,
  soldTickets,
  tickets_total_count,
}) => {
  const {pathname} = useRouter();
  const {openModal: openDeleteModal} = useDeleteEventModal();

  return (
    <Grid
      py="0.5rem"
      px="2rem"
      borderRadius="12px"
      _hover={{bg: "gray.50"}}
      templateColumns="repeat(6,minmax(150px, 1fr))"
    >
      {asSkeleton ? (
        <>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
        </>
      ) : (
        <>
          <Stack direction="row" align="center">
            <Link href={`/event/${eventId}/overview`} variant="switchedDecoration">
              <Text color="#162184">{eventName}</Text>
            </Link>
          </Stack>
          <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                fill="#727CD4"
              />
            </svg>

            <Text color="#162184">
              {soldTickets} /{" "}
              <Text as="span" color="#727CD4">
                {tickets_total_count}
              </Text>
            </Text>
          </Stack>

          <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                fill="#727CD4"
              />
            </svg>

            <Text color="#162184">
              {soldMerch?.sold} /{" "}
              <Text as="span" color="#727CD4">
                {soldMerch?.total}
              </Text>
            </Text>
          </Stack>

          <Stack w="100%" direction="row" justify="center" align="center" spacing="0.5rem">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4356 21.036V2.85414H13.6004V21.036H12.4356ZM16.0791 8.49334C16.0033 7.82099 15.6908 7.30016 15.1416 6.93084C14.5924 6.55679 13.9011 6.36976 13.0677 6.36976C12.4711 6.36976 11.955 6.46446 11.5194 6.65385C11.0838 6.83851 10.7453 7.09419 10.5038 7.4209C10.2671 7.74287 10.1487 8.10982 10.1487 8.52175C10.1487 8.86739 10.2292 9.16569 10.3902 9.41664C10.5559 9.66758 10.7713 9.87828 11.0365 10.0487C11.3064 10.2145 11.5952 10.3541 11.903 10.4678C12.2107 10.5767 12.5067 10.6666 12.7907 10.7377L14.2112 11.107C14.6752 11.2206 15.1511 11.3745 15.6388 11.5686C16.1265 11.7628 16.5786 12.0184 16.9953 12.3357C17.412 12.6529 17.7481 13.0459 18.0038 13.5146C18.2642 13.9834 18.3944 14.5445 18.3944 15.1979C18.3944 16.0218 18.1814 16.7533 17.7552 17.3925C17.3338 18.0317 16.7207 18.536 15.9157 18.9053C15.1156 19.2746 14.1473 19.4593 13.0109 19.4593C11.9219 19.4593 10.9797 19.2864 10.1842 18.9408C9.38876 18.5951 8.76613 18.1051 8.31632 17.4706C7.86651 16.8314 7.61793 16.0738 7.57058 15.1979H9.77228C9.8149 15.7235 9.98535 16.1614 10.2836 16.5118C10.5867 16.8575 10.9726 17.1155 11.4413 17.286C11.9148 17.4517 12.4333 17.5345 12.9967 17.5345C13.617 17.5345 14.1686 17.4375 14.6515 17.2433C15.1392 17.0445 15.5228 16.7699 15.8021 16.4195C16.0815 16.0644 16.2211 15.6501 16.2211 15.1766C16.2211 14.7457 16.098 14.393 15.8518 14.1183C15.6104 13.8437 15.2813 13.6164 14.8646 13.4365C14.4527 13.2566 13.9863 13.098 13.4655 12.9607L11.7467 12.4919C10.5819 12.1747 9.65865 11.7083 8.97683 11.0928C8.29975 10.4772 7.9612 9.66285 7.9612 8.64959C7.9612 7.81152 8.18848 7.07999 8.64302 6.45499C9.09757 5.82999 9.7131 5.34467 10.4896 4.99902C11.2661 4.64864 12.1421 4.47345 13.1175 4.47345C14.1023 4.47345 14.9711 4.64628 15.724 4.99192C16.4816 5.33757 17.0782 5.81342 17.5138 6.41948C17.9494 7.0208 18.1766 7.71209 18.1956 8.49334H16.0791Z"
                fill="#727CD4"
              />
            </svg>

            <Text color="#162184">{revenue}</Text>
          </Stack>

          <Center>
            <EventStatusLabel isPublished={isPublished} />
          </Center>
          {isUserAuthorized("admin") && (
            <Stack direction="row" align="center" justify="flex-end" spacing="1rem">
              <Link
                href={`/event/${eventId}/edit?&backToUrl=${encodeURIComponent(pathname)}`}
                variant="switchedDecoration"
              >
                <Text color="#4754C6">Edit</Text>
              </Link>
              <Icon
                onClick={() => openDeleteModal({eventId})}
                cursor="pointer"
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33317 8.33333C8.67834 8.33333 8.95817 8.61317 8.95817 8.95833V13.5417C8.95817 13.8868 8.67834 14.1667 8.33317 14.1667C7.988 14.1667 7.70817 13.8868 7.70817 13.5417V8.95833C7.70817 8.61317 7.988 8.33333 8.33317 8.33333Z"
                  fill="#D85639"
                />
                <path
                  d="M12.2915 8.95833C12.2915 8.61317 12.0117 8.33333 11.6665 8.33333C11.3213 8.33333 11.0415 8.61317 11.0415 8.95833V13.5417C11.0415 13.8868 11.3213 14.1667 11.6665 14.1667C12.0117 14.1667 12.2915 13.8868 12.2915 13.5417V8.95833Z"
                  fill="#D85639"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.24984 4.16667V2.91667C6.24984 1.99619 6.99603 1.25 7.9165 1.25H12.0832C13.0037 1.25 13.7498 1.99619 13.7498 2.91667V4.16667H17.7082C18.0533 4.16667 18.3332 4.44649 18.3332 4.79167C18.3332 5.13684 18.0533 5.41667 17.7082 5.41667H16.593L15.9254 16.7645C15.8737 17.6455 15.1441 18.3333 14.2617 18.3333H5.73801C4.85555 18.3333 4.12605 17.6455 4.07422 16.7645L3.4067 5.41667H2.2915C1.94633 5.41667 1.6665 5.13684 1.6665 4.79167C1.6665 4.44649 1.94633 4.16667 2.2915 4.16667H6.24984ZM7.9165 2.5H12.0832C12.3133 2.5 12.4998 2.68655 12.4998 2.91667V4.16667H7.49984V2.91667C7.49984 2.68655 7.68639 2.5 7.9165 2.5ZM4.65886 5.41667L5.32206 16.6912C5.33502 16.9113 5.5174 17.0833 5.73801 17.0833H14.2617C14.4823 17.0833 14.6647 16.9113 14.6776 16.6912L15.3408 5.41667H4.65886Z"
                  fill="#D85639"
                />
              </Icon>
            </Stack>
          )}
        </>
      )}
    </Grid>
  );
};

export default EventListItem;
