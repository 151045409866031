import React from "react";

import {Box, Center, Grid, Stack} from "@chakra-ui/react";
import {GridProps} from "@chakra-ui/styled-system";

import Skeleton from "../Skeleton";

export const InvoiceTableItem: React.FC<{
  children?: React.ReactNode;
  noHover?: boolean;
  templateColumns?: GridProps["gridTemplateColumns"];
}> = ({children, templateColumns, noHover}) => {
  return (
    <Grid
      py="0.5rem"
      px="2rem"
      borderRadius="12px"
      _hover={noHover ? {} : {bg: "gray.50"}}
      templateColumns={templateColumns}
    >
      {children}
    </Grid>
  );
};
export const InvoicesTable: React.FC<{
  templateColumns?: GridProps["gridTemplateColumns"];
  headerComponents: React.ReactNode;
  isLoading?: boolean;
  colsSkeletonNumber?: number;
  bodyComponents?: React.ReactNode;
  emptyMessage?: string;
}> = ({
  bodyComponents,
  templateColumns = "repeat(6, minmax(150px, 1fr))",
  headerComponents,
  isLoading,
  colsSkeletonNumber,
  emptyMessage,
}) => {
  return (
    <Box overflowX="auto" pb="1rem" bg="white" borderRadius="12px">
      <Box p="1rem 1rem 0 1rem">
        <Grid
          px="2rem"
          templateColumns={templateColumns}
          sx={
            {
              // p: {textAlign: "center", w: "100%", color: "#727CD4", fontsize: "16px"},
              // "p:first-child, .firstItem": {textAlign: "left"},
              // "p:last-child": {textAlign: "right"},
            }
          }
        >
          {headerComponents}
        </Grid>

        <Box w="100%" mb="1.5rem" mt="1rem" h="1px" bg="#C9CDEE" />

        {emptyMessage && (
          <Center h="100px" color="gray.500" fontSize="16px">
            {emptyMessage}
          </Center>
        )}

        <Stack spacing="0">
          {isLoading && (
            <Grid px="2rem" templateColumns={templateColumns}>
              {new Array(colsSkeletonNumber || 6).fill(0).map((_, i) => (
                <Center key={i} h="50px">
                  <Skeleton h="30px" />
                </Center>
              ))}
            </Grid>
          )}

          {!isLoading && bodyComponents}
        </Stack>
      </Box>
    </Box>
  );
};
