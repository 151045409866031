import {PaymentState} from "@ef-org/api";
import {Link, Skeleton} from "@ef-org/components";
import styled from "@emotion/styled";
import {DateTime} from "luxon";

import React, {useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Center, Grid, Stack, Text, Tooltip} from "@chakra-ui/react";

const StyledText = styled(Text)`
  max-width: 90%;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
`;

const InvoiceListItem: React.FC<{
  asSkeleton?: boolean;
  invoiceId: string;
  startDate: string;
  endDate: string;
  invoiceAmount: number;
  status: PaymentState;
  download: string;
}> = ({asSkeleton, invoiceId, startDate, endDate, invoiceAmount, status, download}) => {
  const {pathname} = useRouter();
  const [startLocale] = useState(DateTime.fromISO(startDate).setZone("local").toLocaleString());
  const [endLocale] = useState(DateTime.fromISO(endDate).setZone("local").toLocaleString());

  const downloadFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    // Create an anchor element and programmatically click it to trigger download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "file.pdf"; // You can also get filename from response headers if the server sends it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid
      py="0.5rem"
      px="2rem"
      borderRadius="12px"
      _hover={{bg: "gray.50"}}
      templateColumns="repeat(6,minmax(170px, 1fr))"
    >
      {asSkeleton ? (
        <>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
        </>
      ) : (
        <>
          <Stack direction="row" align="center">
            <StyledText color="#162184">#{invoiceId}</StyledText>
          </Stack>
          <Stack w="100%" direction="row" align="center" spacing="0.5rem">
            <Box paddingBottom="4px">
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.6001 2.67969H4.8501V1.42969C4.8501 1.01548 5.18589 0.679688 5.6001 0.679688C6.01431 0.679688 6.3501 1.01548 6.3501 1.42969V2.67969H14.8501V1.42969C14.8501 1.01548 15.1859 0.679688 15.6001 0.679688C16.0143 0.679688 16.3501 1.01548 16.3501 1.42969V2.67969H18.6001C19.7047 2.67969 20.6001 3.57512 20.6001 4.67969V16.6797C20.6001 17.7843 19.7047 18.6797 18.6001 18.6797H2.6001C1.49553 18.6797 0.600098 17.7843 0.600098 16.6797V4.67969C0.600098 3.57512 1.49553 2.67969 2.6001 2.67969ZM18.6001 4.17969C18.8762 4.17969 19.1001 4.40355 19.1001 4.67969V6.67969H2.1001V4.67969C2.1001 4.40355 2.32396 4.17969 2.6001 4.17969H18.6001ZM2.6001 17.1797C2.32396 17.1797 2.1001 16.9558 2.1001 16.6797V8.17969H19.1001V16.6797C19.1001 16.9558 18.8762 17.1797 18.6001 17.1797H2.6001Z"
                  fill="#727CD4"
                />
              </svg>
            </Box>

            <Text color="#162184">{startLocale}</Text>
          </Stack>
          <Stack w="100%" direction="row" align="center" spacing="0.5rem">
            <Box paddingBottom="4px">
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.6001 2.67969H4.8501V1.42969C4.8501 1.01548 5.18589 0.679688 5.6001 0.679688C6.01431 0.679688 6.3501 1.01548 6.3501 1.42969V2.67969H14.8501V1.42969C14.8501 1.01548 15.1859 0.679688 15.6001 0.679688C16.0143 0.679688 16.3501 1.01548 16.3501 1.42969V2.67969H18.6001C19.7047 2.67969 20.6001 3.57512 20.6001 4.67969V16.6797C20.6001 17.7843 19.7047 18.6797 18.6001 18.6797H2.6001C1.49553 18.6797 0.600098 17.7843 0.600098 16.6797V4.67969C0.600098 3.57512 1.49553 2.67969 2.6001 2.67969ZM18.6001 4.17969C18.8762 4.17969 19.1001 4.40355 19.1001 4.67969V6.67969H2.1001V4.67969C2.1001 4.40355 2.32396 4.17969 2.6001 4.17969H18.6001ZM2.6001 17.1797C2.32396 17.1797 2.1001 16.9558 2.1001 16.6797V8.17969H19.1001V16.6797C19.1001 16.9558 18.8762 17.1797 18.6001 17.1797H2.6001Z"
                  fill="#727CD4"
                />
              </svg>
            </Box>
            <Text color="#162184">{endLocale}</Text>
          </Stack>

          <Stack w="100%" direction="row" align="center" spacing="0.5rem">
            <Box paddingBottom="4px">
              <svg
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.03598 18.7147V0.532847H6.20075V18.7147H5.03598ZM8.67944 6.17205C8.60369 5.4997 8.29119 4.97887 7.74194 4.60955C7.1927 4.2355 6.50141 4.04847 5.66808 4.04847C5.07149 4.04847 4.55539 4.14317 4.11978 4.33256C3.68418 4.51722 3.34564 4.7729 3.10416 5.09961C2.86742 5.42158 2.74905 5.78853 2.74905 6.20046C2.74905 6.54611 2.82954 6.8444 2.99052 7.09535C3.15624 7.34629 3.37168 7.557 3.63683 7.72745C3.90672 7.89317 4.19554 8.03285 4.50331 8.14648C4.81107 8.25539 5.107 8.34535 5.39109 8.41637L6.81155 8.78569C7.27556 8.89933 7.75141 9.05321 8.2391 9.24734C8.72679 9.44147 9.17897 9.69715 9.59564 10.0144C10.0123 10.3316 10.3485 10.7246 10.6042 11.1934C10.8646 11.6621 10.9948 12.2232 10.9948 12.8766C10.9948 13.7005 10.7817 14.432 10.3556 15.0712C9.93418 15.7104 9.32102 16.2147 8.51609 16.584C7.7159 16.9533 6.74763 17.138 5.61126 17.138C4.52225 17.138 3.58001 16.9651 2.78456 16.6195C1.9891 16.2739 1.36647 15.7838 0.916659 15.1493C0.466849 14.5101 0.218269 13.7525 0.170921 12.8766H2.37263C2.41524 13.4022 2.58569 13.8401 2.88399 14.1905C3.18702 14.5362 3.57291 14.7942 4.04166 14.9647C4.51514 15.1304 5.03361 15.2132 5.59706 15.2132C6.21732 15.2132 6.76893 15.1162 7.25189 14.9221C7.73958 14.7232 8.1231 14.4486 8.40246 14.0982C8.68181 13.7431 8.82149 13.3288 8.82149 12.8553C8.82149 12.4244 8.69838 12.0717 8.45217 11.7971C8.21069 11.5224 7.88162 11.2952 7.46496 11.1152C7.05302 10.9353 6.58664 10.7767 6.06581 10.6394L4.34706 10.1706C3.18228 9.8534 2.25899 9.38701 1.57717 8.77148C0.900087 8.15595 0.561546 7.34156 0.561546 6.3283C0.561546 5.49023 0.788818 4.7587 1.24336 4.1337C1.69791 3.5087 2.31344 3.02338 3.08995 2.67773C3.86647 2.32736 4.74242 2.15217 5.7178 2.15217C6.70264 2.15217 7.57149 2.32499 8.32433 2.67063C9.08191 3.01628 9.6785 3.49213 10.1141 4.09819C10.5497 4.69951 10.777 5.3908 10.7959 6.17205H8.67944Z"
                  fill="#727CD4"
                />
              </svg>
            </Box>
            <Text color="#162184">&nbsp;{invoiceAmount}</Text>
          </Stack>

          <Stack w="100%" direction="row" align="center" spacing="0.5rem">
            {/*<Text color="#162184">{status}</Text>*/}
            {status === "paid" && (
              <Button
                fontSize="14px"
                fontWeight="500"
                height="40px"
                background="#D5F8D4"
                color="#2D802B"
                pointerEvents="none"
              >
                Paid
              </Button>
            )}
            {status === "waiting_for_payment" && (
              <Button
                px="1rem"
                variant="link"
                fontSize="14px"
                fontWeight="500"
                height="40px"
                background="#F6D6CF"
                color="#B84930"
              >
                Unpaid
              </Button>
            )}
          </Stack>

          <Stack flexDirection="row" align="center" justifyContent="right">
            <Link marginLeft="12px" href={download} target="_blank">
              {/*<Link marginLeft="12px" onClick={downloadFile(downloadFile(download))}> CORS ERROR */}
              <Tooltip label="Download">
                <svg
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Vector"
                    d="M12.8244 11.748C12.9095 11.6628 13.0416 11.6517 13.1383 11.7155L13.1871 11.7577C13.2638 11.843 13.2717 11.9689 13.2102 12.062L13.1649 12.1145L8.18019 17.0981L8.18015 17.0982C8.09513 17.1832 7.96315 17.1943 7.86639 17.1306L7.81397 17.0854L2.82335 12.1016C2.82334 12.1016 2.82334 12.1016 2.82334 12.1016C2.72564 12.004 2.72554 11.8458 2.8231 11.7481L2.82314 11.7481C2.90826 11.6628 3.04038 11.6517 3.13714 11.7156L3.1897 11.7608L6.8957 15.4615L7.74891 16.3135L7.749 15.1077L7.75 1.42972V1.42969C7.75 1.3078 7.83771 1.20559 7.95323 1.18397L8.01234 1.17999C8.12871 1.18567 8.22481 1.27125 8.24576 1.38289L8.25 1.44635L8.249 15.1147L8.24891 16.3216L9.10248 15.4683L12.8243 11.748L12.8244 11.748ZM14.4999 19.6747C14.6381 19.6747 14.75 19.7867 14.75 19.9245C14.75 20.0628 14.638 20.1747 14.5001 20.1747L1.50007 20.1785C1.36187 20.1785 1.25 20.0665 1.25 19.9287C1.25 19.7904 1.362 19.6785 1.4999 19.6785L14.4999 19.6747Z"
                    fill="#727CD4"
                    stroke="#4754C6"
                  />
                </svg>
              </Tooltip>
            </Link>
          </Stack>
        </>
      )}
    </Grid>
  );
};

export default InvoiceListItem;
