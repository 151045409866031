import {
  SubscriptionPlanRead,
  useSetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostMutation,
} from "@ef-org/api";
import {IconTarget} from "@ef-org/icons";
import {useCompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostMutation} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import {getUserId} from "apps/ef-org/src/helpers/aws/auth";
import Joi from "joi";
import Cookies from "js-cookie";

import React, {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {InfoIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {Box, Button, Divider, Progress, Spinner, Stack, Text, useToast} from "@chakra-ui/react";

import Input from "../../../components/Input";
import useForm, {OnFormSubmitType} from "../../../hooks/useForm";

const FormSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
});

type FormType = {
  email: string;
};

type Props = {
  setupComplete(): void;
  plan: SubscriptionPlanRead;
};

enum OnboardingProcessSteps {
  INITIAL = "initial",
  REDIRECTING_REGISTER = "redirecting_create",
  REDIRECTING_LINK = "redirecting_link",
  ACCOUNT_CREATED = "account_created",
  ACCOUNT_LINKED = "account_linked",
  ONBOARDING_COMPLETE = "onboarding_complete",
}

const getProgress = (processStep: OnboardingProcessSteps, isLoading: boolean) => {
  switch (processStep) {
    case OnboardingProcessSteps.REDIRECTING_REGISTER:
      return 33;
    case OnboardingProcessSteps.REDIRECTING_LINK:
      return 33;
    case OnboardingProcessSteps.ACCOUNT_CREATED:
      return 66;
    case OnboardingProcessSteps.ACCOUNT_LINKED:
      return 66;
    case OnboardingProcessSteps.ONBOARDING_COMPLETE:
      return 100;
    default:
      return 0;
  }
};

const SubscriptionActivateRyft: React.FC<Props> = ({setupComplete, plan}) => {
  const toast = useToast();
  const organizerId = Cookies.get("organizerId");
  const {query, replace, asPath, pathname} = useRouter();
  const [isLoading, setLoading] = useState(false);

  const [processStep, setProcessStep] = useState<OnboardingProcessSteps>(
    (query?.ryftStep as OnboardingProcessSteps) ?? OnboardingProcessSteps.INITIAL
  );
  const [initiateSetup] =
    useSetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostMutation();
  const [completeSetup] =
    useCompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostMutation();

  useEffect(() => {
    if (processStep === OnboardingProcessSteps.ACCOUNT_CREATED) {
      completeRyftSetup();
    }
    if (processStep === OnboardingProcessSteps.ACCOUNT_LINKED) {
      completeRyftSetup();
    }
  }, [processStep]);

  const completeRyftSetup = async () => {
    const res = await completeSetup({
      userId: getUserId(),
      organizerId: organizerId,
      completeRyftPaymentMethodPayload: {
        subscription_plan_id: plan.id,
        sub_account_id: query?.account ? query?.account.toString() : null,
      },
    });

    if ("data" in res && res) {
      setProcessStep(OnboardingProcessSteps.ONBOARDING_COMPLETE);
      const {ryftSetup, ryftStep, planId, account, ...routerQuery} = query;
      replace({pathname, query: routerQuery}, undefined, {shallow: true});
    } else {
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const {registerWithError, handleSubmit} = useForm<FormType>(FormSchema, {
    keepDataOnSuccess: true,
    defaultValues: {email: ""},
  });
  const handleFormSubmit: OnFormSubmitType<FormType> = async (data) => {
    setLoading(true);
    const isQueryEmpty = Object.keys(query).length === 0;
    const routePrefix = `${window.location.origin}${asPath}${
      isQueryEmpty ? "?" : "&"
    }ryftSetup=true`;
    const register_redirect = `${routePrefix}&ryftStep=${OnboardingProcessSteps.ACCOUNT_CREATED}&planId=${plan.id}`;
    const authorize_redirect = `${routePrefix}&ryftStep=${OnboardingProcessSteps.ACCOUNT_LINKED}&planId=${plan.id}`;
    const res = await initiateSetup({
      userId: getUserId(),
      organizerId: organizerId,
      setupRyftPaymentMethodPayload: {
        email_address: data.email,
        register_redirect,
        authorize_redirect,
      },
    });

    if ("data" in res && res.data.register_url) {
      setProcessStep(OnboardingProcessSteps.REDIRECTING_REGISTER);
      setTimeout(function () {
        window.location.href = res.data.register_url;
      }, 3000);
    } else if ("data" in res && res.data.authorize_url) {
      setProcessStep(OnboardingProcessSteps.REDIRECTING_LINK);
      setTimeout(function () {
        window.location.href = res.data.authorize_url;
      }, 3000);
    } else {
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w="100%" h="100%" borderRadius="12px">
      <Text h="10%" fontSize="20px" fontWeight="600" fontFamily="Oceanwide" pb="2rem">
        Activate subscription and create/connect your Ryft account
      </Text>
      {processStep !== OnboardingProcessSteps.INITIAL && (
        <Progress
          value={getProgress(processStep, isLoading)}
          bgColor="#C9CDEE"
          borderRadius="20px"
        />
      )}
      {processStep === OnboardingProcessSteps.INITIAL && (
        <Stack
          pt="1rem"
          h="90%"
          justifyContent="space-between"
          as="form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Stack spacing="1rem">
            <Input
              w="100%"
              labelColor="primary-2"
              placeholder="Enter your email"
              {...registerWithError("email")}
              noBorder
            />
            <Divider />
            <Stack direction="row" gap="1rem" align="center">
              <IconTarget />
              <Text>
                Save on payment processing fees (not a cost to your business vs Stripe fees)
              </Text>
            </Stack>
            <Stack direction="row" gap="1rem" align="center">
              <IconTarget />
              <Text>Volume based rebate - 2%</Text>
            </Stack>
            <Stack direction="row" gap="1rem" align="center">
              <IconTarget />
              <Text>Get paid immediately</Text>
            </Stack>
            <Stack direction="row" gap="1rem" align="center">
              <IconTarget />
              <Text>Pass on fees feature</Text>
            </Stack>
          </Stack>
          <Stack bgColor="#F6F7FB" borderRadius="16px" direction="row" p="1rem" align="center">
            <InfoOutlineIcon color="ef-primary" mr="0.5rem" />
            <Stack>
              <Text color="#3C3C76" fontSize="15px" fontWeight="400">
                Check your spam folder in case you do not receive the confirmation email from ryft
              </Text>
            </Stack>
          </Stack>

          <Button type="submit" w="100%" isLoading={isLoading}>
            Add Ryft account
          </Button>
        </Stack>
      )}
      {(processStep === OnboardingProcessSteps.REDIRECTING_REGISTER ||
        processStep === OnboardingProcessSteps.REDIRECTING_LINK) && (
        <Stack direction="column" pt="2rem" alignItems="center">
          <Spinner boxSize={8} color="ef-primary" />
          <Text my="1rem" textAlign="center" size="2rem">
            We are redirecting you to Ryft website to{" "}
            {processStep === OnboardingProcessSteps.REDIRECTING_REGISTER ? "create" : "link"} your
            account
          </Text>
        </Stack>
      )}
      {(processStep === OnboardingProcessSteps.ACCOUNT_CREATED ||
        processStep === OnboardingProcessSteps.ACCOUNT_LINKED) && (
        <Stack direction="column" pt="2rem" alignItems="center">
          <Spinner boxSize={8} color="ef-primary" />
          <Text my="1rem" textAlign="center" size="2rem">
            We're finalizing the setup for you. This will only take a few seconds.
          </Text>
        </Stack>
      )}
      {processStep === OnboardingProcessSteps.ONBOARDING_COMPLETE && (
        <Stack direction="column" pt="2rem" alignItems="center">
          {/* <CheckIcon boxSize={8} color="ef-primary" /> */}
          <Text
            textAlign="center"
            fontSize="32px"
            fontWeight="600"
            mb="1rem"
            fontFamily="Oceanwide"
            fontStyle="normal"
            lineHeight="normal"
          >
            🎉 Your subscription is activated!
          </Text>
          <Text my="1rem" textAlign="center" size="2rem">
            Congratulations on activating your subscription and linking your Ryft account! You are
            now all set to receive payments for your events.
          </Text>
          <Button w="100%" size="md" onClick={setupComplete}>
            Start now!🔥
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default SubscriptionActivateRyft;
