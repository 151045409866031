import React from "react";

import {Button, Stack} from "@chakra-ui/react";

import {useInviteTeamMemberModal} from "../../modals/useInviteTeamMemberModal";
import TabHandler from "./components/TabHandler";
import TeamMembersTable from "./components/TeamMembersTable";

const SettingsTeamMembers: React.FC = () => {
  const {openModal} = useInviteTeamMemberModal();
  return (
    <>
      <TabHandler hideUserSetting />
      <Stack spacing="2rem">
        <Button onClick={openModal}>Add New Team Member</Button>
        <TeamMembersTable />
      </Stack>
    </>
  );
};

export default SettingsTeamMembers;
