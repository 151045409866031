import * as React from "react";

import {Flex, Heading, Image, Text} from "@chakra-ui/react";

import {TStep} from "../Onboarding";

const data: Record<TStep, {img: string; heading: string; text: string}> = {
  obSignup: {
    img: "/images/onboarding/rocket.png",
    heading: "Join the future of event management",
    text: "Get access to our unique set of revolutionary tools for event organization.",
  },
  obConfirmation: {
    img: "/images/onboarding/shirt.png",
    heading: "Create digital merch",
    text: "Easily create and sell digital items to bring your eventgoers the freshest fan experience.",
  },
  obPassword: {
    img: "/images/onboarding/wallet.png",
    heading: "Monetize smarter",
    text: "Sell digital assets with less overhead cost and build robust communities.",
  },
  obMoreInfo: {
    img: "/images/onboarding/graphs.png",
    heading: "Get full control over your event",
    text: "We provide all the necessary tools for analytics and event management.",
  },
};

const RightSide: React.FC<{stepName: TStep}> = ({stepName}) => {
  return (
    <>
      <Image alt="rocket" src={data[stepName].img} />
      <Flex direction="column" w="60%" align="center">
        <Heading textAlign="center" color="ob-white" variant="h2">
          {data[stepName].heading}
        </Heading>
        <Text color="ob-white" textAlign="center" mt="16px">
          {data[stepName].text}
        </Text>
      </Flex>
    </>
  );
};

export default RightSide;
