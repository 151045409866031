import {OrderRead} from "@ef-org/api";
import {EventTable} from "@ef-org/components";
import {format, parseISO} from "date-fns";
import Cookies from "js-cookie";

import {useEffect, useState} from "react";

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  Box,
  DrawerFooter,
} from "@chakra-ui/react";

import {useGetOrdersForOrganizerOrganizersOrganizerIdOrdersGetQuery} from "../../api/__generated__/beOrgApi.extended";
import OrderDetail, {OrderDetailActions} from "./components/OrderDetail";
import OrderListItem from "./components/OrderListItem";

const OrderListPage = () => {
  const {data, isLoading} = useGetOrdersForOrganizerOrganizersOrganizerIdOrdersGetQuery(
    {organizerId: Cookies.get("organizerId")},
    {refetchOnMountOrArgChange: true}
  );

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState<OrderRead | null>(null);

  useEffect(() => {
    if (selectedOrder) {
      onOpen();
    } else {
      onClose();
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (data) {
      const refreshedOrder = data.items.find((o) => o.id === selectedOrder?.id);
      setSelectedOrder(refreshedOrder);
    }
  }, [data]);

  return (
    <>
      {/* <Stack
        direction={["column", "column", "row"]}
        align="center"
        w="100%"
        justify="space-between"
        mb="2rem"
      >
        <Stack
          direction={["column", "column", "row"]}
          w={["100%", "100%", "fit-content"]}
          spacing="0.5rem"
        >
          <Select
            variant="white"
            defaultValue="all"
            w={["100%", "100%", "200px"]}
            size="md"
            borderRadius="12px"
          >
            <option value="all">Filter by</option>
          </Select>
          <InputGroup size="md" bg="white" borderRadius="12px" w={["100%", "100%", "200px"]}>
            <Input placeholder="Search..." border="0" borderRadius="12px" />
            <InputRightElement>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0556 2C14.5046 2 18.1111 5.6066 18.1111 10.0556C18.1111 11.9799 17.4363 13.7466 16.3106 15.132L21.7559 20.5775C22.0813 20.9029 22.0813 21.4305 21.7559 21.7559C21.4601 22.0518 20.9971 22.0787 20.6709 21.8366L20.5775 21.7559L15.132 16.3106C13.7466 17.4363 11.9799 18.1111 10.0556 18.1111C5.6066 18.1111 2 14.5046 2 10.0556C2 5.6066 5.6066 2 10.0556 2ZM10.0556 3.66667C6.52707 3.66667 3.66667 6.52707 3.66667 10.0556C3.66667 13.584 6.52707 16.4445 10.0556 16.4445C13.584 16.4445 16.4445 13.584 16.4445 10.0556C16.4445 6.52707 13.584 3.66667 10.0556 3.66667Z"
                  fill="#727CD4"
                />
              </svg>
            </InputRightElement>
          </InputGroup>
        </Stack>
      </Stack> */}

      <EventTable
        colsSkeletonNumber={8}
        templateColumns="repeat(6,minmax(150px, 1fr))"
        headerComponents={
          <>
            <Text className="firstItem">Name</Text>
            <Text>Order ID</Text>
            <Text>Customer</Text>
            <Text>Date</Text>
            <Text>Value</Text>
            <Text>Status</Text>
          </>
        }
        bodyComponents={
          <>
            {data?.items.map((item, i) => (
              <Box onClick={() => setSelectedOrder(item)}>
                <OrderListItem
                  key={item.id}
                  isPaid={item.state === "paid"}
                  orderId={item.readable_id}
                  eventName={item.event.name}
                  currency={item.currency}
                  customer={item.billing_details.first_name}
                  date={format(parseISO(item.payment?.created_at || ""), "yyyy-MM-dd HH:mm")}
                  value={item.price}
                />
              </Box>
            ))}
          </>
        }
      />
      <Drawer placement="right" onClose={() => setSelectedOrder(null)} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Text fontWeight="normal" fontSize="24px" py="1rem">
              Order #{selectedOrder?.readable_id}
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <OrderDetail order={selectedOrder} />
          </DrawerBody>
          <DrawerFooter>
            <OrderDetailActions order={selectedOrder} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OrderListPage;
