import {SubscriptionPlanRead} from "@ef-org/api";
import {IconTarget} from "@ef-org/icons";

import React from "react";

import {Stack, Image, Text, Button} from "@chakra-ui/react";

interface SubscriptionPlan extends SubscriptionPlanRead {
  isActive: boolean;
  noActivePlans: boolean;
}

type Props = {
  planPicked(plan: SubscriptionPlanRead): void;
  plan: SubscriptionPlan;
};

const SubscriptionDetail: React.FC<Props> = ({plan, planPicked}) => {
  return (
    <Stack w="320px" bg="#FAFAFD" borderRadius="20px" justifyContent="space-between" padding="20px">
      <Stack gap="20px" justifyContent="left">
        <Image w="75px" h="30px" src={`/images/payments/${plan.payment_gateway}.png`} />
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>
            {plan.fee_percentage}% fee {plan.payment_gateway === "ryft" && " + $0.25"} per ticket
            sold
          </Text>
        </Stack>
        {plan.payment_gateway === "stripe" && (
          <Stack direction="row" gap="1rem" align="start">
            <IconTarget mt="4px" />
            <Text>Connect your own Stripe account</Text>
          </Stack>
        )}
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>
            {plan.payment_gateway === "ryft"
              ? "Save on payment processing fees (not a cost to your business vs Stripe fees)"
              : "Does not include your Stripe fees"}
          </Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Volume based rebate - {plan.payment_gateway === "stripe" ? "1%" : "2%"}</Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>
            {plan.payment_gateway === "stripe"
              ? "Card on file charged once a month"
              : "Get paid immediately"}
          </Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Pass on fees feature</Text>
        </Stack>
      </Stack>

      {plan.isActive ? (
        <Stack
          w="100%"
          h="40px"
          align="center"
          justify="center"
          bgGradient="linear-gradient(118deg, #A5FFB9 28.1%, #58F5FF 176.66%)"
          borderRadius="12px"
        >
          <Text>Active</Text>
        </Stack>
      ) : (
        <Button onClick={() => planPicked(plan)} size="md" w="100%">
          {plan.noActivePlans ? "Activate subscription" : `Switch to ${plan.payment_gateway} plan`}
        </Button>
      )}
    </Stack>
  );
};

export default SubscriptionDetail;
