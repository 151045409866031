import {useCreateIntegration, useDeleteIntegration} from "@ef-org/hooks";
import {useZapierIntegrationModal} from "apps/ef-org/src/modals/useZapierIntegrationModal";
import Cookies from "js-cookie";

import {CopyIcon} from "@chakra-ui/icons";
import {Button, Stack, useToast, Text, IconButton} from "@chakra-ui/react";

export const ZapierContent: React.FC<{isAuthorized: boolean; id: string; apiKey: string}> = ({
  isAuthorized,
  id,
  apiKey,
}) => {
  const toast = useToast();
  const {openModal: openZapierModal} = useZapierIntegrationModal();

  const createIntegration = useCreateIntegration();
  const deleteIntegration = useDeleteIntegration();

  const organizerId = Cookies.get("organizerId");

  const createZapierIntegration = async () => {
    const res = await createIntegration({
      type: "zapier",
    });
    if (!("data" in res && res.data.id)) {
      toast({
        title: "Ohh no!",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      toast({
        title: "Success",
        description: "API key generated",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return !isAuthorized ? (
    <Button w="100%" variant="outline" onClick={createZapierIntegration}>
      Generate API Key
    </Button>
  ) : (
    <Stack>
      <Button w="100%" variant="outline" onClick={() => openZapierModal({apiKey, organizerId})}>
        Get Integration Info
      </Button>
      <Button
        w="100%"
        variant="outline"
        borderColor={!isAuthorized ? undefined : "#D85639"}
        color={!isAuthorized ? undefined : "#D85639"}
        onClick={() => deleteIntegration(id)}
      >
        Unathorize
      </Button>
    </Stack>
  );
};
