import {
  useUpdateUserUsersUserIdPatchMutation,
  UserDiscoveredByOption,
  useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation,
  OrganizerEventType,
  OrganizerEventExpectedAudienceSizeOption,
} from "apps/ef-org/src/api/__generated__/beOrgApi.generated";
import {getUserId} from "apps/ef-org/src/helpers/aws/auth";
import useCookie from "apps/ef-org/src/hooks/useCookie";

import {useMemo, useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Heading, Select, Stack, Text, useToast} from "@chakra-ui/react";

const ConfirmationForm = () => {
  const {push, query} = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [discoveredBy, setDiscoveredBy] = useState<UserDiscoveredByOption | null>(null);
  const [eventType, setEventType] = useState<OrganizerEventType | null>(null);
  const [audienceSize, setAudienceSize] = useState<OrganizerEventExpectedAudienceSizeOption | null>(
    null
  );

  const [updateUser, {data, isSuccess}] = useUpdateUserUsersUserIdPatchMutation();
  const [updateOrg] = useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation();
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const handleFormSubmit = async () => {
    await Promise.all([
      updateUser({userId: getUserId(), userUpdate: {discovered_by: discoveredBy}}),
      updateOrg({
        userId: getUserId(),
        organizerId: organizerId,
        organizerUpdate: {event_type: eventType, expected_event_audience_size: audienceSize},
      }),
    ]);
    push("/events/promo");
  };

  const valid = useMemo(() => {
    return !!(discoveredBy && eventType && audienceSize);
  }, [audienceSize, eventType, discoveredBy]);

  return (
    <Box w="100%">
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="1rem">
        Congrats!
        <br />
        Your account is created!
      </Heading>

      <Text w="100%" textAlign="center" color="black" fontWeight="bold" pb="2rem">
        Tell us more about yourself!
      </Text>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Stack spacing="1rem">
          <Stack>
            <Text variant="form-label" size="small">
              What types of events do you organize?
            </Text>
            <Select
              onChange={(e) => setEventType(e.target.value as OrganizerEventType)}
              placeholder="Select the type"
            >
              <option value="concert">Concert</option>
              <option value="conference">Conference</option>
              <option value="sport">Sport</option>
            </Select>
          </Stack>
          <Stack>
            <Text variant="form-label" size="small">
              How many people attend your events?
            </Text>
            <Select
              onChange={(e) =>
                setAudienceSize(e.target.value as OrganizerEventExpectedAudienceSizeOption)
              }
              placeholder="Select the number"
            >
              <option value="0_1000">0 - 1000 people</option>
              <option value="1000_10000">1000 - 10,000 people</option>
              <option value="10000_">10,000 and more</option>
            </Select>
          </Stack>
          <Stack>
            <Text variant="form-label" size="small">
              How did you discover EventsFrame?
            </Text>
            <Select
              onChange={(e) => setDiscoveredBy(e.target.value as UserDiscoveredByOption)}
              placeholder="Select the source"
            >
              <option value="social_media">Social Media (Instagram, Facebook...)</option>
              <option value="friends">Referal</option>
              <option value="other">Other</option>
            </Select>
          </Stack>

          <Stack direction={["column", "row", "row"]} spacing="1rem">
            <Button onClick={() => push("/events")} w="100%" variant="neutral" size="md">
              Skip
            </Button>
            <Button type="submit" w="100%" isDisabled={!valid} isLoading={isLoading} size="md">
              Next
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default ConfirmationForm;
