import {beOrgApi} from "@ef-org/api";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";

import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Button, Divider, Stack, Text} from "@chakra-ui/react";

import {
  useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation,
  useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery,
} from "../api/__generated__/beOrgApi.extended";
import useCookie from "../hooks/useCookie";
import {IconTarget} from "./Icons";

interface Props {
  success({billingMethodId}): void;
}

const PaymentIntent = ({success}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const dispatch = useDispatch();
  const [registerBillingMethod, {data: billingMethodRead}] =
    useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation();

  const {data: billingMethods} =
    useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery(
      {organizerId},
      {skip: !organizerId}
    );

  useEffect(() => {
    const createConfirmationPaymentIntent = async () => {
      if (!organizerId) {
        return;
      }

      if (!billingMethods) {
        return;
      }
      const pendingPaymentId = billingMethodRead?.payment.details.stripe_payment_intent_id;
      const oldPendingPayment = billingMethods.items.find(
        (method) => method.payment?.state === "waiting_for_payment"
      );

      const stripeResponse = await stripe.confirmCardPayment(
        pendingPaymentId || oldPendingPayment?.payment.details.stripe_payment_intent_id,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );

      if (stripeResponse?.paymentIntent?.status === "succeeded") {
        success({billingMethodId: billingMethodRead?.id || oldPendingPayment.id});
        //@ts-expect-error
        dispatch(beOrgApi.util.invalidateTags(["subscriptions", "billingMethods"]));
      }
      setIsLoading(false);
    };
    createConfirmationPaymentIntent();
  }, [billingMethodRead]);

  const confirmPayment = useCallback(async () => {
    setIsLoading(true);
    await registerBillingMethod({
      organizerId,
      billingMethodCreate: {
        payment_gateway: "stripe",
        is_default: true,
      },
    });
  }, [organizerId]);

  return (
    <Stack h="90%" pt="20px" justifyContent="space-between">
      <Stack spacing="1rem">
        <Box>
          <Text fontSize="16px" fontWeight="400" color="#656592">
            Payment card
          </Text>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  color: "#32325d",
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSmoothing: "antialiased",
                  fontSize: "16px",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                  backgroundColor: "white",
                  lineHeight: "50px",
                },
                invalid: {
                  color: "#fa755a",
                  iconColor: "#fa755a",
                },
              },
            }}
          />
        </Box>

        <Divider />

        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Does not include your Stripe fees</Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Volume based rebate - 1%</Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Card on file charged once a month</Text>
        </Stack>
        <Stack direction="row" gap="1rem" align="start">
          <IconTarget mt="4px" />
          <Text>Pass on fees feature</Text>
        </Stack>
      </Stack>

      <Stack alignItems="center" display="flex">
        <Text>{isLoading}</Text>
        <Button
          fontSize="14px"
          w="100%"
          maxWidth="335px"
          isDisabled={isLoading}
          onClick={confirmPayment}
        >
          Subscribe
        </Button>
      </Stack>
    </Stack>
  );
};

export default PaymentIntent;
