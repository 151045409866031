import {useVerifyCaptchaUsersVerifyCaptchaPostMutation} from "@ef-org/api";

import {useCallback} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function useRecaptcha() {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [verifyCaptcha] = useVerifyCaptchaUsersVerifyCaptchaPostMutation();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("register");
    const verifyResponse = await verifyCaptcha({
      userVerificationRequest: {recaptchaResponse: token},
    });
    if ("data" in verifyResponse) {
      return verifyResponse.data.success;
    } else {
      return false;
    }
  }, [executeRecaptcha]);

  return handleReCaptchaVerify;
}

export default useRecaptcha;
