import {Link} from "@ef-org/components";
import {useUpdateEvent} from "@ef-org/hooks";
import Cookies from "js-cookie";

import React from "react";

import {useRouter} from "next/router";

import {Stack, Text, Container, Heading, Button} from "@chakra-ui/react";

const FinalScreen = () => {
  const {push, query} = useRouter();
  const organizerId = Cookies.get("organizerId");
  const updateEvent = useUpdateEvent();

  return (
    <Container maxW="5xl" pt="1rem" pb="5rem">
      <Stack align="center" w="100%" pb="3rem">
        <Heading textAlign="center">Congratulations</Heading>
        <Heading textAlign="center" pb="1rem">
          Your event is ready to be published
        </Heading>
        <Text maxW="550px" textAlign="center" color="#727CD4" fontSize="20px">
          You can now preview how attendees are going to see your event and then publish it.
        </Text>
      </Stack>

      {/* <Stack w="100%" direction="row" align="center"> */}
      <Stack direction="column" align="center" justify="center" spacing="1rem">
        <Link href={`/event/preview/${organizerId}/${query?.eventId as string}`}>
          <Button w={["100%", "275px", "275px"]} variant="outline">
            Preview event
          </Button>
        </Link>
        <Button
          variant="outline"
          border="0"
          w={["100%", "275px", "275px"]}
          background="linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)"
          onClick={async () => {
            await updateEvent({state: "published"});
            push("/events");
          }}
        >
          Publish event
        </Button>
      </Stack>
      {/* </Stack> */}
    </Container>
  );
};

export default FinalScreen;
