import {Center, Text} from "@chakra-ui/react";

interface Props {
  variant: "valid" | "invalid";
}

const Header = ({variant}) => {
  return (
    <Center
      width="100%"
      height="111px"
      display="flex"
      flexDirection="column"
      background={variant === "valid" ? "#D5F8D4" : "#FBEEEB"}
    >
      {variant === "valid" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
        >
          <g clipPath="url(#clip0_5505_96339)">
            <path
              d="M20.5 1.36719C9.75523 1.36719 1.04688 10.0755 1.04688 20.8203C1.04688 31.5652 9.75523 40.2734 20.5 40.2734C31.2448 40.2734 39.9531 31.5651 39.9531 20.8203C39.9531 10.0755 31.2448 1.36719 20.5 1.36719ZM20.5 38.7172C10.6321 38.7172 2.60312 30.6882 2.60312 20.8202C2.60312 10.9523 10.6321 2.92336 20.5 2.92336C30.368 2.92336 38.3969 10.9523 38.3969 20.8202C38.3969 30.6882 30.368 38.7172 20.5 38.7172Z"
              fill="#246522"
            />
            <path
              d="M30.5183 13.1797L17.4467 26.252L10.4891 19.296L9.38428 20.4009L17.4467 28.4618L31.6155 14.2845L30.5183 13.1797Z"
              fill="#246522"
            />
          </g>
          <defs>
            <clipPath id="clip0_5505_96339">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.820312)" />
            </clipPath>
          </defs>
        </svg>
      )}
      {variant === "invalid" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
        >
          <g clipPath="url(#clip0_5505_96458)">
            <path
              d="M20.3574 1.36719C9.61266 1.36719 0.904297 10.0756 0.904297 20.8205C0.904297 31.5651 9.61266 40.2734 20.3574 40.2734C31.1022 40.2734 39.8105 31.5651 39.8105 20.8205C39.8105 10.0756 31.1022 1.36719 20.3574 1.36719ZM20.3574 38.7172C10.4895 38.7172 2.46055 30.6882 2.46055 20.8205C2.46055 10.9525 10.4895 2.92344 20.3574 2.92344C30.2253 2.92344 38.2543 10.9524 38.2543 20.8205C38.2543 30.6882 30.2253 38.7172 20.3574 38.7172Z"
              fill="#B84930"
            />
            <path
              d="M29.1502 13.0926L28.0498 11.9922L20.3469 19.6952L12.6805 12.0287L11.5801 13.1291L19.2466 20.7956L11.5801 28.4626L12.6805 29.5629L20.3469 21.8955L28.0498 29.5995L29.1502 28.499L21.4472 20.7956L29.1502 13.0926Z"
              fill="#B84930"
            />
          </g>
          <defs>
            <clipPath id="clip0_5505_96458">
              <rect width="40" height="40" fill="white" transform="translate(0.357422 0.820312)" />
            </clipPath>
          </defs>
        </svg>
      )}
      <Text
        as="h2"
        fontWeight="600"
        fontSize="16px"
        marginTop="12px"
        color={variant === "valid" ? "#246522" : "#B84930"}
      >
        {variant === "valid" && "Ticket is valid"}
        {variant === "invalid" && "Ticket is invalid"}
      </Text>
    </Center>
  );
};

export default Header;
