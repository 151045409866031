import React from "react";

import {useRouter} from "next/router";

import {Box, Button} from "@chakra-ui/react";

import QrIcon from "../../../components/Icons/QrIcon";

const Footer = () => {
  const {push} = useRouter();

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        borderTop: "1px solid #E5E0FF",
      }}
    >
      <Button
        w="100%"
        fontSize="14px"
        fontWeight="500"
        leftIcon={<QrIcon />}
        onClick={() => push("/qr-scanner")}
      >
        Scan another ticket
      </Button>
      <Button
        onClick={() => push("/dashboard")}
        fontSize="14px"
        fontWeight="500"
        variant="neutral"
        marginTop="16px"
        w="100%"
      >
        Close
      </Button>
    </Box>
  );
};

export default Footer;
