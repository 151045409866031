import {Button, Icon, Stack, Text} from "@chakra-ui/react";

import {useNewNFTBuilder} from "../../../hooks/useNFTBuilder";

const NoWorkspaceSelected = () => {
  const setNewWsp = useNewNFTBuilder((store) => store.setSelectWorkspace);
  return (
    <Stack spacing="2rem">
      <Stack direction="row" align="center" spacing="1.5rem">
        <Text color="ef-dark-blue">Workspace:</Text>

        <Stack direction="row" align="center">
          <Button variant="neutral" size="md" onClick={() => setNewWsp("2d")}>
            2D
          </Button>
          <Button
            onClick={() => setNewWsp("3d")}
            variant="neutral"
            size="md"
            leftIcon={
              <Icon viewBox="0 0 16 16" fill="none">
                <path
                  d="M14 10.6679V5.33457C13.9998 5.10075 13.938 4.87111 13.821 4.66868C13.704 4.46625 13.5358 4.29815 13.3333 4.18124L8.66667 1.51457C8.46397 1.39755 8.23405 1.33594 8 1.33594C7.76595 1.33594 7.53603 1.39755 7.33333 1.51457L2.66667 4.18124C2.46418 4.29815 2.29599 4.46625 2.17897 4.66868C2.06196 4.87111 2.00024 5.10075 2 5.33457V10.6679C2.00024 10.9017 2.06196 11.1314 2.17897 11.3338C2.29599 11.5362 2.46418 11.7043 2.66667 11.8212L7.33333 14.4879C7.53603 14.6049 7.76595 14.6665 8 14.6665C8.23405 14.6665 8.46397 14.6049 8.66667 14.4879L13.3333 11.8212C13.5358 11.7043 13.704 11.5362 13.821 11.3338C13.938 11.1314 13.9998 10.9017 14 10.6679Z"
                  stroke="#C2C2C2"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.1792 4.64062L7.9992 8.00729L13.8192 4.64062"
                  stroke="#C2C2C2"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 14.72V8"
                  stroke="#C2C2C2"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Icon>
            }
          >
            3D
          </Button>
        </Stack>
      </Stack>

      <Stack w="100%" align="center" justify="center">
        <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none">
          <path
            d="M12.5 19V5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.5 12L12.5 5L19.5 12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Icon>

        <Text>Begin by selecting your workspace</Text>
      </Stack>
    </Stack>
  );
};

export default NoWorkspaceSelected;
