import {useGetNftappApiAccountByAccountIdTemplateQuery} from "@ef-org/api";
import {NFTCanvas} from "@ef-org/components";
import {useSelectNFTEnums} from "@ef-org/hooks";
// import {useAuth} from "@ef/providers";
import {capitalizeFirstLetter, rewriteFontToSavingFormat} from "@ef-org/utils";
import "cropperjs/dist/cropper.css";
import {is, omit, pathOr, pick} from "ramda";
import {match, Pattern} from "ts-pattern";

import React, {useRef, useState} from "react";
import Cropper from "react-cropper";

import throttle from "lodash.throttle";

import {
  Box,
  Text,
  Image,
  Icon,
  BoxProps,
  Tooltip,
  TextProps,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from "@chakra-ui/react";

import {FileWithPreview, useNewNFTBuilder, useNFTBuilderSelector} from "../hooks/useNFTBuilder";

const HORIZONTAL_TEXT_VALUES = {l: "left", m: "center", r: "right"};
const VERTICAL_TEXT_VALUES = {a: "top", m: "center", s: "baseline", d: "bottom"};

const EmptyIcon: React.FC = () => (
  <Icon
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9167 4.25H7.08333C5.51853 4.25 4.25 5.51853 4.25 7.08333V26.9167C4.25 28.4815 5.51853 29.75 7.08333 29.75H26.9167C28.4815 29.75 29.75 28.4815 29.75 26.9167V7.08333C29.75 5.51853 28.4815 4.25 26.9167 4.25Z"
      stroke="#C2C2C2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.042 14.166C13.2156 14.166 14.167 13.2146 14.167 12.041C14.167 10.8674 13.2156 9.91602 12.042 9.91602C10.8684 9.91602 9.91699 10.8674 9.91699 12.041C9.91699 13.2146 10.8684 14.166 12.042 14.166Z"
      stroke="#C2C2C2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7497 21.2493L22.6663 14.166L7.08301 29.7493"
      stroke="#C2C2C2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

// const MAX_SIZE = 800;

const CropFor2dTemplateImage = () => {
  const cropperFragmentRef = useRef<HTMLImageElement>(null);
  const activeCropFragment = useNewNFTBuilder(
    (x) => x?.selectedStyle?.fragments?.find((x) => x.__crop.isActive) || null
  );

  const {setActivateCropForTemplateFragment, setCropedImageForTemplateFragment} =
    useNFTBuilderSelector(
      "setActivateCropForTemplateFragment",
      "setCropedImageForTemplateFragment"
    );

  if (!activeCropFragment) return null;

  const [leftTopX, leftTopY, rightBottomX, rightBottomY] = activeCropFragment?.coords; // X = left to right | Y = top to bottom

  const onClose = () => {};

  return (
    <Modal
      onClose={onClose}
      isOpen={Boolean(activeCropFragment)}
      closeOnOverlayClick
      closeOnEsc
      isCentered
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pt="1.5rem">
          <Cropper
            // style={{position: "absolute"}}
            // data={{
            //   width: rightBottomX - leftTopX,
            //   height: rightBottomY - leftTopY,
            //   x: 0,
            //   y: 0,
            // }}
            // responsive
            // movable
            // rotatable
            // scalable
            // zoomable
            // cropBoxMovable={false} // nop
            cropBoxResizable={false}
            ref={cropperFragmentRef}
            // minContainerWidth={rightBottomX - leftTopX}
            // minContainerHeight={rightBottomY - leftTopY}
            minCropBoxWidth={rightBottomX - leftTopX}
            minCropBoxHeight={rightBottomY - leftTopY}
            dragMode="crop"
            src={activeCropFragment?.__templateUploadedFile?.preview}
            aspectRatio={(rightBottomX - leftTopX) / (rightBottomY - leftTopY)}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            // guides={false}
            // controls={false}
            // cropBoxResizable={false}
            // responsive={false}
          />
        </ModalBody>
        <ModalFooter>
          <Stack w="100%" pt="1rem" direction="row" align="center" justify="space-between">
            <Button
              w="fit-content"
              variant="neutral"
              border="2px"
              borderRadius="10px"
              fontWeight="bold"
              onClick={() => setActivateCropForTemplateFragment(activeCropFragment.id, false)}
            >
              Cancel
            </Button>

            <Stack direction="row" align="center">
              <Button
                w="fit-content"
                variant="destructive"
                border="2px"
                borderRadius="10px"
                fontWeight="bold"
                onClick={() => {
                  setCropedImageForTemplateFragment(activeCropFragment.id, null);
                  setActivateCropForTemplateFragment(activeCropFragment.id, false);
                }}
              >
                Reset Changes
              </Button>
              <Button
                w="fit-content"
                variant="grayOutline"
                border="2px"
                borderRadius="10px"
                bg="ef-primary"
                color="ef-white"
                fontWeight="bold"
                onClick={async () => {
                  const imageElement: any = cropperFragmentRef?.current;
                  const cropper: Cropper = imageElement?.cropper;

                  // eslint-disable-next-line no-console
                  console.log("cropper", cropper.getCropBoxData());
                  cropper.getCroppedCanvas({maxHeight: 1024, maxWidth: 1024}).toBlob((blob) => {
                    setCropedImageForTemplateFragment(
                      activeCropFragment.id,
                      Object.assign(blob, {
                        preview: URL.createObjectURL(blob),
                      }) as FileWithPreview
                    );
                    setActivateCropForTemplateFragment(activeCropFragment.id, false);
                  });
                }}
              >
                Crop Image
              </Button>
            </Stack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const BORDER_BOX: BoxProps = {
  p: "0.4rem",
  border: "2px",
  borderColor: "ef-d1",
  borderRadius: "6px",
  borderStyle: "dashed",
};

const PreviewOf2dSelectedTemplate: React.FC = () => {
  // const {user} = useAuth();
  const {selectedStyle, setSelectFragmentId} = useNFTBuilderSelector(
    "selectedStyle",
    "setSelectFragmentId"
  );

  const {data: enums} = useSelectNFTEnums();

  const getFont = (fontName: string) => {
    const font = Object.entries(enums?.fonts || {}).find(
      (x) => rewriteFontToSavingFormat(x[0]) === rewriteFontToSavingFormat(fontName)
    );

    if (!font || !Array.isArray(font) || font.length !== 2) return null;

    return {nonReadableFontNameName: font[0], fontUrl: font[1]};
  };
  const {data: templates} = useGetNftappApiAccountByAccountIdTemplateQuery(
    {
      accountId: "", //user?.last_account_id || "",
      style: selectedStyle.id,
    },
    {refetchOnMountOrArgChange: true, skip: !selectedStyle?.id}
  );

  // const shouldDownscale = selectedStyle?.size[0] > MAX_SIZE || selectedStyle.size[1] > MAX_SIZE;

  // const scaling = {
  //   downScale: selectedStyle.size[0] > 700||selectedStyle.size[0] > 700,
  //   downScaleH: selectedStyle.size[1] > 700,
  //   w: selectedStyle.size[0] < 700 ? selectedStyle.size[0] : selectedStyle.size[0] / 700,
  //   h: selectedStyle.size[1] < 700 ? selectedStyle.size[1] : selectedStyle.size[1] / 700,
  // };
  const is2dTemplateCropAtive = selectedStyle.fragments.find((x) => x.__crop.isActive);

  return (
    <Box>
      <CropFor2dTemplateImage />
      <Box sx={Boolean(is2dTemplateCropAtive) ? BORDER_BOX : {}}>
        <Box
          data-test-prop="main-layout"
          w={selectedStyle.size[0]} //shouldDownscale ? selectedStyle.size[0] / MAX_SIZE : selectedStyle.size[0]}
          h={selectedStyle.size[1]} //shouldDownscale ? selectedStyle.size[1] / MAX_SIZE : selectedStyle.size[1]}
          position="relative"
        >
          {selectedStyle.fragments.map((fragment, key, arr) => {
            const sameKinds = arr.filter((x) => x.kind === fragment.kind);
            const kindIndex = (sameKinds?.findIndex((x) => x.id === fragment.id) || 0) + 1;
            const [leftTopX, leftTopY, rightBottomX, rightBottomY] = fragment.coords; // X = left to right | Y = top to bottom

            const componentProps: BoxProps = {
              key,
              id: `nft-preview-fragment[${key}]-${fragment.kind}`,
              top: `${leftTopY}px`, //shouldDownscale ? fragment.coords[0] / MAX_SIZE : `${fragment.coords[0]}px`,
              left: `${leftTopX}px`, //shouldDownscale ? fragment.coords[1] / MAX_SIZE : `${fragment.coords[1]}px`,
              width: `${rightBottomX - leftTopX}px`, //shouldDownscale ? fragment.coords[2] / MAX_SIZE : `${fragment.coords[2]}px`,
              height: `${rightBottomY - leftTopY}px`, //shouldDownscale ? fragment.coords[3] / MAX_SIZE : `${fragment.coords[3]}px`,
              position: "absolute",
              transition: "all 0.4s",
              ...(!fragment?.properties?.is_readonly
                ? {
                    cursor: "pointer",
                    _hover: {
                      div: {display: "block"},
                    },
                    onClick: () => setSelectFragmentId(fragment.id),
                  }
                : {pointerEvents: "none"}),
            };

            componentProps["data-test-type"] = fragment.kind;
            componentProps["data-test-props"] = JSON.stringify(
              pick(["top", "left", "width", "height"], componentProps)
            );

            const getImageFromTemplatesById = (id) =>
              (templates?.items || []).find((x) => x.id === id);

            const WrapWithToolTip: React.FC<
              React.PropsWithChildren<{disableTooltip?: boolean}>
            > = ({disableTooltip, children}) => {
              const {fragmentHoverId} = useNFTBuilderSelector("fragmentHoverId");
              const titleText = capitalizeFirstLetter(
                fragment?.name || `${fragment.kind} ${kindIndex}`
              );

              const component = (
                <Box
                  {...(disableTooltip
                    ? omit(["onClick", "_hover", "cursor"], componentProps)
                    : componentProps)}
                >
                  {children}
                  {!disableTooltip && (
                    <Box
                      display={fragmentHoverId === fragment.id ? "block" : "none"}
                      bg="rgba(101, 100, 255, 0.35)"
                      position="absolute"
                      boxSize="100%"
                    />
                  )}
                </Box>
              );

              if (fragment?.properties?.is_readonly) {
                return component;
              }
              return (
                <Tooltip
                  label={`Click to edit #${titleText}`}
                  aria-label={`Click to edit #${titleText}`}
                  placement="top"
                >
                  {component}
                </Tooltip>
              );
            };

            return match(fragment)
              .with({kind: "template"}, () => {
                const template = getImageFromTemplatesById(fragment.value);

                if (!template) return null;

                return (
                  <WrapWithToolTip>
                    {template.mimetype.includes("image/") ? (
                      <Image
                        objectFit="cover"
                        boxSize="100%"
                        position="absolute"
                        src={
                          fragment?.__crop?.croppedImage?.preview ||
                          fragment?.__templateUploadedFile?.preview ||
                          template.url
                        }
                        alt="nft-preview-template"
                      />
                    ) : (
                      <NFTCanvas
                        nftAssetUrl={template?.url} //{selected3dOwnImageSpace?.file?.preview}
                      />
                    )}
                  </WrapWithToolTip>
                );
              })
              .with({kind: "color"}, () => (
                <WrapWithToolTip>
                  <Box boxSize="100%" position="absolute" bg={fragment.value as string} />
                </WrapWithToolTip>
              ))
              .with({kind: "gradient"}, () => (
                <WrapWithToolTip>
                  <Box
                    position="absolute"
                    boxSize="100%"
                    bg={`linear-gradient(${fragment.value[0]}deg, ${fragment.value[1]}, ${fragment.value[2]})`}
                  />
                </WrapWithToolTip>
              ))
              .with({kind: "text"}, () => {
                const fontUrl = getFont(fragment?.properties?.font || undefined);

                const [horizontal = "l", vertical = "a"] =
                  fragment?.properties?.anchor?.split("") || [];

                const textAligns: TextProps = {
                  textAlign: pathOr("left", [horizontal], HORIZONTAL_TEXT_VALUES),
                  verticalAlign: pathOr("top", [vertical], VERTICAL_TEXT_VALUES),
                };

                return (
                  <WrapWithToolTip>
                    <Box>
                      <Text
                        position="absolute"
                        boxSize="100%"
                        whiteSpace="pre-line"
                        fontFamily={
                          fontUrl?.nonReadableFontNameName
                            ? `custom_${rewriteFontToSavingFormat(
                                fontUrl?.nonReadableFontNameName
                              )}`
                            : undefined
                        }
                        sx={
                          fontUrl
                            ? {
                                "@font-face": {
                                  fontFamily: `custom_${rewriteFontToSavingFormat(
                                    fontUrl?.nonReadableFontNameName
                                  )}`,
                                  src: `url(${fontUrl.fontUrl}) format('truetype')`,
                                },
                              }
                            : undefined
                        }
                        {...(fragment.properties
                          ? {
                              color: fragment?.properties?.color || undefined,
                              font: fragment?.properties?.font || undefined,
                              fontSize: fragment?.properties?.size || undefined,
                              ...textAligns,
                            }
                          : {})}
                      >
                        {fragment.value}
                      </Text>
                    </Box>
                  </WrapWithToolTip>
                );
              })
              .otherwise(() => null);
          })}
        </Box>
      </Box>
    </Box>
  );
};

const ActionCrop2dImage: React.FC = () => {
  const {selected2dOwnImageFile, setCustom2dOwnImageStyles} = useNFTBuilderSelector(
    "custom2dOwnImageStyles",
    "setCustom2dOwnImageStyles",
    "selected2dOwnImageFile"
  );

  const cropperRef = useRef<HTMLImageElement>(null);

  return (
    <Stack align="center">
      <Box
        w="fit-content"
        h="fit-content"
        p="0.4rem"
        border="2px"
        borderColor="ef-d1"
        borderRadius="6px"
        borderStyle="dashed"
      >
        <Box>
          <Cropper
            minCropBoxHeight={300}
            minCropBoxWidth={300}
            src={selected2dOwnImageFile.preview}
            guides={true}
            ref={cropperRef}
          />
        </Box>
      </Box>
      <Stack w="100%" pt="1rem" direction="row" align="center" justify="space-between">
        <Button
          w="fit-content"
          variant="neutral"
          border="2px"
          borderRadius="10px"
          fontWeight="bold"
          onClick={() => setCustom2dOwnImageStyles({isCropActive: false})}
        >
          Cancel
        </Button>

        <Stack direction="row" align="center">
          <Button
            w="fit-content"
            variant="destructive"
            border="2px"
            borderRadius="10px"
            fontWeight="bold"
            onClick={() => {
              setCustom2dOwnImageStyles({isCropActive: false, croppedImage: null});
            }}
          >
            Reset Changes
          </Button>
          <Button
            w="fit-content"
            variant="grayOutline"
            border="2px"
            borderRadius="10px"
            bg="ef-primary"
            color="ef-white"
            fontWeight="bold"
            onClick={async () => {
              const imageElement: any = cropperRef?.current;
              const cropper: Cropper = imageElement?.cropper;

              // eslint-disable-next-line no-console
              console.log("cropper", cropper.getCropBoxData());
              cropper.getCroppedCanvas({maxHeight: 1024, maxWidth: 1024}).toBlob((blob) => {
                setCustom2dOwnImageStyles({
                  isCropActive: false,
                  croppedImage: Object.assign(blob, {
                    preview: URL.createObjectURL(blob),
                  }) as FileWithPreview,
                });
              });
            }}
          >
            Crop Image
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PreviewOf2dOwnImage: React.FC = () => {
  const {selected2dOwnImageFile, custom2dOwnImageStyles} = useNFTBuilderSelector(
    "selected2dOwnImageFile",
    "custom2dOwnImageStyles"
  );

  return (
    <Image
      src={custom2dOwnImageStyles?.croppedImage?.preview || selected2dOwnImageFile.preview}
      alt="2d-own-image-nft-preview"
    />
  );
};

const PreviewOf3dOwnImage: React.FC = () => {
  const {selected3dOwnImageSpace} = useNFTBuilderSelector("selected3dOwnImageSpace");

  if (!selected3dOwnImageSpace?.file?.preview) {
    return <EmptyIcon />;
  }

  return (
    <Box boxSize={["100%", "400px", "500px", "600px"]}>
      <NFTCanvas nftAssetUrl={selected3dOwnImageSpace?.file?.preview} />
    </Box>
  );
};

const WithScalling: React.FC<React.PropsWithChildren> = ({children}) => {
  const {selectedStyle} = useNFTBuilderSelector("selectedStyle");

  const [isSmallerThanTablet] = useMediaQuery("(max-width: 30.1em)", {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const [scale, setScale] = useState(1);
  const hideBorder = useNewNFTBuilder(
    (s) => s.selectedStyle && selectedStyle.fragments.some((x) => x.__crop.isActive)
  );

  const ref = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleResize = throttle(() => {
      const el = document.getElementById("nft-layout-left-section");

      if (!is(Number, el.offsetWidth)) {
        return;
      }

      const safeWidth = el.offsetWidth - 80;
      const safeHeight = el.offsetHeight - 80;

      if (!selectedStyle || isSmallerThanTablet) {
        setScale(1);
        return;
      }

      const scalling =
        safeHeight < safeWidth
          ? safeHeight / selectedStyle.size[1]
          : safeWidth / selectedStyle.size[0];

      setScale(scalling >= 1 ? 1 : scalling);
    }, 40);

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedStyle, isSmallerThanTablet]);

  return (
    <Box
      ref={ref}
      transform={`scale(${scale})`}
      id="main-nft-preview"
      w="fit-content"
      p={hideBorder ? "unset" : "0.4rem"}
      border={hideBorder ? "unset" : "2px"}
      borderColor="ef-d1"
      borderRadius="6px"
      borderStyle={hideBorder ? "unset" : "dashed"}
    >
      {children}
    </Box>
  );
};

const NftPreview: React.FC = () => {
  const {selectedStyle, selected2dOwnImageFile, selectedWorkspace, selectedImageType} =
    useNFTBuilderSelector(
      "selectedStyle",
      "selected2dOwnImageFile",
      "selectedWorkspace",
      "selectedImageType"
    );

  const is2dOwnImageCropActive = useNewNFTBuilder((s) => s.custom2dOwnImageStyles.isCropActive);

  if (is2dOwnImageCropActive) {
    return <ActionCrop2dImage />;
  }

  const component = match({
    selectedWorkspace,
    selectedImageType,
    selected2dOwnImageFile,
    selectedStyle,
  })
    .with(
      {
        selectedWorkspace: "2d",
        selectedImageType: "custom-image",
        selected2dOwnImageFile: Pattern.not(Pattern.nullish),
      },
      () => <PreviewOf2dOwnImage />
    )
    .with(
      {
        // selectedWorkspace: "2d",
        selectedImageType: "from-template",
        selectedStyle: Pattern.not(Pattern.nullish),
      },
      () => <PreviewOf2dSelectedTemplate />
    )
    .with(
      {
        selectedWorkspace: "3d",
        selectedImageType: "custom-image",
        // selected3dOwnImageFile: Pattern.not(Pattern.nullish),
      },
      () => <PreviewOf3dOwnImage />
    )
    .otherwise(() => <EmptyIcon />);

  return <WithScalling>{component}</WithScalling>;
};

export default NftPreview;
