// import {useNFTCreatorSwitchingSpaceModal} from "apps/ef-app/src/modals/useNFTCreatorSwitchingSpaceModal";
import {beNftOrgApi, GetNftappApiAccountByAccountIdStyleApiResponse} from "@ef-org/api";
import {Input, Skeleton, TypingSelect} from "@ef-org/components";
import {useSelectNFTEnums} from "@ef-org/hooks";
import {useErrorModal} from "@ef-org/modals";
import {rewriteFontToHumanReadable, rewriteFontToSavingFormat} from "@ef-org/utils";
import {equals, isEmpty, pathOr} from "ramda";
import {match} from "ts-pattern";

import {useEffect, useMemo, useState} from "react";
import {HexColorPicker} from "react-colorful";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";

import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Stack,
  Text,
  Box,
  useBoolean,
  Image,
  Collapse,
  BoxProps,
} from "@chakra-ui/react";

import {StyleType, useNewNFTBuilder, useNFTBuilderSelector} from "../../../hooks/useNFTBuilder";

const WorkspaceSelected = () => {
  return (
    <Stack spacing="1rem">
      <SelectSpace />

      <SelectTypeOfImage />

      <Box pt="1rem">
        <ComponentByImageType />
      </Box>

      <Box pt="1rem" sx={{".react-colorful": {w: "100%", h: "150px"}}}>
        <Customization2dTemplateComponent />
      </Box>
    </Stack>
  );
};

const ComponentByImageType = () => {
  const {selectedImageType} = useNFTBuilderSelector("selectedImageType");

  if (selectedImageType === "custom-image") {
    return <ComponentWithCustomImage />;
  }
  return <ComponentFromTemplate />;
};

const Memoized2dTemplateListItem: React.FC<{fragment: StyleType["fragments"][0]}> = ({
  fragment,
}) => {
  const {selectedFragmentId, setSelectFragmentId, setHoverFragment, selectedStyle} =
    useNFTBuilderSelector(
      "selectedFragmentId",
      "setSelectFragmentId",
      "setHoverFragment",
      "selectedStyle"
    );

  const sameKinds = selectedStyle?.fragments?.filter((x) => x.kind === fragment.kind) || [];
  const kindIndex = (sameKinds?.findIndex((x) => x.id === fragment.id) || 0) + 1;

  return (
    <Box key={fragment.id} borderRadius="7px">
      <Stack
        direction="row"
        align="center"
        justify="space-between"
        _hover={{bg: "rgba(235,235,235, 0.30)", cursor: "pointer"}}
        onClick={() => setSelectFragmentId(fragment.id)}
        onMouseEnter={() => {
          setHoverFragment(fragment.id);
        }}
        onMouseLeave={() => {
          setHoverFragment(null);
        }}
      >
        <Box px="0.5rem" transition="all 0.4s">
          <Text
            textTransform="capitalize"
            variant="switchedDecoration"
            w="100%"
            color="ef-dark-blue"
            fontSize="0.9rem"
            py="0.5rem"
            fontWeight="bold"
          >
            {fragment?.name || `${fragment.kind} ${kindIndex}`}
          </Text>
        </Box>
        <Box boxSize="30px">
          {fragment.id !== selectedFragmentId ? (
            <ChevronDownIcon boxSize="inherit" />
          ) : (
            <ChevronUpIcon boxSize="inherit" />
          )}
        </Box>
      </Stack>

      <Box
        borderRadius="6px"
        bg={fragment.id === selectedFragmentId ? "#F2F2F2" : "unset"}
        pb={fragment.id === selectedFragmentId ? "1rem" : "0"}
      >
        <Collapse in={fragment.id === selectedFragmentId} animateOpacity>
          <Box p="1rem">
            {fragment.id === selectedFragmentId &&
              match(fragment)
                .with({kind: "template"}, () => {
                  return <TemplateFragment selectedFragmentId={selectedFragmentId} />;
                })
                .with({kind: "color"}, () => {
                  return <ColorFragment selectedFragmentId={selectedFragmentId} />;
                })
                .with({kind: "gradient"}, () => {
                  return <TextFragment selectedFragmentId={selectedFragmentId} />;
                })
                .with({kind: "text"}, () => {
                  return <TextFragment selectedFragmentId={selectedFragmentId} />;
                })
                .otherwise(() => null)}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

const Customization2dTemplateComponent: React.FC = () => {
  const [selectedStyle, selectedImageType] = useNewNFTBuilder((store) => [
    store.selectedStyle,
    store.selectedImageType,
  ]);

  if (!selectedStyle || selectedImageType !== "from-template") return null;

  return (
    <>
      <Text color="ef-dark-blue" fontSize="1.3rem" fontWeight="bold" mb="1rem">
        Edit NFT contents
      </Text>

      {selectedStyle?.fragments
        ?.filter((x) => !x?.properties?.is_readonly)
        ?.map((fragment) => (
          <Memoized2dTemplateListItem key={fragment.id} fragment={fragment} />
        ))}
    </>
  );
};

const TextFragment: React.FC<{selectedFragmentId: string}> = ({selectedFragmentId}) => {
  const {setSelectEditFragment, selectedStyle, setEditTextFragment} = useNFTBuilderSelector(
    "setSelectEditFragment",
    "selectedStyle",
    "setEditTextFragment"
  );
  const frag = selectedStyle.fragments.find((x) => x.id === selectedFragmentId);

  const {data: enums} = useSelectNFTEnums();

  const [inputValue, setInputValue] = useState(frag?.value?.toString() || "");

  const [isTextColorPickerHidden, {on: showTextColorPicker, off: hideTextxColorPicker}] =
    useBoolean(false);

  if (!frag) return null;

  return (
    <Stack>
      <Input
        label="Enter text"
        labelColor="ef-nft-primary"
        placeholder={frag?.__orgValue?.toString() || "Enter Text"}
        bg="ef-white"
        borderColor="ef-white"
        value={inputValue}
        onChange={(e) => {
          const value = e.target.value;
          setInputValue(value);
          setEditTextFragment(selectedFragmentId, value);
        }}
      />

      <TypingSelect
        labelColor="ef-nft-primary"
        placeholder="Choose font size"
        label="Choose font size"
        hideTyping
        bg="ef-white"
        borderColor="ef-white"
        defaultValue={frag?.properties?.size || ""}
        options={new Array(100).fill(8).map((_, i) => ({name: i.toFixed(), value: i.toFixed()}))}
        onChange={(e) => {
          setSelectEditFragment(selectedFragmentId, {
            properties: {size: e.target.value.toString()},
          });
        }}
      />

      <TypingSelect
        labelColor="ef-nft-primary"
        placeholder="Choose font"
        label="Choose font"
        bg="ef-white"
        borderColor="ef-white"
        hideTyping
        defaultValue={rewriteFontToHumanReadable(frag?.properties?.font || "")}
        options={Object.entries(enums.fonts).map((x) => ({
          name: rewriteFontToHumanReadable(x[0]),
          value: x[1],
        }))}
        onChange={(e) => {
          setSelectEditFragment(selectedFragmentId, {
            properties: {font: rewriteFontToSavingFormat(e.target.value.toString())},
          });
        }}
      />

      <Input
        label="Choose text color"
        labelColor="ef-nft-primary"
        bg="ef-white"
        borderColor="ef-white"
        placeholder="black"
        leftContent={
          <Box
            border={
              frag?.properties?.color &&
              ["#fff", "#ffffff", "white"].includes(frag?.properties?.color?.toLowerCase() || "")
                ? undefined
                : "1px solid var(--chakra-colors-ef-border)"
            }
            boxSize="24px"
            borderRadius="4px"
            bg={frag?.properties?.color}
            cursor="pointer"
            onClick={() =>
              !isTextColorPickerHidden ? showTextColorPicker() : hideTextxColorPicker()
            }
          />
        }
        value={frag?.properties?.color || ""}
        onChange={(e) =>
          setSelectEditFragment(selectedFragmentId, {properties: {color: e.target.value}})
        }
        mb="1rem"
      />

      <Collapse in={isTextColorPickerHidden} animateOpacity>
        <Box mb="1.5rem">
          <HexColorPicker
            color={frag?.properties?.color || ""}
            onChange={(color) => setSelectEditFragment(selectedFragmentId, {properties: {color}})}
          />
        </Box>
      </Collapse>
    </Stack>
  );
};

const TemplateFragment: React.FC<{selectedFragmentId: string}> = ({selectedFragmentId}) => {
  const frag = useNewNFTBuilder(
    (store) => store.selectedStyle.fragments.find((x) => x.id === selectedFragmentId),
    (a, b) => equals(a, b)
  );
  const {openModal: openErrorModal} = useErrorModal();

  const {
    setEditTemplateFragment,
    setActivateCropForTemplateFragment,
    setCropedImageForTemplateFragment,
  } = useNFTBuilderSelector(
    "setEditTemplateFragment",
    "setActivateCropForTemplateFragment",
    "setCropedImageForTemplateFragment"
  );

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 6000000, // 1 megabyte = 1 000 000 bytes we need 3mb
    multiple: false,
    accept: {"image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"]},
    onDrop: (acceptedFiles, rejectedFiles) => {
      const rejection = pathOr(null, [0, "errors", 0, "code"], rejectedFiles);

      if (rejection) {
        openErrorModal({
          message:
            rejection === "file-too-large" ? "Maximum file size is 6mb" : "Unsupported file type",
          hideVisitorId: true,
        });

        return;
      }

      // Double check...
      if (isEmpty(acceptedFiles)) {
        return;
      }

      setEditTemplateFragment(
        selectedFragmentId,
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
      setActivateCropForTemplateFragment(selectedFragmentId, false);
      setCropedImageForTemplateFragment(selectedFragmentId, null);
    },
  });

  if (!frag) return null;

  return (
    <>
      <Text fontWeight="bold" mb="1rem" color="ef-nft-primary">
        Upload your image
      </Text>

      {frag?.__templateUploadedFile?.preview ? (
        <Stack
          {...getRootProps({id: `IMAGE_UPLOAD_SECTION_${frag?.id}`})}
          bg="ef-white"
          mb="1rem"
          border="2px"
          borderColor="ef-border"
          borderRadius="6px"
          borderStyle="dashed"
          align="center"
          justify="space-between"
          p="1rem 3rem 1rem 1rem"
          direction="row"
        >
          <Image
            maxH="100px"
            maxW="100px"
            src={frag?.__templateUploadedFile?.preview || undefined}
            alt="3d-own-upload"
            border="1px"
            borderColor="ef-d1"
            borderRadius="8px"
            borderStyle="dashed"
          />

          <Stack spacing="1rem">
            <Stack direction="row" align="center">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditTemplateFragment(selectedFragmentId, null);
                }}
                cursor="pointer"
                width="22px"
                height="22px"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.69749 4.69668C6.05473 3.33946 7.92972 2.5 10.0007 2.5C12.9032 2.5 15.4207 4.14872 16.6675 6.56072V3.9583C16.6675 3.61312 16.9473 3.3333 17.2925 3.3333C17.6377 3.3333 17.9175 3.61312 17.9175 3.9583V7.91663C17.9175 8.0824 17.8517 8.24137 17.7344 8.35858C17.6172 8.47575 17.4582 8.54167 17.2925 8.54167H16.5896C16.5874 8.54167 16.5853 8.54167 16.5832 8.54167C16.5811 8.54167 16.5789 8.54167 16.5768 8.54167H13.3342C12.989 8.54167 12.7092 8.26182 12.7092 7.91663C12.7092 7.57146 12.989 7.29163 13.3342 7.29163H15.6351C14.6259 5.19615 12.4822 3.75 10.0007 3.75C8.27489 3.75 6.71241 4.44954 5.58138 5.58056C4.80484 6.35711 4.23169 7.33705 3.94871 8.43367C3.87133 8.7335 3.61041 8.95825 3.30075 8.95825C2.91591 8.95825 2.61793 8.61717 2.70774 8.243C3.03551 6.87752 3.73679 5.65739 4.69749 4.69668Z"
                  fill="#4B4F72"
                />
                <path
                  d="M16.0529 11.5663C15.7699 12.6629 15.1967 13.6428 14.4202 14.4194C13.2892 15.5504 11.7267 16.2499 10.0008 16.2499C7.51935 16.2499 5.37565 14.8038 4.36653 12.7083H6.66747C7.01265 12.7083 7.29247 12.4285 7.29247 12.0833C7.29247 11.7382 7.01265 11.4583 6.66747 11.4583H3.42374L3.4199 11.4583H3.41845C3.41665 11.4583 3.41487 11.4583 3.41309 11.4583H2.70914C2.54337 11.4583 2.3844 11.5242 2.2672 11.6413C2.14998 11.7586 2.08414 11.9176 2.08414 12.0833V16.0417C2.08414 16.3868 2.36395 16.6667 2.70914 16.6667C3.05431 16.6667 3.33414 16.3868 3.33414 16.0417V13.4392C4.58098 15.8512 7.09836 17.4999 10.0008 17.4999C12.0719 17.4999 13.9469 16.6605 15.3041 15.3032C16.2648 14.3426 16.9661 13.1224 17.2938 11.7569C17.3837 11.3827 17.0857 11.0417 16.7008 11.0417C16.3912 11.0417 16.1302 11.2665 16.0529 11.5663Z"
                  fill="#4B4F72"
                />
              </Icon>
              <Text as="span" cursor="pointer" _hover={{textDecoration: "underline"}}>
                Change image
              </Text>
            </Stack>

            <Stack
              direction="row"
              align="center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActivateCropForTemplateFragment(selectedFragmentId, true);
              }}
            >
              <Icon cursor="pointer" width="22px" height="22px" viewBox="0 0 20 20" fill="none">
                <g>
                  <path
                    d="M4.79168 0.833008C5.13686 0.833008 5.41668 1.11283 5.41668 1.45801V14.1663C5.41668 14.3965 5.60323 14.583 5.83334 14.583H18.5417C18.8869 14.583 19.1667 14.8628 19.1667 15.208C19.1667 15.5532 18.8869 15.833 18.5417 15.833H15.8333V18.5413C15.8333 18.8865 15.5535 19.1663 15.2083 19.1663C14.8632 19.1663 14.5833 18.8865 14.5833 18.5413V15.833H5.83334C4.91287 15.833 4.16668 15.0868 4.16668 14.1663V5.41634H1.45834C1.11317 5.41634 0.833344 5.13652 0.833344 4.79134C0.833344 4.44616 1.11317 4.16634 1.45834 4.16634H4.16668V1.45801C4.16668 1.11283 4.4465 0.833008 4.79168 0.833008Z"
                    fill="#4B4F72"
                  />
                  <path
                    d="M15.8333 12.708C15.8333 13.0532 15.5535 13.333 15.2083 13.333C14.8632 13.333 14.5833 13.0532 14.5833 12.708V5.83301C14.5833 5.60289 14.3968 5.41634 14.1667 5.41634H7.29168C6.9465 5.41634 6.66668 5.13652 6.66668 4.79134C6.66668 4.44616 6.9465 4.16634 7.29168 4.16634H14.1667C15.0872 4.16634 15.8333 4.91253 15.8333 5.83301V12.708Z"
                    fill="#4B4F72"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6178_38898">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </Icon>
              <Text as="span" cursor="pointer" _hover={{textDecoration: "underline"}}>
                Crop image
              </Text>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          {...getRootProps({id: `IMAGE_UPLOAD_SECTION_${frag?.id}`})}
          mb="1rem"
          bg="ef-white"
          border="2px"
          borderColor="ef-border"
          borderRadius="6px"
          borderStyle="dashed"
          align="center"
          justify="center"
          py="2rem"
          px="1rem"
          direction="row"
        >
          <input {...getInputProps()} />
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 10C9.82843 10 10.5 9.32843 10.5 8.5C10.5 7.67157 9.82843 7 9 7C8.17157 7 7.5 7.67157 7.5 8.5C7.5 9.32843 8.17157 10 9 10Z"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 15L16.5 10L5.5 21"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text>
            Drag and drop or{" "}
            <Text
              as="span"
              textDecoration="underline"
              cursor="pointer"
              _hover={{textDecoration: "none"}}
            >
              select image
            </Text>
          </Text>
        </Stack>
      )}

      <Text color="ef-border" fontSize="sm">
        Supported file formats:{" "}
        <Text as="span" color="ef-black" fontWeight="bold" fontSize="inherit">
          .jpg, .jpeg, .png
        </Text>
      </Text>
    </>
  );
};

const ColorFragment: React.FC<{selectedFragmentId: string}> = ({selectedFragmentId}) => {
  const frag = useNewNFTBuilder(
    (store) => store.selectedStyle.fragments.find((x) => x.id === selectedFragmentId),
    (a, b) => equals(a, b)
  );

  const {setEditTextFragment} = useNFTBuilderSelector("setEditTextFragment");
  const [isColorPickerHidden, {toggle: switchColorPicker}] = useBoolean(false);

  if (!frag) return null;

  return (
    <>
      <Input
        label="Choose color"
        labelColor="ef-nft-primary"
        bg="ef-white"
        borderColor="ef-white"
        placeholder={frag?.value?.toString() || "#F8A9A8"}
        leftContent={
          <Box
            border="1px solid var(--chakra-colors-ef-border)"
            boxSize="24px"
            borderRadius="4px"
            bg={frag?.value?.toString() || undefined}
            cursor="pointer"
            onClick={() => switchColorPicker()}
          />
        }
        value={frag?.value?.toString() || ""}
        onChange={(e) => {
          setEditTextFragment(selectedFragmentId, e.target.value);
        }}
        mb="1rem"
      />

      <Collapse in={isColorPickerHidden} animateOpacity>
        <Box mb="1.5rem">
          <HexColorPicker
            color={frag?.value?.toString() || ""}
            onChange={(newColor) => setEditTextFragment(selectedFragmentId, newColor)}
          />
        </Box>
      </Collapse>
    </>
  );
};

const ComponentWithCustomImage: React.FC = () => {
  const {openModal: openErrorModal} = useErrorModal();

  const {
    selectedWorkspace,
    selected2dOwnImageFile,
    selected3dOwnImageSpace,
    setSelect2dOwnImageFile,
    setCustom2dOwnImageStyles,
    setSelect3dOwnImageSpace,
  } = useNFTBuilderSelector(
    "selectedWorkspace",
    "selected2dOwnImageFile",
    "setSelect2dOwnImageFile",
    "setCustom2dOwnImageStyles",
    "setSelect3dOwnImageSpace",
    "selected3dOwnImageSpace"
  );

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 6000000, // 1 megabyte = 1 000 000 bytes we need 3mb
    multiple: false,
    accept:
      selectedWorkspace === "2d"
        ? {"image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"]}
        : {"text/glft": [".gltf"], "text/glb": [".glb"]},
    onDrop: (acceptedFiles, rejectedFiles) => {
      const rejection = pathOr(null, [0, "errors", 0, "code"], rejectedFiles);

      if (rejection) {
        openErrorModal({
          message:
            rejection === "file-too-large" ? "Maximum file size is 6mb" : "Unsupported file type",
          hideVisitorId: true,
        });

        return;
      }

      // Double check...
      if (isEmpty(acceptedFiles)) {
        return;
      }

      if (selectedWorkspace === "2d") {
        setSelect2dOwnImageFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );
        return;
      }

      setSelect3dOwnImageSpace({
        file: Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      });
      setCustom2dOwnImageStyles({isCropActive: false, croppedImage: null});
    },
  });

  return (
    <Box sx={{".react-colorful": {w: "100%", h: "150px"}}}>
      <Text fontWeight="bold" mb="1rem" color="ef-nft-primary">
        Upload your image
      </Text>

      {selected2dOwnImageFile ? (
        <Stack
          {...getRootProps({id: "IMAGE_UPLOAD_SECTION"})}
          mb="1rem"
          border="2px"
          borderColor="ef-border"
          borderRadius="6px"
          borderStyle="dashed"
          align="center"
          justify="space-between"
          p="1rem 3rem 1rem 1rem"
          direction="row"
        >
          <Image
            maxH="100px"
            maxW="100px"
            src={selected2dOwnImageFile.preview}
            alt="2d-own-upload"
            border="1px"
            borderColor="ef-d1"
            borderRadius="8px"
            borderStyle="dashed"
          />
          <input {...getInputProps()} />

          <Stack spacing="1rem">
            <Stack direction="row" align="center">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelect2dOwnImageFile(null);
                  setCustom2dOwnImageStyles({isCropActive: false, croppedImage: null});
                }}
                cursor="pointer"
                width="22px"
                height="22px"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.69749 4.69668C6.05473 3.33946 7.92972 2.5 10.0007 2.5C12.9032 2.5 15.4207 4.14872 16.6675 6.56072V3.9583C16.6675 3.61312 16.9473 3.3333 17.2925 3.3333C17.6377 3.3333 17.9175 3.61312 17.9175 3.9583V7.91663C17.9175 8.0824 17.8517 8.24137 17.7344 8.35858C17.6172 8.47575 17.4582 8.54167 17.2925 8.54167H16.5896C16.5874 8.54167 16.5853 8.54167 16.5832 8.54167C16.5811 8.54167 16.5789 8.54167 16.5768 8.54167H13.3342C12.989 8.54167 12.7092 8.26182 12.7092 7.91663C12.7092 7.57146 12.989 7.29163 13.3342 7.29163H15.6351C14.6259 5.19615 12.4822 3.75 10.0007 3.75C8.27489 3.75 6.71241 4.44954 5.58138 5.58056C4.80484 6.35711 4.23169 7.33705 3.94871 8.43367C3.87133 8.7335 3.61041 8.95825 3.30075 8.95825C2.91591 8.95825 2.61793 8.61717 2.70774 8.243C3.03551 6.87752 3.73679 5.65739 4.69749 4.69668Z"
                  fill="#4B4F72"
                />
                <path
                  d="M16.0529 11.5663C15.7699 12.6629 15.1967 13.6428 14.4202 14.4194C13.2892 15.5504 11.7267 16.2499 10.0008 16.2499C7.51935 16.2499 5.37565 14.8038 4.36653 12.7083H6.66747C7.01265 12.7083 7.29247 12.4285 7.29247 12.0833C7.29247 11.7382 7.01265 11.4583 6.66747 11.4583H3.42374L3.4199 11.4583H3.41845C3.41665 11.4583 3.41487 11.4583 3.41309 11.4583H2.70914C2.54337 11.4583 2.3844 11.5242 2.2672 11.6413C2.14998 11.7586 2.08414 11.9176 2.08414 12.0833V16.0417C2.08414 16.3868 2.36395 16.6667 2.70914 16.6667C3.05431 16.6667 3.33414 16.3868 3.33414 16.0417V13.4392C4.58098 15.8512 7.09836 17.4999 10.0008 17.4999C12.0719 17.4999 13.9469 16.6605 15.3041 15.3032C16.2648 14.3426 16.9661 13.1224 17.2938 11.7569C17.3837 11.3827 17.0857 11.0417 16.7008 11.0417C16.3912 11.0417 16.1302 11.2665 16.0529 11.5663Z"
                  fill="#4B4F72"
                />
              </Icon>
              <Text as="span" cursor="pointer" _hover={{textDecoration: "underline"}}>
                Change image
              </Text>
            </Stack>

            <Stack
              direction="row"
              align="center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCustom2dOwnImageStyles({isCropActive: true});
              }}
            >
              <Icon cursor="pointer" width="22px" height="22px" viewBox="0 0 20 20" fill="none">
                <g>
                  <path
                    d="M4.79168 0.833008C5.13686 0.833008 5.41668 1.11283 5.41668 1.45801V14.1663C5.41668 14.3965 5.60323 14.583 5.83334 14.583H18.5417C18.8869 14.583 19.1667 14.8628 19.1667 15.208C19.1667 15.5532 18.8869 15.833 18.5417 15.833H15.8333V18.5413C15.8333 18.8865 15.5535 19.1663 15.2083 19.1663C14.8632 19.1663 14.5833 18.8865 14.5833 18.5413V15.833H5.83334C4.91287 15.833 4.16668 15.0868 4.16668 14.1663V5.41634H1.45834C1.11317 5.41634 0.833344 5.13652 0.833344 4.79134C0.833344 4.44616 1.11317 4.16634 1.45834 4.16634H4.16668V1.45801C4.16668 1.11283 4.4465 0.833008 4.79168 0.833008Z"
                    fill="#4B4F72"
                  />
                  <path
                    d="M15.8333 12.708C15.8333 13.0532 15.5535 13.333 15.2083 13.333C14.8632 13.333 14.5833 13.0532 14.5833 12.708V5.83301C14.5833 5.60289 14.3968 5.41634 14.1667 5.41634H7.29168C6.9465 5.41634 6.66668 5.13652 6.66668 4.79134C6.66668 4.44616 6.9465 4.16634 7.29168 4.16634H14.1667C15.0872 4.16634 15.8333 4.91253 15.8333 5.83301V12.708Z"
                    fill="#4B4F72"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6178_38898">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </Icon>
              <Text as="span" cursor="pointer" _hover={{textDecoration: "underline"}}>
                Crop image
              </Text>
            </Stack>
          </Stack>
        </Stack>
      ) : selected3dOwnImageSpace?.file ? (
        <Stack
          {...getRootProps({id: "IMAGE_UPLOAD_SECTION"})}
          mb="1rem"
          border="2px"
          borderColor="ef-border"
          borderRadius="6px"
          borderStyle="dashed"
          align="center"
          justify="center"
          p="1rem 3rem 1rem 1rem"
          direction="row"
        >
          <input {...getInputProps()} />

          <Stack spacing="1rem">
            <Stack direction="row" align="center">
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelect3dOwnImageSpace({file: null});
                }}
                cursor="pointer"
                width="22px"
                height="22px"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.69749 4.69668C6.05473 3.33946 7.92972 2.5 10.0007 2.5C12.9032 2.5 15.4207 4.14872 16.6675 6.56072V3.9583C16.6675 3.61312 16.9473 3.3333 17.2925 3.3333C17.6377 3.3333 17.9175 3.61312 17.9175 3.9583V7.91663C17.9175 8.0824 17.8517 8.24137 17.7344 8.35858C17.6172 8.47575 17.4582 8.54167 17.2925 8.54167H16.5896C16.5874 8.54167 16.5853 8.54167 16.5832 8.54167C16.5811 8.54167 16.5789 8.54167 16.5768 8.54167H13.3342C12.989 8.54167 12.7092 8.26182 12.7092 7.91663C12.7092 7.57146 12.989 7.29163 13.3342 7.29163H15.6351C14.6259 5.19615 12.4822 3.75 10.0007 3.75C8.27489 3.75 6.71241 4.44954 5.58138 5.58056C4.80484 6.35711 4.23169 7.33705 3.94871 8.43367C3.87133 8.7335 3.61041 8.95825 3.30075 8.95825C2.91591 8.95825 2.61793 8.61717 2.70774 8.243C3.03551 6.87752 3.73679 5.65739 4.69749 4.69668Z"
                  fill="#4B4F72"
                />
                <path
                  d="M16.0529 11.5663C15.7699 12.6629 15.1967 13.6428 14.4202 14.4194C13.2892 15.5504 11.7267 16.2499 10.0008 16.2499C7.51935 16.2499 5.37565 14.8038 4.36653 12.7083H6.66747C7.01265 12.7083 7.29247 12.4285 7.29247 12.0833C7.29247 11.7382 7.01265 11.4583 6.66747 11.4583H3.42374L3.4199 11.4583H3.41845C3.41665 11.4583 3.41487 11.4583 3.41309 11.4583H2.70914C2.54337 11.4583 2.3844 11.5242 2.2672 11.6413C2.14998 11.7586 2.08414 11.9176 2.08414 12.0833V16.0417C2.08414 16.3868 2.36395 16.6667 2.70914 16.6667C3.05431 16.6667 3.33414 16.3868 3.33414 16.0417V13.4392C4.58098 15.8512 7.09836 17.4999 10.0008 17.4999C12.0719 17.4999 13.9469 16.6605 15.3041 15.3032C16.2648 14.3426 16.9661 13.1224 17.2938 11.7569C17.3837 11.3827 17.0857 11.0417 16.7008 11.0417C16.3912 11.0417 16.1302 11.2665 16.0529 11.5663Z"
                  fill="#4B4F72"
                />
              </Icon>
              <Text as="span" cursor="pointer" _hover={{textDecoration: "underline"}}>
                Change .gltf
              </Text>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          {...getRootProps({id: "IMAGE_UPLOAD_SECTION"})}
          mb="1rem"
          border="2px"
          borderColor="ef-border"
          borderRadius="6px"
          borderStyle="dashed"
          spacing="1.5rem"
          align="center"
          justify="center"
          py="2rem"
          px="1rem"
          direction="row"
        >
          <input {...getInputProps()} />
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 10C9.82843 10 10.5 9.32843 10.5 8.5C10.5 7.67157 9.82843 7 9 7C8.17157 7 7.5 7.67157 7.5 8.5C7.5 9.32843 8.17157 10 9 10Z"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 15L16.5 10L5.5 21"
              stroke="#141A4E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {selectedWorkspace === "2d" ? (
            <Text>
              Drag and drop or{" "}
              <Text
                as="span"
                textDecoration="underline"
                cursor="pointer"
                _hover={{textDecoration: "none"}}
              >
                select image
              </Text>
            </Text>
          ) : (
            <Stack spacing="-5px">
              <Text>Drag and drop or </Text>
              <Text textDecoration="underline" cursor="pointer" _hover={{textDecoration: "none"}}>
                select your .gltf, .glb file
              </Text>
            </Stack>
          )}
        </Stack>
      )}

      <Collapse in={selectedWorkspace === "2d"} animateOpacity>
        <Text color="ef-border" fontSize="sm">
          We recommend you to use a square of at least 1200x1200px. Supported file formats:{" "}
          <Text as="span" color="ef-black" fontWeight="bold" fontSize="inherit">
            .jpg, .jpeg, .png
          </Text>
        </Text>
      </Collapse>

      <Collapse in={selectedWorkspace === "3d"} animateOpacity>
        <Text color="ef-border" fontSize="sm">
          Only files with *.gltf and *.glb file format are permitted
        </Text>
      </Collapse>
    </Box>
  );
};

const ComponentFromTemplate = () => {
  const {selectedWorkspace} = useNFTBuilderSelector("selectedWorkspace");
  const {data, isLoading} = useSelector(beNftOrgApi.endpoints.getNftappApiStyle.select());

  const onlySpecificStyles = useMemo(
    () =>
      data?.items?.filter((x) =>
        x.mimetype?.includes(selectedWorkspace === "2d" ? "image/" : "model/")
      ) || [],
    [data, selectedWorkspace]
  );

  return (
    <>
      {isLoading && (
        <Stack direction="row">
          <Skeleton boxSize="100px" />
          <Skeleton boxSize="100px" />
          <Skeleton boxSize="100px" />
        </Stack>
      )}

      {isEmpty(onlySpecificStyles || []) && !isLoading && <Text>No templates</Text>}

      {!isEmpty(onlySpecificStyles || []) && (
        <Stack
          direction="row"
          spacing="1rem"
          overflowX="auto"
          sx={{"::-webkit-scrollbar": {display: "none"}}}
          mb="1.5rem"
        >
          {onlySpecificStyles?.map((x, i) => (
            <NFTTemplateImage key={i} i={i} styleObj={x} />
          ))}
        </Stack>
      )}
    </>
  );
};

const ImageBox: React.FC<
  React.PropsWithChildren<{
    bottomNode?: React.ReactNode;
    isActive?: boolean;
    hasError?: boolean;
    onClick?: BoxProps["onClick"];
  }>
> = ({children, onClick, bottomNode, isActive, hasError}) => {
  return (
    <Box
      w="100px"
      cursor={hasError ? "unset" : "pointer"}
      opacity={hasError ? "0.5" : "1"}
      onClick={onClick}
    >
      <Stack
        p="0.5rem"
        align="center"
        justify="center"
        border="2px"
        borderColor={isActive ? "var(--chakra-colors-ef-nft-primary)" : "#F2F2F2"}
        boxSize="100px"
        position="relative"
        bg="#F2F2F2"
        borderRadius="6px"
        _hover={hasError ? {} : {borderColor: "ef-border"}}
      >
        {children}
      </Stack>
      {bottomNode && <Box pt="0.3rem">{bottomNode}</Box>}
    </Box>
  );
};

const NFTTemplateImage: React.FC<{
  styleObj: GetNftappApiAccountByAccountIdStyleApiResponse["items"][0];
  i: number;
}> = ({i, styleObj}) => {
  const {selectedStyle, setSelectStyle} = useNFTBuilderSelector("selectedStyle", "setSelectStyle");
  const [isError, {off, on}] = useBoolean(false);

  useEffect(off, [styleObj?.url_preview]);

  return (
    <ImageBox
      onClick={() => {
        if (isError) {
          return;
        }

        //@ts-ignore
        setSelectStyle((prevStyle) => (prevStyle?.id === styleObj?.id ? null : styleObj));
      }}
      hasError={isError}
      isActive={selectedStyle?.id === styleObj?.id}
      bottomNode={
        <Text textAlign="center" w="100%" fontSize="13px">
          {styleObj.name ? styleObj.name : `Ticket #${i + 1}`}
        </Text>
      }
    >
      <Image
        alt="image"
        src={styleObj?.url_preview}
        onError={on}
        fallbackSrc="/app/placeholders/nft-icon.png"
      />
    </ImageBox>
  );
};

const SelectSpace = () => {
  const {selectedWorkspace, setSelectWorkspace} = useNFTBuilderSelector(
    "selectedWorkspace",
    "setSelectWorkspace"
    // "isStyleChanged"
  );

  const handleSwitchingSpace = (newSpace: typeof selectedWorkspace) => {
    setSelectWorkspace(newSpace);
  };

  return (
    <Stack direction="row" align="center" spacing="1.5rem">
      <Text color="ef-dark-blue">Space:</Text>

      <Stack direction="row" align="center">
        <Button
          variant={selectedWorkspace === "2d" ? "primary" : "neutral"}
          size="md"
          onClick={() => handleSwitchingSpace("2d")}
        >
          2D
        </Button>
        <Button
          onClick={() => handleSwitchingSpace("3d")}
          variant={selectedWorkspace === "3d" ? "primary" : "neutral"}
          size="md"
          leftIcon={
            <Icon viewBox="0 0 16 16" fill="none">
              <path
                d="M14 10.6679V5.33457C13.9998 5.10075 13.938 4.87111 13.821 4.66868C13.704 4.46625 13.5358 4.29815 13.3333 4.18124L8.66667 1.51457C8.46397 1.39755 8.23405 1.33594 8 1.33594C7.76595 1.33594 7.53603 1.39755 7.33333 1.51457L2.66667 4.18124C2.46418 4.29815 2.29599 4.46625 2.17897 4.66868C2.06196 4.87111 2.00024 5.10075 2 5.33457V10.6679C2.00024 10.9017 2.06196 11.1314 2.17897 11.3338C2.29599 11.5362 2.46418 11.7043 2.66667 11.8212L7.33333 14.4879C7.53603 14.6049 7.76595 14.6665 8 14.6665C8.23405 14.6665 8.46397 14.6049 8.66667 14.4879L13.3333 11.8212C13.5358 11.7043 13.704 11.5362 13.821 11.3338C13.938 11.1314 13.9998 10.9017 14 10.6679Z"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.1792 4.64062L7.9992 8.00729L13.8192 4.64062"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 14.72V8"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Icon>
          }
        >
          3D
        </Button>
      </Stack>
    </Stack>
  );
};

const SelectTypeOfImage = () => {
  const {selectedImageType, setSelectImageType, selectedWorkspace} = useNFTBuilderSelector(
    "selectedImageType",
    "setSelectImageType",
    "selectedWorkspace"
  );

  return (
    <Stack direction="row" align="center" spacing="1.5rem">
      <Text color="ef-dark-blue">Image:</Text>

      <Stack direction="row" align="center">
        <Button
          size="md"
          variant={
            selectedImageType === "custom-image" ? "nftSelected" : "nftTransparentWithBorder"
          }
          onClick={() => setSelectImageType("custom-image")}
        >
          My own image
        </Button>
        <Button
          size="md"
          onClick={() => setSelectImageType("from-template")}
          variant={
            selectedImageType === "from-template" ? "nftSelected" : "nftTransparentWithBorder"
          }
          leftIcon={
            <Icon viewBox="0 0 16 16" fill="none">
              <path
                d="M14 10.6679V5.33457C13.9998 5.10075 13.938 4.87111 13.821 4.66868C13.704 4.46625 13.5358 4.29815 13.3333 4.18124L8.66667 1.51457C8.46397 1.39755 8.23405 1.33594 8 1.33594C7.76595 1.33594 7.53603 1.39755 7.33333 1.51457L2.66667 4.18124C2.46418 4.29815 2.29599 4.46625 2.17897 4.66868C2.06196 4.87111 2.00024 5.10075 2 5.33457V10.6679C2.00024 10.9017 2.06196 11.1314 2.17897 11.3338C2.29599 11.5362 2.46418 11.7043 2.66667 11.8212L7.33333 14.4879C7.53603 14.6049 7.76595 14.6665 8 14.6665C8.23405 14.6665 8.46397 14.6049 8.66667 14.4879L13.3333 11.8212C13.5358 11.7043 13.704 11.5362 13.821 11.3338C13.938 11.1314 13.9998 10.9017 14 10.6679Z"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.1792 4.64062L7.9992 8.00729L13.8192 4.64062"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 14.72V8"
                stroke="#C2C2C2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Icon>
          }
        >
          Use template
        </Button>
      </Stack>
    </Stack>
  );
};

export default WorkspaceSelected;
