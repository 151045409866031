import {beOrgApi} from "@ef-org/api";

import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {Center, Stack, Text} from "@chakra-ui/react";

import {getValidationResults} from "../../store/validation.slice";
import Details from "./components/Details";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Failure = () => {
  const {
    query: {id},
  } = useRouter();

  const results = useSelector(getValidationResults);

  const ticketData = results[id?.toString()];

  return (
    <Center>
      <Stack
        display="flex"
        position="relative"
        height="100vh"
        width="100%"
        maxHeight="900px"
        maxWidth="500px"
      >
        <Header variant="invalid" />
        <Details ticketData={ticketData} />
        <Footer />
      </Stack>
    </Center>
  );
};

export default Failure;
