import {Input} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import {getCurrentUser, verifyAccountByCode} from "apps/ef-org/src/helpers/aws/auth";
import Joi from "joi";
import {isEmpty} from "ramda";

import {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Heading, Stack, Text, useToast} from "@chakra-ui/react";

const SectionLoginSchema = Joi.object({
  code: Joi.string().required(),
});

type SignupTypes = {
  code: string;
};

const Timer = () => {
  const [remainingTime, setRemainingTime] = useState(60 * 60); // 1 hour in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <Stack
      align="center"
      color="black"
      justify="center"
      bg="#F8F8F8"
      borderRadius="12px"
      p="1rem"
      mb="2rem"
    >
      <Text fontSize="15px" color="inherit">
        The code expires in
      </Text>
      <Text fontSize="32px" color="inherit">
        {formatTime(remainingTime)}
      </Text>
    </Stack>
  );
};

const ConfirmationForm = () => {
  const toast = useToast();
  const {push, query} = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [showSuccessBar, setShowSuccessBar] = useState(false);

  const {registerWithError, handleSubmit} = useForm<SignupTypes>(SectionLoginSchema, {
    keepDataOnSuccess: true,
    defaultValues: {code: ""},
  });

  useEffect(() => {
    if (!isEmpty(query) && !query?.email) {
      push("/register");
    }
  }, [query]);

  const handleFormSubmit: OnFormSubmitType<SignupTypes> = async (data) => {
    setLoading(true);
    const currentUser = getCurrentUser();

    if (currentUser) {
      currentUser.signOut();
    }

    const awsNewUserStatus = await verifyAccountByCode(query?.email?.toString() || "", data.code);

    if (awsNewUserStatus?.errorMessage) {
      toast({
        title: "Ops!",
        description: awsNewUserStatus.errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    push("/login?showSuccess=true");
  };

  return (
    <Box w="100%">
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="2rem">
        Confirm Your Email Address
      </Heading>

      <Text w="100%" textAlign="center" color="black" pb="2rem">
        We&apos;ve sent a code to <b>{decodeURIComponent(query?.email?.toString() || "")}</b> Please
        write it in the field below.
      </Text>

      <Timer />

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            w="100%"
            autoComplete="off"
            label="Enter the code*"
            textAlign="center"
            labelColor="primary-2"
            placeholder="Enter the code"
            {...registerWithError("code")}
            noBorder
          />

          <Box py="1rem">
            {!showSuccessBar ? (
              <Button type="submit" w="100%" isLoading={isLoading}>
                Verify code
              </Button>
            ) : (
              <Box mt="-1rem" bg="green.100" borderRadius="lg" p="1rem">
                Your account has been successfully created! We will redirect you to the login page.
              </Box>
            )}
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default ConfirmationForm;
