import {Link} from "@ef-org/components";

import React from "react";

import {useRouter} from "next/router";

import {Button, Stack} from "@chakra-ui/react";

const TabHandler: React.FC<{
  rightElement?: React.ReactNode;
  hideUserSetting?: boolean;
  hideOrganiserSetting?: boolean;
  hideIntegrations?: boolean;
}> = ({rightElement, hideOrganiserSetting, hideUserSetting, hideIntegrations}) => {
  const {asPath} = useRouter();

  return (
    <Stack
      direction="row"
      align="center"
      justify="space-between"
      mb="2rem"
      overflowX="auto"
      p="3px"
    >
      <Stack direction="row" align="center">
        {!hideUserSetting && (
          <Link href="/settings">
            <Button variant="neutral" bg={asPath === "/settings" ? undefined : "transparent"}>
              User Settings
            </Button>
          </Link>
        )}

        {!hideOrganiserSetting && (
          <Link href="/settings/organiser">
            <Button variant="neutral" bg={asPath.includes("organiser") ? undefined : "transparent"}>
              Organizer Settings
            </Button>
          </Link>
        )}

        {!hideIntegrations && (
          <Link href="/settings/integrations">
            <Button
              variant="neutral"
              bg={asPath.includes("integrations") ? undefined : "transparent"}
            >
              Integrations
            </Button>
          </Link>
        )}
        <Link href="/settings/teamMembers">
          <Button variant="neutral" bg={asPath.includes("teamMembers") ? undefined : "transparent"}>
            Team members
          </Button>
        </Link>
      </Stack>

      {rightElement}
    </Stack>
  );
};

export default TabHandler;
