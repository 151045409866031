import {EfFullLogo, Link, LogoSquare} from "@ef-org/components";

import React from "react";

import {NextSeo, NextSeoProps} from "next-seo";

import {
  AspectRatio,
  Container,
  Grid,
  Heading,
  Stack,
  Image,
  Text,
  Divider,
  Alert,
  Button,
  AlertIcon,
} from "@chakra-ui/react";

const BoardingLayout: React.FC<{
  children?: React.ReactNode;
  metaTags?: NextSeoProps;
  displayLegacyLoginAlert?: boolean;
  rightSection?: {
    heading?: string;
    texting?: string;
    imageUrl?: string;
  };
}> = ({children, metaTags, rightSection, displayLegacyLoginAlert}) => {
  return (
    <>
      <NextSeo {...metaTags} />

      <Container maxW="1280px" py="1.5rem" h="100vh">
        {displayLegacyLoginAlert && (
          <Alert status="error" mb="1rem" position="sticky" top="1rem" zIndex="999">
            <Stack direction={["column", "row"]} align="center" spacing="1rem">
              <AlertIcon />
              <Text size="small" align={["center", "left"]} color="red">
                Legacy EventsFrame platform will be closed down in 2024. Login to manage your events
                created before Nov 13, 2023. Find more information about the transition, timeline
                and new platform{" "}
                <Link
                  as="a"
                  fontSize="small"
                  target="_blank"
                  fontWeight="bold"
                  color="red"
                  href="https://support.eventsframe.com/article/137-transition-from-legacy-platform"
                >
                  HERE
                </Link>
                .
              </Text>
              <Link href="https://app.eventsframe.com/app/login/">
                <Button bg="#D85639">Login to Legacy Platform</Button>
              </Link>
            </Stack>
          </Alert>
        )}

        <Grid
          templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "50% 50%"]}
          w="100%"
          h={[null, null, "100%"]}
          gap="0"
        >
          <Stack justify="space-between" w="100%" direction="column">
            <Stack
              direction="row"
              align="center"
              justify="space-between"
              pt="10px"
              display={["none", "flex"]}
              w="100%"
            >
              <Link href="/">
                <EfFullLogo w="auto" h="24px" />
              </Link>
            </Stack>
            <Stack w="100%" align="center" mt={["10px", "60px"]} direction="column">
              <LogoSquare w="auto" h="24px" display={["block", "none"]} mb="20px" />

              <Stack w={["100%", "100%", "450px"]}>{children}</Stack>
            </Stack>
            <Stack spacing="0.5rem" align="center" justify="space-between" mt="2rem">
              <Stack direction="row" align="center" spacing="1rem">
                <Link
                  variant="switchedDecoration"
                  textDecoration="none"
                  href="https://twitter.com/eventsframe"
                >
                  Twitter
                </Link>
                <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
                <Link
                  href="https://support.eventsframe.com"
                  variant="switchedDecoration"
                  textDecoration="none"
                >
                  Support
                </Link>
                <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
                <Link
                  variant="switchedDecoration"
                  textDecoration="none"
                  href="https://support.eventsframe.com"
                >
                  Contact
                </Link>
              </Stack>
              <Stack align="center" spacing="1rem">
                <Text size="small" color="ef-gray">
                  © Copyright 2022 EventsFrame. All rights reserved.
                </Text>
              </Stack>
            </Stack>
          </Stack>

          <AspectRatio display={["none", "none", "block"]} ratio={1 / 1.4} w="100%">
            <Stack
              borderRadius="30px"
              bg="linear-gradient(152.33deg, #1F2FBA 3.47%, #101D8B 95.03%)"
              justify="center"
              align="center"
              spacing="100px"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
              <Image alt="rocket" src={rightSection?.imageUrl || "/images/onboarding/wallet.png"} />
              <Stack direction="column" w="60%" align="center">
                <Heading textAlign="center" color="ob-white" variant="h2">
                  {rightSection?.heading || "EventsFrame"}
                </Heading>
                <Text color="ob-white" textAlign="center" mt="16px">
                  {rightSection?.texting || "The best way to sell tickets online"}
                </Text>
              </Stack>
            </Stack>
          </AspectRatio>
        </Grid>
      </Container>
    </>
  );
};

export default BoardingLayout;
