import {NFTCanvas} from "@ef-org/components";

import React from "react";

import {AspectRatio, Text, Stack} from "@chakra-ui/react";

const Row: React.FC<{leftText?: string; rightElement?: React.ReactNode}> = ({
  leftText,
  rightElement,
}) => {
  return (
    <Stack direction="row" align="center" w="100%" justify="space-between">
      <Text color="#727CD4" fontSize="14px">
        {leftText}
      </Text>
      {rightElement}
    </Stack>
  );
};

const MerchItem = () => {
  return (
    <Stack p="1.5rem" bg="white" borderRadius="24px">
      <AspectRatio w="100%" ratio={1}>
        <NFTCanvas nftAssetUrl="https://storage.googleapis.com/ef-nft-staging-uploads/6328309642166272.glb" />
      </AspectRatio>

      <Text as="b">Lorem, ipsum.</Text>

      <Stack spacing="0">
        <Row
          leftText="Price"
          rightElement={
            <Stack direction="row" align="center">
              <Text color="#727CD4" fontSize="14px">
                $
              </Text>
              <Text color="#162184" fontSize="14px">
                10
              </Text>
            </Stack>
          }
        />

        <Row
          leftText="Total"
          rightElement={
            <Stack direction="row" align="center">
              <Text color="#727CD4" fontSize="14px">
                $
              </Text>
              <Text color="#162184" fontSize="14px">
                1000
              </Text>
            </Stack>
          }
        />

        <Row
          leftText="Sold Items"
          rightElement={
            <Stack direction="row" align="center">
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.60019 6V4.4C5.60019 3.07452 6.67471 2 8.00019 2C8.61491 2 9.17555 2.23109 9.60019 2.61112C10.0248 2.23108 10.5856 2 11.2002 2C12.5257 2 13.6002 3.07452 13.6002 4.4V6H14.8002C15.4629 6 16.0002 6.53726 16.0002 7.2V15.204C16.0002 16.7482 14.7484 18 13.2042 18H6.40019C4.63288 18 3.2002 16.5673 3.2002 14.8V7.2C3.2002 6.53726 3.73745 6 4.4002 6H5.60019ZM10.9082 16.8C10.593 16.3474 10.4082 15.7973 10.4082 15.204V7.2H4.4002V14.8C4.4002 15.9046 5.29563 16.8 6.40019 16.8H10.9082ZM9.20019 6V4.4C9.20019 3.73726 8.66291 3.2 8.00019 3.2C7.33745 3.2 6.80019 3.73726 6.80019 4.4V6H9.20019ZM10.4002 6H12.4002V4.4C12.4002 3.73726 11.8629 3.2 11.2002 3.2C10.8282 3.2 10.4958 3.36925 10.2757 3.63494C10.3564 3.87522 10.4002 4.1325 10.4002 4.4V6ZM11.6082 15.204C11.6082 16.0854 12.3228 16.8 13.2042 16.8C14.0856 16.8 14.8002 16.0854 14.8002 15.204V7.2H11.6082V15.204Z"
                  fill="#727CD4"
                />
              </svg>

              <Text color="#162184" fontSize="14px">
                10
              </Text>

              <Text color="#727CD4" fontSize="14px">
                / 100
              </Text>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

export default MerchItem;
