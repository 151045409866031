import {Input} from "@ef-org/components";
import {useForm} from "@ef-org/hooks";
import {changePassword} from "apps/ef-org/src/helpers/aws/auth";
import Joi from "joi";

import React from "react";

import {Box, Button, Heading, Stack, Text, useToast} from "@chakra-ui/react";

const ChangePasswordSchema = Joi.object({
  oldPassword: Joi.string().required(),
  newPassword: Joi.string().min(6).required(),
  newPasswordConfirm: Joi.string().min(6).required().valid(Joi.ref("newPassword")),
});

type SignupTypes = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const ChangePassword = () => {
  const toast = useToast();
  const [isLoading, setLoading] = React.useState(false);

  const {registerWithError, handleSubmit, reset} = useForm<SignupTypes>(ChangePasswordSchema, {
    keepDataOnSuccess: true,
    defaultValues: {oldPassword: "", newPassword: "", newPasswordConfirm: ""},
  });

  const handleSubmitChange = async (data: SignupTypes) => {
    setLoading(true);
    const response = await changePassword(data.oldPassword, data.newPassword);

    if (response.errorMessage || !response.isChanged) {
      toast({
        title: "Ops!",
        description: response.errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      reset();
      setLoading(false);
      return;
    } else {
      toast({
        title: "Success!",
        description: "Password changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    reset();
    setLoading(false);
  };

  return (
    <Box>
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Change Password
      </Heading>

      <Stack
        onSubmit={handleSubmit(handleSubmitChange)}
        as="form"
        spacing="1rem"
        bg="white"
        borderRadius="22px"
        p="1rem"
        w="100%"
      >
        <Input
          type="password"
          {...registerWithError("oldPassword")}
          maxW="500px"
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2.17969C12.2091 2.17969 14 3.97055 14 6.17969V8.17969H15.75C16.9926 8.17969 18 9.18705 18 10.4297L18.0004 11.1797C17.6815 11.1797 17.3626 11.2893 17.1038 11.5083L16.9966 11.609C16.8328 11.7795 16.6678 11.9316 16.5009 12.0657L16.5 10.4297C16.5 10.0155 16.1642 9.67969 15.75 9.67969H4.25C3.83579 9.67969 3.5 10.0155 3.5 10.4297V19.9297C3.5 20.3439 3.83579 20.6797 4.25 20.6797L14.1384 20.6805C14.546 21.2571 15.0606 21.7587 15.6783 22.1802L4.25 22.1797C3.00736 22.1797 2 21.1723 2 19.9297V10.4297C2 9.18705 3.00736 8.17969 4.25 8.17969H6V6.17969C6 3.97055 7.79086 2.17969 10 2.17969ZM18.2837 12.302C19.2758 13.3373 20.3751 13.8464 21.6 13.8464C21.7933 13.8464 21.9546 13.9892 21.9919 14.1791L22 14.263V16.7641C22 19.446 20.687 21.2693 18.1265 22.1583C18.0444 22.1868 17.9556 22.1868 17.8735 22.1583C15.3984 21.2989 14.0889 19.5665 14.0044 17.0294L14 16.7641V14.263C14 14.0329 14.1791 13.8464 14.4 13.8464C15.6234 13.8464 16.7233 13.3372 17.7178 12.3017C17.8741 12.1389 18.1276 12.1391 18.2837 12.302ZM10.0001 13.6797C10.8286 13.6797 11.5001 14.3513 11.5001 15.1797C11.5001 16.0081 10.8286 16.6797 10.0001 16.6797C9.1717 16.6797 8.50012 16.0081 8.50012 15.1797C8.50012 14.3513 9.1717 13.6797 10.0001 13.6797ZM10 3.67969C8.61929 3.67969 7.5 4.79898 7.5 6.17969V8.17969H12.5V6.17969C12.5 4.79898 11.3807 3.67969 10 3.67969Z"
                fill="#727CD4"
              />
            </svg>
          }
          label={<Text color="#727CD4">Old Password</Text>}
          placeholder="Enter old password"
          noBorder
        />

        <Input
          type="password"
          {...registerWithError("newPassword")}
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2.17969C12.2091 2.17969 14 3.97055 14 6.17969V8.17969H15.75C16.9926 8.17969 18 9.18705 18 10.4297L18.0004 11.1797C17.6815 11.1797 17.3626 11.2893 17.1038 11.5083L16.9966 11.609C16.8328 11.7795 16.6678 11.9316 16.5009 12.0657L16.5 10.4297C16.5 10.0155 16.1642 9.67969 15.75 9.67969H4.25C3.83579 9.67969 3.5 10.0155 3.5 10.4297V19.9297C3.5 20.3439 3.83579 20.6797 4.25 20.6797L14.1384 20.6805C14.546 21.2571 15.0606 21.7587 15.6783 22.1802L4.25 22.1797C3.00736 22.1797 2 21.1723 2 19.9297V10.4297C2 9.18705 3.00736 8.17969 4.25 8.17969H6V6.17969C6 3.97055 7.79086 2.17969 10 2.17969ZM18.2837 12.302C19.2758 13.3373 20.3751 13.8464 21.6 13.8464C21.7933 13.8464 21.9546 13.9892 21.9919 14.1791L22 14.263V16.7641C22 19.446 20.687 21.2693 18.1265 22.1583C18.0444 22.1868 17.9556 22.1868 17.8735 22.1583C15.3984 21.2989 14.0889 19.5665 14.0044 17.0294L14 16.7641V14.263C14 14.0329 14.1791 13.8464 14.4 13.8464C15.6234 13.8464 16.7233 13.3372 17.7178 12.3017C17.8741 12.1389 18.1276 12.1391 18.2837 12.302ZM10.0001 13.6797C10.8286 13.6797 11.5001 14.3513 11.5001 15.1797C11.5001 16.0081 10.8286 16.6797 10.0001 16.6797C9.1717 16.6797 8.50012 16.0081 8.50012 15.1797C8.50012 14.3513 9.1717 13.6797 10.0001 13.6797ZM10 3.67969C8.61929 3.67969 7.5 4.79898 7.5 6.17969V8.17969H12.5V6.17969C12.5 4.79898 11.3807 3.67969 10 3.67969Z"
                fill="#727CD4"
              />
            </svg>
          }
          maxW="500px"
          label={<Text color="#727CD4">New Password</Text>}
          placeholder="Set new password"
          noBorder
          // customBottomElement={
          //   <Text>
          //     Don&apos;t remember password ?{" "}
          //     <Link variant="switchedDecoration" color="inherit" fontWeight="bold">
          //       Reset it
          //     </Link>
          //   </Text>
          // }
        />
        <Input
          type="password"
          {...registerWithError("newPasswordConfirm")}
          maxW="500px"
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2.17969C12.2091 2.17969 14 3.97055 14 6.17969V8.17969H15.75C16.9926 8.17969 18 9.18705 18 10.4297L18.0004 11.1797C17.6815 11.1797 17.3626 11.2893 17.1038 11.5083L16.9966 11.609C16.8328 11.7795 16.6678 11.9316 16.5009 12.0657L16.5 10.4297C16.5 10.0155 16.1642 9.67969 15.75 9.67969H4.25C3.83579 9.67969 3.5 10.0155 3.5 10.4297V19.9297C3.5 20.3439 3.83579 20.6797 4.25 20.6797L14.1384 20.6805C14.546 21.2571 15.0606 21.7587 15.6783 22.1802L4.25 22.1797C3.00736 22.1797 2 21.1723 2 19.9297V10.4297C2 9.18705 3.00736 8.17969 4.25 8.17969H6V6.17969C6 3.97055 7.79086 2.17969 10 2.17969ZM18.2837 12.302C19.2758 13.3373 20.3751 13.8464 21.6 13.8464C21.7933 13.8464 21.9546 13.9892 21.9919 14.1791L22 14.263V16.7641C22 19.446 20.687 21.2693 18.1265 22.1583C18.0444 22.1868 17.9556 22.1868 17.8735 22.1583C15.3984 21.2989 14.0889 19.5665 14.0044 17.0294L14 16.7641V14.263C14 14.0329 14.1791 13.8464 14.4 13.8464C15.6234 13.8464 16.7233 13.3372 17.7178 12.3017C17.8741 12.1389 18.1276 12.1391 18.2837 12.302ZM10.0001 13.6797C10.8286 13.6797 11.5001 14.3513 11.5001 15.1797C11.5001 16.0081 10.8286 16.6797 10.0001 16.6797C9.1717 16.6797 8.50012 16.0081 8.50012 15.1797C8.50012 14.3513 9.1717 13.6797 10.0001 13.6797ZM10 3.67969C8.61929 3.67969 7.5 4.79898 7.5 6.17969V8.17969H12.5V6.17969C12.5 4.79898 11.3807 3.67969 10 3.67969Z"
                fill="#727CD4"
              />
            </svg>
          }
          label={<Text color="#727CD4">Confirm new password</Text>}
          placeholder="New password again"
          noBorder
        />

        <Button type="submit" isLoading={isLoading}>
          Change password
        </Button>
      </Stack>
    </Box>
  );
};

export default ChangePassword;
