import {
  Button,
  Grid,
  Heading,
  Stack,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  StackProps,
} from "@chakra-ui/react";

import {useNftBuilderModal} from "../../modals/useNftBuilderModal";
import MerchItem from "./components/MerchItem";

const Actions: React.FC<{display?: StackProps["display"]}> = ({display}) => {
  const {openModal} = useNftBuilderModal();

  return (
    <Stack
      display={display}
      spacing="0.5rem"
      direction={["column-reverse", "column-reverse", "row"]}
      w={["100%", "100%", "fit-content"]}
    >
      <div />
      <Button
        w={["100%", "100%", "fit-content"]}
        size="md"
        variant="outline"
        leftIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6024 3.3975L20.6024 3.39752C21.7991 4.59419 21.7991 6.5344 20.6024 7.73108L20.956 8.08464L20.6024 7.73108L8.68145 19.6521C8.46712 19.8664 8.20063 20.0211 7.90838 20.1008L7.90835 20.1008L2.81286 21.4905L2.94362 21.9699L2.81286 21.4905C2.62847 21.5408 2.45924 21.3715 2.50953 21.1872L2.02715 21.0556L2.50953 21.1871L3.89921 16.0916L3.89921 16.0916C3.97894 15.7993 4.13355 15.5329 4.34782 15.3186C4.34783 15.3186 4.34783 15.3186 4.34784 15.3186L16.2689 3.39751L16.2689 3.3975C17.4655 2.20083 19.4058 2.20083 20.6024 3.3975ZM15.3032 5.76911L14.9497 5.41556L14.5961 5.76911L4.69719 15.668L4.69717 15.668C4.54379 15.8214 4.43298 16.0122 4.37586 16.2218L3.33018 20.0559L3.09994 20.9001L3.94412 20.6698L7.77822 19.6241C7.77827 19.6241 7.77832 19.6241 7.77837 19.6241C7.98777 19.567 8.17861 19.4562 8.33205 19.3028L18.2307 9.4036L18.5842 9.05005L18.2306 8.69651L15.3032 5.76911ZM15.6521 4.71214L15.2983 5.06564L15.6519 5.41935L18.5793 8.34774L18.9327 8.7013L19.2863 8.3479L20.253 7.38182L20.2531 7.3817C21.2568 6.37798 21.2568 4.75063 20.2531 3.74689L20.2531 3.74687C19.2494 2.74315 17.622 2.74315 16.6182 3.74687L15.6521 4.71214Z"
              fill="#727CD4"
              stroke="#4754C6"
            />
          </svg>
        }
      >
        Edit store
      </Button>

      <Button
        onClick={() => {
          openModal();
        }}
        w={["100%", "100%", "fit-content"]}
        size="md"
        leftIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0002 20.2495C11.8781 20.2495 11.7759 20.1618 11.7543 20.0463L11.75 19.9826L11.748 12.7501L11.7479 12.2502H11.248H3.99902C3.86095 12.2502 3.74902 12.1383 3.74902 12.0002C3.74902 11.8784 3.83666 11.7762 3.95221 11.7545L4.01571 11.7502H11.248H11.7481L11.748 11.2502L11.7468 4.00031V4.00027C11.7468 3.86211 11.8588 3.75024 11.9966 3.75024C12.1188 3.75024 12.2209 3.83803 12.2425 3.95348L12.2468 4.01706L12.248 11.2503L12.2481 11.7502H12.748H20.0011C20.1392 11.7502 20.2511 11.8622 20.2511 12.0002C20.2511 12.1221 20.1635 12.2243 20.0479 12.246L19.9844 12.2502H12.748H12.2479L12.248 12.7504L12.25 19.9995C12.2501 20.1377 12.138 20.2495 12.0002 20.2495Z"
              fill="#727CD4"
              stroke="white"
            />
          </svg>
        }
      >
        Create new merch
      </Button>
    </Stack>
  );
};

const MerchStore = () => {
  return (
    <>
      <Stack
        direction={["column", "column", "row"]}
        align="center"
        w="100%"
        justify="space-between"
        mb="2rem"
      >
        <Stack
          direction={["column", "column", "row"]}
          w={["100%", "100%", "fit-content"]}
          spacing="0.5rem"
        >
          <Select
            variant="white"
            placeholder="Filter by"
            w={["100%", "100%", "200px"]}
            size="md"
            borderRadius="12px"
          />
          <InputGroup size="md" bg="white" borderRadius="12px" w={["100%", "100%", "200px"]}>
            <Input placeholder="Search..." border="0" borderRadius="12px" />
            <InputRightElement>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0556 2C14.5046 2 18.1111 5.6066 18.1111 10.0556C18.1111 11.9799 17.4363 13.7466 16.3106 15.132L21.7559 20.5775C22.0813 20.9029 22.0813 21.4305 21.7559 21.7559C21.4601 22.0518 20.9971 22.0787 20.6709 21.8366L20.5775 21.7559L15.132 16.3106C13.7466 17.4363 11.9799 18.1111 10.0556 18.1111C5.6066 18.1111 2 14.5046 2 10.0556C2 5.6066 5.6066 2 10.0556 2ZM10.0556 3.66667C6.52707 3.66667 3.66667 6.52707 3.66667 10.0556C3.66667 13.584 6.52707 16.4445 10.0556 16.4445C13.584 16.4445 16.4445 13.584 16.4445 10.0556C16.4445 6.52707 13.584 3.66667 10.0556 3.66667Z"
                  fill="#727CD4"
                />
              </svg>
            </InputRightElement>
          </InputGroup>
        </Stack>

        <Actions display={["none", "none", "block"]} />
      </Stack>

      <Heading as="h3" fontSize="24px" mb="2rem">
        Total merch items: XX
      </Heading>

      <Actions display={["block", "block", "none"]} />

      <Grid gap="1rem" templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
          <MerchItem key={item} />
        ))}
      </Grid>
    </>
  );
};

export default MerchStore;
