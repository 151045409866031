import {useEventDetailSelectorByQueryEventId} from "apps/ef-org/src/scenes/EventDetail/hooks/useEventDetailSelectorByQueryEventId";

import React from "react";

import {AspectRatio, Box, Grid, Stack, Image} from "@chakra-ui/react";

const Details = () => {
  const {data} = useEventDetailSelectorByQueryEventId();

  return (
    <>
      <Box dangerouslySetInnerHTML={{__html: data?.description_html}} />
      <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} gap="1rem">
        {data?.images?.map((a) => (
          <AspectRatio key={a.id} ratio={1 / 1}>
            <Box w="100%" h="100%">
              <Image
                w="100%"
                h="100%"
                objectFit="cover"
                border="1px"
                borderColor="gray.50"
                fallback={
                  <Stack
                    w="100%"
                    h="100%"
                    bg="gray.50"
                    borderRadius="24px"
                    align="center"
                    justify="center"
                  >
                    <p>Unable to load image</p>
                  </Stack>
                }
                src={a.url}
                borderRadius="24px"
                alt="img-preview"
              />
            </Box>
          </AspectRatio>
        ))}
      </Grid>
    </>
  );
};

export default Details;
