import React from "react";

import {Box, Container, Stack, useMediaQuery} from "@chakra-ui/react";

import EventDetails from "./components/EventDetails/EventDetails";
import NewRightSection from "./components/NewRightSection";

export const EventPreviewLayout = () => {
  const [isMenuVisible] = useMediaQuery("(min-width: 880px)", {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  return (
    <Box pt="4rem" zIndex="100" position="absolute" w="100%" pb="3rem">
      <Container maxW="880px">
        <Stack direction="row" spacing="2rem">
          <Container minH="600px" maxW={isMenuVisible ? "610px" : "100%"} p="0">
            <EventDetails />
          </Container>

          {isMenuVisible && <NewRightSection />}
        </Stack>
      </Container>
    </Box>
  );
};
