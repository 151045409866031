import {useGetUserUsersUserIdGetQuery, useUpdateUserUsersUserIdPatchMutation} from "@ef-org/api";
import {Input} from "@ef-org/components";
import {useForm} from "@ef-org/hooks";
import {getUserId} from "apps/ef-org/src/helpers/aws/auth";
import Joi from "joi";

import React, {useEffect} from "react";

import {Box, Button, Heading, Stack, Text} from "@chakra-ui/react";

export interface UserSettingsData {
  first_name: string;
  last_name: string;
}

const validationSchema = Joi.object({
  first_name: Joi.string().required().label("First name"),
  last_name: Joi.string().required().label("Last name"),
});

const UserDetails = () => {
  const {data} = useGetUserUsersUserIdGetQuery({userId: getUserId()});
  const [updateUser, status] = useUpdateUserUsersUserIdPatchMutation();
  const {handleSubmit, registerWithError, reset} = useForm<UserSettingsData>(validationSchema);

  const onSubmit = async (data: UserSettingsData) => {
    const res = await updateUser({userId: getUserId(), userUpdate: data});
  };

  useEffect(() => {
    reset({first_name: data?.first_name, last_name: data?.last_name});
  }, [data]);

  return (
    <Box>
      {status.data && (
        <Text
          bg="green.200"
          color="black"
          w="100%"
          textAlign="center"
          borderRadius="lg"
          p="10px"
          mb="5vh"
        >
          Saved!
        </Text>
      )}
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        User Details
      </Heading>
      <Stack spacing="1rem" bg="white" borderRadius="22px" p="1rem" w="100%">
        <Input
          maxW="500px"
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7506 14.177C18.9926 14.177 19.9995 15.1838 19.9995 16.4259V17.0013C19.9995 17.8955 19.6799 18.7604 19.0984 19.4398C17.529 21.2733 15.1418 22.178 11.9964 22.178C8.8506 22.178 6.46459 21.2729 4.89828 19.4387C4.31852 18.7598 4 17.8963 4 17.0035V16.4259C4 15.1838 5.00686 14.177 6.24888 14.177H17.7506ZM17.7506 15.677H6.24888C5.83529 15.677 5.5 16.0123 5.5 16.4259V17.0035C5.5 17.5392 5.69111 18.0572 6.03897 18.4646C7.29228 19.9322 9.25816 20.678 11.9964 20.678C14.7347 20.678 16.7023 19.9322 17.9588 18.4643C18.3077 18.0567 18.4995 17.5378 18.4995 17.0013V16.4259C18.4995 16.0123 18.1642 15.677 17.7506 15.677ZM11.9964 2.18164C14.7578 2.18164 16.9964 4.42022 16.9964 7.18164C16.9964 9.94307 14.7578 12.1817 11.9964 12.1817C9.23504 12.1817 6.99646 9.94307 6.99646 7.18164C6.99646 4.42022 9.23504 2.18164 11.9964 2.18164ZM11.9964 3.68164C10.0634 3.68164 8.49646 5.24865 8.49646 7.18164C8.49646 9.11464 10.0634 10.6817 11.9964 10.6817C13.9294 10.6817 15.4964 9.11464 15.4964 7.18164C15.4964 5.24865 13.9294 3.68164 11.9964 3.68164Z"
                fill="#727CD4"
              />
            </svg>
          }
          {...registerWithError("first_name")}
          label={<Text color="#727CD4">Name</Text>}
          placeholder="Enter your name"
          noBorder
        />

        <Input
          leftContent={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7506 14.177C18.9926 14.177 19.9995 15.1838 19.9995 16.4259V17.0013C19.9995 17.8955 19.6799 18.7604 19.0984 19.4398C17.529 21.2733 15.1418 22.178 11.9964 22.178C8.8506 22.178 6.46459 21.2729 4.89828 19.4387C4.31852 18.7598 4 17.8963 4 17.0035V16.4259C4 15.1838 5.00686 14.177 6.24888 14.177H17.7506ZM17.7506 15.677H6.24888C5.83529 15.677 5.5 16.0123 5.5 16.4259V17.0035C5.5 17.5392 5.69111 18.0572 6.03897 18.4646C7.29228 19.9322 9.25816 20.678 11.9964 20.678C14.7347 20.678 16.7023 19.9322 17.9588 18.4643C18.3077 18.0567 18.4995 17.5378 18.4995 17.0013V16.4259C18.4995 16.0123 18.1642 15.677 17.7506 15.677ZM11.9964 2.18164C14.7578 2.18164 16.9964 4.42022 16.9964 7.18164C16.9964 9.94307 14.7578 12.1817 11.9964 12.1817C9.23504 12.1817 6.99646 9.94307 6.99646 7.18164C6.99646 4.42022 9.23504 2.18164 11.9964 2.18164ZM11.9964 3.68164C10.0634 3.68164 8.49646 5.24865 8.49646 7.18164C8.49646 9.11464 10.0634 10.6817 11.9964 10.6817C13.9294 10.6817 15.4964 9.11464 15.4964 7.18164C15.4964 5.24865 13.9294 3.68164 11.9964 3.68164Z"
                fill="#727CD4"
              />
            </svg>
          }
          maxW="500px"
          label={<Text color="#727CD4">Last Name</Text>}
          {...registerWithError("last_name")}
          placeholder="Enter your last name"
          noBorder
        />
        <Button size="md" w={["100%", "100%", "fit-content"]} onClick={handleSubmit(onSubmit)}>
          Save changes
        </Button>
      </Stack>
    </Box>
  );
};

export default UserDetails;
