import React from "react";

import {NFTBuilderPage} from "../scenes/NFTBuilderPage";
import {InitialUseCreateModalType, useCreateModal} from "./ModalsProvider";

const EventCreationDiscountsModal: React.FC = () => {
  return <NFTBuilderPage />;
};

export const useNftBuilderModal: InitialUseCreateModalType = () =>
  useCreateModal("useNftBuilderModal", EventCreationDiscountsModal, {
    size: "5xl",
    borderRadius: "22px",
  });
