import {EfFullLogo, LogoSquare} from "@ef-org/components";

import * as React from "react";

import {useRouter} from "next/router";

import {AspectRatio, Container, Divider, Flex, Grid, Stack, Text, Link} from "@chakra-ui/react";

import ConfirmationPrompt from "./LeftSide/ConfirmationPrompt";
import ConfirmationSuccess from "./LeftSide/ConfirmationSucces";
import MoreInfo from "./LeftSide/MoreInfo";
import SignUp from "./LeftSide/SignUp";
import RightSide from "./RightSide";

export type TStep = "obSignup" | "obConfirmation" | "obPassword" | "obMoreInfo";

const Onboarding: React.FC = ({}) => {
  const {asPath} = useRouter();
  const [leftElement, setLeftElement] = React.useState<React.ReactNode>();
  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [email, setEmail] = React.useState("");
  const [stepName, setStepName] = React.useState<TStep>("obSignup");

  React.useEffect(() => {
    const paths = asPath.split("/");
    const part = paths[paths.length - 2];
    switch (part) {
      case "success":
        if (step === "1") {
          setLeftElement(<ConfirmationSuccess stepHandler={() => setStep("2")} />);
          setStepName("obPassword");
          break;
        }
        setLeftElement(<MoreInfo />);
        setStepName("obMoreInfo");
        break;
      default:
        if (step === "1") {
          setLeftElement(
            <SignUp
              stepHandler={(email) => {
                setStep("2");
                setEmail(email);
              }}
            />
          );
          setStepName("obSignup");
          break;
        }
        setLeftElement(<ConfirmationPrompt email={email} />);
        setStepName("obConfirmation");
        break;
    }
  }, [asPath, step]);

  return (
    <Container maxW="1280px" py="1.5rem" h="100vh">
      <Grid
        templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "50% 50%"]}
        w="100%"
        h="100%"
        gap="2rem"
      >
        <Flex justify="space-between" direction="column">
          <Stack
            direction="row"
            align="center"
            justify="space-between"
            pt="10px"
            display={["none", "flex"]}
          >
            <Link href="/">
              <EfFullLogo w="auto" h="24px" />
            </Link>
          </Stack>
          <Flex align="center" mt={["10px", "60px"]} direction="column">
            <LogoSquare w="auto" h="24px" display={["block", "none"]} mb="20px" />
            {leftElement}
          </Flex>
          <Stack spacing="0.5rem" align="center" justify="space-between" mt="2rem">
            <Stack direction="row" align="center" spacing="1rem">
              <Link
                variant="switchedDecoration"
                textDecoration="none"
                href="https://twitter.com/eventsframe"
              >
                Twitter
              </Link>
              <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
              <Link
                href="https://support.eventsframe.com"
                variant="switchedDecoration"
                textDecoration="none"
              >
                Support
              </Link>
              <Divider orientation="vertical" h="18px" borderColor="ef-gray" />
              <Link
                variant="switchedDecoration"
                textDecoration="none"
                href="https://support.eventsframe.com"
              >
                Contact
              </Link>
            </Stack>
            <Stack align="center" spacing="1rem">
              <Text size="small" color="ef-gray">
                © Copyright 2022 EventsFrame. All rights reserved.
              </Text>
            </Stack>
          </Stack>
        </Flex>

        <AspectRatio display={["none", "block"]} ratio={1 / 1.4} w="100%">
          <Stack
            borderRadius="30px"
            bg="linear-gradient(152.33deg, #1F2FBA 3.47%, #101D8B 95.03%)"
            justify="center"
            align="center"
            spacing="100px"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          >
            <RightSide stepName={stepName} />
          </Stack>
        </AspectRatio>
      </Grid>
    </Container>
  );
};

export default Onboarding;
