import events from "events";
import {create} from "zustand";

type Store = {
  eventFilter: string[];
  toggle: (eventId: string) => void;
  remove: (eventId: string) => void;
};

export const useStore = create<Store>()((set) => ({
  eventFilter: [],
  toggle: (eventId) =>
    set((state) =>
      state.eventFilter.includes(eventId)
        ? {eventFilter: state.eventFilter.filter((id) => id !== eventId)}
        : {eventFilter: [...state.eventFilter, eventId]}
    ),
  remove: (eventId) =>
    set((state) => ({eventFilter: state.eventFilter.filter((id) => id !== eventId)})),
}));
