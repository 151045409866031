import {Stack} from "@chakra-ui/react";

import EmailIntegrations from "./components/integrations/EmailIntegrations";
import TabHandler from "./components/TabHandler";

const SettingsIntegrations = () => {
  return (
    <>
      <TabHandler hideUserSetting />
      <Stack spacing="2rem">
        <EmailIntegrations />
      </Stack>
    </>
  );
};

export default SettingsIntegrations;
