import {DiscountType} from "@ef-org/api";
import {useEventCreationDiscountsModal} from "apps/ef-org/src/modals/useEventCreationDiscountsModal";

import React, {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";

import {TicketsStepDataType, useSubscribeToTicketsStep} from "../newEventStore";

const convertDiscountTypeToText = (type: DiscountType) => {
  switch (type) {
    case "order_discount":
      return "Order";
    case "type_discount":
      return "Type";
    case "volume_discount":
      return "Volume";

    default:
      return "";
  }
};

const YourDiscounts = () => {
  const [discounts, setDiscounts] =
    useSubscribeToTicketsStep<TicketsStepDataType["discounts"]>("discounts");

  const [, setEditingDiscountId] =
    useSubscribeToTicketsStep<TicketsStepDataType["editingDiscountId"]>("editingDiscountId");
  const [, setAddingNewDiscount] =
    useSubscribeToTicketsStep<TicketsStepDataType["addingNewDiscount"]>("addingNewDiscount");

  return (
    <Box>
      <Box bg="#F8F8F8" borderRadius="14px" p="1rem" mb="2rem">
        <Grid templateColumns="repeat(3, 1fr) 50px" opacity="50%">
          <Text textAlign="center">Discount name</Text>
          <Text w="100%" textAlign="center">
            Type
          </Text>
          <Text w="100%" textAlign="center">
            Amount
          </Text>
          <Text textAlign="end" w="100%">
            Action
          </Text>
        </Grid>
        <Divider borderColor="gray.400" my="0.6rem" />

        {discounts.value.length === 0 && (
          <Stack w="100%" align="center" justify="end" py="1rem">
            <Text>No discounts</Text>
          </Stack>
        )}

        <Stack>
          {discounts.value.map((discount) => (
            <Grid key={discount._id} templateColumns="repeat(3, 1fr) 50px">
              <Text textAlign="center">{discount.name}</Text>
              <Text w="100%" textAlign="center">
                {convertDiscountTypeToText(discount.type)}
              </Text>

              <Text w="100%" textAlign="center">
                {discount.amount} %
              </Text>

              <Stack direction="row" align="center" justify="flex-end" spacing="1rem">
                <Text
                  cursor="pointer"
                  onClick={() => {
                    setEditingDiscountId({value: discount._id});
                    setAddingNewDiscount({value: true});
                  }}
                >
                  Edit
                </Text>
                <Popover>
                  {({onClose}) => (
                    <>
                      <PopoverTrigger>
                        <Icon
                          cursor="pointer"
                          width="17px"
                          height="17px"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                            fill="#D85639"
                          />
                          <path
                            d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                            fill="#D85639"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                            fill="#D85639"
                          />
                        </Icon>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody py="1rem">
                          <Text w="100%" textAlign="center" mb="1rem" fontWeight="bold">
                            Do you want to delete this discount ?
                          </Text>
                          <Stack direction="row">
                            <Button onClick={onClose} w="100%" size="md" variant="outline">
                              Cancel
                            </Button>
                            <Button
                              w="100%"
                              onClick={() => {
                                setDiscounts((p) => ({
                                  value: p.value.filter((x) => x._id !== discount._id),
                                }));
                              }}
                              size="md"
                              variant="outline"
                              borderColor="#D85639"
                              color="#D85639"
                              leftIcon={
                                <Icon
                                  width="17px"
                                  height="17px"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                                    fill="#D85639"
                                  />
                                  <path
                                    d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                                    fill="#D85639"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                                    fill="#D85639"
                                  />
                                </Icon>
                              }
                            >
                              Delete
                            </Button>
                          </Stack>
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              </Stack>
            </Grid>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const Discounts = () => {
  const {pathname, query, replace} = useRouter();
  const [tickets] = useSubscribeToTicketsStep<TicketsStepDataType["tickets"]>("tickets");
  const closeClicked = () => {
    setAddingNewDiscount({value: false});
    setEditingId({value: undefined});
    delete query.discountId;
    replace({pathname, query});
  };
  const {openModal, closeAllModals} = useEventCreationDiscountsModal({onClose: closeClicked});
  const [addingNewDiscount, setAddingNewDiscount] =
    useSubscribeToTicketsStep<TicketsStepDataType["addingNewDiscount"]>("addingNewDiscount");
  const [editingId, setEditingId] =
    useSubscribeToTicketsStep<TicketsStepDataType["editingDiscountId"]>("editingDiscountId");

  useEffect(() => {
    if (addingNewDiscount?.value) {
      openModal();
    } else {
      closeAllModals();
    }
  }, [addingNewDiscount]);

  if (!tickets?.value?.length) return null;

  return (
    <Box mt="1rem">
      <Stack
        direction={["column", "row", "row"]}
        align={["flex-start", "center", "center"]}
        justify="space-between"
        mb="1rem"
      >
        <Heading fontWeight="bold" fontSize="18px">
          Discounts
        </Heading>

        <Button
          w={["100%", "fit-content", "fit-content"]}
          size="md"
          variant="outline"
          onClick={() => {
            setEditingId({value: null});
            openModal();
          }}
          p="1rem 2.5rem"
          leftIcon={
            <svg
              width="17px"
              height="16px"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.47222 8.27778H2.02784C2.02782 8.27778 2.0278 8.27778 2.02778 8.27778C1.95976 8.27775 1.89412 8.25278 1.8433 8.20758C1.79514 8.16476 1.76345 8.10661 1.75348 8.04316L1.75023 7.98886C1.75282 7.92485 1.77748 7.86356 1.8202 7.81552C1.86302 7.76736 1.92117 7.73568 1.98461 7.7257L2.04272 7.72222H7.47222H7.97222V7.22222V1.77784C7.97222 1.77782 7.97222 1.7778 7.97222 1.77778C7.97225 1.70976 7.99723 1.64412 8.04242 1.5933C8.08524 1.54514 8.1434 1.51345 8.20684 1.50348L8.26114 1.50023C8.32515 1.50282 8.38644 1.52748 8.43448 1.5702L8.7652 1.19828L8.43448 1.5702C8.48264 1.61302 8.51432 1.67117 8.5243 1.73461L8.52778 1.79272V7.22222V7.72222H9.02778H14.4722C14.4722 7.72222 14.4722 7.72222 14.4722 7.72222C14.5402 7.72225 14.6059 7.74723 14.6567 7.79242C14.7049 7.83524 14.7365 7.8934 14.7465 7.95683L14.7498 8.01114C14.7472 8.07515 14.7225 8.13644 14.6798 8.18448L15.0534 8.51673L14.6798 8.18448C14.637 8.23264 14.5788 8.26432 14.5154 8.2743L14.4573 8.27778H9.02778H8.52778V8.77778V14.2222C8.52778 14.2222 8.52778 14.2222 8.52778 14.2222C8.52775 14.2902 8.50278 14.3559 8.45758 14.4067L8.83122 14.739L8.45758 14.4067C8.41476 14.4549 8.3566 14.4865 8.29317 14.4965L8.23886 14.4998C8.17485 14.4972 8.11356 14.4725 8.06552 14.4298C8.01736 14.387 7.98568 14.3288 7.9757 14.2654L7.97222 14.2073V8.77778V8.27778H7.47222Z"
                fill="white"
                stroke="var(--chakra-colors-btn-neutral-text)"
              />
            </svg>
          }
        >
          Add discount
        </Button>
      </Stack>

      <YourDiscounts />
    </Box>
  );
};

export default Discounts;
