import React from "react";

import {AspectRatio, Box, Center, Grid, Image, Link, Progress, Stack, Text} from "@chakra-ui/react";

const EventDashboardPreview: React.FC<{
  eventName: string;
  startDate: string;
  timeCounter: string;
  featuredImage?: string;
  soldTickets: number;
  totalTickets: number;
  revenue: number;
  eventId: string;
}> = ({
  eventName,
  revenue,
  featuredImage,
  soldTickets,
  startDate,
  timeCounter,
  totalTickets,
  eventId,
}) => {
  return (
    <Link href={`/event/${eventId}/overview`} textDecoration="none">
      <Box>
        <Stack spacing="0" w="350px" align="flex-start" bg="white" borderRadius="16px">
          <AspectRatio ratio={27 / 9} w="100%">
            <Box w="100%" h="100%" borderTopRadius="16px" backgroundColor="lightgray">
              {featuredImage && <Image w="100%" src={featuredImage} />}
            </Box>
          </AspectRatio>
          <Box w="100%" p="1rem">
            <Text as="b">{eventName}</Text>

            <Grid pt="1rem" templateColumns="1fr 1fr" gap="1rem" w="100%">
              <Text
                w="100%"
                textAlign="center"
                borderRadius="12px"
                py="0.7rem"
                as="b"
                background="linear-gradient(104.88deg, #A5FFB9 18.5%, #58F5FF 142.21%)"
              >
                {timeCounter}
              </Text>

              <Stack justify="center" align="center" w="100%" h="100%">
                <Text color="primary-2">{startDate}</Text>
              </Stack>
            </Grid>

            <Grid pt="1rem" templateColumns="1fr 1fr" gap="1rem" w="100%">
              <Stack bg="#F9FAFF" borderRadius="12px" p="1rem">
                <Text w="100%" textAlign="center">
                  Sold Tickets
                </Text>

                <Text as="b" w="100%" textAlign="center">
                  {soldTickets}{" "}
                  <Text as="span" color="primary-2">
                    / {totalTickets}
                  </Text>
                </Text>

                <Box sx={{"[role='progressbar']": {bg: "#1F2FBA"}}}>
                  <Progress
                    value={(soldTickets / totalTickets) * 100}
                    bg="#C9CDEE"
                    borderRadius="20px"
                  />
                </Box>
              </Stack>

              <Stack bg="#F9FAFF" borderRadius="12px" p="1rem">
                <Text w="100%" textAlign="center">
                  Revenue
                </Text>

                <Center h="100%">
                  <Text as="b" fontSize="26px" w="100%" textAlign="center">
                    $ {revenue}
                  </Text>
                </Center>
              </Stack>
            </Grid>
          </Box>
        </Stack>
      </Box>
    </Link>
  );
};

export default EventDashboardPreview;
