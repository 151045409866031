import {OrderRead, PaymentState} from "@ef-org/api";
import {IconXMark} from "@ef-org/icons";
import {capitalizeFirstLetter, formatCurrency} from "@ef-org/utils";
import {useUpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchMutation} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import IconClock from "apps/ef-org/src/components/Icons/Clock";
import IconTicket from "apps/ef-org/src/components/Icons/Ticket";
import {isUserAuthorized} from "apps/ef-org/src/helpers/aws/auth";
import useCookie from "apps/ef-org/src/hooks/useCookie";
import {format, parseISO} from "date-fns";

import {useMemo, useState} from "react";
import React from "react";

import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";

type EmptyRow = {};
interface DataRow {
  title: string;
  subtitle?: string;
  values: string[];
  valueNode?: React.ReactNode;
}
type RowItem = EmptyRow | DataRow;

const OrderDetailRow: React.FC<{row: RowItem}> = ({row}) => {
  if (!("title" in row)) {
    return <Box h="32px" />;
  }
  const item = row as DataRow;
  return (
    <>
      <Flex py={item.subtitle ? "12px" : "5px"} justifyContent="space-between">
        <Box>
          <Text
            fontWeight={item.subtitle !== null ? "medium" : "normal"}
            fontSize={item.subtitle !== null ? "16px" : "12px"}
            color={item.subtitle !== null ? "primary-4" : "#162184"}
          >
            {item.title}
          </Text>
          {item.subtitle && <Text color="primary-4">{item.subtitle}</Text>}
        </Box>
        <Flex direction="column" justifyContent="flex-end" alignItems="flex-end">
          {item.values.map((val) => (
            <Text textAlign="right" fontWeight="medium" color="#162184">
              {val ?? "None"}
            </Text>
          ))}
          {item.valueNode && React.isValidElement(item.valueNode) && item.valueNode}
        </Flex>
      </Flex>
      {item.subtitle && <Divider />}
    </>
  );
};

export const OrderDetailActions: React.FC<{order: OrderRead}> = ({order}) => {
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const [updateOrder, status] =
    useUpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchMutation();
  if (!order) {
    return null;
  }
  const commonParams = {eventId: order.event_id, orderId: order.id, organizerId};

  if (order.state === "cancelled") {
    return null;
  }

  return (
    <Box w="100%">
      <Divider mb="1rem" />
      <Flex justifyContent="space-around" w="100%">
        {order.payment.state !== "cancelled" && (
          <>
            {order.payment.state !== "paid" && (
              <Button
                variant="neutral"
                fontWeight="medium"
                color="#4754C6"
                w="100%"
                mr="1rem"
                cursor={order.state === "paid" ? "not-allowed" : "pointer"}
                isLoading={status.isLoading}
                onClick={() => {
                  if (order.state !== "paid") {
                    updateOrder({...commonParams, orderUpdate: {state: "paid"}});
                  }
                }}
              >
                Mark as paid
              </Button>
            )}

            <Popover>
              {({onClose}) => (
                <>
                  <PopoverTrigger>
                    <Button
                      isDisabled={!isUserAuthorized("admin")}
                      bg="#D85639"
                      w="100%"
                      _hover={{bg: "#D85639"}}
                    >
                      Cancel order
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody py="1rem">
                      <Text w="100%" textAlign="center" mb="1rem" fontWeight="bold">
                        Do you want to delete this order ?
                      </Text>
                      <Stack direction="row">
                        <Button onClick={onClose} w="100%" size="md" variant="outline">
                          Cancel
                        </Button>
                        <Button
                          w="100%"
                          onClick={() =>
                            updateOrder({...commonParams, orderUpdate: {state: "cancelled"}})
                          }
                          size="md"
                          variant="outline"
                          borderColor="#D85639"
                          color="#D85639"
                          leftIcon={
                            <Icon
                              width="17px"
                              height="17px"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                                fill="#D85639"
                              />
                              <path
                                d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                                fill="#D85639"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                                fill="#D85639"
                              />
                            </Icon>
                          }
                        >
                          Delete
                        </Button>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </>
        )}
      </Flex>
    </Box>
  );
};

const OrderStatus: React.FC<{status: PaymentState}> = ({status}) => {
  const colorForStatus = (s: PaymentState) => {
    if (s === "paid") {
      return "#43BF40";
    }
    if (["waiting_for_payment", "requested"].includes(s)) {
      return "#C29010";
    }
    return "ef-red";
  };

  const icon = (s: PaymentState) => {
    if (s === "paid") {
      return <CheckIcon color="#43BF40" />;
    }
    if (["waiting_for_payment", "requested"].includes(s)) {
      return <IconClock fill="#C29010" />;
    }
    return <CloseIcon color="ef-red" boxSize="12px" />;
  };

  return (
    <Stack direction="row" align="center">
      {icon(status)}
      <Text color={colorForStatus(status)}>
        {capitalizeFirstLetter(status.replaceAll("_", " "))}
      </Text>
    </Stack>
  );
};

const OrderDetail: React.FC<{order: OrderRead}> = ({order}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const rowData = useMemo(() => {
    if (!order) {
      return null;
    }
    const orderDetails: RowItem[] = [
      {title: "Order ID", values: [order.readable_id]},
      {title: "Date created", values: [format(parseISO(order.created_at), "MMM dd, yyyy")]},
      {title: "Date Completed", values: [format(parseISO(order.updated_at), "MMM dd, yyyy")]},
      {},
      {title: "Status", values: [], valueNode: <OrderStatus status={order.state} />},
      {title: "Email", values: [order.billing_details.email]},
      {
        title: "Name",
        values: [`${order.billing_details.first_name} ${order.billing_details.last_name}`],
      },
      {},
      {
        title: "Total Tickets",
        values: [],
        valueNode: (
          <Stack direction="row" align="center">
            <Text>{order.items.filter((i) => i.type === "ticket").length.toString()} x </Text>
            <IconTicket />
          </Stack>
        ),
      },
      {title: "Price", values: [formatCurrency(order.currency, order.price)]},
      {
        title: "Discounts",
        values: [
          formatCurrency(order.currency, order.full_price - order.price, {displayFree: false}),
        ],
      },
      {title: "Payment Method", values: [order.payment_method]},
      {title: "Total Price", values: [formatCurrency(order.currency, order.full_price)]},
    ];

    const tickets: RowItem[] = order.items
      .flatMap((item) => ({...item.details.attendee, ticket: item}))
      .filter(Boolean)
      .map((att) => ({
        title: att.first_name && att.last_name ? `${att.first_name} ${att.last_name}` : null,
        subtitle: att.ticket.details.name,
        values: [
          att.email ?? "Anonymous attendee",
          ...(att.attendee_fields ?? []).map((af) => af.value),
        ],
      }));

    const billing: RowItem[] = [
      {title: "Status", values: [], valueNode: <OrderStatus status={order.state} />},
      {
        title: "Total Tickets",
        values: [order.items.filter((i) => i.type === "ticket").length.toString()],
      },

      {title: "Currency", values: [order.currency]},
      {title: "VAT ID", values: [order.billing_details.vat_number]},
      {title: "Total Price", values: [formatCurrency(order.currency, order.full_price)]},
      {title: "Payment Method", values: [order.payment_method]},
      {},
      {title: "Email", values: [order.billing_details.email]},
      {
        title: "Name",
        values: [`${order.billing_details.first_name} ${order.billing_details.last_name}`],
      },
      {title: "Address 1", values: [order.billing_details.street]},
      {title: "City", values: [order.billing_details.city]},
      {title: "Region", values: [order.billing_details.billing_state]},
      {title: "Country", values: [order.billing_details.country]},
      {title: "Postal Code", values: [order.billing_details.zip]},
    ];

    return [orderDetails, tickets, billing];
  }, [order]);

  const tabs = ["Order info", "Tickets", "Billing"];

  return (
    <>
      <Stack
        p="0.5rem"
        direction="row"
        align="center"
        fontSize="15px"
        fontWeight="normal"
        overflowX="auto"
      >
        {tabs.map((tab, i) => (
          <Button
            onClick={() => setSelectedTab(i)}
            variant="neutral"
            fontWeight="medium"
            color="primary-5"
            cursor="pointer"
            bg={selectedTab === i ? undefined : "transparent"}
          >
            {tab}
          </Button>
        ))}
      </Stack>
      <Divider my="1rem" />
      {rowData && rowData[selectedTab].map((row) => <OrderDetailRow row={row} />)}
    </>
  );
};

export default OrderDetail;
