import {
  DigitalMerchandiseUpdate,
  PaymentMethod,
  TicketTypeRead,
  TicketTypeUpdate,
  useCreateEventOrganizersOrganizerIdEventsPostMutation,
} from "@ef-org/api";
import {format, formatISO, parse, parseISO} from "date-fns";
import Cookies from "js-cookie";
import {isEmpty, mergeDeepRight, pathOr} from "ramda";

import {useEffect} from "react";

import {useRouter} from "next/router";

import {
  useGetEventImagesEventsEventIdImagesGetQuery,
  useGetEventOrganizersOrganizerIdEventsEventIdGetQuery,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery,
  useUpdateEventOrganizersOrganizerIdEventsEventIdPatchMutation,
  useUploadEventImageEventsEventIdImagesPostMutation,
} from "../../api/__generated__/beOrgApi.extended";
import {useUpdateEvent} from "../../hooks/useSelectors";
import {useErrorModal} from "../../modals/useErrorModal";
import {
  BasicStepDataType,
  DescriptionStepDataType,
  MerchStepDataType,
  SettingsStepDataType,
  StepVariant,
  StoreType,
  TicketsStepDataType,
  useNewEventStore,
} from "./newEventStore";

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const useEventDataHandlers = () => {
  const {replace, query} = useRouter();
  const [createNewEvent] = useCreateEventOrganizersOrganizerIdEventsPostMutation();
  const updateEvent = useUpdateEvent();
  const [uploadPhoto] = useUploadEventImageEventsEventIdImagesPostMutation();
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId");
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery({organizerId, userId});

  const {data: eventAssets} = useGetEventImagesEventsEventIdImagesGetQuery(
    {eventId: query.eventId as string},
    {skip: !query.eventId}
  );

  useEffect(() => {
    //@ts-ignore
    useNewEventStore.setState((prev) =>
      mergeDeepRight<StoreType, DeepPartial<StoreType>>(prev, {
        data: {
          DESCRIPTION: {
            data: {assets: {value: []}},
          },
        },
      })
    );
  }, [eventAssets]);

  const {data} = useGetEventOrganizersOrganizerIdEventsEventIdGetQuery(
    {organizerId, eventId: query?.eventId?.toString()},
    {skip: !query?.eventId}
  );

  const errorMessage = useNewEventStore((p) => p.errorMessage);
  const {goToStep, goBack, currentStep, payments} = useNewEventStore((p) => ({
    goBack: p.actions.goBack,
    goToStep: p.actions.goToStep,
    currentStep: p.currentStep,
    payments: p.data.TICKETS.data.payment_options,
  }));
  const {openModal} = useErrorModal();

  useEffect(() => {
    if (errorMessage) {
      openModal({
        message:
          "We are unable to create your event at this time. Please try again later. " +
          errorMessage,
        onClose: () => {
          useNewEventStore.setState({errorMessage: ""});
          if (currentStep !== StepVariant.TICKETS) {
            goBack();
          }
        },
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    const currentStep = decodeURIComponent(query?.step?.toString()) as StepVariant | null;
    if (query?.step && currentStep) {
      goToStep(currentStep);
    }
    //@ts-ignore
    useNewEventStore.setState((prev) =>
      mergeDeepRight<StoreType, DeepPartial<StoreType>>(prev, {
        data: {
          BASIC: {
            data: {
              eventName: {value: data?.name || ""},
              timeZone: {value: data?.timezone_info || ""},
              eventType: {
                value:
                  data?.type === "hybrid"
                    ? "Hybrid"
                    : data?.type === "virtual"
                    ? "Online event"
                    : "In-person",
              },
              eventLocation: {
                value: data?.location,
              },
              eventLink: {value: data?.virtual_event_url || ""},
              eventCurrency: {value: data?.currency},
              eventDurationStart: {
                value: data?.start_datetime
                  ? format(parseISO(data?.start_datetime || ""), "yyyy-MM-dd")
                  : format(new Date(), "yyyy-MM-dd"),
              },
              use_24_hour_time_format: {value: data?.use_24_hour_time_format ?? true},
              eventDurationStartTime: {
                value: data?.start_datetime
                  ? format(parseISO(data?.start_datetime || ""), "HH:mm")
                  : format(new Date(), "HH:mm"),
              },
              eventDurationEnd: {
                value: data?.end_datetime
                  ? format(parseISO(data?.end_datetime || ""), "yyyy-MM-dd")
                  : format(new Date(), "yyyy-MM-dd"),
              },
              eventDurationEndTime: {
                value: data?.end_datetime
                  ? format(parseISO(data?.end_datetime || ""), "HH:mm")
                  : format(new Date(), "HH:mm"),
              },
              eventRegistrationStart: {
                value: data?.registration_start_datetime
                  ? format(parseISO(data?.registration_start_datetime || ""), "yyyy-MM-dd")
                  : format(new Date(), "yyyy-MM-dd"),
              },
              eventRegistrationStartTime: {
                value: data?.registration_start_datetime
                  ? format(parseISO(data?.registration_start_datetime || ""), "HH:mm")
                  : format(new Date(), "HH:mm"),
              },
              eventRegistrationEnd: {
                value: data?.registration_end_datetime
                  ? format(parseISO(data?.registration_end_datetime || ""), "yyyy-MM-dd")
                  : "",
              },
              eventRegistrationEndTime: {
                value: data?.registration_end_datetime
                  ? format(parseISO(data?.registration_end_datetime || ""), "HH:mm")
                  : "",
              },
            },
          },
          DESCRIPTION: {
            data: {descriptionHTML: {value: data?.description_html || ""}, assets: {value: []}},
          },
          TICKETS: {
            data: {
              addingNewTicket: {value: isEmpty(data?.ticket_types)},
              // editingDiscountId: {value: discountId},
              editingTicket: {value: undefined},
              tickets: {
                value: data?.ticket_types.map((ticketType: TicketTypeRead) => ({
                  _id: ticketType.id,
                  ticketName: ticketType.name,
                  description: ticketType.description,
                  price: ticketType.price,
                  isDescriptionVisible: Boolean(ticketType.description),
                  isPaidTicket: Boolean(ticketType.price),
                  minimumPerOrder: ticketType.minimum_order_quantity,
                  maximumPerOrder: ticketType.maximum_order_quantity,
                  ticketAmount: ticketType.statistics.tickets_total_count ?? null,
                  smartTicket: false,
                  state: ticketType.state,
                  ticketAmountUnlimited: !Boolean(ticketType.maximum_order_quantity),
                })),
              },
              payment_options: {
                ...payments,
                methods: {
                  offlinePayment: {
                    isActive: data?.payment_options.methods.includes("wire_transfer"),
                  },
                  stripe: {isActive: data?.payment_options.methods.includes("stripe")},
                  ryft: {isActive: data?.payment_options.methods.includes("ryft")},
                },
                vat: {
                  isEnabled: Boolean(data?.payment_options?.vat_rate),
                  value: data?.payment_options?.vat_rate,
                },
              },
              discounts: {
                value: data?.discounts.map((dis) => ({
                  _id: dis.id,
                  name: dis.name,
                  amount: dis.percentage,
                  volume: dis.minimum_order_quantity,
                  ticketTypeId: dis.ticket_type_id,
                  isExclusive: dis.is_exclusive_discount,
                  startDate: dis?.start_datetime
                    ? format(parseISO(dis?.start_datetime || ""), "yyyy-MM-dd")
                    : format(new Date(), "yyyy-MM-dd"),
                  startTime: dis?.start_datetime
                    ? format(parseISO(dis?.start_datetime || ""), "HH:mm")
                    : "",
                  endDate: dis?.end_datetime
                    ? format(parseISO(dis?.end_datetime || ""), "yyyy-MM-dd")
                    : "",
                  endTime: dis?.end_datetime
                    ? format(parseISO(dis?.end_datetime || ""), "HH:mm")
                    : "",
                  type: dis.type,
                  required: false,
                  couponCode: dis.coupon_code ?? "",
                })),
              },
            },
          },
          // MERCH: {
          //   data: {
          //     addingNewMerch: {value: isEmpty(data?.digital_merchandises)},
          //     merch: {
          //       value: data?.digital_merchandises.map((merch) => ({
          //         _id: merch.id,
          //         amount: merch.quantity_available,
          //         name: merch.name,
          //         price: merch.price,
          //         description: merch.description,
          //         isPaid: Boolean(merch.price),
          //         isDescriptionVisible: Boolean(merch.description),
          //         unlimitedAmount: false,
          //         minimumPerOrder: merch.maximum_order_quantity,
          //         maximumPerOrder: merch.maximum_order_quantity,
          //       })),
          //     },
          //   },
          // },
          SETTINGS: {
            data: {
              customAttendeeFields: {value: data?.custom_attendee_fields},
              emailNote: {value: data?.email_note},
              termsLink: {value: data?.terms_and_conditions_url},
            },
          },
        },
      })
    );
  }, [data]);

  useEffect(() => {
    const onBasicInfoSuccess = async (data: BasicStepDataType) => {
      const start_datetime = !data.eventDurationStart.value
        ? formatISO(new Date())
        : formatISO(
            parse(
              `${data.eventDurationStart.value} ${data.eventDurationStartTime.value || "00:00"}`,
              "yyyy-MM-dd HH:mm",
              new Date()
            )
          );

      const end_datetime = !data.eventDurationEnd.value
        ? undefined
        : formatISO(
            parse(
              `${data.eventDurationEnd.value} ${data.eventDurationEndTime.value || "00:00"}`,
              "yyyy-MM-dd HH:mm",
              new Date()
            )
          );

      const registration_end_datetime = !data.eventRegistrationEnd.value
        ? undefined
        : formatISO(
            parse(
              `${data.eventRegistrationEnd.value} ${
                data.eventRegistrationEndTime.value || "00:00"
              }`,
              "yyyy-MM-dd HH:mm",
              new Date()
            )
          );

      const registration_start_datetime = !data.eventRegistrationStart.value
        ? formatISO(new Date())
        : formatISO(
            parse(
              `${data.eventRegistrationStart.value} ${
                data.eventRegistrationStartTime.value || "00:00"
              }`,
              "yyyy-MM-dd HH:mm",
              new Date()
            )
          );

      if (query?.eventId) {
        const res = await updateEvent({
          start_datetime,
          end_datetime,
          registration_start_datetime,
          registration_end_datetime,
          currency: data.eventCurrency?.value,
          name: data.eventName.value,
          type:
            data.eventType.value === "Hybrid"
              ? "hybrid"
              : data.eventType.value === "Online event"
              ? "virtual"
              : "physical",
          virtual_event_url: data.eventLink.value,
          timezone_info: data.timeZone.value,
          use_24_hour_time_format: data.use_24_hour_time_format.value,
          location: data.eventLocation.value || undefined,
        });
      } else {
        const res = await createNewEvent({
          organizerId,
          eventCreate: {
            currency: data.eventCurrency?.value,
            start_datetime,
            end_datetime,
            registration_start_datetime,
            registration_end_datetime,
            require_email_before_checkout: false,
            require_attendee_name_before_checkout: false,
            collect_attendee_name: true,
            use_24_hour_time_format: data.use_24_hour_time_format.value ?? true,
            allow_attendee_name_update: true,
            timezone_info: data.timeZone.value,
            digital_merchandises: [],
            discounts: [],
            name: data.eventName.value,
            type:
              data.eventType.value === "Hybrid"
                ? "hybrid"
                : data.eventType.value === "Online event"
                ? "virtual"
                : "physical",
            virtual_event_url: data.eventLink.value,
            location: data.eventLocation.value,
          },
        });
        if ("data" in res && res.data.id) {
          if (data.eventImage.value) {
            const form = new FormData();

            form.append("file", data.eventImage.value);
            //@ts-ignore
            form.append("is_featured", true);

            await uploadPhoto({
              eventId: res?.data?.id,
              //@ts-ignore
              bodyUploadEventImageEventsEventIdImagesPost: form,
            });
          }
          replace(`/event/${res.data.id}/edit?step=DESCRIPTION`);
        } else {
          useNewEventStore.setState({errorMessage: "More"});
        }
      }
    };
    const onDescriptionSuccess = async (data: DescriptionStepDataType) => {
      await updateEvent({
        description_html: data.descriptionHTML.value,
      });

      // if (data.assets.value.length > 0) {
      //   for (let i = 0; i < data.assets.value.length; i++) {
      //     const file = data.assets.value[i];

      //     const form = new FormData();

      //     form.append("file", file);
      //     //@ts-ignore
      //     form.append("is_featured", false);

      //     await uploadPhoto({
      //       eventId: query?.eventId as string,
      //       //@ts-ignore
      //       bodyUploadEventImageEventsEventIdImagesPost: form,
      //     });
      //   }
      // }
    };
    const methodMap = {offlinePayment: "wire_transfer", stripe: "stripe", ryft: "ryft"};
    const onTicketsSuccess = async (data: TicketsStepDataType) => {
      const methods = Object.keys(data.payment_options.methods)
        .filter((key) => data.payment_options.methods[key].isActive)
        .map((key) => methodMap[key]) as PaymentMethod[];

      await updateEvent({
        ticket_types: data.tickets?.value?.map<TicketTypeUpdate>((x) => ({
          id: x._id,
          name: x.ticketName,
          description: x.description,
          price: x.price || 0,
          maximum_order_quantity: x.maximumPerOrder || undefined,
          minimum_order_quantity: x.minimumPerOrder,
          maximum_quantity: x.ticketAmount,
        })),
        payment_options: {
          collect_billing_details: data.payment_options.collectBillingDetails,
          methods,
          vat_rate: data.payment_options.vat.isEnabled ? data.payment_options.vat.value : null,
        },
      });
    };
    const onMerchSuccess = async (data: MerchStepDataType) => {
      await updateEvent({
        digital_merchandises: data.merch.value.map<DigitalMerchandiseUpdate>((x) => ({
          id: x._id,
          currency: "USD",
          name: x.name,
          description: x.description,
          price: x.price || 0,
          maximum_order_quantity: x.maximumPerOrder || undefined,
          minimum_order_quantity: x.minimumPerOrder,
          maximum_quantity: x.amount,
        })),
      });
    };
    const onSettingsSuccess = async (data: SettingsStepDataType) => {
      await updateEvent({
        custom_attendee_fields: data.customAttendeeFields.value ?? [],
        email_note: data.emailNote.value ?? "",
        terms_and_conditions_url: data.termsLink.value ?? "",
      });
    };

    const handleStepChange = (newStep: StepVariant) => {
      const allowedStepsBack = useNewEventStore.getState().allowedStepsBack;
      const index = allowedStepsBack.findIndex((step) => step === newStep);
      const prevStep = allowedStepsBack[index - 1];
      if (!prevStep) {
        return;
      }

      const prevStepData = pathOr(null, ["data", prevStep, "data"], useNewEventStore.getState());
      if (!prevStepData) {
        return;
      }

      if (prevStep === StepVariant.BASIC) {
        onBasicInfoSuccess(prevStepData);
      } else if (prevStep === StepVariant.DESCRIPTION) {
        onDescriptionSuccess(prevStepData);
      } else if (prevStep === StepVariant.TICKETS) {
        onTicketsSuccess(prevStepData);
      } else if (prevStep === StepVariant.SETTINGS) {
        onSettingsSuccess(prevStepData);
      }
      replace({query: {...query, step: newStep}});
    };

    const unsubscribe = useNewEventStore.subscribe((state) => state.currentStep, handleStepChange);

    return () => {
      unsubscribe();
    };
  }, [query?.eventId]);
};

export default useEventDataHandlers;
