import {AspectRatio, Box, Container, Image} from "@chakra-ui/react";

const EventLandingPageImage = (props: {src: string}) => {
  return (
    <Box position="absolute" w="100%">
      <Container maxW="2500px" p="0">
        <AspectRatio w="100%" ratio={21 / 2}>
          <Image w="100%" h="100%" src={props.src} alt="featured image" />
        </AspectRatio>
      </Container>
    </Box>
  );
};

export default EventLandingPageImage;
