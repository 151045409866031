import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";
import Joi from "joi";
import Cookies from "js-cookie";

import React, {useState} from "react";

import {Box, Button, FormLabel, Heading, Select, Stack, useToast} from "@chakra-ui/react";

import {UserRole} from "../api/__generated__/beOrgApi.generated";
import Input from "../components/Input";
import useForm, {OnFormSubmitType} from "../hooks/useForm";
import {useInviteTeamMember} from "../hooks/useSelectors";

const FormSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  role: Joi.string().required(),
});

type FormType = {
  email: string;
  role: UserRole;
};

const InviteTeamMember: React.FC = () => {
  const {closeModal} = useModal("CreateEmailIntegrationModal");
  const toast = useToast();
  const inviteTeamMember = useInviteTeamMember();
  const [isLoading, setLoading] = useState(false);

  const {registerWithError, handleSubmit, setValue} = useForm<FormType>(FormSchema, {
    keepDataOnSuccess: true,
    defaultValues: {email: "", role: "member"},
  });

  const handleFormSubmit: OnFormSubmitType<FormType> = async (data) => {
    setLoading(true);

    const res = await inviteTeamMember({...data});

    if (!("data" in res)) {
      toast({
        title: "Ohh no!",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    toast({
      title: "Success",
      description: "Invitation sent successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
    setLoading(false);
    closeModal();
  };

  return (
    <Box p="1.5rem" w="100%">
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="2rem">
        Add a new team member
      </Heading>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            {...registerWithError("email")}
            name="email"
            w="100%"
            label="Email*"
            backgroundColor="#F8F8F8"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                  fill="#727CD4"
                />
              </svg>
            }
            placeholder="Enter an email of team member"
            noBorder
          />
          <FormLabel m="0" htmlFor="role">
            Role*
          </FormLabel>
          <Select
            onChange={(e) => setValue("role", e.target.value as UserRole)}
            {...registerWithError("role")}
            background="#F8F8F8"
            borderRadius="15px"
            border="none"
            h="48px"
          >
            <option value="member">Member</option>
            <option value="admin">Admin</option>
          </Select>

          <Box py="1rem">
            <Button type="submit" w="100%" isLoading={isLoading}>
              Invite
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export const useInviteTeamMemberModal: InitialUseCreateModalType = () =>
  useCreateModal("InviteTeamMemberModal", InviteTeamMember, {
    closeOnEsc: true,
    closeOnOverlayClick: true,
    hideCloseButton: false,
    size: "lg",
  });
