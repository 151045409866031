import {useGetEventOrganizersOrganizerIdEventsEventIdGetQuery} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import useCookie from "apps/ef-org/src/hooks/useCookie";

import React, {useEffect} from "react";

import {useRouter} from "next/router";

const EventDetailLoader: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const {query, push} = useRouter();
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const {data, isLoading, isUninitialized} = useGetEventOrganizersOrganizerIdEventsEventIdGetQuery(
    {organizerId, eventId: query?.eventId?.toString()},
    {refetchOnMountOrArgChange: true, skip: !query?.eventId || !organizerId}
  );

  useEffect(() => {
    if (!data?.id && !isLoading && !isUninitialized) {
      push(`/events`);
    }
  }, [data, isLoading, isUninitialized]);

  if (!data?.id) return null;

  return <>{children}</>;
};

export default EventDetailLoader;
