import {useCreateUserUsersUserIdPutMutation} from "@ef-org/api";
import {Checkbox, Link, Input} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import {authenticateUser, getCurrentUser} from "apps/ef-org/src/helpers/aws/auth";
// import {usePostPy3ApiUserRegisterMutation} from "@platform/api";
import Joi from "joi";

import * as React from "react";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

export type SignupTypes = {
  email: string;
  password: string;
  name: string;
  surname: string;
  terms: boolean;
};

export const SectionLoginSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  name: Joi.string().min(4).required(),
  password: Joi.string().min(6).required(),
  surname: Joi.string().min(4).required(),
  terms: Joi.boolean().valid(true).required(),
});

const SignUp: React.FC<{stepHandler: (email: string) => void}> = ({stepHandler}) => {
  // const [registerApi] = usePostPy3ApiUserRegisterMutation();
  const [emailError, setEmailError] = React.useState<React.ReactNode>();
  const [putUser] = useCreateUserUsersUserIdPutMutation();
  const [focusState, setFocusState] = React.useState<Record<keyof SignupTypes, boolean>>({
    email: false,
    name: false,
    surname: false,
    terms: false,
    password: false,
  });

  const {registerWithError, handleSubmit} = useForm<SignupTypes>(SectionLoginSchema, {
    keepDataOnSuccess: true,
    defaultValues: {email: "", name: "", surname: "", terms: false},
  });
  const handleOnSubmit: OnFormSubmitType<SignupTypes> = async ({email, password}) => {
    // await registerUser(email, password);
    await authenticateUser(email, password);
    // eslint-disable-next-line no-console
    console.log(getCurrentUser());
    // putUser({
    //   userId: "f424ac4a-02e0-47d7-b798-b4e5b7cc5c7c",
    //   userCreate: {email: "rado@eventsframe.com", first_name: "Rado", last_name: "G"},
    // });
    // stepHandler(email);
  };

  return (
    <Flex direction="column" align="center" w={["100%", "70%"]}>
      <Heading variant="h2" mb="20px" textAlign="center">
        Sign up for Eventsframe
      </Heading>
      <Box as="form" w="100%" onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack pb="0.8rem">
          <Input
            leftContent={
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                    fill={focusState.email ? "#1F2FBA" : "#888888"}
                  />
                </svg>
              </>
            }
            label="Email"
            {...registerWithError("email")}
            placeholder="Enter your email"
            type="email"
            onFocus={() => setFocusState((s) => ({...s, email: true}))}
            onBlurCustom={() => setFocusState((s) => ({...s, email: false}))}
          />
          {emailError ? (
            <Stack direction="row" h="50px" align="center" color="ob-blue">
              <Box h="100%" w="3px" bg="ob-blue" borderRadius="5px" />
              {emailError}
            </Stack>
          ) : null}
          <Input
            leftContent={
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7545 13.9992C18.9965 13.9992 20.0034 15.006 20.0034 16.2481V16.8235C20.0034 17.7178 19.6838 18.5826 19.1023 19.262C17.5329 21.0955 15.1457 22.0003 12.0003 22.0003C8.85451 22.0003 6.4685 21.0952 4.90219 19.2609C4.32243 18.582 4.00391 17.7185 4.00391 16.8257V16.2481C4.00391 15.006 5.01077 13.9992 6.25279 13.9992H17.7545ZM17.7545 15.4992H6.25279C5.8392 15.4992 5.50391 15.8345 5.50391 16.2481V16.8257C5.50391 17.3614 5.69502 17.8795 6.04288 18.2869C7.29619 19.7545 9.26207 20.5003 12.0003 20.5003C14.7386 20.5003 16.7062 19.7545 17.9627 18.2866C18.3116 17.879 18.5034 17.36 18.5034 16.8235V16.2481C18.5034 15.8345 18.1681 15.4992 17.7545 15.4992ZM12.0003 2.00391C14.7617 2.00391 17.0003 4.24249 17.0003 7.00391C17.0003 9.76534 14.7617 12.0039 12.0003 12.0039C9.23895 12.0039 7.00037 9.76534 7.00037 7.00391C7.00037 4.24249 9.23895 2.00391 12.0003 2.00391ZM12.0003 3.50391C10.0673 3.50391 8.50037 5.07092 8.50037 7.00391C8.50037 8.93691 10.0673 10.5039 12.0003 10.5039C13.9333 10.5039 15.5003 8.93691 15.5003 7.00391C15.5003 5.07092 13.9333 3.50391 12.0003 3.50391Z"
                    fill={focusState.name ? "#1F2FBA" : "#888888"}
                  />
                </svg>
              </>
            }
            label="Name"
            {...registerWithError("name")}
            placeholder="Enter your name"
            onFocus={() => setFocusState((s) => ({...s, name: true}))}
            onBlurCustom={() => setFocusState((s) => ({...s, name: false}))}
          />
          <Input
            label="Password"
            {...registerWithError("password")}
            placeholder="Enter your password"
            onFocus={() => setFocusState((s) => ({...s, surname: true}))}
            onBlurCustom={() => setFocusState((s) => ({...s, surname: false}))}
          />
          <Input
            label="Surname"
            {...registerWithError("surname")}
            placeholder="Enter your surname"
            onFocus={() => setFocusState((s) => ({...s, surname: true}))}
            onBlurCustom={() => setFocusState((s) => ({...s, surname: false}))}
          />
        </Stack>
        <Checkbox variant="onboarding" {...registerWithError("terms")} customError="Required field">
          I agree with the{" "}
          <Link
            fontSize="15px"
            variant="black"
            href="/"
            isExternal
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Event organiser’s Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            fontSize="15px"
            variant="black"
            href="https://www.eventsframe.com/privacy/"
            isExternal
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Privacy Policy
          </Link>
        </Checkbox>

        {/* <AlertSubscriber boxId="loginResponse" /> */}

        <Button variant="ob_primary" type="submit" w="100%" mt="32px">
          Sign up
        </Button>
      </Box>

      <Text pt="1rem">
        Already have an account? <Link href="/login">Log in</Link>
      </Text>
    </Flex>
  );
};

export default SignUp;
