import {OrganizerStatsPeriod} from "@ef-org/api";

import {useMemo, useState} from "react";

import {Stack} from "@chakra-ui/react";

import DashboardHeader from "./components/DashboardHeader";
import PublishedEventsSection from "./components/PublishedEventsSection";
import RevenuesSection from "./components/RevenuesSection";

// import UpcomingExperiencesSection from "./components/UpcomingExperiencesSection";

export const statsPeriods: {value: OrganizerStatsPeriod; name: string}[] = [
  {value: "day", name: "24 hours"},
  {value: "week", name: "week"},
  {value: "month", name: "month"},
];

const DashBoard = () => {
  const [period, setPeriod] = useState<OrganizerStatsPeriod>("day");

  const periodName = useMemo(() => {
    return statsPeriods.find((p) => p.value === period)?.name;
  }, [period]);

  return (
    <Stack spacing="2rem">
      <DashboardHeader period={periodName} onPeriodChanged={setPeriod} />

      <RevenuesSection period={periodName} onPeriodChanged={setPeriod} />

      <PublishedEventsSection />

      {/* <UpcomingExperiencesSection /> */}
    </Stack>
  );
};

export default DashBoard;
