import {equals} from "ramda";

import React from "react";

import {useNewNFTBuilder} from "../../hooks/useNFTBuilder";
import NoWorkspaceSelected from "./components/NoWorkspaceSelected";
import WorkspaceSelected from "./components/WorkspaceSelected";

const NftBuilderMainSection: React.FC = () => {
  const selectedWsp = useNewNFTBuilder((store) => store.selectedWorkspace, equals);

  if (selectedWsp) {
    return <WorkspaceSelected />;
  }

  return <NoWorkspaceSelected />;
};

export default NftBuilderMainSection;
