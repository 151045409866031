import dynamic from "next/dynamic";

import {Box} from "@chakra-ui/react";

import EventAssets from "../components/EventAssets";
import Section from "../components/Section";
import {StepVariant, useNewEventStepSubscriber} from "../newEventStore";

const QuillEditor = dynamic(() => import("../components/QuillEditor"), {
  ssr: false,
});

const DescribeEvent = () => {
  const {currentStep} = useNewEventStepSubscriber();

  return (
    <Section
      stepNumber={2}
      stepName="Describe event"
      isExposed={currentStep === StepVariant.DESCRIPTION}
    >
      <Box bg="gray.50" borderRadius="12px" p="1rem" mb="1rem">
        <QuillEditor />
      </Box>

      <EventAssets />
    </Section>
  );
};

export default DescribeEvent;
