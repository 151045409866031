import {InitialUseCreateModalType, useCreateModal} from "@ef-org/modals";

import React from "react";

import SubscriptionOverview from "../scenes/Finance/components/SubscriptionOverview";

const PickSubscriptionModal: React.FC = () => {
  return <SubscriptionOverview fromEventCreation />;
};

export const usePickSubscriptionModal: InitialUseCreateModalType = () =>
  useCreateModal("pickSubscriptionModal", PickSubscriptionModal, {
    size: "5xl",
    borderRadius: "24px",
  });
