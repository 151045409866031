import {Input} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import {useNftBuilderModal} from "apps/ef-org/src/modals/useNftBuilderModal";
import Joi from "joi";
import {nanoid} from "nanoid";
import {isEmpty} from "ramda";

import React, {useEffect} from "react";

import {
  Grid,
  Stack,
  Text,
  Button,
  Switch,
  Heading,
  Collapse,
  Box,
  Divider,
  Popover,
  PopoverTrigger,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";

import Section from "../components/Section";
import {
  MerchStepDataType,
  MerchType,
  StepVariant,
  useNewEventStepSubscriber,
  useSubscribeToMerchStep,
} from "../newEventStore";

const Title: React.FC<{text?: string}> = ({text}) => (
  <Heading fontWeight="bold" fontSize="18px" pt="1.5rem" pb="0.5rem">
    {text}
  </Heading>
);

const formInputsSchema = Joi.object({
  name: Joi.string().min(3).max(200),
  isPaid: Joi.boolean(),
  price: Joi.any().when("isPaid", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  amount: Joi.any().when("unlimitedAmount", {
    is: false,
    then: Joi.number().min(1).required(),
    otherwise: Joi.any(),
  }),
  minimumPerOrder: Joi.number().min(1).required(),
  maximumPerOrder: Joi.number().min(1).required(),
  unlimitedAmount: Joi.boolean().required(),
  isDescriptionVisible: Joi.boolean(),
  description: Joi.string().optional().allow(""),
});

const AddNewMerch: React.FC<{
  successCallback?: () => void;
}> = ({successCallback}) => {
  const {openModal} = useNftBuilderModal();
  const [editingMerchId] =
    useSubscribeToMerchStep<MerchStepDataType["editingMerchId"]>("editingMerchId");
  const [, setAddingNewMerch] =
    useSubscribeToMerchStep<MerchStepDataType["addingNewMerch"]>("addingNewMerch");

  const [merch, setMerch] = useSubscribeToMerchStep<MerchStepDataType["merch"]>("merch");

  const {registerWithError, watch, handleSubmit, setValue, trigger, reset, setError} =
    useForm<MerchType>(formInputsSchema, {
      keepDataOnSuccess: true,
      defaultValues: {unlimitedAmount: false},
    });

  const isFirstTicket = merch.value.length === 0;

  useEffect(() => {
    const found = merch.value.find((x) => x._id === editingMerchId.value);
    if (found) {
      //@ts-ignore
      Object.keys(found).forEach((key) => setValue(key, found[key]));
    }
  }, [editingMerchId, merch.value]);

  // const data: Partial<TicketType> = {...editingTicket, ...newTicketData.value};

  // const setValue = <T extends keyof TicketType>(key: T, value: TicketType[T]) => {
  //   setData((p) => ({...p, [key]: value}));
  // };

  const submitData: OnFormSubmitType<MerchType> = (data) => {
    if (
      data.maximumPerOrder &&
      data.minimumPerOrder &&
      data.maximumPerOrder < data.minimumPerOrder
    ) {
      return setError("maximumPerOrder", {
        message: "Maximum per order must be greater than minimum per order",
      });
    }
    setAddingNewMerch({value: false});
    setMerch((p) => ({
      value: editingMerchId.value
        ? p.value.map((x) => (x._id === editingMerchId.value ? {...x, ...data} : x))
        : [
            ...p.value,
            {
              _id: nanoid(),
              ...data,
            },
          ],
    }));
    reset({});
    successCallback && successCallback();
  };

  const isPaidMerchValue = watch("isPaid");
  const unlimitedAmount = watch("unlimitedAmount");
  const isDescriptionVisible = watch("isDescriptionVisible");

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <Stack
        direction={["column", "row", "row"]}
        align={["start", "center", "center"]}
        justify="space-between"
      >
        <Title text="Merch templates" />

        <Stack direction="row" align="center" w={["100%", "auto", "auto"]}>
          {/* <Button variant="neutral" isDisabled size="md">
            <ArrowBackIcon color="gray.500" />
          </Button>
          <Button variant="neutral" isDisabled size="md">
            <ArrowForwardIcon color="gray.500" />
          </Button> */}
          <Button
            onClick={() => openModal()}
            size="md"
            variant="outline"
            w={["100%", "fit-content", "fit-content"]}
            leftIcon={
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.72222 8.77778H2.27784C2.27782 8.77778 2.2778 8.77778 2.27778 8.77778C2.20976 8.77775 2.14412 8.75278 2.0933 8.70758C2.04514 8.66476 2.01345 8.60661 2.00348 8.54316L2.00023 8.48886C2.00282 8.42485 2.02748 8.36356 2.0702 8.31552C2.11302 8.26736 2.17117 8.23568 2.23461 8.2257L2.29272 8.22222H7.72222H8.22222V7.72222V2.27784C8.22222 2.27782 8.22222 2.2778 8.22222 2.27778C8.22225 2.20976 8.24723 2.14412 8.29242 2.0933C8.33524 2.04514 8.3934 2.01345 8.45684 2.00348L8.51114 2.00023C8.57515 2.00282 8.63644 2.02748 8.68448 2.0702L9.0152 1.69828L8.68448 2.0702C8.73264 2.11302 8.76432 2.17117 8.7743 2.23461L8.77778 2.29272V7.72222V8.22222H9.27778H14.7222C14.7222 8.22222 14.7222 8.22222 14.7222 8.22222C14.7902 8.22225 14.8559 8.24723 14.9067 8.29242C14.9549 8.33524 14.9865 8.3934 14.9965 8.45683L14.9998 8.51114C14.9972 8.57515 14.9725 8.63644 14.9298 8.68448L15.3034 9.01673L14.9298 8.68448C14.887 8.73264 14.8288 8.76432 14.7654 8.7743L14.7073 8.77778H9.27778H8.77778V9.27778V14.7222C8.77778 14.7222 8.77778 14.7222 8.77778 14.7222C8.77775 14.7902 8.75278 14.8559 8.70758 14.9067L9.08122 15.239L8.70758 14.9067C8.66476 14.9549 8.6066 14.9865 8.54317 14.9965L8.48886 14.9998C8.42485 14.9972 8.36356 14.9725 8.31552 14.9298C8.26736 14.887 8.23568 14.8288 8.2257 14.7654L8.22222 14.7073V9.27778V8.77778H7.72222Z"
                  fill="white"
                  stroke="#4754C6"
                />
              </svg>
            }
          >
            Create new merch template
          </Button>
        </Stack>
      </Stack>
      {/* <Text mb="2rem" opacity="50%">
        Select one of available merch templates or create your own
      </Text> */}
      {/* <Grid
        templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"]}
        gap="1rem"
        mb="1rem"
      >
        {["Cap 1", "Cap 2", "Cap 3", "Cap 4", "Cap 5"].map((x, i) => (
          <Stack key={i}>
            <Image src={`https://picsum.photos/150/150?${i}`} w="100%" borderRadius="14px" />
            <Text w="100%" textAlign="center">
              {x}
            </Text>
          </Stack>
        ))}
      </Grid> */}
      <Title text="Merch name*" />
      <Stack direction="row" align="flex-start" spacing="1rem">
        <Input
          bigFontSize
          size="lg"
          placeholder="T-Shirt by Coachella"
          {...registerWithError("name")}
          noBorder
        />
        {!isDescriptionVisible && (
          <Button
            px="3rem"
            size="lg"
            variant="neutral"
            onClick={() => {
              setValue("isDescriptionVisible", true);
            }}
            leftIcon={
              <svg
                width="25px"
                height="24px"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16788 12.3736C4.07934 12.2949 4.02173 12.1874 4.0051 12.0703L4.00016 11.9877C4.0031 11.8696 4.04773 11.7563 4.12636 11.6679C4.20509 11.5793 4.31262 11.5217 4.42968 11.5051L4.51494 11.5H11.5H12V11V4.00007C12 4.00004 12 4.00002 12 4C12 3.87756 12.045 3.75938 12.1264 3.66788C12.2051 3.57934 12.3126 3.52173 12.4297 3.5051L12.5123 3.50016C12.6304 3.5031 12.7437 3.54773 12.8321 3.62636C12.9207 3.70509 12.9783 3.81262 12.9949 3.92968L13 4.01494V11V11.5H13.5H20.4999C20.5 11.5 20.5 11.5 20.5 11.5C20.6224 11.5 20.7406 11.545 20.8321 11.6264C20.9207 11.7051 20.9783 11.8126 20.9949 11.9297L20.9998 12.0123C20.9969 12.1304 20.9523 12.2437 20.8736 12.3321C20.7949 12.4207 20.6874 12.4783 20.5703 12.4949L20.4851 12.5H13.5H13V13V19.9999C13 20 13 20 13 20C13 20.1224 12.955 20.2406 12.8736 20.3321C12.7949 20.4207 12.6874 20.4783 12.5703 20.4949L12.4877 20.4998C12.3696 20.4969 12.2563 20.4523 12.1679 20.3736C12.0793 20.2949 12.0217 20.1874 12.0051 20.0703L12 19.9851V13V12.5H11.5H4.50007C4.50004 12.5 4.50002 12.5 4.5 12.5C4.37756 12.5 4.25938 12.455 4.16788 12.3736Z"
                  fill="white"
                  stroke="#4754C6"
                />
              </svg>
            }
          >
            Add Merch description
          </Button>
        )}
      </Stack>

      <Collapse in={isDescriptionVisible}>
        <Title text="Merch description" />

        <Input size="lg" placeholder="Description" {...registerWithError("description")} noBorder />
      </Collapse>

      <Title text="Merch price" />

      <Stack direction="row" align="center" mb="1rem">
        <Switch
          isChecked={isPaidMerchValue}
          onChange={() => setValue("isPaid", !isPaidMerchValue)}
        />
        <Text>Paid Merch</Text>
      </Stack>

      <Collapse in={isPaidMerchValue}>
        <Input
          maxW="200px"
          leftContent={<Text as="span">$</Text>}
          placeholder="0.00"
          type="number"
          {...registerWithError("price")}
          customError="Required"
          noBorder
          bigFontSize
        />
      </Collapse>

      <Stack direction="row" align="center" justify="space-between">
        <Title text="Merch amount*" />
        <Stack direction="row" align="center">
          <Switch
            isChecked={unlimitedAmount}
            onChange={() => {
              setValue("unlimitedAmount", !unlimitedAmount);
              trigger("amount");
            }}
          />
          <Text variant="gray">Unlimited</Text>
        </Stack>
      </Stack>

      <Stack direction={["column", "row", "row"]} align="flex-start">
        <Stack w="100%">
          <Text>Amount</Text>

          <Input
            bigFontSize
            type="number"
            customError="Required"
            isDisabled={unlimitedAmount}
            placeholder={unlimitedAmount ? "Unlimited" : "Enter Merch amount"}
            leftContent={
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9982 4.19782C14.709 3.90865 14.2401 3.90865 13.951 4.19782L4.19782 13.951C3.90865 14.2402 3.90865 14.709 4.19782 14.9982L5.25247 16.0528C5.64464 15.8335 6.09679 15.7088 6.57605 15.7088C8.07554 15.7088 9.29112 16.9243 9.29112 18.4238C9.29112 18.9032 9.16631 19.3552 8.94705 19.7474L10.0018 20.8022C10.291 21.0914 10.7598 21.0914 11.049 20.8022L20.8022 11.049C21.0914 10.7598 21.0914 10.291 20.8022 10.0018L19.7475 8.94713C19.3552 9.16648 18.903 9.29136 18.4236 9.29136C16.9241 9.29136 15.7086 8.07578 15.7086 6.57629C15.7086 6.09691 15.8334 5.64465 16.0528 5.2524L14.9982 4.19782ZM12.9038 3.15064C13.7714 2.28312 15.1779 2.28312 16.0454 3.15064L17.2019 4.30724C17.7063 4.8115 17.6159 5.51937 17.3616 5.94703C17.2524 6.1307 17.1895 6.34502 17.1895 6.57629C17.1895 7.25788 17.742 7.81041 18.4236 7.81041C18.6548 7.81041 18.8692 7.7475 19.0528 7.63829C19.4805 7.38402 20.1884 7.29365 20.6926 7.79792L21.8493 8.95464C22.7169 9.82217 22.7169 11.2287 21.8493 12.0962L12.0962 21.8493C11.2287 22.7169 9.82215 22.7169 8.95464 21.8493L7.79786 20.6926C7.29366 20.1884 7.38393 19.4806 7.63812 19.053C7.74728 18.8693 7.81017 18.655 7.81017 18.4238C7.81017 17.7423 7.25763 17.1897 6.57605 17.1897C6.34484 17.1897 6.13056 17.2526 5.94693 17.3618C5.51927 17.616 4.81149 17.7063 4.30729 17.202L3.15063 16.0454C2.28312 15.1779 2.28313 13.7714 3.15063 12.9038L12.9038 3.15064Z"
                  fill="#727CD4"
                />
              </svg>
            }
            {...registerWithError("amount")}
            noBorder
          />
        </Stack>

        <Stack direction="row" align="flex-start">
          <Stack w={["100%", "220px", "220px"]}>
            <Text>Minimum per order</Text>

            <Input
              bigFontSize
              type="number"
              size="lg"
              {...registerWithError("minimumPerOrder")}
              customError="Required"
              leftContent={
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9982 4.19782C14.709 3.90865 14.2401 3.90865 13.951 4.19782L4.19782 13.951C3.90865 14.2402 3.90865 14.709 4.19782 14.9982L5.25247 16.0528C5.64464 15.8335 6.09679 15.7088 6.57605 15.7088C8.07554 15.7088 9.29112 16.9243 9.29112 18.4238C9.29112 18.9032 9.16631 19.3552 8.94705 19.7474L10.0018 20.8022C10.291 21.0914 10.7598 21.0914 11.049 20.8022L20.8022 11.049C21.0914 10.7598 21.0914 10.291 20.8022 10.0018L19.7475 8.94713C19.3552 9.16648 18.903 9.29136 18.4236 9.29136C16.9241 9.29136 15.7086 8.07578 15.7086 6.57629C15.7086 6.09691 15.8334 5.64465 16.0528 5.2524L14.9982 4.19782ZM12.9038 3.15064C13.7714 2.28312 15.1779 2.28312 16.0454 3.15064L17.2019 4.30724C17.7063 4.8115 17.6159 5.51937 17.3616 5.94703C17.2524 6.1307 17.1895 6.34502 17.1895 6.57629C17.1895 7.25788 17.742 7.81041 18.4236 7.81041C18.6548 7.81041 18.8692 7.7475 19.0528 7.63829C19.4805 7.38402 20.1884 7.29365 20.6926 7.79792L21.8493 8.95464C22.7169 9.82217 22.7169 11.2287 21.8493 12.0962L12.0962 21.8493C11.2287 22.7169 9.82215 22.7169 8.95464 21.8493L7.79786 20.6926C7.29366 20.1884 7.38393 19.4806 7.63812 19.053C7.74728 18.8693 7.81017 18.655 7.81017 18.4238C7.81017 17.7423 7.25763 17.1897 6.57605 17.1897C6.34484 17.1897 6.13056 17.2526 5.94693 17.3618C5.51927 17.616 4.81149 17.7063 4.30729 17.202L3.15063 16.0454C2.28312 15.1779 2.28313 13.7714 3.15063 12.9038L12.9038 3.15064Z"
                    fill="#727CD4"
                  />
                </svg>
              }
              placeholder="1"
              noBorder
            />
          </Stack>

          <Stack w={["100%", "220px", "220px"]}>
            <Text>Maximum per order</Text>
            <Input
              bigFontSize
              type="number"
              {...registerWithError("maximumPerOrder")}
              leftContent={
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9982 4.19782C14.709 3.90865 14.2401 3.90865 13.951 4.19782L4.19782 13.951C3.90865 14.2402 3.90865 14.709 4.19782 14.9982L5.25247 16.0528C5.64464 15.8335 6.09679 15.7088 6.57605 15.7088C8.07554 15.7088 9.29112 16.9243 9.29112 18.4238C9.29112 18.9032 9.16631 19.3552 8.94705 19.7474L10.0018 20.8022C10.291 21.0914 10.7598 21.0914 11.049 20.8022L20.8022 11.049C21.0914 10.7598 21.0914 10.291 20.8022 10.0018L19.7475 8.94713C19.3552 9.16648 18.903 9.29136 18.4236 9.29136C16.9241 9.29136 15.7086 8.07578 15.7086 6.57629C15.7086 6.09691 15.8334 5.64465 16.0528 5.2524L14.9982 4.19782ZM12.9038 3.15064C13.7714 2.28312 15.1779 2.28312 16.0454 3.15064L17.2019 4.30724C17.7063 4.8115 17.6159 5.51937 17.3616 5.94703C17.2524 6.1307 17.1895 6.34502 17.1895 6.57629C17.1895 7.25788 17.742 7.81041 18.4236 7.81041C18.6548 7.81041 18.8692 7.7475 19.0528 7.63829C19.4805 7.38402 20.1884 7.29365 20.6926 7.79792L21.8493 8.95464C22.7169 9.82217 22.7169 11.2287 21.8493 12.0962L12.0962 21.8493C11.2287 22.7169 9.82215 22.7169 8.95464 21.8493L7.79786 20.6926C7.29366 20.1884 7.38393 19.4806 7.63812 19.053C7.74728 18.8693 7.81017 18.655 7.81017 18.4238C7.81017 17.7423 7.25763 17.1897 6.57605 17.1897C6.34484 17.1897 6.13056 17.2526 5.94693 17.3618C5.51927 17.616 4.81149 17.7063 4.30729 17.202L3.15063 16.0454C2.28312 15.1779 2.28313 13.7714 3.15063 12.9038L12.9038 3.15064Z"
                    fill="#727CD4"
                  />
                </svg>
              }
              placeholder="1"
              noBorder
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack mt="2rem" direction="row" justify="flex-end" w="100%" mb="1rem">
        {!isFirstTicket && (
          <Button
            variant="neutral"
            onClick={() => {
              setAddingNewMerch({value: false});
              reset({});
            }}
          >
            Cancel
          </Button>
        )}
        <Button type="submit">
          {isFirstTicket
            ? "Add your first Merch"
            : editingMerchId.value
            ? "Edit Merch"
            : "Add Merch"}
        </Button>
      </Stack>
    </form>
  );
};

const YourMerch = () => {
  const [tickets, setTickets] = useSubscribeToMerchStep<MerchStepDataType["merch"]>("merch");
  const [, setEditingMerchId] =
    useSubscribeToMerchStep<MerchStepDataType["editingMerchId"]>("editingMerchId");
  const [, setAddingNewMerch] =
    useSubscribeToMerchStep<MerchStepDataType["addingNewMerch"]>("addingNewMerch");

  return (
    <Box>
      <Stack direction="row" align="center" mb="1rem" justify="space-between">
        <Title text="Your tickets" />

        <Button
          size="md"
          variant="outline"
          onClick={() => setAddingNewMerch({value: true})}
          leftIcon={
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.72222 8.77778H2.27784C2.27782 8.77778 2.2778 8.77778 2.27778 8.77778C2.20976 8.77775 2.14412 8.75278 2.0933 8.70758C2.04514 8.66476 2.01345 8.60661 2.00348 8.54316L2.00023 8.48886C2.00282 8.42485 2.02748 8.36356 2.0702 8.31552C2.11302 8.26736 2.17117 8.23568 2.23461 8.2257L2.29272 8.22222H7.72222H8.22222V7.72222V2.27784C8.22222 2.27782 8.22222 2.2778 8.22222 2.27778C8.22225 2.20976 8.24723 2.14412 8.29242 2.0933C8.33524 2.04514 8.3934 2.01345 8.45684 2.00348L8.51114 2.00023C8.57515 2.00282 8.63644 2.02748 8.68448 2.0702L9.0152 1.69828L8.68448 2.0702C8.73264 2.11302 8.76432 2.17117 8.7743 2.23461L8.77778 2.29272V7.72222V8.22222H9.27778H14.7222C14.7222 8.22222 14.7222 8.22222 14.7222 8.22222C14.7902 8.22225 14.8559 8.24723 14.9067 8.29242C14.9549 8.33524 14.9865 8.3934 14.9965 8.45683L14.9998 8.51114C14.9972 8.57515 14.9725 8.63644 14.9298 8.68448L15.3034 9.01673L14.9298 8.68448C14.887 8.73264 14.8288 8.76432 14.7654 8.7743L14.7073 8.77778H9.27778H8.77778V9.27778V14.7222C8.77778 14.7222 8.77778 14.7222 8.77778 14.7222C8.77775 14.7902 8.75278 14.8559 8.70758 14.9067L9.08122 15.239L8.70758 14.9067C8.66476 14.9549 8.6066 14.9865 8.54317 14.9965L8.48886 14.9998C8.42485 14.9972 8.36356 14.9725 8.31552 14.9298C8.26736 14.887 8.23568 14.8288 8.2257 14.7654L8.22222 14.7073V9.27778V8.77778H7.72222Z"
                fill="white"
                stroke="#4754C6"
              />
            </svg>
          }
        >
          Add new ticket
        </Button>
      </Stack>

      <Box bg="#F8F8F8" borderRadius="14px" p="1rem" mb="2rem">
        <Grid templateColumns="repeat(4, 1fr)" opacity="50%">
          <Text textAlign="center">Merch name</Text>
          <Text w="100%" textAlign="center">
            Price
          </Text>
          <Text w="100%" textAlign="center">
            Amount
          </Text>
          <Text textAlign="end" w="100%">
            Action
          </Text>
        </Grid>
        <Divider borderColor="gray.400" my="0.6rem" />

        <Stack>
          {tickets.value.map((ticket) => (
            <Grid key={ticket._id} templateColumns="repeat(4, 1fr)">
              <Text textAlign="center">{ticket.name}</Text>
              <Text w="100%" textAlign="center">
                $ {ticket.price || 0}
              </Text>
              <Stack w="100%" direction="row" align="center" justify="center" spacing="1rem">
                <svg
                  width="17px"
                  height="17px"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.3781 4.19782C15.0889 3.90865 14.62 3.90865 14.3309 4.19782L4.5777 13.951C4.28853 14.2402 4.28853 14.709 4.5777 14.9982L5.63235 16.0528C6.02453 15.8335 6.47667 15.7088 6.95593 15.7088C8.45542 15.7088 9.671 16.9243 9.671 18.4238C9.671 18.9032 9.5462 19.3552 9.32694 19.7474L10.3817 20.8022C10.6709 21.0914 11.1397 21.0914 11.4289 20.8022L21.1821 11.049C21.4713 10.7598 21.4713 10.291 21.1821 10.0018L20.1274 8.94713C19.7351 9.16648 19.2828 9.29136 18.8035 9.29136C17.304 9.29136 16.0884 8.07578 16.0884 6.57629C16.0884 6.09691 16.2133 5.64465 16.4327 5.2524L15.3781 4.19782ZM13.2837 3.15064C14.1513 2.28312 15.5578 2.28312 16.4253 3.15064L17.5818 4.30724C18.0861 4.8115 17.9958 5.51937 17.7415 5.94703C17.6323 6.1307 17.5694 6.34502 17.5694 6.57629C17.5694 7.25788 18.1219 7.81041 18.8035 7.81041C19.0347 7.81041 19.2491 7.7475 19.4327 7.63829C19.8604 7.38402 20.5683 7.29365 21.0725 7.79792L22.2292 8.95464C23.0968 9.82217 23.0968 11.2287 22.2292 12.0962L12.4761 21.8493C11.6086 22.7169 10.202 22.7169 9.33452 21.8493L8.17774 20.6926C7.67354 20.1884 7.76381 19.4806 8.01801 19.053C8.12716 18.8693 8.19005 18.655 8.19005 18.4238C8.19005 17.7423 7.63751 17.1897 6.95593 17.1897C6.72472 17.1897 6.51045 17.2526 6.32681 17.3618C5.89915 17.616 5.19138 17.7063 4.68717 17.202L3.53052 16.0454C2.663 15.1779 2.66301 13.7714 3.53052 12.9038L13.2837 3.15064Z"
                    fill="#727CD4"
                  />
                </svg>
                <Text>{ticket.amount}</Text>
              </Stack>
              <Stack direction="row" align="center" justify="flex-end" spacing="1rem">
                <Text
                  cursor="pointer"
                  onClick={() => {
                    setEditingMerchId({value: ticket._id});
                    setAddingNewMerch({value: true});
                  }}
                >
                  Edit
                </Text>
                <Popover>
                  {({onClose}) => (
                    <>
                      <PopoverTrigger>
                        <Icon
                          cursor="pointer"
                          width="17px"
                          height="17px"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                            fill="#D85639"
                          />
                          <path
                            d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                            fill="#D85639"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                            fill="#D85639"
                          />
                        </Icon>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody py="1rem">
                          <Text w="100%" textAlign="center" mb="1rem" fontWeight="bold">
                            Do you want to delete this merch ?
                          </Text>
                          <Stack direction="row">
                            <Button onClick={onClose} w="100%" size="md" variant="outline">
                              Cancel
                            </Button>
                            <Button
                              w="100%"
                              onClick={() => {
                                setTickets((p) => ({
                                  value: p.value.filter((x) => x._id !== ticket._id),
                                }));
                              }}
                              size="md"
                              variant="outline"
                              borderColor="#D85639"
                              color="#D85639"
                              leftIcon={
                                <Icon
                                  width="17px"
                                  height="17px"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                                    fill="#D85639"
                                  />
                                  <path
                                    d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                                    fill="#D85639"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                                    fill="#D85639"
                                  />
                                </Icon>
                              }
                            >
                              Delete
                            </Button>
                          </Stack>
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              </Stack>
            </Grid>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const Merch = () => {
  const {currentStep} = useNewEventStepSubscriber();
  const [merch] = useSubscribeToMerchStep<MerchStepDataType["merch"]>("merch");
  const [addingNewMerch] =
    useSubscribeToMerchStep<MerchStepDataType["addingNewMerch"]>("addingNewMerch");

  return (
    <Section stepNumber={4} stepName="Digital Merch" isExposed={currentStep === StepVariant.MERCH}>
      <Collapse in={isEmpty(merch.value) || addingNewMerch.value}>
        {(isEmpty(merch.value) || addingNewMerch.value) && <AddNewMerch />}
      </Collapse>

      <Collapse in={!isEmpty(merch.value) && !addingNewMerch.value}>
        <YourMerch />
      </Collapse>
    </Section>
  );
};

export default Merch;
