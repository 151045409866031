import {EventRead} from "@ef-org/api";
import {useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import useCookie from "apps/ef-org/src/hooks/useCookie";
import {format, intlFormatDistance, parseISO} from "date-fns";

import React, {useMemo} from "react";

import {useRouter} from "next/router";

import {Box, Heading, Stack} from "@chakra-ui/react";

import EventDashboardPreview from "./EventDashboardPreview";

const PublishedEventsSection = () => {
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const {data, isLoading} = useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery(
    {organizerId},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );

  const featured_image = (event: EventRead) => event.images.find((img) => img.is_featured);

  const publishedEvents = useMemo(
    () => data?.items?.filter((e) => e.state === "published") ?? [],
    [data]
  );

  if (publishedEvents.length < 1) {
    return null;
  }

  return (
    <div>
      <Heading as="h3" mb="1rem" fontSize="20px" color="primary-5">
        Published events
      </Heading>

      <Box overflowX="auto" pb="1rem">
        <Stack direction="row" spacing="1rem">
          {publishedEvents.map((event) => (
            <EventDashboardPreview
              key={event.id}
              eventId={event.id}
              eventName={event.name}
              featuredImage={featured_image(event)?.url}
              revenue={event.statistics.total_revenue}
              soldTickets={event.statistics.tickets_sold_count}
              startDate={format(parseISO(event.start_datetime), "MMM d, yyyy")}
              totalTickets={event.statistics.tickets_total_count}
              timeCounter={intlFormatDistance(parseISO(event.start_datetime), new Date())}
            />
          ))}
        </Stack>
      </Box>
    </div>
  );
};

export default PublishedEventsSection;
