import {CustomAttendeeFieldConfigCreate} from "@ef-org/api";
import {Input} from "@ef-org/components";
import {useUpdateEvent} from "@ef-org/hooks";
import {useAddCustomAttendeeFieldModal} from "apps/ef-org/src/modals/useAddCustomAttendeFieldModal";
import {useAddEmailNoteModal} from "apps/ef-org/src/modals/useAddEmailNoteModal";

import React from "react";

import {Stack, Text, Switch, Heading, Button, Flex, Box, Icon} from "@chakra-ui/react";

import Section from "../components/Section";
import {
  SettingsStepDataType,
  StepVariant,
  useNewEventStepSubscriber,
  useSubscribeToSettingsStep,
  useSubscribeToTicketsStep,
} from "../newEventStore";

const Title: React.FC<{text?: string}> = ({text}) => (
  <Heading fontWeight="bold" fontSize="18px" pt="1.5rem" pb="0.5rem">
    {text}
  </Heading>
);

const Settings = () => {
  const {currentStep} = useNewEventStepSubscriber();
  const [customAttendeeFields, setCustomAttendeeFields] =
    useSubscribeToSettingsStep<SettingsStepDataType["customAttendeeFields"]>(
      "customAttendeeFields"
    );

  const [emailNote, setEmailNote] =
    useSubscribeToSettingsStep<SettingsStepDataType["emailNote"]>("emailNote");
  const [termsLink, setTermsLink] =
    useSubscribeToSettingsStep<SettingsStepDataType["termsLink"]>("termsLink");

  const {openModal} = useAddCustomAttendeeFieldModal();
  const {openModal: openEmailNoteModal} = useAddEmailNoteModal();
  const deleteField = (i: CustomAttendeeFieldConfigCreate) => {
    const newfields = customAttendeeFields.value.filter((item) => item != i);
    setCustomAttendeeFields({value: newfields});
  };

  return (
    <Section
      stepNumber={4}
      stepName="Additional settings"
      isExposed={currentStep === StepVariant.SETTINGS}
    >
      {/* <Title text="Manage attendees" />

      <Stack bg="#F8F8F8" border="24px" p="1rem">
        <Stack direction="row" align="center">
          <Switch isChecked />
          <Text variant="gray">Always require email before checkout</Text>
        </Stack>

        <Stack direction="row" align="center">
          <Switch />
          <Text variant="gray">Collect attendee&apos;s first & last name</Text>
        </Stack>

        <Stack direction="row" align="center">
          <Switch />
          <Text variant="gray">Attendees can update their own name</Text>
        </Stack>

        <Stack direction="row" align="center">
          <Switch />
          <Text variant="gray">Always require first & last name before checkout</Text>
        </Stack>
      </Stack> */}
      <Flex justifyContent="space-between">
        <Title text="Attendee fields" />
        <Button variant="outline" onClick={() => openModal({})}>
          Add attendee field
        </Button>
      </Flex>
      <Stack bg="#FAFAFD" borderRadius="24px" p="20px">
        {customAttendeeFields.value?.map((caf) => (
          <Flex justifyContent="space-between" borderRadius="10px" backgroundColor="white" p="16px">
            <Text fontSize="16px">{caf.name}</Text>
            <Box>
              <Icon
                cursor="pointer"
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                fill="none"
                onClick={() => deleteField(caf)}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33317 8.33333C8.67834 8.33333 8.95817 8.61317 8.95817 8.95833V13.5417C8.95817 13.8868 8.67834 14.1667 8.33317 14.1667C7.988 14.1667 7.70817 13.8868 7.70817 13.5417V8.95833C7.70817 8.61317 7.988 8.33333 8.33317 8.33333Z"
                  fill="#D85639"
                />
                <path
                  d="M12.2915 8.95833C12.2915 8.61317 12.0117 8.33333 11.6665 8.33333C11.3213 8.33333 11.0415 8.61317 11.0415 8.95833V13.5417C11.0415 13.8868 11.3213 14.1667 11.6665 14.1667C12.0117 14.1667 12.2915 13.8868 12.2915 13.5417V8.95833Z"
                  fill="#D85639"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.24984 4.16667V2.91667C6.24984 1.99619 6.99603 1.25 7.9165 1.25H12.0832C13.0037 1.25 13.7498 1.99619 13.7498 2.91667V4.16667H17.7082C18.0533 4.16667 18.3332 4.44649 18.3332 4.79167C18.3332 5.13684 18.0533 5.41667 17.7082 5.41667H16.593L15.9254 16.7645C15.8737 17.6455 15.1441 18.3333 14.2617 18.3333H5.73801C4.85555 18.3333 4.12605 17.6455 4.07422 16.7645L3.4067 5.41667H2.2915C1.94633 5.41667 1.6665 5.13684 1.6665 4.79167C1.6665 4.44649 1.94633 4.16667 2.2915 4.16667H6.24984ZM7.9165 2.5H12.0832C12.3133 2.5 12.4998 2.68655 12.4998 2.91667V4.16667H7.49984V2.91667C7.49984 2.68655 7.68639 2.5 7.9165 2.5ZM4.65886 5.41667L5.32206 16.6912C5.33502 16.9113 5.5174 17.0833 5.73801 17.0833H14.2617C14.4823 17.0833 14.6647 16.9113 14.6776 16.6912L15.3408 5.41667H4.65886Z"
                  fill="#D85639"
                />
              </Icon>
            </Box>
          </Flex>
        ))}
        <Flex>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M11.4903 14.0625C11.212 14.0625 10.9736 13.8333 11.0167 13.5584C11.0381 13.4225 11.0699 13.3285 11.1123 13.2033C11.1276 13.1582 11.1442 13.109 11.1622 13.0524C11.2773 12.7008 11.4403 12.4164 11.6512 12.1991C11.8622 11.9817 12.1163 11.7836 12.4135 11.6046C12.6052 11.4831 12.7778 11.3473 12.9312 11.1971C13.0847 11.0469 13.2061 10.8743 13.2956 10.6793C13.3851 10.4844 13.4298 10.2687 13.4298 10.0321C13.4298 9.7477 13.3627 9.50161 13.2285 9.29387C13.0942 9.08613 12.9153 8.92633 12.6915 8.81447C12.471 8.69941 12.2249 8.64188 11.9533 8.64188C11.7072 8.64188 11.4723 8.69302 11.2485 8.79529C11.0248 8.89756 10.8394 9.05736 10.6924 9.27469C10.6187 9.38209 10.5606 9.50516 10.5183 9.6439C10.4413 9.89577 10.2336 10.1041 9.97027 10.1041H9.50078C9.21702 10.1041 8.985 9.86658 9.03856 9.58792C9.10695 9.23211 9.23317 8.91837 9.41722 8.64667C9.68568 8.24717 10.0404 7.94515 10.4815 7.7406C10.9257 7.53606 11.4163 7.43378 11.9533 7.43378C12.5413 7.43378 13.0559 7.54405 13.4969 7.76457C13.938 7.9819 14.28 8.28712 14.5229 8.68023C14.769 9.07015 14.892 9.52558 14.892 10.0465C14.892 10.4045 14.8361 10.7273 14.7242 11.0149C14.6124 11.2994 14.4526 11.5535 14.2448 11.7772C14.0403 12.0009 13.7942 12.1991 13.5065 12.3716C13.2349 12.541 13.0143 12.7168 12.845 12.899C12.6788 13.0812 12.5573 13.2969 12.4806 13.5462C12.4695 13.5823 12.459 13.6189 12.4491 13.6549C12.3852 13.8884 12.1798 14.0625 11.9377 14.0625H11.4903Z"
              fill="#727CD4"
            />
            <path
              d="M11.7088 17.4773C11.4467 17.4773 11.2214 17.3846 11.0328 17.1992C10.8442 17.0107 10.75 16.7838 10.75 16.5185C10.75 16.2564 10.8442 16.0327 11.0328 15.8473C11.2214 15.6588 11.4467 15.5645 11.7088 15.5645C11.9676 15.5645 12.1914 15.6588 12.3799 15.8473C12.5717 16.0327 12.6676 16.2564 12.6676 16.5185C12.6676 16.6943 12.6228 16.8557 12.5333 17.0027C12.447 17.1465 12.332 17.2616 12.1882 17.3478C12.0443 17.4341 11.8845 17.4773 11.7088 17.4773Z"
              fill="#727CD4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 12.4362C22 17.959 17.5228 22.4362 12 22.4362C6.47715 22.4362 2 17.959 2 12.4362C2 6.91337 6.47715 2.43622 12 2.43622C17.5228 2.43622 22 6.91337 22 12.4362ZM20.5 12.4362C20.5 17.1306 16.6944 20.9362 12 20.9362C7.30558 20.9362 3.5 17.1306 3.5 12.4362C3.5 7.7418 7.30558 3.93622 12 3.93622C16.6944 3.93622 20.5 7.7418 20.5 12.4362Z"
              fill="#727CD4"
            />
          </svg>
          <Text color="primary-4" px="4px" fontSize="16px">
            Select additional pieces of information you want to collect from your attendees, e.g.
            “Preferred Meal”, “T-Shirt Size” or similar.
          </Text>
        </Flex>
      </Stack>
      <Flex justifyContent="space-between" mt="1rem">
        <Title text="Email note" />
        <Button variant="outline" onClick={() => openEmailNoteModal({})}>
          Add note
        </Button>
      </Flex>
      {emailNote.value && (
        <Stack bg="#FAFAFD" borderRadius="24px" p="20px">
          <Flex justifyContent="space-between">
            <Text color="primary-4">{emailNote.value}</Text>
            <Flex maxH="40px">
              <Button
                variant="link"
                p="1rem"
                backgroundColor="#E9EAF8"
                color="ef-primary"
                onClick={() => openEmailNoteModal({})}
              >
                Edit
              </Button>
              <Button
                variant="link"
                p="1rem"
                color="ef-red"
                backgroundColor="transparent"
                onClick={() => setEmailNote({value: undefined})}
              >
                Remove
              </Button>
            </Flex>
          </Flex>
        </Stack>
      )}
      <Title text="Terms & Conditions" />
      <Input
        mb="1rem"
        value={termsLink?.value}
        error={termsLink?.error}
        onChange={(e) => setTermsLink({value: e.target.value})}
        placeholder="Paste the link of your event terms and conditions"
        leftContent={
          <Icon
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.25 7C9.66421 7 10 7.33579 10 7.75C10 8.12656 9.72249 8.4383 9.36083 8.49187L9.25 8.5H7C5.067 8.5 3.5 10.067 3.5 12C3.5 13.864 4.95707 15.3876 6.79435 15.4941L7 15.5H9.25C9.66421 15.5 10 15.8358 10 16.25C10 16.6266 9.72249 16.9383 9.36083 16.9919L9.25 17H7C4.23858 17 2 14.7614 2 12C2 9.32226 4.10496 7.13615 6.75045 7.00612L7 7H9.25ZM17 7C19.7614 7 22 9.23858 22 12C22 14.6777 19.895 16.8638 17.2495 16.9939L17 17H14.75C14.3358 17 14 16.6642 14 16.25C14 15.8734 14.2775 15.5617 14.6392 15.5081L14.75 15.5H17C18.933 15.5 20.5 13.933 20.5 12C20.5 10.136 19.0429 8.6124 17.2057 8.50594L17 8.5H14.75C14.3358 8.5 14 8.16421 14 7.75C14 7.37344 14.2775 7.0617 14.6392 7.00813L14.75 7H17ZM7 11.25H17C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.3797 17.4678 12.6935 17.1018 12.7432L17 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12C6.25 11.6203 6.53215 11.3065 6.89823 11.2568L7 11.25Z"
              fill="#727CD4"
            />
          </Icon>
        }
        noBorder
      />
    </Section>
  );
};

export default Settings;
