import {InitialUseCreateModalType, useCreateModal, ReactModalComponent} from "@ef-org/modals";
import {useDeleteEvent} from "apps/ef-org/src/hooks/useSelectors";
import {push} from "mixpanel-browser";

import React from "react";

import {useRouter} from "next/router";

import {Box, Heading, Text, Button, Stack, useToast, Icon} from "@chakra-ui/react";

type Props = {
  eventId: string;
};

const DeleteEventModal: ReactModalComponent<Props> = ({closeModal, eventId}) => {
  const {push} = useRouter();
  const toast = useToast();
  const deleteEvent = useDeleteEvent();

  const handleDeleteEvent = async () => {
    try {
      const response = await deleteEvent(eventId);
      if ("data" in response) {
        closeModal();
        push("/events");
      } else {
        toast({
          title: "Oops!",
          // @ts-ignore
          description: response.error.data.error.message,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {}
  };

  return (
    <Stack p="2rem" align="center">
      <Heading variant="h4" textAlign="center" px="1rem">
        Do you want to delete the&nbsp;event?
      </Heading>

      <Text pt="1rem" textAlign="center" fontSize="md" w="100%">
        This will remove all the data related to this event including the attendees.
      </Text>

      <Text textAlign="center" fontSize="md" w="100%">
        This action cannot be undone.
      </Text>

      <Stack pt="1.5rem" direction="row" justifyContent="center" gap="2rem">
        <Button
          size="md"
          onClick={() => {
            handleDeleteEvent();
          }}
          variant="outline"
          borderColor="#D85639"
          color="#D85639"
          leftIcon={
            <Icon
              width="17px"
              height="17px"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.25 8.5C8.6642 8.5 9 8.8358 9 9.25V14.75C9 15.1642 8.6642 15.5 8.25 15.5C7.83579 15.5 7.5 15.1642 7.5 14.75V9.25C7.5 8.8358 7.83579 8.5 8.25 8.5Z"
                fill="#D85639"
              />
              <path
                d="M13 9.25C13 8.8358 12.6642 8.5 12.25 8.5C11.8358 8.5 11.5 8.8358 11.5 9.25V14.75C11.5 15.1642 11.8358 15.5 12.25 15.5C12.6642 15.5 13 15.1642 13 14.75V9.25Z"
                fill="#D85639"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.75 3.5V2C5.75 0.89543 6.64543 0 7.75 0H12.75C13.8546 0 14.75 0.89543 14.75 2V3.5H19.5C19.9142 3.5 20.25 3.83579 20.25 4.25C20.25 4.66421 19.9142 5 19.5 5H18.1618L17.3607 18.6174C17.2986 19.6746 16.4231 20.5 15.3642 20.5H5.13581C4.07686 20.5 3.20145 19.6746 3.13926 18.6174L2.33824 5H1C0.58579 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.58579 3.5 1 3.5H5.75ZM7.75 1.5H12.75C13.0261 1.5 13.25 1.72386 13.25 2V3.5H7.25V2C7.25 1.72386 7.47386 1.5 7.75 1.5ZM3.84083 5L4.63667 18.5294C4.65222 18.7936 4.87107 19 5.13581 19H15.3642C15.6289 19 15.8478 18.7936 15.8633 18.5294L16.6592 5H3.84083Z"
                fill="#D85639"
              />
            </Icon>
          }
        >
          Delete
        </Button>

        <Button size="md" onClick={closeModal} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export const useDeleteEventModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("deleteEventModal", DeleteEventModal);
