import {beOrgApi} from "@ef-org/api";
import Cookies from "js-cookie";

import {useSelector} from "react-redux";

import {useRouter} from "next/router";

export const useEventDetailSelectorByQueryEventId = () => {
  const {query} = useRouter();
  const organizerId = Cookies.get("organizerId") ?? "";
  return useSelector(
    beOrgApi.endpoints.getEventOrganizersOrganizerIdEventsEventIdGet.select({
      organizerId,
      eventId: query?.eventId?.toString(),
    })
  );
};
