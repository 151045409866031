import {useEffect} from "react";

import {useRouter} from "next/router";

import {AspectRatio, Box, Stack, Image, Collapse, Container} from "@chakra-ui/react";

import AuthGuard from "../../components/AuthGuard";
import StepBanner from "./components/StepBanner";
import {
  BasicStepDataType,
  StepVariant,
  TicketsStepDataType,
  useEventLogger,
  useNewEventStepSubscriber,
  useNewEventStore,
  useSubscribeToBasicStep,
  useSubscribeToTicketsStep,
} from "./newEventStore";
import BasicInformation from "./steps/BasicInformation";
import DescribeEvent from "./steps/DescribeEvent";
import FinalScreen from "./steps/FinalScreen";
import Merch from "./steps/Merch";
import Settings from "./steps/Settings";
import Tickets from "./steps/Tickets";
import useEventDataHandlers from "./useEventDataHandlers";

const ImageLandingPage = () => {
  const [eventImage] = useSubscribeToBasicStep<BasicStepDataType["eventImage"]>("eventImage");

  if (!eventImage?.value?.preview) return null;

  return (
    <Box position="absolute" w="100%">
      <Container maxW="2500px" p="0">
        <AspectRatio mt="-3rem" w="100%" ratio={21 / 7}>
          <Image
            w="100%"
            h="100%"
            src={eventImage?.value?.preview || undefined}
            alt="3d-own-upload"
          />
        </AspectRatio>
      </Container>
    </Box>
  );
};

const NewEvent = () => {
  const {query} = useRouter();

  useEventLogger();
  useEventDataHandlers();

  const {currentStep, allowedStepsBack} = useNewEventStepSubscriber();

  useEffect(() => {
    document.body.style.backgroundColor = "var(--chakra-colors-light-blue)";

    return () => {
      document.body.style.backgroundColor = "#FFFFFF";
    };
  }, []);

  const [, setAddingNewDiscount] =
    useSubscribeToTicketsStep<TicketsStepDataType["addingNewDiscount"]>("addingNewDiscount");
  const [, setAddingNewTicket] =
    useSubscribeToTicketsStep<TicketsStepDataType["addingNewTicket"]>("addingNewTicket");
  const [, setEditingDiscountId] =
    useSubscribeToTicketsStep<TicketsStepDataType["editingDiscountId"]>("editingDiscountId");
  const goToStep = useNewEventStore((p) => p.actions.goToStep);
  useEffect(() => {
    let queryState = {};
    const step = query.step as StepVariant;
    const discountId = query.discountId ? String(query.discountId) : undefined;
    const ticketId = query.ticketId ? String(query.ticketId) : undefined;

    setEditingDiscountId({value: discountId});
    setAddingNewDiscount({value: Boolean(discountId)});
    setAddingNewTicket({value: Boolean(ticketId)});
    useNewEventStore.setState(queryState);
    goToStep(step ?? StepVariant.BASIC);
  }, [query.step]);

  return (
    <AuthGuard requiredPermissions="admin">
      <Box bg="light-blue" py="3rem" h="100vh">
        <Collapse in={currentStep === StepVariant.BASIC}>
          <ImageLandingPage />
        </Collapse>

        <Collapse in={currentStep === StepVariant.BASIC}>
          <ImageLandingPage />
        </Collapse>

        <Collapse in={currentStep === StepVariant.DONE}>
          <FinalScreen />
        </Collapse>

        <Stack spacing="2rem" align="center" zIndex="100" position="absolute" width="100%">
          {(currentStep === StepVariant.BASIC || allowedStepsBack.includes(StepVariant.BASIC)) && (
            <BasicInformation />
          )}
          {(currentStep === StepVariant.DESCRIPTION ||
            allowedStepsBack.includes(StepVariant.DESCRIPTION)) && <DescribeEvent />}

          {(currentStep === StepVariant.TICKETS ||
            allowedStepsBack.includes(StepVariant.TICKETS)) && <Tickets />}

          {(currentStep === StepVariant.MERCH || allowedStepsBack.includes(StepVariant.MERCH)) && (
            <Merch />
          )}

          {(currentStep === StepVariant.SETTINGS ||
            allowedStepsBack.includes(StepVariant.SETTINGS)) && <Settings />}
          <Box w="100%" h="120px" />
        </Stack>

        <StepBanner />
      </Box>
    </AuthGuard>
  );
};

export default NewEvent;
