import React from "react";

import {Heading, HeadingProps} from "@chakra-ui/react";

const Title: React.FC<{text?: string; noPadding?: boolean} & HeadingProps> = ({
  text,
  noPadding,
  ...rest
}) => (
  <Heading
    fontWeight="bold"
    fontSize="20px"
    pt={noPadding ? "0" : "1.5rem"}
    pb={noPadding ? "0" : "0.5rem"}
    {...rest}
  >
    {text}
  </Heading>
);

export default Title;
