import {Input} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import Joi from "joi";

import * as React from "react";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

export type Type = {
  password: string;
};

export const Schema = Joi.object({
  password: Joi.string(),
});

const CrossIcon: React.FC<{isError: boolean}> = ({isError}) => (
  <>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013C18.3327 14.6036 14.6017 18.3346 9.99935 18.3346C5.39697 18.3346 1.66602 14.6036 1.66602 10.0013C1.66602 5.39893 5.39697 1.66797 9.99935 1.66797ZM9.99935 2.91797C6.08733 2.91797 2.91602 6.08929 2.91602 10.0013C2.91602 13.9133 6.08733 17.0846 9.99935 17.0846C13.9113 17.0846 17.0827 13.9133 17.0827 10.0013C17.0827 6.08929 13.9113 2.91797 9.99935 2.91797ZM12.8712 6.99884L12.9413 7.05936C13.1632 7.28125 13.1833 7.62847 13.0018 7.87314L12.9413 7.94324L10.8835 10.0013L12.9413 12.0594C13.1632 12.2812 13.1833 12.6285 13.0018 12.8731L12.9413 12.9432C12.7194 13.1651 12.3722 13.1853 12.1275 13.0037L12.0574 12.9432L9.99935 10.8855L7.94129 12.9432C7.7194 13.1651 7.37218 13.1853 7.12751 13.0037L7.05741 12.9432C6.83552 12.7214 6.81535 12.3741 6.99689 12.1295L7.05741 12.0594L9.11518 10.0013L7.05741 7.94324C6.83552 7.72135 6.81535 7.37414 6.99689 7.12946L7.05741 7.05936C7.2793 6.83747 7.62652 6.8173 7.87119 6.99884L7.94129 7.05936L9.99935 9.11714L12.0574 7.05936C12.2793 6.83747 12.6265 6.8173 12.8712 6.99884Z"
        fill={isError ? "#ED1F1F" : "#888888"}
      />
    </svg>
  </>
);
const schemaForMin = Joi.object({
  password: Joi.string().min(8),
});
const schemaForUppercase = Joi.object({
  password: Joi.string().custom((value: string, helpers) => {
    if (!value.match(/[A-Z]/g)) {
      return helpers.error("string.pattern.name");
    }
    return value;
  }),
});

const ConfirmationSuccess: React.FC<{stepHandler: () => void}> = ({stepHandler}) => {
  const {handleSubmit, watch, register} = useForm<Type>(Schema, {
    defaultValues: {password: ""},
  });
  const [errors, setErrors] = React.useState({min: true, uppercase: true});
  const handleOnSubmit: OnFormSubmitType<Type> = async (data) => {
    stepHandler();
  };
  const [isFocusedPassword, setIsFocusedPassword] = React.useState(false);
  const watchedPassword = watch("password");
  React.useEffect(() => {
    const min = schemaForMin.validate({password: watchedPassword});
    const upper = schemaForUppercase.validate({password: watchedPassword});
    setErrors({min: "error" in min, uppercase: "error" in upper});
  }, [watchedPassword]);
  return (
    <Flex direction="column" align="center" w={["100%", "70%"]}>
      <Heading textAlign="center" variant="h2" mb="20px">
        Great! <br />
        Your email is confirmed
      </Heading>
      <Text mb="20px" fontWeight="bold">
        Please set your password
      </Text>
      <Box as="form" w="100%" onSubmit={handleSubmit(handleOnSubmit)}>
        <Input
          onFocus={() => setIsFocusedPassword(true)}
          onBlurCustom={() => setIsFocusedPassword(false)}
          leftContent={
            <>
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C14.2091 0 16 1.79086 16 4V6H17.75C18.9926 6 20 7.00736 20 8.25V17.75C20 18.9926 18.9926 20 17.75 20H6.25C5.00736 20 4 18.9926 4 17.75V8.25C4 7.00736 5.00736 6 6.25 6H8V4C8 1.79086 9.79086 0 12 0ZM17.75 7.5H6.25C5.83579 7.5 5.5 7.83579 5.5 8.25V17.75C5.5 18.1642 5.83579 18.5 6.25 18.5H17.75C18.1642 18.5 18.5 18.1642 18.5 17.75V8.25C18.5 7.83579 18.1642 7.5 17.75 7.5ZM12.0001 11.5C12.8286 11.5 13.5001 12.1716 13.5001 13C13.5001 13.8284 12.8286 14.5 12.0001 14.5C11.1717 14.5 10.5001 13.8284 10.5001 13C10.5001 12.1716 11.1717 11.5 12.0001 11.5ZM12 1.5C10.6193 1.5 9.5 2.61929 9.5 4V6H14.5V4C14.5 2.61929 13.3807 1.5 12 1.5Z"
                  fill={isFocusedPassword ? "#1F2FBA" : "#888888"}
                />
              </svg>
            </>
          }
          label="Password"
          {...register("password")}
          placeholder="Enter your password"
          type="password"
        />
        <Stack mt="16px" color="ob-grey-400">
          <Stack direction="row" align="center">
            <CrossIcon isError={errors.min} />
            <Text fontWeight="bold" color={errors.min ? "ef-red" : "unset"}>
              Use a minimum of 8 characters
            </Text>
          </Stack>
          <Stack direction="row" align="center">
            <CrossIcon isError={errors.uppercase} />
            <Text fontWeight="bold" color={errors.uppercase ? "ef-red" : "unset"}>
              Use a minimum of 1 Uppercase
            </Text>
          </Stack>
        </Stack>
        {/* <AlertSubscriber boxId="loginResponse" /> */}
        <Button
          variant="ob_primary"
          type="submit"
          w="100%"
          mt="32px"
          isDisabled={errors.min || errors.uppercase}
        >
          Next
        </Button>
      </Box>
    </Flex>
  );
};

export default ConfirmationSuccess;
