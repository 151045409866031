import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";
import Joi from "joi";

import React from "react";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

import Input from "../components/Input";
import useForm from "../hooks/useForm";
import {SettingsStepDataType, useSubscribeToSettingsStep} from "../scenes/NewEvent/newEventStore";

type Props = {
  title?: string;
  onClose?: () => void;
};

export interface AddEmailNoteModalData {
  email_note: string;
}

const validationSchema = Joi.object({
  email_note: Joi.string().required(),
});

const AddEmailNoteModal: React.FC<Props> = ({title, onClose}) => {
  const {closeModal} = useModal("AddEmailNoteModal");
  const {handleSubmit, registerWithError, reset} = useForm<AddEmailNoteModalData>(validationSchema);
  const [emailNote, setEmailNote] =
    useSubscribeToSettingsStep<SettingsStepDataType["emailNote"]>("emailNote");
  const onSubmit = (data: AddEmailNoteModalData) => {
    setEmailNote({value: data.email_note});
    closeModal();
  };

  return (
    <Box m="2rem">
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Add email note
      </Heading>
      <Stack spacing="1rem" mt="1rem" bg="white" w="100%">
        <Text color="#727CD4" pb="10px">
          Note message
        </Text>
        <Box backgroundColor="ef-gray-light" pt="10px" pr="5px" borderRadius="1rem">
          <Input
            maxW="500px"
            as="textarea"
            minH="100px"
            py="5px"
            defaultValue={emailNote.value}
            {...registerWithError("email_note")}
            placeholder="Write the message..."
            noBorder
          />
        </Box>

        <Box>
          <Flex justifyContent="end" w="100%">
            <Button size="md" w={["100%", "100%", "fit-content"]} onClick={handleSubmit(onSubmit)}>
              Add email note
            </Button>
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
};

export const useAddEmailNoteModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("AddEmailNoteModal", AddEmailNoteModal, {
    closeOnEsc: true,
    closeOnOverlayClick: true,
    hideCloseButton: false,
    size: "lg",
  });
