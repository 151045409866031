// import { useRouter } from "next/router";
import {Language, resolveTranslation, TranslationKeys} from "../utils/localization";

export type LocalizationContextType = {
  language: Language;
  translate: (id: TranslationKeys) => string;
};

// const resolveLanguageFromQuery = (
//   queryLanguage: string | Array<string> | undefined,
// ): Language => {
//   if (!queryLanguage) {
//     return DEFAULT_APP_LANGUAGE;
//   }
//   return queryLanguage?.toString().toLowerCase() as Language;
// };

export function useLocalization(): LocalizationContextType {
  // const query = useRouter()?.query;

  // const language: LocalizationContextType["language"] = query
  //   ? resolveLanguageFromQuery(query.language)
  //   : DEFAULT_APP_LANGUAGE;
  const translate: LocalizationContextType["translate"] = (id) => {
    return resolveTranslation("en", id);
  };

  return {language: "en", translate};
}
