import {EventTable, EventTableItem, Link, TicketStatusLabel} from "@ef-org/components";
import Cookies from "js-cookie";

import {useEffect, useState} from "react";

import {useRouter} from "next/router";

import {Box, Heading, Icon, Stack, Text} from "@chakra-ui/react";

import {
  useRemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteMutation,
  useUpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutMutation,
} from "../../api/__generated__/beOrgApi.extended";
import {TicketTypeRead} from "../../api/__generated__/beOrgApi.generated";
import {SortableList} from "../../components/Tables/SortableTable/components";
import {isUserAuthorized} from "../../helpers/aws/auth";
import EventDetailLoader from "./components/EventDetailLoader";
import EventDetailTabHandler from "./components/EventDetailTabHandler";
import {useEventDetailSelectorByQueryEventId} from "./hooks/useEventDetailSelectorByQueryEventId";

const TicketsSection = () => {
  const {query, pathname} = useRouter();
  const {data} = useEventDetailSelectorByQueryEventId();
  const [removeTicketType] =
    useRemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteMutation();
  const [updateTicketsOrder] =
    useUpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutMutation();

  const deleteTicketType = async (ticketTypeId: string) => {
    const organizerId = Cookies.get("organizerId");
    const eventId = query?.eventId?.toString() || "";
    await removeTicketType({ticketTypeId, organizerId, eventId});
  };

  const [ticketTypes, setTicketTypes] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (data) {
      setTicketTypes(data.ticket_types);
      setIsFirstLoad(true);
    }
  }, [data]);

  useEffect(() => {
    if (ticketTypes) {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        return;
      }
      console.log("payload", {
        organizerId: Cookies.get("organizerId"),
        eventId: query?.eventId as string,
        body: ticketTypes.map((ticket: TicketTypeRead, index) => {
          return {id: ticket.id, order: index};
        }),
      });
      updateTicketsOrder({
        organizerId: Cookies.get("organizerId"),
        eventId: query?.eventId as string,
        body: ticketTypes.map((ticket: TicketTypeRead, index) => {
          return {id: ticket.id, order: index};
        }),
      });
    }
  }, [ticketTypes]);

  return (
    <Box>
      <Heading as="h3" mb="1rem" fontSize="24px">
        Your tickets
      </Heading>

      {ticketTypes && (
        <EventTable
          templateColumns="repeat(7, minmax(150px, 1fr))"
          colsSkeletonNumber={7}
          headerComponents={
            <>
              <Text>Ticket name</Text>
              <Text>Price</Text>
              <Text>Sold tickets</Text>
              <Text>Revenue</Text>
              <Text>State</Text>
              <Text>Actions</Text>
              <Text>Reorder</Text>
            </>
          }
          bodyComponents={
            <SortableList
              items={ticketTypes}
              onChange={setTicketTypes}
              renderItem={(ticket: TicketTypeRead) => (
                <SortableList.Item id={ticket.id}>
                  <EventTableItem
                    key={ticket.id}
                    noHover
                    templateColumns="repeat(7, minmax(150px, 1fr))"
                  >
                    <Stack direction="row" align="center">
                      <Text color="#162184">{ticket.name}</Text>
                    </Stack>
                    <Stack
                      w="100%"
                      justify="center"
                      direction="row"
                      align="center"
                      spacing="0.5rem"
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                          fill="#727CD4"
                        />
                      </svg>

                      <Text color="#162184">
                        {ticket.price} {data.currency}
                      </Text>
                    </Stack>

                    <Stack
                      w="100%"
                      justify="center"
                      direction="row"
                      align="center"
                      spacing="0.5rem"
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                          fill="#727CD4"
                        />
                      </svg>

                      <Text color="#162184">
                        {ticket.statistics.tickets_sold}/{" "}
                        <Text as="span" color="#727CD4">
                          {ticket.statistics.tickets_total_count}
                        </Text>
                      </Text>
                    </Stack>

                    <Stack
                      w="100%"
                      direction="row"
                      justify="center"
                      align="center"
                      spacing="0.5rem"
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.4356 21.036V2.85414H13.6004V21.036H12.4356ZM16.0791 8.49334C16.0033 7.82099 15.6908 7.30016 15.1416 6.93084C14.5924 6.55679 13.9011 6.36976 13.0677 6.36976C12.4711 6.36976 11.955 6.46446 11.5194 6.65385C11.0838 6.83851 10.7453 7.09419 10.5038 7.4209C10.2671 7.74287 10.1487 8.10982 10.1487 8.52175C10.1487 8.86739 10.2292 9.16569 10.3902 9.41664C10.5559 9.66758 10.7713 9.87828 11.0365 10.0487C11.3064 10.2145 11.5952 10.3541 11.903 10.4678C12.2107 10.5767 12.5067 10.6666 12.7907 10.7377L14.2112 11.107C14.6752 11.2206 15.1511 11.3745 15.6388 11.5686C16.1265 11.7628 16.5786 12.0184 16.9953 12.3357C17.412 12.6529 17.7481 13.0459 18.0038 13.5146C18.2642 13.9834 18.3944 14.5445 18.3944 15.1979C18.3944 16.0218 18.1814 16.7533 17.7552 17.3925C17.3338 18.0317 16.7207 18.536 15.9157 18.9053C15.1156 19.2746 14.1473 19.4593 13.0109 19.4593C11.9219 19.4593 10.9797 19.2864 10.1842 18.9408C9.38876 18.5951 8.76613 18.1051 8.31632 17.4706C7.86651 16.8314 7.61793 16.0738 7.57058 15.1979H9.77228C9.8149 15.7235 9.98535 16.1614 10.2836 16.5118C10.5867 16.8575 10.9726 17.1155 11.4413 17.286C11.9148 17.4517 12.4333 17.5345 12.9967 17.5345C13.617 17.5345 14.1686 17.4375 14.6515 17.2433C15.1392 17.0445 15.5228 16.7699 15.8021 16.4195C16.0815 16.0644 16.2211 15.6501 16.2211 15.1766C16.2211 14.7457 16.098 14.393 15.8518 14.1183C15.6104 13.8437 15.2813 13.6164 14.8646 13.4365C14.4527 13.2566 13.9863 13.098 13.4655 12.9607L11.7467 12.4919C10.5819 12.1747 9.65865 11.7083 8.97683 11.0928C8.29975 10.4772 7.9612 9.66285 7.9612 8.64959C7.9612 7.81152 8.18848 7.07999 8.64302 6.45499C9.09757 5.82999 9.7131 5.34467 10.4896 4.99902C11.2661 4.64864 12.1421 4.47345 13.1175 4.47345C14.1023 4.47345 14.9711 4.64628 15.724 4.99192C16.4816 5.33757 17.0782 5.81342 17.5138 6.41948C17.9494 7.0208 18.1766 7.71209 18.1956 8.49334H16.0791Z"
                          fill="#727CD4"
                        />
                      </svg>

                      <Text color="#162184">
                        {ticket.statistics.total_revenue} {data.currency}
                      </Text>
                    </Stack>
                    <Stack
                      w="100%"
                      direction="row"
                      justify="center"
                      align="center"
                      spacing="0.5rem"
                    >
                      <TicketStatusLabel isPublished={ticket.state === "published"} />
                    </Stack>
                    {isUserAuthorized("admin") && ticket.state === "published" ? (
                      <Stack direction="row" align="center" justify="center" spacing="1rem">
                        <Link
                          href={`/event/${query?.eventId as string}/edit?step=TICKETS&ticketId=${
                            ticket.id
                          }&backToUrl=${encodeURIComponent(pathname)}`}
                        >
                          <Text color="#4754C6">Edit</Text>
                        </Link>
                        <Text
                          onClick={() => deleteTicketType(ticket.id)}
                          cursor="pointer"
                          color="red.400"
                        >
                          Unpublish
                        </Text>
                      </Stack>
                    ) : (
                      <Stack direction="row" align="center" justify="center" spacing="1rem">
                        <Text color="grey">No action available</Text>
                      </Stack>
                    )}
                    <Stack direction="row" align="center" justify="flex-end" mr="1.5rem">
                      <SortableList.DragHandle />
                    </Stack>
                  </EventTableItem>
                </SortableList.Item>
              )}
            />
          }
        />
      )}
    </Box>
  );
};

const EventDetailOverview = () => {
  return (
    <EventDetailLoader>
      <EventDetailTabHandler />

      <Stack spacing="2rem">
        <TicketsSection />
      </Stack>
    </EventDetailLoader>
  );
};

export default EventDetailOverview;
