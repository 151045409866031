import {useEventDetailSelectorByQueryEventId} from "apps/ef-org/src/scenes/EventDetail/hooks/useEventDetailSelectorByQueryEventId";
import {isEmpty} from "ramda";

import {Heading, Select, Stack, Text} from "@chakra-ui/react";

const TicketsPreview = () => {
  const {data} = useEventDetailSelectorByQueryEventId();

  if (isEmpty(data?.ticket_types || [])) {
    return null;
  }

  return (
    <>
      <Heading color="#616174" as="h2" fontSize="24px" id="ticket-preview">
        Select Tickets
      </Heading>

      <Stack spacing="1rem">
        {data.ticket_types.map((ticket) => (
          <Stack
            direction="row"
            justify="space-between"
            spacing="2rem"
            key={ticket.id}
            bg="#FAFAFD"
            borderRadius="16px"
            p="1rem"
          >
            <Stack spacing="0.3rem">
              <Text as="h4" fontWeight="600" fontSize="16px" color="text-neutral-blue-10">
                {ticket.name}
              </Text>

              {ticket?.description && <Text color="#A4A5B6">{ticket.description}</Text>}
            </Stack>
            <Stack direction="row" align="center" spacing="1rem">
              <Text color="ef-black">
                {ticket.price}&nbsp;{data.currency}
              </Text>

              <Select size="lg" bg="white" borderRadius="12px" w="fit-content">
                {new Array(ticket.maximum_order_quantity + 1).fill(0).map((_, quantity) => {
                  if (ticket.minimum_order_quantity > quantity && quantity !== 0) {
                    return null;
                  }

                  return (
                    <option key={quantity} value={quantity}>
                      {quantity}
                    </option>
                  );
                })}
              </Select>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default TicketsPreview;
