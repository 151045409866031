import {SubscriptionPlanRead} from "@ef-org/api";
import {IconInfo} from "@ef-org/icons";
import usePlansAndSubscriptions from "apps/ef-org/src/hooks/usePlansAndSubscriptions";
import {useActivateSubscriptionModal} from "apps/ef-org/src/modals/useActivateSubscriptionModal";
import {usePickSubscriptionModal} from "apps/ef-org/src/modals/usePickSubscriptionModal";

import React, {useCallback, useEffect} from "react";

import {useRouter} from "next/router";

import {Box, Heading, Stack, Text, useBreakpointValue} from "@chakra-ui/react";

import SubscriptionDetail from "./SubscriptionDetail";

type Props = {
  fromEventCreation?: boolean;
};

const SubscriptionOverview: React.FC<Props> = ({fromEventCreation}) => {
  const isMobile = useBreakpointValue([true, true, false]);

  const {isDataReady, availablePlans, activePlan, refetch} = usePlansAndSubscriptions();

  const {openModal: openSubscriptionModal} = useActivateSubscriptionModal();
  const {closeModal: closePickSubModal} = usePickSubscriptionModal();

  const onPlanPicked = useCallback(
    (plan: SubscriptionPlanRead) => {
      openSubscriptionModal({
        plan: availablePlans.find((p) => p.id === plan.id),
        onSuccess: refetch,
      });
      if (fromEventCreation) {
        closePickSubModal("pickSubscriptionModal");
      }
    },
    [availablePlans]
  );

  return (
    <Box p={fromEventCreation ? "1rem" : "0"}>
      <Heading
        marginBottom="0"
        lineHeight="2"
        fontWeight="600"
        fontSize={isMobile ? "16px" : "20px"}
        color="#1F2FBA"
      >
        {fromEventCreation
          ? "Activate subscription and add payment option"
          : "Subscription details"}
      </Heading>
      <Stack padding="1rem" gap="1rem" alignItems="start" bg="white" borderRadius="12px">
        <Stack direction="row" gap="1rem" wrap="wrap">
          {isDataReady &&
            availablePlans.map((plan) => (
              <SubscriptionDetail
                plan={{
                  ...plan,
                  isActive: plan.id === activePlan?.id,
                  noActivePlans: activePlan === null,
                }}
                planPicked={onPlanPicked}
              />
            ))}
        </Stack>
        <Stack
          w="100%"
          px="24px"
          py="18px"
          gap="16px"
          direction="row"
          bg="#E9EAF8"
          border="1px"
          borderColor="#4754C6"
          borderRadius="12px"
          align="center"
        >
          <IconInfo boxSize="26px" />
          <Text align="left">
            Please note that the billing method you choose must match your payment method, meaning
            you can have either Stripe or Ryft for both billing and receiving payments.
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SubscriptionOverview;
