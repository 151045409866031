import {ValidationError, AsyncValidationOptions, Schema} from "joi";

import {
  appendErrors,
  FieldError,
  ResolverOptions,
  FieldValues,
  ResolverResult,
  UnpackNestedValue,
  set,
  get,
  FieldErrors,
  Field,
} from "react-hook-form";

// Native validation (web only)
export const validateFieldsNatively = <TFieldValues>(
  errors: Record<string, FieldError>,
  options: ResolverOptions<TFieldValues>
): void => {
  for (const fieldPath in options.fields) {
    const field = options.fields[fieldPath];

    if (field && field.ref && "reportValidity" in field.ref) {
      const error = get(errors, fieldPath) as FieldError | undefined;

      field.ref.setCustomValidity((error && error.message) || "");

      field.ref.reportValidity();
    }
  }
};

export const toNestError = <TFieldValues extends FieldValues>(
  errors: Record<string, FieldError>,
  options: ResolverOptions<TFieldValues>
): FieldErrors<TFieldValues> => {
  options.shouldUseNativeValidation && validateFieldsNatively(errors, options);

  const fieldErrors = {} as FieldErrors<TFieldValues>;
  for (const path in errors) {
    const field = get(options.fields, path) as Field["_f"] | undefined;

    set(fieldErrors, path, Object.assign(errors[path], {ref: field && field.ref}));
  }

  return fieldErrors;
};

export type Resolver = <T extends Schema>(
  schema: T,
  schemaOptions?: AsyncValidationOptions,
  factoryOptions?: {mode?: "async" | "sync"}
) => <TFieldValues extends FieldValues, TContext>(
  values: UnpackNestedValue<TFieldValues>,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>
) => Promise<ResolverResult<TFieldValues>>;

const parseErrorSchema = (error: ValidationError, validateAllFieldCriteria: boolean) =>
  error.details.length
    ? error.details.reduce<Record<string, FieldError>>((previous, error) => {
        const _path = error.path.join(".");

        if (!previous[_path]) {
          previous[_path] = {message: error.message, type: error.type};
        }

        if (validateAllFieldCriteria) {
          const types = previous[_path].types;
          const messages = types && types[error.type!];

          previous[_path] = appendErrors(
            _path,
            validateAllFieldCriteria,
            previous,
            error.type,
            messages
              ? ([] as Array<string>).concat(messages as Array<string>, error.message)
              : error.message
          ) as FieldError;
        }

        return previous;
      }, {})
    : {};

export const joiResolver: Resolver =
  (
    schema,
    schemaOptions = {
      abortEarly: false,
    },
    resolverOptions = {}
  ) =>
  async (values, context, options) => {
    const _schemaOptions = Object.assign({}, schemaOptions, {
      context,
    });

    let result: Record<string, any> = {};
    if (resolverOptions.mode === "sync") {
      result = schema.validate(values, _schemaOptions);
    } else {
      try {
        result.value = await schema.validateAsync(values, _schemaOptions);
      } catch (e) {
        result.error = e;
      }
    }

    if (result.error) {
      return {
        values: {},
        errors: toNestError(
          parseErrorSchema(
            result.error,
            !options.shouldUseNativeValidation && options.criteriaMode === "all"
          ),
          options
        ),
      };
    }

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return {
      errors: {},
      values: result.value,
    };
  };
