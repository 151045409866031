import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";
import Joi from "joi";

import React, {useState} from "react";

import {Box, Button, Heading, Stack, useToast} from "@chakra-ui/react";

import {IntegrationType} from "../api/__generated__/beOrgApi.generated";
import Input from "../components/Input";
import useForm, {OnFormSubmitType} from "../hooks/useForm";
import {useCreateIntegration} from "../hooks/useSelectors";

type Props = {
  type: IntegrationType;
};

const FormSchema = Joi.object({
  apiKey: Joi.string().required(),
  serverPrefix: Joi.string().required(),
  audienceId: Joi.string().allow(null),
});

type FormType = {
  apiKey: string;
  serverPrefix: string;
  audienceId?: string;
};

const CreateIntegrationModal: React.FC<Props> = ({type}) => {
  const {closeModal} = useModal("CreateIntegrationModal");

  const toast = useToast();
  const createIntegration = useCreateIntegration();

  const [isLoading, setLoading] = useState(false);

  const {registerWithError, handleSubmit} = useForm<FormType>(FormSchema, {
    keepDataOnSuccess: true,
    defaultValues: {apiKey: "", serverPrefix: "", audienceId: null},
  });

  const handleFormSubmit: OnFormSubmitType<FormType> = async (data) => {
    setLoading(true);

    const res = await createIntegration({
      type: type,
      api_key: data.apiKey,
      server_prefix: data.serverPrefix,
      audience_id: data.audienceId,
    });

    if (!("data" in res && res.data.id)) {
      toast({
        title: "Ohh no!",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    closeModal();
  };

  return (
    <Box p="1.5rem" w="100%">
      <Heading w="100%" textAlign="center" as="h2" color="#141A4E" mb="2rem">
        Create a new integration
      </Heading>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="1rem">
          <Input
            w="100%"
            label="API key*"
            leftContent={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7506 13.9978C18.9926 13.9978 19.9995 15.0046 19.9995 16.2467V16.8221C19.9995 17.7164 19.6799 18.5812 19.0984 19.2606C17.529 21.0941 15.1418 21.9988 11.9964 21.9988C8.8506 21.9988 6.46459 21.0938 4.89828 19.2595C4.31852 18.5806 4 17.7171 4 16.8243V16.2467C4 15.0046 5.00686 13.9978 6.24888 13.9978H17.7506ZM17.7506 15.4978H6.24888C5.83529 15.4978 5.5 15.8331 5.5 16.2467V16.8243C5.5 17.36 5.69111 17.878 6.03897 18.2854C7.29228 19.753 9.25816 20.4988 11.9964 20.4988C14.7347 20.4988 16.7023 19.753 17.9588 18.2852C18.3077 17.8776 18.4995 17.3586 18.4995 16.8221V16.2467C18.4995 15.8331 18.1642 15.4978 17.7506 15.4978ZM11.9964 2.00244C14.7578 2.00244 16.9964 4.24102 16.9964 7.00244C16.9964 9.76387 14.7578 12.0025 11.9964 12.0025C9.23504 12.0025 6.99646 9.76387 6.99646 7.00244C6.99646 4.24102 9.23504 2.00244 11.9964 2.00244ZM11.9964 3.50244C10.0634 3.50244 8.49646 5.06945 8.49646 7.00244C8.49646 8.93544 10.0634 10.5025 11.9964 10.5025C13.9294 10.5025 15.4964 8.93544 15.4964 7.00244C15.4964 5.06945 13.9294 3.50244 11.9964 3.50244Z"
                  fill="#727CD4"
                />
              </svg>
            }
            labelColor="primary-2"
            placeholder="Enter your API key"
            {...registerWithError("apiKey")}
            noBorder
          />
          <Input
            w="100%"
            label="Server prefix*"
            labelColor="primary-2"
            placeholder="Enter mailchimp server prefix"
            {...registerWithError("serverPrefix")}
            noBorder
          />

          <Input
            w="100%"
            label="Audience ID"
            labelColor="primary-2"
            placeholder="Enter audience ID"
            {...registerWithError("audienceId")}
            noBorder
          />

          <Box py="1rem">
            <Button type="submit" w="100%" isLoading={isLoading}>
              Save
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export const useCreateIntegrationModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("CreateIntegrationModal", CreateIntegrationModal, {
    closeOnEsc: true,
    closeOnOverlayClick: true,
    hideCloseButton: false,
    size: "lg",
  });
