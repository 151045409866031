import {OrderStatusLabel, Link, Skeleton, Checkbox} from "@ef-org/components";

import React from "react";

import {useRouter} from "next/router";

import {Center, Flex, Grid, Icon, Stack, Text} from "@chakra-ui/react";

const OrderListItem: React.FC<{
  asSkeleton?: boolean;
  orderId: string;
  eventName: string;
  customer: string;
  date: string;
  value: number;
  currency: string;
  isPaid?: boolean;
}> = ({asSkeleton, isPaid, orderId, eventName, value, date, customer, currency}) => {
  const {pathname} = useRouter();

  return (
    <Grid
      py="0.5rem"
      px="2rem"
      borderRadius="12px"
      _hover={{bg: "gray.50"}}
      templateColumns="repeat(6,minmax(150px, 1fr))"
    >
      {asSkeleton ? (
        <>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
          <Center>
            <Skeleton h="25px" />
          </Center>
        </>
      ) : (
        <>
          <Stack direction="row" align="center">
              <Text color="#162184">{eventName}</Text>
          </Stack>
          <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
            <Text color="#162184">#{orderId}</Text>
          </Stack>
          <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
            <Text color="#162184">{customer}</Text>
          </Stack>

          <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
            <Text color="#162184">{date}</Text>
          </Stack>

          <Stack w="100%" direction="row" justify="center" align="center" spacing="0.5rem">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4356 21.036V2.85414H13.6004V21.036H12.4356ZM16.0791 8.49334C16.0033 7.82099 15.6908 7.30016 15.1416 6.93084C14.5924 6.55679 13.9011 6.36976 13.0677 6.36976C12.4711 6.36976 11.955 6.46446 11.5194 6.65385C11.0838 6.83851 10.7453 7.09419 10.5038 7.4209C10.2671 7.74287 10.1487 8.10982 10.1487 8.52175C10.1487 8.86739 10.2292 9.16569 10.3902 9.41664C10.5559 9.66758 10.7713 9.87828 11.0365 10.0487C11.3064 10.2145 11.5952 10.3541 11.903 10.4678C12.2107 10.5767 12.5067 10.6666 12.7907 10.7377L14.2112 11.107C14.6752 11.2206 15.1511 11.3745 15.6388 11.5686C16.1265 11.7628 16.5786 12.0184 16.9953 12.3357C17.412 12.6529 17.7481 13.0459 18.0038 13.5146C18.2642 13.9834 18.3944 14.5445 18.3944 15.1979C18.3944 16.0218 18.1814 16.7533 17.7552 17.3925C17.3338 18.0317 16.7207 18.536 15.9157 18.9053C15.1156 19.2746 14.1473 19.4593 13.0109 19.4593C11.9219 19.4593 10.9797 19.2864 10.1842 18.9408C9.38876 18.5951 8.76613 18.1051 8.31632 17.4706C7.86651 16.8314 7.61793 16.0738 7.57058 15.1979H9.77228C9.8149 15.7235 9.98535 16.1614 10.2836 16.5118C10.5867 16.8575 10.9726 17.1155 11.4413 17.286C11.9148 17.4517 12.4333 17.5345 12.9967 17.5345C13.617 17.5345 14.1686 17.4375 14.6515 17.2433C15.1392 17.0445 15.5228 16.7699 15.8021 16.4195C16.0815 16.0644 16.2211 15.6501 16.2211 15.1766C16.2211 14.7457 16.098 14.393 15.8518 14.1183C15.6104 13.8437 15.2813 13.6164 14.8646 13.4365C14.4527 13.2566 13.9863 13.098 13.4655 12.9607L11.7467 12.4919C10.5819 12.1747 9.65865 11.7083 8.97683 11.0928C8.29975 10.4772 7.9612 9.66285 7.9612 8.64959C7.9612 7.81152 8.18848 7.07999 8.64302 6.45499C9.09757 5.82999 9.7131 5.34467 10.4896 4.99902C11.2661 4.64864 12.1421 4.47345 13.1175 4.47345C14.1023 4.47345 14.9711 4.64628 15.724 4.99192C16.4816 5.33757 17.0782 5.81342 17.5138 6.41948C17.9494 7.0208 18.1766 7.71209 18.1956 8.49334H16.0791Z"
                fill="#727CD4"
              />
            </svg>

            <Text color="#162184">
              {value} {currency}
            </Text>
          </Stack>

          <Flex justifyContent="flex-end">
            <OrderStatusLabel isPaid={isPaid} />
          </Flex>
        </>
      )}
    </Grid>
  );
};

export default OrderListItem;
