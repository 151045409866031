import {Link} from "@ef-org/components";

import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {Container, Stack, Icon, Text, Box, Progress, Button} from "@chakra-ui/react";

import {useNewEventStepSubscriber, useNewEventStore} from "../newEventStore";

const Buttons = () => {
  const {data} = useNewEventStore();
  const {currentIndex, steps, currentStep} = useNewEventStepSubscriber();
  const dispatch = useDispatch();

  const {goBack, goNext} = useNewEventStore((p) => ({
    goBack: p.actions.goBack,
    goNext: p.actions.goNext,
  }));

  const [disabled, setDisabled] = useState(false);

  return (
    <Stack direction="row" align="center">
      <Button variant="neutral" isDisabled={currentIndex <= 0} onClick={goBack}>
        Back
      </Button>

      {currentIndex < steps.length && (
        <Button
          onClick={async () => {
            await goNext({dispatch});
          }}
        >
          Next
        </Button>
      )}
    </Stack>
  );
};

const StepBanner = () => {
  const setNewStep = useNewEventStore((p) => p.actions.setNewStep);
  const {allowedStepsBack, currentIndex, steps} = useNewEventStepSubscriber();

  return (
    <Box position="fixed" bottom="0" bg="light-blue" w="100%" zIndex="101">
      <Progress
        value={(currentIndex / steps.length) * 100}
        h={["4px", "3px", "2px"]}
        bgColor="#C9CDEE"
        // eslint-disable-next-line react/forbid-component-props
        css={{div: {backgroundColor: "#1F2FBA"}}}
      />
      <Container maxW="1624" py="1rem">
        <Stack w="100%" direction="row" align="center" justify="space-between">
          <Box display={["none", "none", "none", "block"]}>
            <Link href="/dashboard" variant="switchedDecoration">
              <Stack direction="row" align="center">
                <Icon
                  width="19px"
                  height="19px"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5664 9.53882H4.06641"
                    stroke="#1F2FBA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.31641 14.7888L4.06641 9.53882L9.31641 4.28882"
                    stroke="#1F2FBA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Icon>
                <Text fontWeight="bold">Home Dashboard</Text>
              </Stack>
            </Link>
          </Box>

          <Box>
            <Box display={["block", "block", "none"]}>
              <Stack direction="row" align="center">
                <Link href="/dashboard" variant="switchedDecoration">
                  <Stack
                    bg="white"
                    p="10px 14px"
                    borderRadius="12px"
                    direction="row"
                    align="center"
                    border="2px"
                    borderColor="white"
                    cursor="pointer"
                    _hover={{borderColor: "#1F2FBA"}}
                  >
                    <Icon
                      width="23px"
                      height="25px"
                      viewBox="0 0 23 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0307 1.19122C11.0623 0.32135 12.5706 0.32135 13.6021 1.19123L21.912 8.19874C22.5361 8.72506 22.8963 9.49989 22.8963 10.3163V22.3844C22.8963 23.5743 21.9317 24.5388 20.7419 24.5388H16.433C15.2432 24.5388 14.2786 23.5743 14.2786 22.3844V15.6134C14.2786 15.4435 14.1409 15.3056 13.9708 15.3056H9.66201C9.492 15.3056 9.35422 15.4435 9.35422 15.6134V22.3844C9.35422 23.5743 8.38967 24.5388 7.19982 24.5388H2.89099C1.70114 24.5388 0.736572 23.5743 0.736572 22.3844V10.3163C0.736572 9.49989 1.09673 8.72506 1.72086 8.19874L10.0307 1.19122ZM12.4117 2.60293C12.0678 2.31297 11.565 2.31297 11.2212 2.60293L2.91131 9.61045C2.70327 9.78588 2.58321 10.0442 2.58321 10.3163V22.3844C2.58321 22.5544 2.72101 22.6922 2.89099 22.6922H7.19982C7.3698 22.6922 7.50758 22.5544 7.50758 22.3844V15.6134C7.50758 14.4235 8.47216 13.459 9.66201 13.459H13.9708C15.1607 13.459 16.1253 14.4235 16.1253 15.6134V22.3844C16.1253 22.5544 16.263 22.6922 16.433 22.6922H20.7419C20.9119 22.6922 21.0496 22.5544 21.0496 22.3844V10.3163C21.0496 10.0442 20.9296 9.78588 20.7215 9.61045L12.4117 2.60293Z"
                        fill="#727CD4"
                      />
                    </Icon>
                  </Stack>
                </Link>

                <Stack
                  bg="white"
                  p="10px 14px"
                  borderRadius="12px"
                  direction="row"
                  align="center"
                  border="2px"
                  borderColor="white"
                  cursor="pointer"
                  spacing="5px"
                  _hover={{borderColor: "#1F2FBA"}}
                >
                  <Text fontWeight="bold">{currentIndex + 1}</Text>
                  <Text fontWeight="bold" color="primary-2">
                    /
                  </Text>
                  <Text fontWeight="bold" color="primary-2">
                    {steps.length}
                  </Text>
                </Stack>
              </Stack>
            </Box>

            <Box display={["none", "none", "block"]}>
              <Stack direction="row" align="center" spacing="1rem">
                {steps.map((x, i) =>
                  allowedStepsBack.includes(x.id) ? (
                    <Stack
                      key={i}
                      bg="white"
                      p="10px 14px"
                      borderRadius="12px"
                      direction="row"
                      align="center"
                      border="2px"
                      borderColor="white"
                      cursor="pointer"
                      onClick={() => setNewStep(x.id)}
                      _hover={{borderColor: "#1F2FBA"}}
                    >
                      <Stack
                        bg="#E9EAF8"
                        boxSize="27px"
                        borderRadius="20px"
                        align="center"
                        justify="center"
                      >
                        {i !== currentIndex ? (
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.4001 8.39998C17.8687 8.86861 17.8687 9.62845 17.4001 10.097L11.6486 15.8485C11.18 16.3171 10.4202 16.3171 9.9516 15.8485L7.20012 13.097C6.73149 12.6284 6.73149 11.8686 7.20012 11.4C7.66875 10.9314 8.42854 10.9314 8.89718 11.4L10.8001 13.303L15.7031 8.39998C15.7616 8.3414 15.8248 8.29015 15.8914 8.24621C16.3571 7.93868 16.9901 7.98993 17.4001 8.39998Z"
                              fill="#1F2FBA"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69806 21.6 2.4 17.302 2.4 12C2.4 6.69806 6.69806 2.4 12 2.4C17.302 2.4 21.6 6.69806 21.6 12Z"
                              fill="#1F2FBA"
                            />
                          </svg>
                        ) : (
                          <Text fontWeight="bold" lineHeight="27px" textAlign="center">
                            {i + 1}
                          </Text>
                        )}
                      </Stack>

                      <Text fontWeight="bold">{x.name}</Text>
                    </Stack>
                  ) : (
                    <Stack key={i} p="12px 16px" direction="row" align="center" opacity="20%">
                      <Text
                        fontWeight="bold"
                        bg="#E9EAF8"
                        boxSize="27px"
                        lineHeight="27px"
                        textAlign="center"
                        borderRadius="20px"
                      >
                        {i + 1}
                      </Text>
                      <Text fontWeight="bold">{x.name}</Text>
                    </Stack>
                  )
                )}
              </Stack>
            </Box>
          </Box>

          <Buttons />
        </Stack>
      </Container>
    </Box>
  );
};

export default StepBanner;
