import {
  beNftOrgApi,
  BillingMethodCreate,
  EventUpdate,
  IntegrationCreate,
  InvitationCreate,
  OrganizerStatsPost,
  OrganizerUpdate,
  useCreateOrganizersOrganizerIdInvitationsPostMutation,
  useDeleteEventOrganizersOrganizerIdEventsEventIdDeleteMutation,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostMutation,
} from "@ef-org/api";
import Cookies from "js-cookie";

import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {
  enhanceApis,
  useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation,
  useUpdateEventOrganizersOrganizerIdEventsEventIdPatchMutation,
  useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation,
  useCreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostMutation,
  useDeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteMutation,
} from "../api/__generated__/beOrgApi.extended";
import {useNewEventStore} from "../scenes/NewEvent/newEventStore";

export const useSelectNFTEnums = () => {
  return useSelector(beNftOrgApi.endpoints.getNftappApiEnums.select());
};

export const useGetPhotosForEventByEventIdSelector = () => {
  const {query} = useRouter();
  return useSelector(
    enhanceApis.endpoints.getEventImagesEventsEventIdImagesGet.select({
      eventId: (query?.eventId as string) || "",
    })
  );
};

export const useGetFeaturedEventPhotosSelector = (props?: {featured?: boolean}) => {
  const {data} = useGetPhotosForEventByEventIdSelector();

  return (data?.items || []).filter((item) =>
    props?.featured ? item.is_featured : !item.is_featured
  );
};

export const useUpdateEvent = () => {
  const {query} = useRouter();

  const [updateEvent] = useUpdateEventOrganizersOrganizerIdEventsEventIdPatchMutation();
  const organizerId = Cookies.get("organizerId") ?? "";
  return async (eventUpdate: EventUpdate) => {
    const res = await updateEvent({
      eventId: query?.eventId as string,
      organizerId,
      eventUpdate,
    });
    if ("error" in res && "data" in res.error) {
      useNewEventStore.setState({errorMessage: res.error.data["error"].message ?? "Unknown error"});
    }
    return res;
  };
};

export const useGetEventDetails = () => {
  const {query} = useRouter();
  const organizerId = Cookies.get("organizerId");
  return useSelector(
    enhanceApis.endpoints.getEventOrganizersOrganizerIdEventsEventIdGet.select({
      eventId: query?.eventId as string,
      organizerId,
    })
  );
};

export const useGetOrganiserDetails = () => {
  const userId = Cookies.get("userId") ?? "";
  const organizerId = Cookies.get("organizerId") ?? Cookies.get("teamOwnerId") ?? "";
  return useSelector(
    enhanceApis.endpoints.getOrganizerUsersUserIdOrganizersOrganizerIdGet.select({
      userId,
      organizerId,
    })
  );
};

export const useUpdateOrganiser = () => {
  const [updateOrganiser] = useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation();
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId");
  return async (organizerUpdate: OrganizerUpdate) => {
    await updateOrganiser({
      userId,
      organizerId,
      organizerUpdate,
    });
  };
};

export const useOrganizerStats = () => {
  const [getStats] = useGetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostMutation();
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId");
  return async (organizerStatsPost: OrganizerStatsPost) => {
    return await getStats({
      userId,
      organizerId,
      organizerStatsPost,
    });
  };
};

export const useCreateBillingMethod = () => {
  const [create] =
    useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation();
  const organizerId = Cookies.get("organizerId");
  return async (billingMethodCreate: BillingMethodCreate) => {
    return await create({
      organizerId,
      billingMethodCreate,
    });
  };
};

export const useCreateIntegration = () => {
  const [create] = useCreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostMutation();
  const organizerId = Cookies.get("organizerId");
  return async (integrationCreate: IntegrationCreate) => {
    return await create({
      organizerId,
      integrationCreate,
    });
  };
};

export const useDeleteIntegration = () => {
  const [remove] =
    useDeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteMutation();
  const organizerId = Cookies.get("organizerId");
  return async (integrationId: string) => {
    return await remove({
      organizerId,
      integrationId,
    });
  };
};

export const useGetActiveSubscription = () => {
  const organizerId = Cookies.get("organizerId") ?? "";
  return useSelector(
    enhanceApis.endpoints.getOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGet.select({
      organizerId,
    })
  );
};

export const useDeleteEvent = () => {
  const [remove] = useDeleteEventOrganizersOrganizerIdEventsEventIdDeleteMutation();
  const organizerId = Cookies.get("organizerId");
  return async (eventId: string) => {
    return await remove({
      organizerId,
      eventId,
    });
  };
};

export const useInviteTeamMember = () => {
  const [invite] = useCreateOrganizersOrganizerIdInvitationsPostMutation();
  const organizerId = Cookies.get("organizerId");
  const userId = Cookies.get("userId") ?? "";
  return async (invitationCreate: InvitationCreate) => {
    return await invite({
      organizerId,
      invitationCreate,
    });
  };
};
