import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

import RegisterForm from "./components/RegisterForm";

const RegisterPage: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}>
      <RegisterForm />
    </GoogleReCaptchaProvider>
  );
};

export default RegisterPage;
