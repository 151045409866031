import {
  usePostNftappApiAccountByAccountIdStyleMutation,
  usePostNftappApiAccountByAccountIdTemplateMutation,
} from "@ef-org/api";
import {Link} from "@ef-org/components";
import {useErrorModal, useNFTPublishedModal} from "@ef-org/modals";
// import {useStrictAuth} from "@ef-org/providers";
import {omit} from "ramda";

import React from "react";

import {useRouter} from "next/router";

import {Icon} from "@chakra-ui/icons";
import {
  Box,
  Container,
  ContainerProps,
  Divider,
  Button,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import {useNewNFTBuilder, useNFTBuilder} from "../../scenes/NFTBuilderPage/hooks/useNFTBuilder";
import NFTEditableName from "./components/NFTEditableName";

const SharedContainer: React.FC<ContainerProps> = ({children, ...rest}) => {
  return (
    <Container maxW="1400px" h="100%" {...rest}>
      {children}
    </Container>
  );
};

const PublishNFTButton = () => {
  const builder = useNFTBuilder();
  const builder2 = useNewNFTBuilder();
  // const {user} = useStrictAuth();
  const {openModal} = useNFTPublishedModal();
  const {push, query} = useRouter();
  const [createNft] = usePostNftappApiAccountByAccountIdStyleMutation();
  const [uploadTemplate] = usePostNftappApiAccountByAccountIdTemplateMutation();
  const {openModal: openErrorModal} = useErrorModal();

  // eslint-disable-next-line no-console
  console.log("builder", builder2);

  const handleSubmitNft = async () => {
    const handleNFTCreateResponse = (res: any, opts: {localNftPreview?: string} = {}) => {
      if ("data" in res && res?.data?.url) {
        // eslint-disable-next-line no-console
        console.log("res?.data", res?.data);
        openModal({
          nftUrl: res?.data?.url_preview || "",
          nftName: builder.nftName,
          nftPreview: opts?.localNftPreview,
          onClose: () => {
            if (query.iframe) {
              const windowOrigin = window.opener || window.parent;
              windowOrigin.postMessage("styler_close", "*");
              return;
            }
            push("/my-wallet/");
          },
        });
      } else {
        openErrorModal({message: "We couldn't create your NFT."});
      }
    };

    const getHeightAndWidthFromDataUrl = (file): Promise<{width: number; height: number}> =>
      new Promise((resolve) => {
        if (!file) {
          return resolve({height: 0, width: 0});
        }

        const fr = new FileReader();

        fr.onload = function () {
          const img = new Image();

          img.onload = function () {
            resolve({
              height: img.height,
              width: img.width,
            });
          };

          //@ts-expect-error fix
          img.src = fr.result;
        };

        fr.readAsDataURL(file);
      });

    if (builder2.selectedWorkspace === "2d" && builder2.selectedImageType === "custom-image") {
      const fragments: Array<any> = [];
      const imageSize = await getHeightAndWidthFromDataUrl(builder2?.selected2dOwnImageFile);

      const data = new FormData();

      data.append(
        "file",
        builder2.custom2dOwnImageStyles.croppedImage || builder2.selected2dOwnImageFile
      );

      const res = await uploadTemplate({
        accountId: "user.last_account_id",
        // @ts-ignore ignore this type...
        data,
      });

      if ("data" in res && res.data.id) {
        fragments.push({
          kind: "template",
          value: res.data.id,
          coords: [0, 0, imageSize.width, imageSize.height],
        });
      } else {
        // eslint-disable-next-line no-console
        console.log("NFT: ", res);

        openErrorModal({
          message: "we couldn't create your NFT.",
          smallText: "Unable to upload your image.",
        });

        return;
      }

      const createRes = await createNft({
        accountId: "user?.last_account_id",
        stylePayloadAf2A349: {
          fragments,
          mimetype: "image/png",
          name: builder.nftName,
          size: [imageSize.width, imageSize.height],
        },
      });

      return handleNFTCreateResponse(createRes);
    }

    if (builder2.selectedImageType === "from-template") {
      const fragments: Array<any> = [];

      for (const frag of builder2.selectedStyle.fragments) {
        const newFrag = omit(["id", "__orgValue", "__templateUploadedFile", "__orgProps"], frag);

        if (frag.__templateUploadedFile) {
          const data = new FormData();
          data.append("file", frag.__crop.croppedImage || frag.__templateUploadedFile);

          const res = await uploadTemplate({
            accountId: "user.last_account_id",
            // @ts-ignore ignore this type...
            data,
          });

          if ("data" in res && res.data.id) {
            fragments.push({...newFrag, value: res.data.id});
          } else {
            // eslint-disable-next-line no-console
            console.log("NFT: ", res);

            openErrorModal({
              message: "we couldn't create your NFT.",
              smallText: "Unable to upload your image.",
            });

            return;
          }
        } else {
          fragments.push(newFrag);
        }
      }

      const createRes = await createNft({
        accountId: "user?.last_account_id",
        stylePayloadAf2A349: {
          fragments,
          mimetype: builder2.selectedStyle.mimetype,
          name: builder.nftName,
          size: builder2.selectedStyle?.size || [],
        },
      });

      return handleNFTCreateResponse(createRes);
    }

    if (builder2.selectedWorkspace === "3d") {
      if (builder2.selectedImageType === "custom-image") {
        const fragments: Array<any> = [];

        const data = new FormData();
        data.append("file", builder2.selected3dOwnImageSpace.file);

        const res = await uploadTemplate({
          accountId: "user.last_account_id",
          // @ts-ignore ignore this type...
          data,
        });

        if ("data" in res && res.data.id) {
          fragments.push({
            kind: "template",
            value: res.data.id,
            coords: [0, 0, 800, 800],
          });
        } else {
          // eslint-disable-next-line no-console
          console.log("NFT: ", res);

          openErrorModal({
            message: "we couldn't create your NFT.",
            smallText: "Unable to upload your image.",
          });

          return;
        }

        const createRes = await createNft({
          accountId: "user?.last_account_id",
          stylePayloadAf2A349: {
            fragments,
            mimetype: "model/gltf+json",
            name: builder.nftName,
            size: [800, 800],
          },
        });

        return handleNFTCreateResponse(createRes, {
          localNftPreview: builder2.selected3dOwnImageSpace.file.preview,
        });
      }
    }

    // if (builder.backgroundActiveVariant === "image" && builder.nftBackgroundImage) {
    //   // const file = document.getElementById("LAYOUT_UPLOAD_SECTION").querySelectorAll("input");
    //   // console.log("file", file);

    //   // return;
    //   const data = new FormData();
    //   data.append("file", builder.nftBackgroundImage);

    //   const res = await uploadTemplate({
    //     accountId: "user.last_account_id",
    //     data,
    //   });

    //   if ("data" in res && res.data.id) {
    //     fragments.push({
    //       kind: "template",
    //       value: res.data.id,
    //       coords: [0, 0, 800, 800],
    //     });
    //   } else {
    //     // eslint-disable-next-line no-console
    //     console.log("NFT: ", res);
    //     toast({
    //       title: "Oops!",
    //       description: "Something went wrong and we couldn't create your NFT.",
    //       status: "error",
    //       duration: 7000,
    //       isClosable: true,
    //     });
    //     return;
    //   }
    // }
  };

  const isButtonEnabled = React.useMemo(() => {
    if (builder2.selectedWorkspace === "3d" && builder2.selectedImageType === "custom-image") {
      return !Boolean(builder2.selected3dOwnImageSpace?.file?.preview);
    }

    return (
      (builder2.selectedImageType === "custom-image" && !builder2.selected2dOwnImageFile) ||
      (builder2.selectedImageType === "from-template" && !builder2.isStyleChanged)
    );
  }, [builder2]);

  return (
    <Button
      w={["100%", "100%", "fit-content"]}
      isDisabled={isButtonEnabled}
      onClick={handleSubmitNft}
    >
      Publish NFT
    </Button>
  );
};

const NFTLayout: React.FC<React.PropsWithChildren<{isDetail?: boolean}>> = ({
  children,
  isDetail,
}) => {
  return (
    <Stack justify="space-between" h="100%" spacing="0">
      <Box
        borderBottom="1px"
        h="80px"
        minH="80px"
        maxH="80px"
        bg="ef-white"
        borderBottomColor="ef-divider"
      >
        <SharedContainer>
          <Stack h="100%" direction="row" align="center" justify="space-between">
            <Stack direction="row" align="center" h="inherit" spacing="1.5rem">
              <Icon width="30" height="22" viewBox="0 0 30 22" fill="none">
                <g clipPath="url(#clip0_2036_35557)">
                  <path
                    d="M4.20435 0.5H0V4.70393V8.90786V13.0921V17.2961V21.5H4.20435H8.40869H12.5934V17.2961H8.40869H4.20435V13.0921V8.90786V4.70393H8.40869H12.5934V0.5H8.40869H4.20435Z"
                    fill="#141A4E"
                  />
                  <path
                    d="M25.2065 0.5H21.0022H16.7979V4.70393H21.0022H25.2065V8.90786V13.0921V17.2961V21.5H29.4109V17.2961V13.0921V8.90786V4.70393V0.5H25.2065Z"
                    fill="#141A4E"
                  />
                  <path d="M12.6135 8.9082H8.40918V13.1121H12.6135V8.9082Z" fill="#141A4E" />
                  <path d="M21.0022 8.9082H16.7979V13.1121H21.0022V8.9082Z" fill="#141A4E" />
                </g>
                <defs>
                  <clipPath id="clip0_2036_35557">
                    <rect width="29.1667" height="21" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </Icon>

              <Heading whiteSpace="nowrap" variant="h4" lineHeight="0" pt="5px" color="#141A4E">
                NFT Creator
              </Heading>
              {isDetail && (
                <>
                  <Divider h="50%" orientation="vertical" />
                  <Box pt="5px">
                    <NFTEditableName />
                  </Box>
                </>
              )}
            </Stack>

            {/* {!query?.iframe && (
              <Link href={isDetail ? "/nfts" : "/my-wallet"} variant="black">
                <CloseIcon />
              </Link>
            )} */}
          </Stack>
        </SharedContainer>
      </Box>
      <Box h="calc(70vh - 160px)">
        <SharedContainer>{children}</SharedContainer>
      </Box>
      <Box
        borderTop="1px"
        h="80px"
        minH="80px"
        maxH="80px"
        bg="ef-white"
        borderTopColor="ef-divider"
      >
        <SharedContainer>
          <Stack
            h="100%"
            direction={["column", "column", "row"]}
            align="center"
            justify="space-between"
            spacing={["1rem", "1rem", "0"]}
          >
            <Link href="/" variant="blackWithNoDecoration" color="#141A4E">
              <Stack direction="row" align="center">
                <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#141A4E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.08984 9.00057C9.32495 8.33224 9.789 7.76868 10.3998 7.4097C11.0106 7.05073 11.7287 6.91951 12.427 7.03928C13.1253 7.15906 13.7587 7.52209 14.2149 8.0641C14.6712 8.6061 14.9209 9.29209 14.9198 10.0006C14.9198 12.0006 11.9198 13.0006 11.9198 13.0006"
                    stroke="#141A4E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17H12.01"
                    stroke="#141A4E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Icon>
                <Text color="inherit">Need help?</Text>
              </Stack>
            </Link>
            {isDetail && (
              <Stack
                direction={["column", "column", "row"]}
                align="center"
                spacing={["0.5rem", "0.5rem", "1.5rem"]}
                w={["100%", "100%", "fit-content"]}
              >
                {/* <ButtonLink
                  href="/nfts/"
                  variant="ghost"
                  bg="ef-white"
                  color="ef-black"
                  border="2px"
                  borderColor="ef-border"
                  w={["100%", "100%", "fit-content"]}
                >
                  Save draft
                </ButtonLink> */}
                <PublishNFTButton />
              </Stack>
            )}
          </Stack>
        </SharedContainer>
      </Box>
    </Stack>
  );
};

export default NFTLayout;
