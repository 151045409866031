import {IntegrationType} from "@ef-org/api";
import {IconCheck, IconXMark} from "@ef-org/icons";
import {useGetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetQuery} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import {useDeleteIntegration} from "apps/ef-org/src/hooks/useSelectors";
import {useCreateIntegrationModal} from "apps/ef-org/src/modals/useCreateEmailIntegrationModal";
import Cookies from "js-cookie";

import React, {useEffect, useState} from "react";

import {AspectRatio, Box, Button, Image, Grid, Heading, Stack, Text} from "@chakra-ui/react";

import {MailchimpContent} from "./MailchimpContent";
import {ZapierContent} from "./ZapierContent";

const integrationTypes = {
  mail_chimp: "MailChimp",
  zapier: "Zapier",
} as const;

export interface IntegrationItemProps {
  type: IntegrationType;
  id?: string;
  apiKey?: string;
}

const IntegrationItem: React.FC<IntegrationItemProps> = ({type, id, apiKey}) => {
  const isAuthorized = id != null && apiKey != null;
  const name = integrationTypes[type];

  return (
    <Box height="300px" width="250px">
      <Stack
        direction="row"
        borderTopRadius="16px"
        align="center"
        p="0.5rem 0rem"
        justify="center"
        bg={!id ? "#E9EAF8" : "#727CD4"}
        h="20%"
      >
        {!isAuthorized ? <IconXMark /> : <IconCheck />}
        <Text>{!isAuthorized ? "Unauthorized" : "Authorized"}</Text>
      </Stack>
      <Box
        h="80%"
        border="1px solid"
        p="1rem"
        borderBottomRadius="16px"
        bg="white"
        borderColor={!isAuthorized ? "#E9EAF8" : "#727CD4"}
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box py="0.5rem" justifyContent="center" display="flex">
          <Image w="150px" src={`/images/integrations/${name.toLowerCase()}.png`} />
        </Box>

        {/* <Text textAlign="center" w="100%" mb="1rem" fontWeight="bold">
          {name}
        </Text> */}

        {type === "mail_chimp" ? (
          <MailchimpContent id={id} isAuthorized={isAuthorized} />
        ) : (
          <ZapierContent id={id} isAuthorized={isAuthorized} apiKey={apiKey} />
        )}
      </Box>
    </Box>
  );
};

const EmailIntegrations = () => {
  const organizerId = Cookies.get("organizerId");
  const [integrations, setIntegrations] = useState<IntegrationItemProps[]>([]);

  const {data: integrationsData} =
    useGetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetQuery({
      organizerId,
    });

  useEffect(() => {
    let integrationProps: IntegrationItemProps[] = [];

    Object.keys(integrationTypes).forEach((key) => {
      const integration = integrationsData?.items.find((item) => item.type === key);
      if (integration) {
        integrationProps.push({
          id: integration.id,
          type: integration.type,
          apiKey: integration.api_key,
        });
      } else {
        integrationProps.push({type: key as IntegrationType});
      }
      setIntegrations(integrationProps);
    });
  }, [integrationsData]);

  return (
    <Box>
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Integrations
      </Heading>

      <Stack display="flex" gap="1rem" direction="row">
        {integrations.map((integration) => (
          <IntegrationItem
            key={integration.type}
            type={integration.type}
            id={integration.id}
            apiKey={integration.apiKey}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default EmailIntegrations;
