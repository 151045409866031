import {NFTCanvas} from "@ef-org/components";
import useScrollToId from "apps/ef-org/src/hooks/useScrollToId";
import {useEventDetailSelectorByQueryEventId} from "apps/ef-org/src/scenes/EventDetail/hooks/useEventDetailSelectorByQueryEventId";
import {isEmpty, take} from "ramda";

import React from "react";

import {AspectRatio, Box, Button, Container, Heading, Select, Stack, Text} from "@chakra-ui/react";

const Section: React.FC<{children?: React.ReactNode}> = ({children}) => {
  return (
    <Box
      h="fit-content"
      bg="white"
      w="100%"
      p="1rem"
      border="1px solid #E9EAF8"
      borderRadius="24px"
    >
      {children}
    </Box>
  );
};

const NewRightSection = () => {
  const {scroll} = useScrollToId();
  const {data} = useEventDetailSelectorByQueryEventId();

  if (!data) {
    return null;
  }

  if (isEmpty(data?.ticket_types || []) && isEmpty(data?.digital_merchandises || [])) {
    return null;
  }

  return (
    <Container p="0" h="fit-content" maxW="250px" position="sticky" top="1rem">
      <Stack spacing="2rem">
        {!isEmpty(data?.ticket_types || []) && (
          <Section>
            <Heading color="text-neutral-blue-10" as="h3" fontSize="18px" mt="0.5rem" mb="1rem">
              Select tickets
            </Heading>

            <Stack spacing="1rem">
              {take(3, data?.ticket_types).map((ticket) => (
                <Stack spacing="0rem" key={ticket.id} bg="#FAFAFD" borderRadius="16px" p="1rem">
                  <Text
                    as="h4"
                    fontWeight="600"
                    mb="0.5rem"
                    fontSize="16px"
                    color="text-neutral-blue-10"
                  >
                    {ticket.name}
                  </Text>

                  <Stack direction="row" align="center" justify="space-between">
                    <Text color="ef-black">
                      {ticket.price}&nbsp;{data.currency}
                    </Text>

                    <Select size="md" bg="white" borderRadius="12px" w="fit-content">
                      {new Array(ticket.maximum_order_quantity + 1).fill(0).map((_, quantity) => {
                        if (ticket.minimum_order_quantity > quantity && quantity !== 0) {
                          return null;
                        }

                        return (
                          <option key={quantity} value={quantity}>
                            {quantity}
                          </option>
                        );
                      })}
                    </Select>
                  </Stack>
                </Stack>
              ))}

              {data.ticket_types.length > 3 && (
                <Button
                  variant="neutral"
                  bg="#FAFAFD"
                  fontSize="14px"
                  w="100%"
                  onClick={() => {
                    scroll("ticket-preview");
                  }}
                >
                  Show {data.ticket_types.length - 3} more tickets
                </Button>
              )}
            </Stack>
          </Section>
        )}

        {!isEmpty(data?.digital_merchandises || []) && (
          <Section>
            <Heading color="text-neutral-blue-10" as="h3" fontSize="18px" mt="0.5rem" mb="1rem">
              Select digital merch
            </Heading>

            <Stack spacing="1rem">
              {data?.digital_merchandises.map((merch) => (
                <Stack key={merch.id} bg="#FAFAFD" borderRadius="16px" p="1rem" spacing="0.5rem">
                  <Text
                    as="h4"
                    fontWeight="600"
                    mb="0.5rem"
                    fontSize="16px"
                    color="text-neutral-blue-10"
                  >
                    {merch.name}
                  </Text>

                  <AspectRatio w="100%" ratio={1}>
                    <NFTCanvas nftAssetUrl="https://storage.googleapis.com/ef-nft-staging-uploads/6328309642166272.glb" />
                  </AspectRatio>

                  <Stack direction="row" align="center" justify="space-between">
                    <Text>
                      {merch.price} {data.currency}
                    </Text>

                    <Select size="md" bg="white" borderRadius="12px" w="fit-content">
                      {new Array(merch.maximum_order_quantity).fill(0).map((_, quantity) => {
                        if (1 > quantity && quantity !== 0) {
                          return null;
                        }

                        return (
                          <option key={quantity} value={quantity}>
                            {quantity}
                          </option>
                        );
                      })}
                    </Select>
                  </Stack>
                </Stack>
              ))}

              {data.digital_merchandises.length > 3 && (
                <Button
                  variant="neutral"
                  bg="#FAFAFD"
                  fontSize="14px"
                  w="100%"
                  onClick={() => {
                    scroll("merch-preview");
                  }}
                >
                  Show {data.ticket_types.length - 3} more merch
                </Button>
              )}
            </Stack>
          </Section>
        )}
      </Stack>
    </Container>
  );
};

export default NewRightSection;
