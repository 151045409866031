import {addAPIExtraHeaders} from "@ef-org/api";
import {IconMessage} from "@ef-org/icons";
import {isEmpty} from "ramda";

import {useState} from "react";

import {CloseIcon} from "@chakra-ui/icons";
import {Stack, Button, Text, Checkbox} from "@chakra-ui/react";

import {
  useGetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetQuery,
  useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery,
} from "../../api/__generated__/beOrgApi.extended";
import Link from "../../components/Link";
import {EventTable, EventTableItem} from "../../components/Tables";
import {getUserId} from "../../helpers/aws/auth";
import useCookie from "../../hooks/useCookie";
import {useSendCommunityMessageModal} from "../../modals/useSendCommunityMessage";
import FilterMenu from "./components/FilterMenu";
import {useStore} from "./components/FilterMenuStore";

function getEventsAttendedText(count: number) {
  if (count > 5) {
    return "5+ events";
  }
  if (count > 1) {
    return `${count} events`;
  }
  if (count === 1) {
    return "1 event";
  }
  return "No events";
}

function truncateName(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const CommunityListPage = () => {
  const {eventFilter, remove} = useStore();
  const {openModal: sendCommunityMessageModal} = useSendCommunityMessageModal();

  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const {data: attendees, isLoading} = useAttendeesDataFetcher(organizerId, eventFilter);
  const {data: events} = useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery(
    {organizerId},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );

  const [usersSelected, setUsersSelected] = useState<string[]>([]);

  const toggleUserSelection = (userEmail: string) => {
    if (usersSelected.includes(userEmail)) {
      setUsersSelected(usersSelected.filter((email) => email !== userEmail));
    } else {
      setUsersSelected([...usersSelected, userEmail]);
    }
  };

  const handleDownload = async () => {
    const response = await fetch(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/organizers/${organizerId}/events/${eventFilter[0]?.toString()}/attendees/csv`,
      {
        headers: await addAPIExtraHeaders(new Headers()),
      }
    );

    if (response.status !== 200) {
      console.error(response.status, response.statusText);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "attendees.csv";
    link.click();
  };

  if (isLoading || attendees == null) return null;

  return isEmpty(eventFilter) && isEmpty(attendees) ? (
    <Stack align="center" spacing="2rem" p="2rem" bg="white" borderRadius="18px">
      <Text textAlign="center" color="#727CD4">
        You don’t have any attendees in your community yet.
        <br />
        Try to create <b>regular event</b> or
        <b> experience</b> to attract your first guests !
      </Text>
      <Link href="/event/new">
        <Button
          leftIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0002 20.2493C11.8781 20.2493 11.7759 20.1615 11.7543 20.0461L11.75 19.9824L11.748 12.7499L11.7479 12.25H11.248H3.99902C3.86095 12.25 3.74902 12.1381 3.74902 12C3.74902 11.8781 3.83666 11.7759 3.95221 11.7542L4.01571 11.75H11.248H11.7481L11.748 11.2499L11.7468 4.00007V4.00003C11.7468 3.86187 11.8588 3.75 11.9966 3.75C12.1188 3.75 12.2209 3.83779 12.2425 3.95323L12.2468 4.01682L12.248 11.2501L12.2481 11.75H12.748H20.0011C20.1392 11.75 20.2511 11.8619 20.2511 12C20.2511 12.1219 20.1635 12.2241 20.0479 12.2458L19.9844 12.25H12.748H12.2479L12.248 12.7501L12.25 19.9992C12.2501 20.1374 12.138 20.2493 12.0002 20.2493Z"
                fill="#727CD4"
                stroke="white"
              />
            </svg>
          }
        >
          Create regular event
        </Button>
      </Link>
    </Stack>
  ) : (
    <Stack spacing={6}>
      <Stack direction="row" justify="space-between" align="center">
        <FilterMenu events={events} />
        <Stack direction="row" spacing="1rem">
          <Button
            onClick={() =>
              sendCommunityMessageModal({
                userEmails: isEmpty(usersSelected) ? attendees.map((x) => x.email) : usersSelected,
              })
            }
            size="md"
            leftIcon={<IconMessage boxSize="20px" />}
            isDisabled={isEmpty(usersSelected) && isEmpty(attendees)}
          >
            Send message
          </Button>

          <Button
            onClick={handleDownload}
            isDisabled={eventFilter.length > 1 || isEmpty(eventFilter)}
            size="md"
          >
            Download CSV
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Text fontWeight="bold" textAlign="center" fontSize="24px">
          Total attendees: {attendees.length}
        </Text>
        {eventFilter.map((eventId) => (
          <Stack
            key={eventId}
            w="fit-content"
            justify="center"
            direction="row"
            align="center"
            spacing="0.5rem"
            py="0.3rem"
            px="1rem"
            h="fit-content"
            borderRadius="12px"
            bg="ob-blue-100"
          >
            <Text align="center">
              {truncateName(events?.items.find((event) => event.id === eventId)?.name, 15)}
            </Text>
            <CloseIcon cursor="pointer" boxSize="12px" onClick={() => remove(eventId)} />
          </Stack>
        ))}
      </Stack>

      <EventTable
        colsSkeletonNumber={3}
        templateColumns="repeat(3,minmax(150px, 1fr))"
        headerComponents={
          <>
            <Text>Attendee name</Text>
            <Text>Events</Text>
            <Stack w="100%" direction="row" align="center" justifyContent="flex-end">
              <Checkbox
                isChecked={usersSelected.length === attendees.length}
                onChange={(e) =>
                  setUsersSelected(e.target.checked ? attendees.map((x) => x.email) : [])
                }
              />
            </Stack>
          </>
        }
        bodyComponents={
          <>
            {attendees.map((attendee, i) => (
              <EventTableItem
                key={attendee.user_id}
                noHover
                templateColumns="repeat(3, minmax(150px, 1fr))"
              >
                <Stack direction="row" align="center">
                  <Text color="#4754C6">
                    {attendee.first_name} {attendee.last_name}
                  </Text>
                </Stack>
                <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
                  <Text
                    py="0.3rem"
                    px="1rem"
                    h="fit-content"
                    w="7rem"
                    borderRadius="12px"
                    bg="ob-blue-100"
                    align="center"
                  >
                    {getEventsAttendedText(attendee.events_attended)}
                  </Text>
                </Stack>
                <Stack w="100%" direction="row" align="center" justifyContent="flex-end">
                  <Checkbox
                    onChange={() => toggleUserSelection(attendee.email)}
                    isChecked={usersSelected.includes(attendee.email)}
                  />
                </Stack>
              </EventTableItem>
            ))}
          </>
        }
      />
    </Stack>
  );
};

const useAttendeesDataFetcher = (organizerId: string, eventFilter?: string[]) =>
  useGetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetQuery(
    {organizerId, userId: getUserId(), eventFilter},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );
