import {Box, Heading, Stack, Image, Button, Text, Center, ImageProps} from "@chakra-ui/react";

import Link from "../../components/Link";

const items = [
  {
    title: "Quick & User-Friendly Event Setup",
    description: "Organize events with ease and customizable control",
  },
  {
    title: "Revenue-Boosting Digital Merch & Smart Tickets (coming soon)",
    description: "Enhance profits and attendee engagement",
  },
  {
    title: "AI-Generated Images & Descriptions (coming soon)",
    description: "Craft unique and stylish content effortlessly",
  },
];

const GlobeImage: React.FC<{display?: ImageProps["display"]; maxW?: ImageProps["maxW"]}> = ({
  display,
  maxW = "300px",
}) => {
  return (
    <Image
      display={display}
      maxW={maxW}
      src="/images/illustrations/glossy-globe.png"
      h="fit-content"
      alt="globe"
      objectFit="contain"
    />
  );
};

const EventsPromo = () => {
  return (
    <Stack
      w="100%"
      align="space-between"
      direction="row"
      justify="center"
      background="linear-gradient(95.74deg, #2F3BA0 27.06%, #4754C6 87.33%)"
      p="2rem"
      borderRadius="24px"
    >
      <Box w="100%">
        <Stack direction="row" spacing="1rem" align={["center", null, null]}>
          <GlobeImage maxW="100px" display={["block", "none", "none"]} />
          <Heading color="white" fontSize="24px" as="h2">
            Maximize Your Event Impact
          </Heading>
        </Stack>

        <Stack
          my="2rem"
          background="linear-gradient(101.93deg, #4754C6 -89.4%, rgba(71, 84, 198, 0) 95.6%)"
          p="2rem"
          borderRadius="24px"
          spacing="2rem"
        >
          {items.map((x, i) => (
            <Stack key={i} direction="row" spacing="1rem">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9982 1.99805C13.2019 1.99805 14.3559 2.21068 15.4248 2.60043L14.231 3.79427C13.5195 3.60113 12.7709 3.49805 11.9982 3.49805C7.30261 3.49805 3.49609 7.30457 3.49609 12.0002C3.49609 16.6958 7.30261 20.5023 11.9982 20.5023C16.6938 20.5023 20.5003 16.6958 20.5003 12.0002C20.5003 11.2286 20.3975 10.4809 20.2049 9.77024L21.3989 8.57624C21.788 9.64435 22.0003 10.7975 22.0003 12.0002C22.0003 17.5242 17.5222 22.0023 11.9982 22.0023C6.47419 22.0023 1.99609 17.5242 1.99609 12.0002C1.99609 6.47615 6.47419 1.99805 11.9982 1.99805ZM11.9982 6.00016C12.5168 6.00016 13.02 6.06596 13.5 6.18967L13.5001 7.7569C13.0304 7.59064 12.5248 7.50016 11.9982 7.50016C9.51289 7.50016 7.49817 9.51488 7.49817 12.0002C7.49817 14.4855 9.51289 16.5002 11.9982 16.5002C14.4834 16.5002 16.4982 14.4855 16.4982 12.0002C16.4982 11.4742 16.4079 10.9694 16.2421 10.5002H17.8091C17.9325 10.9796 17.9982 11.4823 17.9982 12.0002C17.9982 15.3139 15.3119 18.0002 11.9982 18.0002C8.68446 18.0002 5.99817 15.3139 5.99817 12.0002C5.99817 8.68645 8.68446 6.00016 11.9982 6.00016ZM13.9982 12.0002C13.9982 13.1048 13.1027 14.0002 11.9982 14.0002C10.8936 14.0002 9.99817 13.1048 9.99817 12.0002C9.99817 10.8956 10.8936 10.0002 11.9982 10.0002C12.2758 10.0002 12.5402 10.0568 12.7805 10.159L14.5001 8.4394L14.5 5.2502C14.5 5.05128 14.579 4.86051 14.7197 4.71985L17.2196 2.21979C17.4341 2.00528 17.7567 1.94111 18.0369 2.05719C18.3172 2.17328 18.4999 2.44676 18.4999 2.75011V5.50013L21.2501 5.50018C21.5535 5.50018 21.827 5.68292 21.943 5.96318C22.0591 6.24343 21.995 6.56602 21.7805 6.78051L19.2804 9.28051C19.1397 9.42116 18.949 9.50017 18.7501 9.50017L15.5607 9.50018L13.8405 11.2204C13.942 11.46 13.9982 11.7236 13.9982 12.0002ZM18.4394 8.00017L19.4395 7.00015L17.7499 7.00012C17.3357 7.00011 16.9999 6.66432 16.9999 6.25012V4.56083L16 5.56081L16.0001 7.94121C16.0104 7.95042 16.0205 7.95997 16.0303 7.96984C16.0402 7.97975 16.0498 7.98987 16.059 8.00018L18.4394 8.00017Z"
                  fill="url(#paint0_linear_3684_53215)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3684_53215"
                    x1="1.78772"
                    y1="18.7516"
                    x2="37.3236"
                    y2="37.3624"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#A5FFB9" />
                    <stop offset="1" stopColor="#58F5FF" />
                  </linearGradient>
                </defs>
              </svg>

              <Stack spacing="0.3rem">
                <Text as="b" color="#E9EAF8" fontSize="20px">
                  {x.title}
                </Text>
                <Text color="#9FA6E1">{x.description}</Text>
              </Stack>
            </Stack>
          ))}
        </Stack>

        <Link href="/event/new">
          <Button
            leftIcon={
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.0896 8.26154L14.0896 8.2615C14.6831 7.66815 15.6451 7.66807 16.2385 8.26149L14.0896 8.26154ZM14.0896 8.26154C13.4962 8.85496 13.4962 9.81709 14.0896 10.4105L14.0896 10.4105M14.0896 8.26154L14.0896 10.4105M14.0896 10.4105C14.6831 11.0039 15.6451 11.004 16.2385 10.4106M14.0896 10.4105L16.2385 10.4106M16.2385 10.4106C16.2386 10.4105 16.2386 10.4105 16.2386 10.4105C16.8321 9.81707 16.8321 8.85496 16.2386 8.26154L16.2385 10.4106ZM13.7153 7.88722C14.5155 7.08707 15.8128 7.08707 16.6129 7.88722C17.4131 8.68737 17.4131 9.98467 16.6129 10.7848C15.8128 11.585 14.5155 11.585 13.7153 10.7848C12.9152 9.98468 12.9152 8.68737 13.7153 7.88722Z"
                  fill="#1F2FBA"
                  stroke="#1F2FBA"
                />
                <path
                  d="M21.9786 4.36461C21.7046 3.48289 21.014 2.7926 20.1322 2.51892L19.4571 2.30944C17.0168 1.55207 14.3578 2.2091 12.5509 4.01589L11.5356 5.03126C10.139 3.98249 8.14774 4.09346 6.87705 5.36416L5.6102 6.63101C5.31157 6.92963 5.31157 7.41379 5.6102 7.71242L7.2323 9.33452L7.04898 9.51785C6.35218 10.2146 6.35218 11.3443 7.04898 12.0412L7.55402 12.5462L6.13115 13.3578C5.92403 13.4758 5.78387 13.6838 5.75213 13.9201C5.72038 14.1565 5.80069 14.394 5.96929 14.5627L9.93449 18.5279C10.103 18.6963 10.3404 18.7767 10.5765 18.745C10.8127 18.7135 11.0206 18.5737 11.139 18.3669L11.9525 16.9447L12.4594 17.4515C13.1562 18.1484 14.2859 18.1484 14.9827 17.4515L15.1629 17.2713L16.7836 18.892C17.0823 19.1906 17.5664 19.1906 17.8651 18.892L19.1319 17.6252C20.4021 16.355 20.5135 14.3647 19.466 12.9683L20.4834 11.9508C22.2909 10.1433 22.9477 7.48292 22.1891 5.04192L21.9786 4.36461ZM19.6789 3.97955C20.0797 4.10395 20.3936 4.41771 20.5182 4.8185L20.7286 5.4958C21.3187 7.39437 20.8079 9.46354 19.4021 10.8694L13.9013 16.3702C13.8017 16.4697 13.6403 16.4697 13.5408 16.3702L8.13039 10.9597C8.03085 10.8602 8.03085 10.6988 8.13039 10.5993L13.6323 5.0973C15.0376 3.69202 17.1058 3.18101 19.0038 3.77006L19.6789 3.97955ZM18.3648 14.0695C18.8261 14.8502 18.7214 15.8728 18.0504 16.5438L17.3243 17.2699L16.2444 16.1899L18.3648 14.0695ZM7.95846 6.44558C8.62986 5.77417 9.65353 5.66976 10.4345 6.13233L8.31372 8.25311L7.23232 7.17171L7.95846 6.44558ZM10.8317 15.8239L10.3083 16.7388L7.75922 14.1897L8.67518 13.6674L10.8317 15.8239Z"
                  fill="#1F2FBA"
                />
                <path
                  d="M6.8697 18.7117C7.16832 18.413 7.16832 17.9289 6.8697 17.6302C6.57107 17.3316 6.08691 17.3316 5.78829 17.6302L3.26498 20.1536C2.96636 20.4522 2.96636 20.9364 3.26498 21.235C3.56361 21.5336 4.04777 21.5336 4.3464 21.235L6.8697 18.7117Z"
                  fill="#1F2FBA"
                />
                <path
                  d="M4.88729 15.6478C5.18591 15.9464 5.18591 16.4306 4.88729 16.7292L3.80588 17.8106C3.50724 18.1092 3.02308 18.1092 2.72446 17.8106C2.42583 17.512 2.42583 17.0278 2.72446 16.7292L3.80588 15.6478C4.1045 15.3492 4.58866 15.3492 4.88729 15.6478Z"
                  fill="#1F2FBA"
                />
                <path
                  d="M8.85051 20.6969C9.14914 20.3983 9.14914 19.9142 8.85052 19.6156C8.55191 19.3169 8.06774 19.3169 7.76911 19.6156L6.6898 20.6948C6.39116 20.9934 6.39116 21.4776 6.68978 21.7762C6.98841 22.0749 7.47257 22.0749 7.7712 21.7762L8.85051 20.6969Z"
                  fill="#1F2FBA"
                />
              </svg>
            }
            background="linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)"
            variant="neutral"
            color="#1F2FBA"
          >
            Create my first event
          </Button>
        </Link>
      </Box>

      <Center>
        <GlobeImage display={["none", "block", "block"]} />
      </Center>
    </Stack>
  );
};

export default EventsPromo;
