import {FC} from "react";

import BillingOverview from "./components/BillingOverview";
import PaymentOverview from "./components/PaymentOverview";
import SubscriptionOverview from "./components/SubscriptionOverview";
import TabHandler from "./components/TabHandler";

export const supportedPaymentOptionsMap = {
  stripe: "Stripe",
  ryft: "Ryft", // to be switched to ryft once model on BE is updated
  wire_transfer: "Wire/Bank transfer",
};

const FinanceSubscription: FC = () => {
  return (
    <>
      <TabHandler />
      <SubscriptionOverview />
      <BillingOverview />
      <PaymentOverview />
    </>
  );
};

export default FinanceSubscription;
