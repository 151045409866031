import {useUpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchMutation} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import Cookies from "js-cookie";

import React, {useCallback, useState} from "react";

import {Box, Button, Heading, Stack, Text} from "@chakra-ui/react";

import PaymentIntent from "../../../components/PaymentIntent";
import PaymentAddOption from "./PaymentAddOption";

enum Steps {
  INITIAL = "initial",
  SUBSCRIPTION_ACTIVE = "subscription_active",
  ADD_PAYMENT = "add_payment",
  SUCCESS = "success",
}

type Props = {
  setupComplete(): void;
};

const SubscriptionActivateStripe: React.FC<Props> = ({setupComplete}) => {
  const organizerId = Cookies.get("organizerId");
  const [step, setStep] = useState<Steps>(Steps.INITIAL);

  const [createBillingMethod, {data}] =
    useUpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchMutation();

  const onConfirmPayment = useCallback(({billingMethodId}) => {
    createBillingMethod({
      organizerId: organizerId,
      billingMethodId: billingMethodId,
    });
    setStep(Steps.SUBSCRIPTION_ACTIVE);
  }, []);

  return (
    <Box h="100%" width="100%" pt="1rem">
      <Heading h="10%" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Activate subscription
      </Heading>
      {step === Steps.INITIAL && <PaymentIntent success={onConfirmPayment} />}
      {step === Steps.SUBSCRIPTION_ACTIVE && (
        <Stack pt="30px" spacing="2rem">
          <Text fontSize="32px" fontWeight="600" color="#3C3C76" lineHeight="40px">
            🎉 You subscription is activated!
          </Text>
          <Text color="#3C3C76">
            Congratulations on activating your subscription! To receive payment for your events,
            please connect your payment options.
          </Text>
          <Button
            fontSize="14px"
            w="100%"
            maxWidth="335px"
            onClick={() => setStep(Steps.ADD_PAYMENT)}
          >
            Connect your payment options
          </Button>
        </Stack>
      )}
      {step === Steps.ADD_PAYMENT && <PaymentAddOption success={() => setStep(Steps.SUCCESS)} />}
      {step === Steps.SUCCESS && (
        <Stack pt="30px" spacing="2rem">
          <Text fontSize="32px" fontWeight="600" color="#3C3C76" lineHeight="40px">
            🎉 You subscription is activated and payment option added!
          </Text>
          <Button fontSize="14px" w="100%" maxWidth="335px" onClick={setupComplete}>
            Start now!🔥
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default SubscriptionActivateStripe;
