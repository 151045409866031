import {EventTable} from "@ef-org/components";
import {isEmpty} from "ramda";

import {useSelector} from "react-redux";

import {Button, Heading, Stack, Text, StackProps} from "@chakra-ui/react";

import {enhanceApis} from "../../api/__generated__/beOrgApi.extended";
import Link from "../../components/Link";
import {isUserAuthorized} from "../../helpers/aws/auth";
import useCookie from "../../hooks/useCookie";
import EventListItem from "./components/EventListItem";

const Actions: React.FC<{display?: StackProps["display"]}> = ({display}) => {
  return (
    <Stack
      display={display}
      spacing="0.5rem"
      direction={["column-reverse", "column-reverse", "row"]}
      w={["100%", "100%", "fit-content"]}
    >
      <div />

      <Link href="/event/new">
        <Button
          isDisabled={!isUserAuthorized("admin")}
          w={["100%", "100%", "fit-content"]}
          size="md"
          leftIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0002 20.2495C11.8781 20.2495 11.7759 20.1618 11.7543 20.0463L11.75 19.9826L11.748 12.7501L11.7479 12.2502H11.248H3.99902C3.86095 12.2502 3.74902 12.1383 3.74902 12.0002C3.74902 11.8784 3.83666 11.7762 3.95221 11.7545L4.01571 11.7502H11.248H11.7481L11.748 11.2502L11.7468 4.00031V4.00027C11.7468 3.86211 11.8588 3.75024 11.9966 3.75024C12.1188 3.75024 12.2209 3.83803 12.2425 3.95348L12.2468 4.01706L12.248 11.2503L12.2481 11.7502H12.748H20.0011C20.1392 11.7502 20.2511 11.8622 20.2511 12.0002C20.2511 12.1221 20.1635 12.2243 20.0479 12.246L19.9844 12.2502H12.748H12.2479L12.248 12.7504L12.25 19.9995C12.2501 20.1377 12.138 20.2495 12.0002 20.2495Z"
                fill="#727CD4"
                stroke="white"
              />
            </svg>
          }
        >
          Create new event
        </Button>
      </Link>
    </Stack>
  );
};

const EventsListPage = () => {
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const {data, isLoading} = useSelector(
    enhanceApis.endpoints.getOrganizerEventsOrganizersOrganizerIdEventsGet.select({organizerId})
  );

  return (
    <>
      <Stack
        direction={["column", "column", "row"]}
        align="center"
        w="100%"
        justify="space-between"
        mb="2rem"
      >
        <Stack
          direction={["column", "column", "row"]}
          w={["100%", "100%", "fit-content"]}
          spacing="0.5rem"
        >
          {/* <Select
            variant="white"
            defaultValue="upcoming"
            w={["100%", "100%", "200px"]}
            size="md"
            borderRadius="12px"
          >
            <option value="all">All Events</option>
            <option value="upcoming">Upcoming events</option>
            <option value="past">Past Events</option>
          </Select>
          <InputGroup size="md" bg="white" borderRadius="12px" w={["100%", "100%", "200px"]}>
            <Input placeholder="Search..." border="0" borderRadius="12px" />
            <InputRightElement>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0556 2C14.5046 2 18.1111 5.6066 18.1111 10.0556C18.1111 11.9799 17.4363 13.7466 16.3106 15.132L21.7559 20.5775C22.0813 20.9029 22.0813 21.4305 21.7559 21.7559C21.4601 22.0518 20.9971 22.0787 20.6709 21.8366L20.5775 21.7559L15.132 16.3106C13.7466 17.4363 11.9799 18.1111 10.0556 18.1111C5.6066 18.1111 2 14.5046 2 10.0556C2 5.6066 5.6066 2 10.0556 2ZM10.0556 3.66667C6.52707 3.66667 3.66667 6.52707 3.66667 10.0556C3.66667 13.584 6.52707 16.4445 10.0556 16.4445C13.584 16.4445 16.4445 13.584 16.4445 10.0556C16.4445 6.52707 13.584 3.66667 10.0556 3.66667Z"
                  fill="#727CD4"
                />
              </svg>
            </InputRightElement>
          </InputGroup> */}
        </Stack>

        <Actions display={["none", "none", "block"]} />
      </Stack>

      <Heading as="h3" fontSize="24px" mb="2rem">
        Upcoming events
      </Heading>

      <Actions display={["block", "block", "none"]} />

      <EventTable
        colsSkeletonNumber={6}
        templateColumns="repeat(6,minmax(150px, 1fr))"
        emptyMessage={isEmpty(data?.items) ? "No events found" : ""}
        isLoading={isLoading}
        headerComponents={
          <>
            <Text>Event name</Text>
            <Text>Sold tickets</Text>
            <Text>Sold Merch</Text>
            <Text>Revenue</Text>
            <Text>State</Text>
            <Text>Actions</Text>
          </>
        }
        bodyComponents={
          <>
            {data?.items.map((item) => (
              <EventListItem
                key={item.id}
                isPublished={item.state === "published"}
                eventId={item.id}
                eventName={item.name}
                soldTickets={item.statistics.tickets_sold_count}
                tickets_total_count={item.statistics.tickets_total_count}
                soldMerch={{
                  total: 0, //item.statistics.total_orders_count,
                  sold: 0, //item.statistics.merch_sold_count,
                }}
                revenue={item.statistics.total_revenue}
              />
            ))}
          </>
        }
      />
    </>
  );
};

export default EventsListPage;
