import {TicketRead} from "@ef-org/api";

import {FC, PropsWithChildren} from "react";

import {Stack, Text, TextProps} from "@chakra-ui/react";

interface Props {
  ticketData: TicketRead;
}

const BoldInfo: FC<PropsWithChildren<TextProps>> = ({children, ...props}) => (
  <Text
    sx={{
      color: "#121B6A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "140%",
    }}
    {...props}
  >
    {children}
  </Text>
);

const InfoLabel: FC<PropsWithChildren<TextProps>> = ({children, ...props}) => (
  <Text
    sx={{
      color: "#3C3C76",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "24px",
    }}
    {...props}
  >
    {children}
  </Text>
);

const Details = ({ticketData}: Props) => {
  return (
    <Stack padding="12px">
      <Text as="h2" color="#3C3C76" fontWeight="400" fontSize="20px" marginTop="12px">
        Ticket Details
      </Text>
      <BoldInfo>{ticketData?.event?.name}</BoldInfo>
      <InfoLabel>Attendee name(s)</InfoLabel>
      <BoldInfo>
        {ticketData?.attendee.first_name} {ticketData?.attendee.last_name}
      </BoldInfo>
      <Stack display="flex" justifyContent="space-between" flexDirection="row">
        <Stack>
          <InfoLabel>Ticket</InfoLabel>
          <BoldInfo>{ticketData?.ticket_type.name}</BoldInfo>
        </Stack>
        <Stack>
          <InfoLabel width="100%" textAlign="right">
            Ticket number
          </InfoLabel>
          <BoldInfo width="100%" textAlign="right">
            {ticketData?.readable_id}
          </BoldInfo>
        </Stack>
      </Stack>
      <Stack display="flex" justifyContent="space-between" flexDirection="row">
        <Stack>
          <InfoLabel>Order ID</InfoLabel>
          <BoldInfo>{ticketData?.order.readable_id}</BoldInfo>
        </Stack>
        <Stack>
          <InfoLabel width="100%" textAlign="right">
            Attendee ID
          </InfoLabel>
          <BoldInfo width="100%" textAlign="right">
            {ticketData?.attendee.readable_id}
          </BoldInfo>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Details;
