import {UserRole, beOrgApi} from "@ef-org/api";

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import {NextSeo, NextSeoProps} from "next-seo";
import {useRouter} from "next/router";

import {HamburgerIcon} from "@chakra-ui/icons";
import {
  Box,
  Container,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";

import {
  useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery,
} from "../../api/__generated__/beOrgApi.extended";
import AuthGuard, {AuthProps} from "../../components/AuthGuard";
import {EfIcon} from "../../components/Icons/EfIcon";
import QrIcon from "../../components/Icons/QrIcon";
import ItemRow from "../../components/Menu/ItemRow";
import MenuBottomSection from "../../components/Menu/MenuBottomSection";
import {getCurrentUser, getUserId, isUserAuthorized} from "../../helpers/aws/auth";
import useCookie from "../../hooks/useCookie";
import {menuItems} from "./menuItems";

const MenuItems: React.FC<{fullMenuWidth: boolean}> = ({fullMenuWidth}) => {
  const {asPath} = useRouter();
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const user = getCurrentUser();
  const {data} = useSelector(
    beOrgApi.endpoints.getOrganizerEventsOrganizersOrganizerIdEventsGet.select({
      organizerId,
    })
  );

  return (
    <Stack mt={["0", "0", "2.5rem"]} spacing="0.3rem" overflow="hidden">
      {menuItems.map((item, i) => {
        if (item.type === "divider") {
          return (
            <Box key={i}>
              <Divider w="100%" my="0.5rem" borderColor="#2A429C" />
            </Box>
          );
        }

        if (item.type === "item" && isUserAuthorized(item.requiredPermissions)) {
          return (
            <ItemRow
              fullMenuWidth={fullMenuWidth}
              key={i}
              href={
                item.href ||
                (item.hrefResolver &&
                  item?.hrefResolver({hasEvents: (data?.items || [])?.length === 0}))
              }
              isActive={item?.matcher?.test(asPath)}
              icon={item.icon}
              name={item.name}
            />
          );
        }

        return null;
      })}
    </Stack>
  );
};

const useDashboardDataFetcher = (organizerId: string) => {
  useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery(
    {organizerId},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery(
    {organizerId, userId: getUserId()},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery(
    {organizerId, userId: getUserId()},
    {refetchOnMountOrArgChange: true, skip: !organizerId}
  );
};

const DashBoardLayout: React.FC<{
  children?: React.ReactNode;
  pageTitle?: string;
  metaTags?: NextSeoProps;
  requiredPermissions: UserRole;
}> = ({children, pageTitle, metaTags, requiredPermissions}) => {
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  useDashboardDataFetcher(organizerId);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {push} = useRouter();

  const [isBiggerThanMobile] = useMediaQuery("(min-width: 768px)", {
    ssr: true,
    fallback: false,
  });

  useEffect(() => {
    if (isBiggerThanMobile && isOpen) {
      onClose();
    }
  }, [isBiggerThanMobile, isOpen]);

  const [isSmall] = useMediaQuery("(max-width: 960px)", {
    ssr: true,
    fallback: false,
  });

  const [animate, setAnimate] = useState(false);
  const {cookieValue, setCookie} = useCookie<"normal" | "small">("layout-menu", {
    defaultFallback: "normal",
  });

  const toggleMenu = useCallback(() => {
    if (isSmall) {
      return;
    }

    setCookie((p) => (p === "normal" ? "small" : "normal"));
  }, [cookieValue, isSmall]);

  const fullMenuWidth = useMemo(
    () => (cookieValue === "normal" && !isSmall) || !isBiggerThanMobile,
    [cookieValue, isSmall, isBiggerThanMobile]
  );

  useEffect(() => {
    document.body.style.backgroundColor = "var(--chakra-colors-light-blue)";
    setTimeout(() => {
      setAnimate(true);
    }, 100);
    return () => {
      document.body.style.backgroundColor = "#FFFFFF";
    };
  }, []);

  return (
    <AuthGuard requiredPermissions={requiredPermissions}>
      {metaTags && <NextSeo {...metaTags} />}

      <Grid
        pl={["0", "0", "1rem"]}
        w="100%"
        h={["auto", "auto", "100vh"]}
        transition={animate ? "all 0.4s" : ""}
        templateColumns={["1fr", "1fr", `${fullMenuWidth ? "227px" : "72px"} auto`]}
      >
        <GridItem
          my={[0, 0, "1rem"]}
          pb={["0", "0", "2rem"]}
          px="12px"
          bg="#162184"
          borderRadius={["0", "0", "24px"]}
        >
          <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay top="62px" />
            <DrawerContent>
              <Box
                mt={fullMenuWidth ? "62px" : "46px"}
                position="fixed"
                zIndex="1"
                bg="#162184"
                w="100%"
                borderBottomRadius="6px"
              >
                <Divider />
                <Box px="1rem" pt="1rem" mb="2rem">
                  <MenuItems fullMenuWidth={fullMenuWidth} />
                  <MenuBottomSection fullMenuWidth={fullMenuWidth} />
                </Box>
              </Box>
            </DrawerContent>
          </Drawer>
          <Stack h={["auto", "auto", "100%"]} justify="space-between">
            <Box>
              <Stack
                direction="row"
                overflow="hidden"
                mt={["0", "0", "1rem"]}
                py="10px"
                w="100%"
                align="center"
                justify={["space-between", "space-between", "center"]}
              >
                {fullMenuWidth ? (
                  <EfIcon onClick={toggleMenu} />
                ) : (
                  <Icon
                    cursor="pointer"
                    onClick={toggleMenu}
                    width="25"
                    mb="15px"
                    height="18"
                    viewBox="0 0 25 18"
                    fill="none"
                    _hover={{path: {fill: "#FFFFFF"}}}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.26773 0H0.825928V3.59174V7.18348V10.7584V14.3502V17.9419H4.26773H7.70953H11.1353V14.3502H7.70953H4.26773V10.7584V7.18348V3.59174H7.70953H11.1353V0H7.70953H4.26773Z"
                      fill="#C9CDEE"
                    />
                    <path
                      d="M21.4608 0H18.019H14.5771V3.59174H18.019H21.4608V7.18348V10.7584V14.3502V17.9419H24.9026V14.3502V10.7584V7.18348V3.59174V0H21.4608Z"
                      fill="#C9CDEE"
                    />
                    <path d="M11.1513 7.18408H7.70947V10.7758H11.1513V7.18408Z" fill="#C9CDEE" />
                    <path d="M18.019 7.18408H14.5771V10.7758H18.019V7.18408Z" fill="#C9CDEE" />
                  </Icon>
                )}

                <Stack flexDir="row">
                  <a
                    onClick={() => {
                      push("/qr-scanner");
                    }}
                  >
                    <QrIcon />
                  </a>
                  <HamburgerIcon
                    color="white"
                    cursor="pointer"
                    boxSize="26px"
                    display={["block", "block", "none"]}
                    onClick={() => (isOpen ? onClose() : onOpen())}
                  />
                </Stack>
              </Stack>

              <Box display={["none", "none", "block"]}>
                <MenuItems fullMenuWidth={fullMenuWidth} />
              </Box>
            </Box>
            <Box display={["none", "none", "block"]}>
              <MenuBottomSection fullMenuWidth={fullMenuWidth} />
            </Box>
          </Stack>
        </GridItem>

        <GridItem overflowX="auto" pt="2rem">
          <Container maxW="calc(1200px + 4rem)" pb="1rem" px={["1rem", "2rem", "2rem"]}>
            {pageTitle && (
              <Heading mb="2rem" as="h1" fontSize="32px" fontWeight="600" color="#1F2FBA">
                {pageTitle}
              </Heading>
            )}

            {children}
          </Container>
        </GridItem>
      </Grid>
    </AuthGuard>
  );
};

export default DashBoardLayout;
