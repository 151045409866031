import {useGetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetQuery} from "@ef-org/api";
import Cookies from "js-cookie";

import React, {FC} from "react";

import {Heading, Stack, Text} from "@chakra-ui/react";

import {InvoicesTable} from "../../components/Tables/InvoiceTable";
import InvoiceListItem from "./components/InvoiceListItem";
import TabHandler from "./components/TabHandler";

const FinanceInvoices: FC = () => {
  const organizerId = Cookies.get("organizerId");
  const {data: organiserInvoiceData} = useGetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetQuery(
    {organizerId}
  );

  return (
    <>
      <TabHandler />
      <Stack flexDirection="row" justifyContent="space-between" paddingBottom="16px">
        <Heading marginBottom="0" lineHeight="2" fontWeight="600" fontSize="20px" color="#1F2FBA">
          Your invoices
        </Heading>
      </Stack>
      {!organiserInvoiceData?.items.length && (
        <Stack
          justifyContent="center"
          direction={["column", "row", "row"]}
          spacing="1rem"
          bg="white"
          borderRadius="22px"
          p="1.5rem"
          w="100%"
        >
          <Text textAlign="center" w="100%" fontSize="18px" color="#727CD4">
            You have no invoices yet
          </Text>
        </Stack>
      )}
      {organiserInvoiceData?.items.length > 0 && (
        <Stack>
          <InvoicesTable
            colsSkeletonNumber={6}
            templateColumns="repeat(6,minmax(170px, 1fr))"
            headerComponents={
              <>
                <Text className="firstItem">Invoice ID</Text>
                <Text>Start date</Text>
                <Text>End date</Text>
                <Text>Invoice amount</Text>
                <Text>Status</Text>
                <Text textAlign="right" width="100%">
                  Actions
                </Text>
              </>
            }
            bodyComponents={
              <>
                {organiserInvoiceData?.items.map((item, i) => (
                  <InvoiceListItem
                    key={i}
                    invoiceId={item.id}
                    startDate={item.period_start_date}
                    endDate={item.period_end_date}
                    invoiceAmount={item.amount}
                    status={item.payment.state}
                    download={item.pdf_url}
                  />
                ))}
              </>
            }
          />
        </Stack>
      )}
    </>
  );
};

export default FinanceInvoices;
