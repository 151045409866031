import {NFTCanvas} from "@ef-org/components";
import {useEventDetailSelectorByQueryEventId} from "apps/ef-org/src/scenes/EventDetail/hooks/useEventDetailSelectorByQueryEventId";
import {isEmpty} from "ramda";

import {AspectRatio, Box, Grid, Heading, Icon, Select, Stack, Text} from "@chakra-ui/react";

const MerchPreview = () => {
  const {data} = useEventDetailSelectorByQueryEventId();

  if (isEmpty(data?.digital_merchandises || [])) {
    return null;
  }

  return (
    <>
      <Heading color="#616174" as="h2" fontSize="24px" id="merch-preview">
        Select digital merch
      </Heading>

      <Stack direction="row" bg="#FAFAFD" p="1rem" borderRadius="22px" mb="1.5rem">
        <Icon
          width="24px"
          height="25px"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0086 4.7076C10.603 4.32666 11.2941 4.12418 12.0001 4.12418C12.706 4.12418 13.3972 4.32666 13.9915 4.7076L21.6716 9.6305C21.7719 9.6949 21.8545 9.78338 21.9119 9.88787C21.9692 9.99236 21.9995 10.1095 22 10.2287C22.0005 10.3479 21.9711 10.4654 21.9146 10.5703C21.8581 10.6752 21.7762 10.7644 21.6764 10.8296L18.6668 12.7962V18.0887C18.6668 18.2432 18.6167 18.3936 18.524 18.5173L18.523 18.5182L18.5221 18.5201L18.5192 18.5239L18.5106 18.5334L18.4963 18.5525C18.037 19.0725 17.5037 19.5221 16.9135 19.8868C15.8439 20.5458 14.2277 21.1839 12.0001 21.1839C9.77246 21.1839 8.15625 20.5458 7.08672 19.8877C6.65872 19.627 6.25965 19.3214 5.89623 18.9763C5.79145 18.8759 5.69104 18.771 5.59527 18.662C5.55422 18.6158 5.51482 18.5682 5.47718 18.5192L5.47622 18.5173C5.38349 18.3936 5.33337 18.2432 5.33337 18.0887V12.7962L3.42859 11.5515V16.6601C3.42859 16.8495 3.35333 17.0312 3.21937 17.1652C3.08542 17.2991 2.90373 17.3744 2.71429 17.3744C2.52485 17.3744 2.34317 17.2991 2.20921 17.1652C2.07526 17.0312 2 16.8495 2 16.6601V10.2315C1.9999 10.1092 2.0312 9.98891 2.0909 9.88218C2.1506 9.77546 2.2367 9.68585 2.34096 9.62193L10.0077 4.7076H10.0086ZM14.0182 15.8325C13.4181 16.2244 12.7169 16.4331 12.0001 16.4331C11.2833 16.4331 10.5821 16.2244 9.98198 15.8325L6.76195 13.7286V17.8211C7.08393 18.147 7.44436 18.4326 7.83529 18.6715C8.70102 19.203 10.061 19.7554 12.0001 19.7554C13.9392 19.7554 15.2992 19.203 16.1649 18.6706C16.5558 18.4319 16.9162 18.1467 17.2382 17.8211V13.7286L14.0192 15.8334L14.0182 15.8325ZM13.2211 5.90951C12.4772 5.43332 11.5229 5.43332 10.7791 5.90951L4.02859 10.2372L10.7629 14.6372C11.5153 15.1277 12.4858 15.1277 13.2373 14.6372L19.9706 10.2372L13.2211 5.90951Z"
            fill="#727CD4"
          />
        </Icon>
        <Text color="#727CD4" fontSize="16px">
          Digital merch are <b>digital-only NFT items</b> will be sent to your EventsFrame wallet
          for storage or withdrawal to a private wallet; no physical items are provided.
        </Text>
      </Stack>

      <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]} gap="1rem">
        {data.digital_merchandises.map((merch) => (
          <Box key={merch.id} bg="#FAFAFD" borderRadius="16px" p="1rem">
            <AspectRatio bg="white" borderRadius="12px" w="100%" ratio={1} mb="1rem">
              <NFTCanvas nftAssetUrl="https://storage.googleapis.com/ef-nft-staging-uploads/6328309642166272.glb" />
            </AspectRatio>

            <Text mb="0.5rem" as="h4" fontWeight="600" fontSize="16px" color="text-neutral-blue-10">
              {merch.name}
            </Text>

            {merch?.description && (
              <Text mb="1rem" color="#A4A5B6">
                {merch.description}
              </Text>
            )}

            <Stack direction="row" align="center" justify="flex-end" spacing="1rem">
              <Text>$ {merch.price}</Text>

              <Select size="lg" bg="white" borderRadius="12px" w="fit-content">
                {new Array(merch.maximum_order_quantity).fill(0).map((_, quantity) => {
                  if (1 > quantity && quantity !== 0) {
                    return null;
                  }

                  return (
                    <option key={quantity} value={quantity}>
                      {quantity}
                    </option>
                  );
                })}
              </Select>
            </Stack>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default MerchPreview;
