export const joi = {
  "any.required": "Required",
  "any.only": "Must be same",

  "string.base": "Must be a string",
  "string.email": "Must be a valid email",
  "string.only_invited": "This email has not been invited to the experience",
  "string.empty": "Required",
  "string.length": "Length must be {{#limit}} characters long",
  "string.lowercase": "Must only contain lowercase characters",
  "string.max": "Length must be less than or equal to {{#limit}} characters long",
  "string.min": "Length must be at least {{#limit}} characters long",
  "string.one_uppercase": "Must contain at least one uppercase character",
  "string.uppercase": "Must only contain uppercase characters",
  "string.number": "Must contain at least one number",
  "string.special": "Must contain at least one special character",
  "string.pattern.base": "Fails to match the required pattern",
  "string.same": "Passwords must match",

  "number.base": "Must be a number",
  "number.greater": "Must be greater than {{#limit}}",
  "number.infinity": "Cannot be infinity",
  "number.integer": "Must be an integer",
  "number.less": "Must be less than {{#limit}}",
  "number.max": "Must be less than or equal to {{#limit}}",
  "number.min": "Must be greater than or equal to {{#limit}}",
  "number.multiple": "Must be a multiple of {{#multiple}}",
  "number.negative": "Must be a negative number",
  "number.positive": "Must be a positive number",
  "number.precision": "Must have no more than {{#limit}} decimal places",

  "date.base": "Must be a valid date",

  "phoneNumber.invalid": "Value does not seem to be a phone number",
  "phoneNumber.code.invalid": "Invalid country calling code",
  "date.invalid": "Invalid date",
  "time.invalid": "Invalid time",
};

export const translations = {
  "page.homepage.title": "EventsFrame",

  "invalid.country": "Invalid country",
  ...joi,
};
