import {EventReadMultiple} from "@ef-org/api";
import {IconFilter, IconCaret} from "@ef-org/icons";

import {useState} from "react";

import {Button, Menu, MenuButton, MenuItem, MenuList, Stack, Text} from "@chakra-ui/react";

import {useStore} from "./FilterMenuStore";

interface Props {
  events: EventReadMultiple;
}

const FilterMenu = ({events}: Props) => {
  const {eventFilter, toggle} = useStore();

  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

  const closeSubmenu = () => {
    if (submenuOpen) {
      setSubmenuOpen(false);
    }
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  return (
    <Menu>
      <MenuButton as={Button} size="md" variant="outline" rightIcon={<IconFilter boxSize="20px" />}>
        Filters
      </MenuButton>
      <MenuList borderRadius="12px" boxShadow="-1px 1px 10px 0px rgba(0, 31, 142, 0.1)">
        {/* Submenu */}
        <Menu isOpen={submenuOpen} placement="right-end" onClose={closeSubmenu}>
          <MenuButton as={MenuItem} onClick={toggleSubmenu}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text>Events</Text>
              <IconCaret />
            </Stack>
          </MenuButton>
          <MenuList borderRadius="12px" boxShadow="-1px 1px 10px 0px rgba(0, 31, 142, 0.1)">
            {events &&
              events.items.map((event) => (
                <MenuItem
                  closeOnSelect={false}
                  key={event.id}
                  cursor="pointer"
                  color={
                    eventFilter.includes(event.id)
                      ? "var(--chakra-colors-ef-primary)"
                      : "var(--chakra-colors-ef-secondary)"
                  }
                  onClick={() => toggle(event.id)}
                >
                  {event.name}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
        {/* <MenuItem>Experiences</MenuItem>
        <MenuItem>Tags</MenuItem> */}
      </MenuList>
    </Menu>
  );
};

export default FilterMenu;
