import {beOrgApi} from "@ef-org/api";
import {Input} from "@ef-org/components";
import {useForm} from "@ef-org/hooks";
import {useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import Joi from "joi";
import Cookies from "js-cookie";

import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Heading, Stack, Box, Button, Text, useBreakpointValue} from "@chakra-ui/react";

import {supportedPaymentOptionsMap} from "../FinanceSubscription";

type StripeTypes = {
  client_secret: string;
  client_public_key: string;
};

const StripeFormSchema = Joi.object({
  client_secret: Joi.string().min(8).required(),
  client_public_key: Joi.string().min(8).required(),
});

type Props = {
  success(): void;
};

const PaymentAddOption: React.FC<Props> = ({success}) => {
  const [selected, setSelected] = useState<string>("stripe");
  const [selectedOption, setSelectedOption] = useState(null);
  const isMobile = useBreakpointValue([true, true, false]);

  const {
    registerWithError,
    handleSubmit,
    setValue,
    formState: {isValid, errors},
  } = useForm<StripeTypes>(StripeFormSchema, {
    keepDataOnSuccess: true,
    mode: "onChange",
    defaultValues: {client_secret: "", client_public_key: ""},
  });

  const userId = Cookies.get("userId");
  const organizerId = Cookies.get("organizerId");

  const {data: paymentOptions} = useSelector(
    beOrgApi.endpoints.getOrganizerUsersUserIdOrganizersOrganizerIdGet.select({userId, organizerId})
  );

  const [updateOrganiser, {isLoading, isSuccess}] =
    useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation();

  useEffect(() => {
    if (!paymentOptions) {
      return;
    }
    if (paymentOptions.payment_methods.stripe.is_active) {
      setValue("client_secret", paymentOptions.payment_methods.stripe.client_secret);
      setValue("client_public_key", paymentOptions.payment_methods.stripe.client_public_key);
    }
    if (!selected) {
      setSelected(Object.keys(paymentOptions?.payment_methods)[0]);
    }
    setSelectedOption(paymentOptions?.payment_methods[selected]);
  }, [paymentOptions, selected]);

  useEffect(() => {
    isSuccess && success();
  }, [isSuccess]);

  const addPaymentOption = useCallback(() => {
    updateOrganiser({
      userId,
      organizerId,
      organizerUpdate: {
        payment_methods: {
          wire_transfer: {is_active: true},
        },
      },
    });
  }, [updateOrganiser, selected, userId, organizerId]);

  const onFormSubmit = (value: StripeTypes) => {
    updateOrganiser({
      userId,
      organizerId,
      organizerUpdate: {
        payment_methods: {
          stripe: {...value, is_active: true},
        },
      },
    });
  };

  return (
    <Box p="1.5rem">
      <Heading
        marginBottom="0"
        fontSize="24px"
        fontWeight="500"
        lineHeight="29px"
        color="#1F2FBA"
        paddingBottom="24px"
      >
        Connect payment option
      </Heading>
      <Stack direction="column" spacing="16px">
        {paymentOptions &&
          Object.keys(paymentOptions?.payment_methods)
            .filter((key) => key !== "ryft")
            .map((payment) => (
              <Button
                key={payment}
                width={isMobile ? "100%" : "auto"}
                justifyContent={isMobile ? "left" : "auto"}
                variant="neutral"
                border={selected === payment ? "1.5px solid #1F2FBA" : "auto"}
                background="#E9EAF8"
                color="#4754C6"
                fontWeight="500"
                padding="12px 16px"
                onClick={() => setSelected(payment)}
                leftIcon={
                  selected === payment ? (
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #727CD4",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: "#4754C6",
                          borderRadius: "100%",
                        }}
                      ></div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #727CD4",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                  )
                }
              >
                {supportedPaymentOptionsMap[payment]}
              </Button>
            ))}
      </Stack>
      {selected === "stripe" && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Stack marginTop="24px" paddingTop="24px" borderTop="1px solid #E9EAF8">
            <Heading as="h2" fontSize="20">
              {supportedPaymentOptionsMap[selected]} details
            </Heading>
          </Stack>
          <Stack marginTop="24px">
            <Input
              {...registerWithError("client_public_key")}
              leftContent={
                <svg
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Vector"
                    d="M13.7509 12.177C14.9929 12.177 15.9998 13.1838 15.9998 14.4259V15.0013C15.9998 15.8955 15.6802 16.7604 15.0987 17.4398C13.5293 19.2733 11.1421 20.178 7.99668 20.178C4.85084 20.178 2.46483 19.2729 0.898524 17.4387C0.318764 16.7598 0.000244141 15.8963 0.000244141 15.0035V14.4259C0.000244141 13.1838 1.0071 12.177 2.24912 12.177H13.7509ZM13.7509 13.677H2.24912C1.83553 13.677 1.50024 14.0123 1.50024 14.4259V15.0035C1.50024 15.5392 1.69135 16.0572 2.03921 16.4646C3.29252 17.9322 5.2584 18.678 7.99668 18.678C10.735 18.678 12.7026 17.9322 13.9591 16.4643C14.308 16.0567 14.4998 15.5378 14.4998 15.0013V14.4259C14.4998 14.0123 14.1645 13.677 13.7509 13.677ZM7.99668 0.181641C10.7581 0.181641 12.9967 2.42022 12.9967 5.18164C12.9967 7.94307 10.7581 10.1817 7.99668 10.1817C5.23528 10.1817 2.9967 7.94307 2.9967 5.18164C2.9967 2.42022 5.23528 0.181641 7.99668 0.181641ZM7.99668 1.68164C6.06368 1.68164 4.4967 3.24865 4.4967 5.18164C4.4967 7.11464 6.06368 8.68165 7.99668 8.68165C9.92968 8.68165 11.4967 7.11464 11.4967 5.18164C11.4967 3.24865 9.92968 1.68164 7.99668 1.68164Z"
                    fill="#727CD4"
                  />
                </svg>
              }
              maxW="100%"
              label={<Text color="#727CD4">Public key</Text>}
              placeholder="Enter your public key"
              noBorder
            />
          </Stack>
          <Stack marginTop="24px">
            <Input
              {...registerWithError("client_secret")}
              leftContent={
                <svg
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Vector"
                    d="M13.7509 12.177C14.9929 12.177 15.9998 13.1838 15.9998 14.4259V15.0013C15.9998 15.8955 15.6802 16.7604 15.0987 17.4398C13.5293 19.2733 11.1421 20.178 7.99668 20.178C4.85084 20.178 2.46483 19.2729 0.898524 17.4387C0.318764 16.7598 0.000244141 15.8963 0.000244141 15.0035V14.4259C0.000244141 13.1838 1.0071 12.177 2.24912 12.177H13.7509ZM13.7509 13.677H2.24912C1.83553 13.677 1.50024 14.0123 1.50024 14.4259V15.0035C1.50024 15.5392 1.69135 16.0572 2.03921 16.4646C3.29252 17.9322 5.2584 18.678 7.99668 18.678C10.735 18.678 12.7026 17.9322 13.9591 16.4643C14.308 16.0567 14.4998 15.5378 14.4998 15.0013V14.4259C14.4998 14.0123 14.1645 13.677 13.7509 13.677ZM7.99668 0.181641C10.7581 0.181641 12.9967 2.42022 12.9967 5.18164C12.9967 7.94307 10.7581 10.1817 7.99668 10.1817C5.23528 10.1817 2.9967 7.94307 2.9967 5.18164C2.9967 2.42022 5.23528 0.181641 7.99668 0.181641ZM7.99668 1.68164C6.06368 1.68164 4.4967 3.24865 4.4967 5.18164C4.4967 7.11464 6.06368 8.68165 7.99668 8.68165C9.92968 8.68165 11.4967 7.11464 11.4967 5.18164C11.4967 3.24865 9.92968 1.68164 7.99668 1.68164Z"
                    fill="#727CD4"
                  />
                </svg>
              }
              maxW="100%"
              label={<Text color="#727CD4">Client secret</Text>}
              placeholder="Enter your client secret"
              noBorder
            />
          </Stack>
          <Stack alignItems="end" paddingTop="24px">
            <Button
              type="submit"
              isDisabled={!isValid}
              fontSize="14px"
              height="40px"
              fontWeight="500"
            >
              Add payment option
            </Button>
          </Stack>
        </form>
      )}
      {selected === "wire_transfer" && (
        <Stack alignItems="end" paddingTop="24px">
          <Button
            isDisabled={selectedOption?.is_active || isLoading}
            fontSize="14px"
            height="40px"
            fontWeight="500"
            onClick={addPaymentOption}
          >
            Add payment option
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default PaymentAddOption;
