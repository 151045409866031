/* eslint-disable @typescript-eslint/ban-ts-comment */
import {translations as englishTranslation, joi as englishJoiLocalization} from "./locales/en";

export const locales = {en: englishTranslation};

export type Language = keyof typeof locales;
export type TranslationKeys = keyof typeof locales.en;

export const DEFAULT_APP_LANGUAGE: Language = "en";

export const JOI_LOCALIZATION: Record<string, string> = {
  // @ts-expect-error replace ts-ignore
  en: englishJoiLocalization,
};

export const resolveTranslation = (language: Language, translationKey: TranslationKeys): string => {
  const translation = locales[language][translationKey] || translationKey;

  return translation;
};
