import * as echarts from "echarts";

import React, {useEffect, useRef, useState} from "react";

interface DoughnutChartProps {
  data: {value: number; name: string; color: string}[];
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [chart, setChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current && !chart) {
      const dchart = echarts.init(chartRef.current, null, {ssr: false});
      setChart(dchart);
      const options: echarts.EChartsOption = {
        color: data.map((d) => d.color),
        grid: {
          bottom: "20%",
        },
        legend: {
          bottom: "0",
          left: "center",
          icon: "none",
          itemGap: 5,
          formatter: function (name) {
            return `${name} {circle${name.replace(" ", "")}|·}\n {value|$ ${Number(
              data.filter((a) => a.name === name).map((a) => a.value)
            )}} `;
          },
          textStyle: {
            fontSize: 14,
            align: "left",
            color: "#4754C6",
            //this is creating dot on the right even if item is aligned to the left, classes are defined in formatter above and styled here
            rich: {
              ...data.reduce((acc, curr) => {
                const key = `circle${curr.name.replace(" ", "")}`;
                const value = {color: curr.color, fontSize: 59, align: "right", height: 20};
                return {...acc, [key]: value};
              }, {}),
              value: {
                lineHeight: 20,
                fontSize: 14,
                width: 60,
                color: "#727CD4",
              },
            },
          },
          // orient: "horizontal",
          show: true,
          align: "left",
          // data: data.map(d => (d.name)),
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            avoidLabelOverlap: false,
            startAngle: 300,
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: false,
                // fontSize: 16,
                // fontWeight: "bold", //TODO: demo this and let design decide
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderRadius: 15,
              borderCap: "round",
            },
            data: data.map((item) => ({
              name: item.name,
              value: item.value,
            })),
          },
        ],
      };

      dchart.setOption(options);
    }
  }, [data]);

  return (
    <div style={{width: "100%", overflow: "hidden", display: "flex", justifyContent: "center"}}>
      <div style={{display: "inline-block", objectFit: "cover"}}>
        <div
          ref={chartRef}
          style={{
            maxWidth: "300px",
            minWidth: "245px",
            width: "100%",
            flexGrow: 1,
            height: "300px",
            marginTop: "-50px",
            overflow: "hidden",
          }}
        />
      </div>
    </div>
  );
};

export default DoughnutChart;
