import {useGetTeamMembersUsersUserIdTeamGetQuery} from "@ef-org/api";
import {EventTable, EventTableItem} from "@ef-org/components";
import {getUserId} from "apps/ef-org/src/helpers/aws/auth";

import {Stack, Text} from "@chakra-ui/react";

const TeamMembersTable = () => {
  const teamMembers = useGetTeamMembersUsersUserIdTeamGetQuery({userId: getUserId()});

  return (
    <EventTable
      colsSkeletonNumber={3}
      templateColumns="repeat(3,minmax(150px, 1fr))"
      headerComponents={
        <>
          <Text>First name</Text>
          <Text>Last name</Text>
          <Text>Role</Text>
        </>
      }
      bodyComponents={
        <>
          {teamMembers?.data?.map((member, i) => (
            <EventTableItem key={member.id} noHover templateColumns="repeat(3, minmax(150px, 1fr))">
              <Stack direction="row" align="center">
                <Text color="#4754C6">{member.first_name}</Text>
              </Stack>
              <Stack w="100%" direction="row" justify="center">
                <Text color="#4754C6">{member.last_name}</Text>
              </Stack>
              <Stack direction="row" justify="end">
                <Text color="#4754C6">{member.role}</Text>
              </Stack>
            </EventTableItem>
          ))}
        </>
      }
    />
  );
};

export default TeamMembersTable;
