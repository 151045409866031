import React from "react";

import {ChevronUpIcon, ChevronDownIcon} from "@chakra-ui/icons";
import {Box, Collapse, Container, Heading, Stack, Text, useBoolean} from "@chakra-ui/react";

const Section: React.FC<{
  stepNumber: number;
  stepName: string;
  isExposed?: boolean;
  children?: React.ReactNode;
}> = ({stepName, stepNumber, isExposed, children}) => {
  const [isOpen, {toggle, on, off}] = useBoolean(isExposed || false);

  React.useEffect(() => (Boolean(isExposed) ? on() : off()), [isExposed]);

  return (
    <Container maxW="5xl" bg="white" borderRadius="24px" p="1.5rem">
      <Stack direction="row" align="center" justify="space-between">
        <Stack bg="white" direction="row" align="center" spacing="1rem">
          <Stack
            onClick={toggle}
            boxSize="35px"
            align="center"
            justify="center"
            bg="#E9EAF8"
            borderRadius="20px"
          >
            {!isOpen ? (
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4001 8.39998C17.8687 8.86861 17.8687 9.62845 17.4001 10.097L11.6486 15.8485C11.18 16.3171 10.4202 16.3171 9.9516 15.8485L7.20012 13.097C6.73149 12.6284 6.73149 11.8686 7.20012 11.4C7.66875 10.9314 8.42854 10.9314 8.89718 11.4L10.8001 13.303L15.7031 8.39998C15.7616 8.3414 15.8248 8.29015 15.8914 8.24621C16.3571 7.93868 16.9901 7.98993 17.4001 8.39998Z"
                  fill="#1F2FBA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69806 21.6 2.4 17.302 2.4 12C2.4 6.69806 6.69806 2.4 12 2.4C17.302 2.4 21.6 6.69806 21.6 12Z"
                  fill="#1F2FBA"
                />
              </svg>
            ) : (
              <Text fontWeight="bold" fontSize="24px">
                {stepNumber}
              </Text>
            )}
          </Stack>
          <Heading pt="7px" fontWeight="bold" fontSize="24px">
            {stepName}
          </Heading>
        </Stack>

        <Box
          onClick={toggle}
          p="5px"
          cursor="pointer"
          _hover={{bg: "light-blue"}}
          borderRadius="12px"
        >
          {!isOpen ? (
            <ChevronDownIcon boxSize="28px" color="#727CD4" />
          ) : (
            <ChevronUpIcon boxSize="28px" color="#727CD4" />
          )}
        </Box>
      </Stack>

      <Box transition="padding-top 0.4s" pt={isOpen ? "1rem" : "0"}>
        <Collapse in={isOpen} animateOpacity>
          <Box p="2px">{children}</Box>
        </Collapse>
      </Box>
    </Container>
  );
};

export default Section;
