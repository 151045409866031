// import ObSelect from "apps/ef-org/src/components/ObSelect";
import {Select} from "@ef-org/components";
import {OnFormSubmitType, useForm} from "@ef-org/hooks";
import Joi from "joi";

import * as React from "react";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

export type Type = {
  type: string;
  events: string;
  source: string;
};

export const Schema = Joi.object({
  type: Joi.string().allow(""),
  events: Joi.string().allow(""),
  source: Joi.string().allow(""),
});

const MoreInfo: React.FC = () => {
  const {registerWithError, handleSubmit, setValue} = useForm<Type>(Schema, {
    defaultValues: {type: "", events: "", source: ""},
  });
  const handleOnSubmit: OnFormSubmitType<Type> = async () => {
    //
  };

  return (
    <Flex direction="column" align="center" w={["100%", "70%"]}>
      <Heading textAlign="center" variant="h2" mb="20px">
        Congrats! <br />
        Your account is created!
      </Heading>
      <Text mb="20px" fontWeight="bold">
        Tell us more about yourself!
      </Text>
      <Box as="form" w="100%" onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack>
          <Select
            onSelectValue={(value) => setValue("type", value)}
            label="What types of events do you organize?"
            {...registerWithError("type")}
            options={[{value: "In-person"}, {value: "Online event"}]}
            placeholder="Select the type"
          />
          <Select
            onSelectValue={(value) => setValue("events", value)}
            label="How many people attend your events?"
            {...registerWithError("events")}
            options={[
              {value: "0 - 1000 people"},
              {value: "1000 - 10,000 people"},
              {value: "10,000 and more"},
            ]}
            placeholder="Select the number"
          />
          <Select
            onSelectValue={(value) => setValue("source", value)}
            label="How did you discover EventsFrame?"
            {...registerWithError("source")}
            options={[
              {value: "Search Engine (Google, Yahoo, etc.)"},
              {value: "Social Media (Instagram, Facebook...)"},
              {value: "Friends"},
            ]}
            placeholder="Select the source"
          />
        </Stack>

        {/* <AlertSubscriber boxId="loginResponse" /> */}
        <Flex justify="space-between">
          <Button variant="ob_secondary" w="48%" mt="32px">
            Skip
          </Button>
          <Button variant="ob_primary" type="submit" w="48%" mt="32px">
            Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default MoreInfo;
