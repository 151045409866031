// import {format, parseISO} from "date-fns";
import {format, parseISO} from "date-fns";

import React from "react";

import {Box, Icon, Stack, Text} from "@chakra-ui/react";

const LocationAndDates: React.FC<{
  eventId?: string;
  eventName?: string;
  startDate?: string;
  endDate?: string;
  location?: string;
  showLinkToEvent?: boolean;
}> = ({location, startDate, endDate}) => {
  const parseStartDate = startDate ? parseISO(startDate) : null;
  const parseEndDate = endDate ? parseISO(endDate) : null;

  if (!parseStartDate && !parseEndDate && !location) {
    return null;
  }

  return (
    <>
      <Stack mb="1.5rem" spacing="1rem">
        {(parseStartDate || parseEndDate) && (
          <Stack direction="row" align="center">
            <Icon
              width="24px"
              height="25px"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5.5H6.25V4.25C6.25 3.83579 6.58579 3.5 7 3.5C7.41421 3.5 7.75 3.83579 7.75 4.25V5.5H16.25V4.25C16.25 3.83579 16.5858 3.5 17 3.5C17.4142 3.5 17.75 3.83579 17.75 4.25V5.5H20C21.1046 5.5 22 6.39543 22 7.5V19.5C22 20.6046 21.1046 21.5 20 21.5H4C2.89543 21.5 2 20.6046 2 19.5V7.5C2 6.39543 2.89543 5.5 4 5.5ZM20 7C20.2761 7 20.5 7.22386 20.5 7.5V9.5H3.5V7.5C3.5 7.22386 3.72386 7 4 7H20ZM4 20C3.72386 20 3.5 19.7761 3.5 19.5V11H20.5V19.5C20.5 19.7761 20.2761 20 20 20H4Z"
                fill="#727CD4"
              />
            </Icon>
            <Stack w="100%" direction={["column", "row", "row"]} align={["center", "center"]}>
              {parseStartDate && (
                <Box
                  bg="#FAFAFD"
                  borderRadius="12px"
                  p="0.4rem 0.8rem"
                  w={["100%", "auto", "auto"]}
                >
                  <Stack direction="row" align="center">
                    <Text as="b" whiteSpace="nowrap" color="#616174">
                      {format(parseStartDate, "MMM dd, yyyy")}
                    </Text>
                    <Text as="span" color="#6C6C6C" whiteSpace="nowrap">
                      {format(parseStartDate, "hh:mm bbb")}
                    </Text>
                  </Stack>
                </Box>
              )}

              {parseEndDate && (
                <Box
                  bg="#C9CDEE"
                  borderRadius="1px"
                  h="2px"
                  w="20px"
                  display={["none", "block", "block"]}
                />
              )}

              {parseEndDate && (
                <Box
                  bg="#FAFAFD"
                  borderRadius="12px"
                  p="0.4rem 0.8rem"
                  w={["100%", "auto", "auto"]}
                >
                  <Stack direction="row" align="center">
                    <Text as="b" whiteSpace="nowrap" color="#616174">
                      {format(parseEndDate, "MMM dd, yyyy")}
                    </Text>

                    <Text as="span" color="#6C6C6C" whiteSpace="nowrap">
                      {format(parseEndDate, "hh:mm bbb")}
                    </Text>

                    <Text as="span" color="#6C6C6C" display={["block", "none", "none"]}>
                      {format(parseStartDate, "(z)")}
                    </Text>
                  </Stack>
                </Box>
              )}

              {parseStartDate && (
                <Text as="span" color="#6C6C6C" display={["none", "block", "block"]}>
                  {format(parseStartDate, "(z)")}
                </Text>
              )}
            </Stack>
          </Stack>
        )}

        {location && (
          <Stack direction="row" align="center">
            <Icon
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9988 8C14.9988 9.65686 13.6557 11 11.9988 11C10.342 11 8.99884 9.65686 8.99884 8C8.99884 6.34315 10.342 5 11.9988 5C13.6557 5 14.9988 6.34315 14.9988 8ZM13.4988 8C13.4988 8.82843 12.8272 9.5 11.9988 9.5C11.1704 9.5 10.4988 8.82843 10.4988 8C10.4988 7.17157 11.1704 6.5 11.9988 6.5C12.8272 6.5 13.4988 7.17157 13.4988 8Z"
                fill="#727CD4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2398 17.8498C15.2508 15.8779 18.9988 11.7054 18.9988 8C18.9988 4.11 15.8648 1 11.9988 1C8.13284 1 4.99884 4.11 4.99884 8C4.99884 11.7054 8.74687 15.8779 10.7578 17.8498C11.4564 18.5349 12.5412 18.5349 13.2398 17.8498ZM15.6319 12.7332C16.7693 11.0555 17.4988 9.39555 17.4988 8C17.4988 4.94178 15.0397 2.5 11.9988 2.5C8.95791 2.5 6.49884 4.94178 6.49884 8C6.49884 9.39555 7.22829 11.0555 8.36577 12.7332C9.47586 14.3705 10.8383 15.8279 11.808 16.7789C11.8738 16.8433 11.9413 16.8636 11.9988 16.8636C12.0563 16.8636 12.1238 16.8433 12.1896 16.7789C13.1593 15.8279 14.5218 14.3705 15.6319 12.7332Z"
                fill="#727CD4"
              />
              <path
                d="M5.6568 15.0935C5.30809 14.7448 4.72352 14.8218 4.47695 15.2488L1.30085 20.75C0.723507 21.75 1.44519 23 2.59989 23H21.4007C22.5554 23 23.2771 21.75 22.6998 20.75L19.5226 15.2469C19.276 14.8199 18.6914 14.7429 18.3427 15.0916C18.1027 15.3316 18.0538 15.703 18.2235 15.9969L21.4007 21.5H2.59989L5.77599 15.9988C5.94571 15.7049 5.89683 15.3335 5.6568 15.0935Z"
                fill="#727CD4"
              />
            </Icon>
            <Text
              fontSize="18px"
              color="#616174"
              bg="#FAFAFD"
              as="b"
              borderRadius="12px"
              p="0.4rem 0.8rem"
            >
              {location.replace(/,/g, ", ")}
            </Text>
          </Stack>
        )}
      </Stack>

      {/* {showLinkToEvent && (
        <Stack direction="row" mb="1.5rem">
          <Text fontWeight="bold">{eventName}</Text>
          <Link variant="black" href={`/event/${eventId}`} isExternal>
            ( Open Event )
          </Link>
        </Stack>
      )} */}
    </>
  );
};

export default LocationAndDates;
