import {resolveTranslation} from "./localization";

export const customPasswordValidation = (value: string, helpers) => {
  if (value.length < 8) {
    return helpers.error("string.min");
  }
  if (value.search(/[A-Z]/) < 0) {
    return helpers.error("string.one_uppercase");
  }
  if (value.search(/[!@#$%^&*_\-+=.,:;?!/\\|()\[\]{}<>]/i) < 0) {
    return helpers.error("string.special");
  }
  if (value.search(/[0-9]/) < 0) {
    return helpers.error("string.number");
  } else {
    return value;
  }
};

export function validatePassword(password: string, repeatPassword?: string) {
  const validations = {
    "string.min": password.length >= 8,
    "string.one_uppercase": /[A-Z]/.test(password),
    "string.number": /[0-9]/.test(password),
    "string.special": /[!@#$%^&*_\-+=.,:;?!/\\|()\[\]{}<>]/.test(password),
  };

  if (repeatPassword !== undefined) {
    validations["string.same"] = password === repeatPassword;
  }

  return validations;
}

export const passwordValidationMessages = [
  {
    code: "string.min",
    text: "Length must be at least 8 characters long",
  },
  {code: "string.one_uppercase", text: resolveTranslation("en", "string.one_uppercase")},
  {code: "string.number", text: resolveTranslation("en", "string.number")},
  {code: "string.special", text: resolveTranslation("en", "string.special")},
  {code: "string.same", text: resolveTranslation("en", "string.same")},
];
