import React from "react";

import Link from "next/link";
import {useRouter} from "next/router";

import {Stack, StackProps, Text} from "@chakra-ui/react";

const ItemRow: React.FC<{
  icon?: React.ReactNode;
  name: string;
  textColor?: string;
  rightElement?: React.ReactNode;
  isActive?: boolean;
  href?: string;
  fullMenuWidth: boolean;
}> = ({icon, name, textColor, href, rightElement, isActive, fullMenuWidth}) => {
  const {push} = useRouter();
  const props: StackProps = {
    bg: "linear-gradient(95.74deg, #4754C6 27.06%, #2F3BA0 87.33%)",
    p: {
      background: "linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)",
      backgroundClip: "text",
      textFillColor: "transparent",
    },

    __css: {
      //@ts-ignore
      path: {fill: "#A5FFB9"},
    },
    //@ts-ignore
    path: {fill: "#A5FFB9"},
  };
  if (!href) {
    return;
  }

  return (
    <Link href={href}>
      <Stack
        px="12px"
        py="0.6rem"
        direction="row"
        align="center"
        justify="space-between"
        spacing="1rem"
        borderRadius="16px"
        cursor="pointer"
        transition="all 0.3s"
        minW={fullMenuWidth ? "180px" : ""}
        _hover={props}
        {...(isActive && props)}
      >
        <Stack direction="row" align="center" spacing="16px">
          {icon}
          {fullMenuWidth && (
            <Text
              noOfLines={1}
              fontWeight="500"
              letterSpacing="wider"
              fontSize="15px"
              color={textColor || "#E9EAF8"}
            >
              {name}
            </Text>
          )}
        </Stack>
        {fullMenuWidth && rightElement}
      </Stack>
    </Link>
  );
};

export default ItemRow;
