import {useMemo} from "react";

import {useRouter} from "next/router";

import {useGetEventOrganizersOrganizerIdEventsEventIdGetQuery} from "../../api/__generated__/beOrgApi.extended";
import EventLandingPageImage from "./components/EventLandingPageImage";
import {EventPreviewLayout} from "./components/EventPreviewLayout/EventPreviewLayout";
import FixedBottomBanner from "./components/FixedBottomBanner";

const EventPreview = () => {
  const {query} = useRouter();
  const {data, isLoading, isUninitialized} = useGetEventOrganizersOrganizerIdEventsEventIdGetQuery(
    {organizerId: query?.organizerId?.toString(), eventId: query?.eventId?.toString()},
    {refetchOnMountOrArgChange: true, skip: !query?.eventId || !query?.organizerId}
  );
  const featured_image = () => data?.images.find((img) => img.is_featured);
  return (
    <>
      {featured_image() && <EventLandingPageImage src={featured_image()?.url} />}

      <EventPreviewLayout />

      <FixedBottomBanner event={data} />
    </>
  );
};

export default EventPreview;
