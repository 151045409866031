import * as React from "react";

import {Flex, Heading, Text, Button} from "@chakra-ui/react";

const ConfirmationPrompt: React.FC<{email: string}> = ({email}) => {
  return (
    <Flex direction="column" align="center" w={["100%", "70%"]}>
      <Heading w={["80%", "70%"]} textAlign="center" variant="h2" mb="20px">
        Please confirm your email address
      </Heading>
      <Text fontWeight="bold" textAlign="center">
        We sent a confirmation link to your email:
      </Text>
      <Flex
        my="20px"
        bg="ob-grey-100"
        py="8px"
        borderRadius="15px"
        w="100%"
        justify="center"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <Text color="ob-blue" fontWeight="bold">
          {email}
        </Text>
      </Flex>
      <Text textAlign="center">
        If you don&apos;t receive the email, check your spam folder or send the confirmation again.
      </Text>
      <Button variant="ob_primary" w="100%" mt="32px">
        Send again
      </Button>
    </Flex>
  );
};

export default ConfirmationPrompt;
