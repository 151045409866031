import Cookies, {CookieAttributes} from "js-cookie";
import {omit} from "ramda";

import React, {useState} from "react";

export type CookieNames = "layout-menu" | "userName" | "userId" | "organizerId";

type SetCookie<T> = (newValue: ((prev: T) => T) | T, opts?: CookieAttributes) => void;
type CookieOpts<CookieType> = CookieAttributes & {
  stringify?: boolean;
  defaultFallback?: CookieType;
};

const useCookie = <CookieType>(
  cookieName: CookieNames,
  cookieOpts: CookieOpts<CookieType> = {stringify: false}
) => {
  const [cookieValue, _setCookie] = useState<CookieType>(cookieOpts?.defaultFallback);

  React.useEffect(() => {
    const initialCookie = Cookies.get(cookieName);

    if (initialCookie) {
      _setCookie(cookieOpts?.stringify ? JSON.parse(initialCookie) : (initialCookie as CookieType));
    } else if (cookieOpts?.defaultFallback) {
      setCookie(cookieOpts?.defaultFallback);
    }
  }, []);

  const setCookie: SetCookie<CookieType> = (newValue, opts = {}) => {
    const set = (v: CookieType) =>
      //@ts-ignore
      Cookies.set(cookieName, cookieOpts?.stringify ? JSON.stringify(v) : v, {
        ...omit(["stringify"], cookieOpts),
        ...opts,
      });

    if (typeof newValue === "function") {
      _setCookie((p) => {
        //@ts-ignore
        const val = newValue(p);
        set(val);
        return val;
      });
    } else {
      set(newValue);
      _setCookie(newValue);
    }
  };

  return {cookieValue, hasCookie: Boolean(cookieValue), setCookie};
};

export default useCookie;
