import Joi from "joi";

import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {useRouter} from "next/router";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

import Input from "../../../components/Input";
import {getUser} from "../../../helpers/aws/auth";
import useForm from "../../../hooks/useForm";
import {
  customPasswordValidation,
  passwordValidationMessages,
  validatePassword,
} from "../../../utils/validations";
import {SignupTypes} from "../../Onboarding/LeftSide/SignUp";

type ResetTypes = {password: string; repeatPassword: string; code: string};

const ForgotPasswordForm = () => {
  const [step, setStep] = useState(1);
  const [userEmail, setEmail] = useState();
  const [info, setInfo] = useState<string | undefined>();
  const [passwordHints, setPasswordHints] = useState({});
  const delayDebounceRef = useRef(null);
  const {push} = useRouter();

  const schema = Joi.object({
    email: Joi.string().email({tlds: false}).required(),
  });

  const resetSchema = Joi.object({
    password: Joi.string().min(8).max(50).required().custom(customPasswordValidation),
    repeatPassword: Joi.string().required().valid(Joi.ref("password")),
    code: Joi.string().length(6).required(),
  });

  const {
    handleSubmit,
    registerWithError,
    watch: watchEmail,
    trigger,
    formState: {isValid, dirtyFields},
  } = useForm<Pick<SignupTypes, "email">>(schema, {
    mode: "onBlur",
    defaultValues: {
      email: "",
    } as Pick<SignupTypes, "email">,
  });

  const {
    watch,
    register,
    handleSubmit: handlePasswordSubmit,
    registerWithError: registerPasswordWithError,
    formState: {isValid: isPasswordFormValid},
  } = useForm<ResetTypes>(resetSchema, {
    mode: "onChange",
    defaultValues: {
      password: "",
      repeatPassword: "",
      code: "",
    } as ResetTypes,
  });

  useEffect(() => {
    const result = validatePassword(watch("password"), watch("repeatPassword"));
    setPasswordHints(result);
  }, [watch("password"), watch("repeatPassword")]);

  const handleFormSubmit = ({email}) => {
    setEmail(email);
    getUser(email).forgotPassword({
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
      },
      onFailure: (err) => {
        // console.error("onFailure:", err);
      },
      inputVerificationCode: (data) => {
        setInfo(
          "We've sent you the instructions! Please check your email, including your spam folder, for further details"
        );
        setTimeout(() => {
          setStep(2);
          setInfo(undefined);
        }, 2000);
        // console.log("Input code:", data);
      },
    });
  };

  const onPasswordsSubmit = (values) => {
    if (values.password !== values.repeatPassword) {
      return;
    }

    getUser(userEmail).confirmPassword(values.code, values.password, {
      onSuccess: (data) => {
        setInfo("Password has been changed");
        setTimeout(() => {
          setInfo(undefined);
          push("/login");
        }, 2000);
      },
      onFailure: (err) => {
        // console.error("onFailure:", err);
      },
    });
  };

  useEffect(() => {
    if (dirtyFields["email"]) {
      clearTimeout(delayDebounceRef.current);
      const delayDebounce = setTimeout(() => {
        trigger("email");
      }, 1000);
      delayDebounceRef.current = delayDebounce;
    }
  }, [watchEmail("email"), dirtyFields]);

  return (
    <>
      <Box
        sx={{
          height: "90%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Heading
          w="100%"
          textAlign="center"
          as="h2"
          color="var(--chakra-colors-ef-primary)"
          mb="2rem"
        >
          {step === 1 && "Reset Password"}
          {step === 2 && "Create new password"}
        </Heading>
        {step === 1 && (
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Text>
              To reset your password, please provide your email address. We will send you detailed
              instructions on how to proceed.
            </Text>
            <Stack spacing="1rem" padding="20px 0px">
              <Input
                {...registerWithError("email")}
                name="email"
                w="100%"
                label="Email*"
                backgroundColor="#F8F8F8"
                leftContent={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                      fill="#727CD4"
                    />
                  </svg>
                }
                placeholder="Enter your email"
                noBorder
              />
              {info && (
                <Stack direction="row" align="flex-start" w="fit-content" pt="4px">
                  <Box w="5px" bg="#1F2FBA" alignSelf="stretch" borderRadius="2px" />
                  <Text variant="form-label" color="#1F2FBA">
                    {info}
                  </Text>
                </Stack>
              )}
              <Box pt="10px">
                <Button
                  type="submit"
                  color="#FFFFFF"
                  backgroundColor="var(--chakra-colors-btn-primary-default)"
                  w="100%"
                  disabled={!isValid}
                >
                  Send instructions
                </Button>
              </Box>
            </Stack>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handlePasswordSubmit(onPasswordsSubmit)}>
            <Stack width="100%" spacing="1rem" padding="20px 0px">
              <Input
                {...registerPasswordWithError("code")}
                name="code"
                w="100%"
                label="Verification code*"
                backgroundColor="#F8F8F8"
                leftContent={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                      fill="#727CD4"
                    />
                  </svg>
                }
                placeholder="Verification code"
                noBorder
              />

              <Input
                {...register("password")}
                type="password"
                name="password"
                w="100%"
                label="New password*"
                backgroundColor="#F8F8F8"
                leftContent={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                      fill="#727CD4"
                    />
                  </svg>
                }
                placeholder="Enter new password"
                noBorder
              />

              <Input
                {...register("repeatPassword")}
                type="password"
                name="repeatPassword"
                w="100%"
                label="Confirm new password*"
                backgroundColor="#F8F8F8"
                leftContent={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                      fill="#727CD4"
                    />
                  </svg>
                }
                placeholder="Confirm your password"
                noBorder
              />
              <Box mt="1rem">
                {Object.keys(passwordHints).map((key) => {
                  const isError = !passwordHints[key];
                  const fillColor = !watch("password")?.length
                    ? "#888888"
                    : isError
                    ? "#D85639"
                    : "#727CD4";
                  return (
                    <Flex align="center" key={key} mt="3px !important">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill={fillColor}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6001 6.06874C11.9125 6.38115 11.9125 6.88771 11.6001 7.20011L7.76576 11.0344C7.45336 11.3468 6.9468 11.3468 6.6344 11.0344L4.80008 9.20011C4.48766 8.88771 4.48766 8.38115 4.80008 8.06875C5.1125 7.75635 5.61903 7.75635 5.93145 8.06875L7.20008 9.33739L10.4687 6.06874C10.5078 6.02968 10.5498 5.99551 10.5942 5.96623C10.9047 5.7612 11.3267 5.79537 11.6001 6.06874Z"
                          fill={fillColor}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16 8.46875C16 12.887 12.4182 16.4688 8 16.4688C3.58172 16.4688 0 12.887 0 8.46875C0 4.05047 3.58172 0.46875 8 0.46875C12.4182 0.46875 16 4.05047 16 8.46875ZM14.4 8.46875C14.4 12.0034 11.5346 14.8688 8 14.8688C4.46538 14.8688 1.6 12.0034 1.6 8.46875C1.6 4.93413 4.46538 2.06875 8 2.06875C11.5346 2.06875 14.4 4.93413 14.4 8.46875Z"
                          fill={fillColor}
                        />
                      </svg>

                      <Text size="14px" color={fillColor} paddingLeft={2}>
                        {passwordValidationMessages.find((m) => m.code === key)?.text}
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
              {info && (
                <Stack direction="row" align="flex-start" w="fit-content" pt="4px">
                  <Box w="5px" bg="#1F2FBA" alignSelf="stretch" borderRadius="2px" />
                  <Text variant="form-label" color="#1F2FBA">
                    {info}
                  </Text>
                </Stack>
              )}
              <Box pt="10px">
                <Button
                  type="submit"
                  color="#FFFFFF"
                  backgroundColor="var(--chakra-colors-btn-primary-default)"
                  w="100%"
                  disabled={!isPasswordFormValid}
                >
                  Create new password
                </Button>
              </Box>
            </Stack>
          </form>
        )}
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
