import {CustomAttendeeFieldConfigCreate, CustomAttendeeFieldType} from "@ef-org/api";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";
import Joi from "joi";

import React, {useState} from "react";

import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";

import Checkbox from "../components/Checkbox";
import Input from "../components/Input";
import Select from "../components/Select";
import useForm from "../hooks/useForm";
import {SettingsStepDataType, useSubscribeToSettingsStep} from "../scenes/NewEvent/newEventStore";

type Props = {
  title?: string;
  onClose?: () => void;
};

const validationSchema = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().required(),
  description: Joi.string(),
  isRequired: Joi.boolean().required(),
});

type FormInputsTypes = {
  type: CustomAttendeeFieldType;
  name: string;
  description: string;
  isRequired: boolean;
};

const AddCustomAttendeeFieldModal: React.FC<Props> = ({title, onClose}) => {
  const {closeModal} = useModal("AddCustomAttendeeFieldModal");
  const {handleSubmit, registerWithError, watch} = useForm<FormInputsTypes>(validationSchema, {
    defaultValues: {
      name: "",
      type: "free_text",
      description: "",
      isRequired: false,
    },
  });

  const [customAttendeeFields, setCustomAttendeeFields] =
    useSubscribeToSettingsStep<SettingsStepDataType["customAttendeeFields"]>(
      "customAttendeeFields"
    );

  const onSubmit = (data: CustomAttendeeFieldConfigCreate) => {
    const finalData = {
      ...data,
      enum_options: enumOptions, // Include enum options in the submission
    };
    setCustomAttendeeFields({
      value: [...customAttendeeFields.value, {...finalData}],
    });

    closeModal();
  };

  const [enumOptions, setEnumOptions] = useState<string[]>([]);

  const addEnumOption = () => {
    setEnumOptions([...enumOptions, ""]); // Add a new empty string to the array
  };

  const removeEnumOption = (index: number) => {
    setEnumOptions(enumOptions.filter((_, idx) => idx !== index)); // Remove the option at the specified index
  };

  const selectedType = watch("type");

  return (
    <Box m="2rem">
      <Heading mb="1rem" fontWeight="600" fontSize="20px" color="#1F2FBA">
        Add new attendee field
      </Heading>
      <Stack spacing="1rem" bg="white" w="100%">
        <Input
          maxW="500px"
          {...registerWithError("name")}
          label={<Text color="#727CD4">Label*</Text>}
          placeholder="Enter label"
          noBorder
        />
        <Input
          maxW="500px"
          {...registerWithError("description")}
          label={<Text color="#727CD4">Description</Text>}
          placeholder="Enter label"
          noBorder
        />
        <Select
          maxW="500px"
          {...registerWithError("type")}
          label={<Text color="#727CD4">Type*</Text>}
        >
          <option value="free_text">Free text</option>
          <option value="dropdown">Dropdown</option>
          <option value="checkbox">Checkbox</option>
        </Select>
        {(selectedType === "dropdown" || selectedType === "checkbox") && (
          <Stack bg="#F8F8F8" p="1rem" borderRadius="14px">
            <Text>Define your options</Text>
            {enumOptions.map((option, index) => (
              <Stack key={index} direction="row" align="center">
                <Input
                  bg="white"
                  value={option}
                  onChange={(e) => {
                    const newEnumOptions = [...enumOptions];
                    newEnumOptions[index] = e.target.value;
                    setEnumOptions(newEnumOptions);
                  }}
                  placeholder="Enter option"
                />
                <Button variant="outline" size="md" onClick={() => removeEnumOption(index)}>
                  Remove option
                </Button>
              </Stack>
            ))}
            <Button variant="outline" size="md" type="button" onClick={addEnumOption}>
              Add new option
            </Button>
          </Stack>
        )}
        <Checkbox {...registerWithError("isRequired")}>Required at checkout</Checkbox>
        <Box>
          <Flex justifyContent="end" w="100%">
            <Button size="md" w={["100%", "100%", "fit-content"]} onClick={handleSubmit(onSubmit)}>
              Add attendee field
            </Button>
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
};

export const useAddCustomAttendeeFieldModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("AddCustomAttendeeFieldModal", AddCustomAttendeeFieldModal, {
    closeOnEsc: true,
    closeOnOverlayClick: true,
    hideCloseButton: true,
    size: "lg",
  });
