import {EventTable, EventTableItem, Link} from "@ef-org/components";
import {format, parseISO} from "date-fns";
import Cookies from "js-cookie";

import {useRouter} from "next/router";

import {Box, Heading, Icon, Stack, Text} from "@chakra-ui/react";

import {useRemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteMutation} from "../../api/__generated__/beOrgApi.extended";
import {isUserAuthorized} from "../../helpers/aws/auth";
import EventDetailLoader from "./components/EventDetailLoader";
import EventDetailTabHandler from "./components/EventDetailTabHandler";
import {useEventDetailSelectorByQueryEventId} from "./hooks/useEventDetailSelectorByQueryEventId";

const DiscountSection = () => {
  const {query, pathname} = useRouter();

  const {data} = useEventDetailSelectorByQueryEventId();

  const [removeDiscount] =
    useRemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteMutation();

  const deleteDiscount = async (discountId: string) => {
    const organizerId = Cookies.get("organizerId");
    const eventId = query?.eventId?.toString() || "";
    await removeDiscount({organizerId, eventId, discountId});
  };

  const discoutTypeTostring = {
    order_discount: "Order discount",
    volume_discount: "Volume discount",
    type_discount: "Type discount",
  };

  return (
    <Box>
      <Heading as="h3" mb="1rem" fontSize="24px">
        Your discounts
      </Heading>

      <EventTable
        templateColumns="repeat(5, minmax(150px, 1fr))"
        colsSkeletonNumber={5}
        headerComponents={
          <>
            <Text>Discount name</Text>
            <Text>Discount type</Text>
            <Text>Date & Time</Text>
            <Text>Amount</Text>
            <Text>Actions</Text>
          </>
        }
        bodyComponents={
          <>
            {data?.discounts.map((discount, i) => (
              <EventTableItem
                key={discount.id}
                noHover
                templateColumns="repeat(5, minmax(150px, 1fr))"
              >
                <Stack direction="row" align="center">
                  <Text color="#162184">{discount.name}</Text>
                </Stack>
                <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
                  <Text color="#162184">{discoutTypeTostring[discount.type]}</Text>
                </Stack>

                <Stack w="100%" justify="center" direction="row" align="center" spacing="0.5rem">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                      fill="#727CD4"
                    />
                  </svg>

                  <Text color="#162184">
                    {format(parseISO(discount.created_at), "dd.MM.yyyy HH:mm")}
                  </Text>
                </Stack>

                <Stack w="100%" direction="row" justify="center" align="center" spacing="0.5rem">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0983 3.69782C14.8091 3.40865 14.3402 3.40865 14.0511 3.69782L4.29792 13.451C4.00875 13.7402 4.00875 14.209 4.29792 14.4982L5.35257 15.5528C5.74474 15.3335 6.19688 15.2088 6.67615 15.2088C8.17563 15.2088 9.39121 16.4243 9.39121 17.9238C9.39121 18.4032 9.26641 18.8552 9.04715 19.2474L10.1019 20.3022C10.3911 20.5914 10.8599 20.5914 11.1491 20.3022L20.9023 10.549C21.1915 10.2598 21.1915 9.791 20.9023 9.50184L19.8476 8.44713C19.4553 8.66648 19.003 8.79136 18.5237 8.79136C17.0242 8.79136 15.8087 7.57578 15.8087 6.07629C15.8087 5.59691 15.9335 5.14465 16.1529 4.7524L15.0983 3.69782ZM13.0039 2.65064C13.8715 1.78312 15.278 1.78312 16.1455 2.65064L17.302 3.80724C17.8063 4.3115 17.716 5.01937 17.4617 5.44703C17.3525 5.6307 17.2896 5.84502 17.2896 6.07629C17.2896 6.75788 17.8421 7.31041 18.5237 7.31041C18.7549 7.31041 18.9693 7.2475 19.1529 7.13829C19.5806 6.88402 20.2885 6.79365 20.7927 7.29792L21.9494 8.45464C22.817 9.32217 22.817 10.7287 21.9494 11.5962L12.1963 21.3493C11.3288 22.2169 9.92225 22.2169 9.05473 21.3493L7.89796 20.1926C7.39375 19.6884 7.48402 18.9806 7.73822 18.553C7.84738 18.3693 7.91027 18.155 7.91027 17.9238C7.91027 17.2423 7.35773 16.6897 6.67615 16.6897C6.44493 16.6897 6.23066 16.7526 6.04702 16.8618C5.61937 17.116 4.91159 17.2063 4.40739 16.702L3.25073 15.5454C2.38321 14.6779 2.38322 13.2714 3.25073 12.4038L13.0039 2.65064Z"
                      fill="#727CD4"
                    />
                  </svg>

                  <Text color="#162184">{discount.percentage}%</Text>
                </Stack>
                {isUserAuthorized("admin") && (
                  <Stack direction="row" align="center" justify="flex-end" spacing="1rem">
                    <Link
                      href={`/event/${query?.eventId as string}/edit?step=TICKETS&discountId=${
                        discount.id
                      }&backToUrl=${encodeURIComponent(pathname)}`}
                      variant="switchedDecoration"
                    >
                      <Text color="#4754C6">Edit</Text>
                    </Link>
                    <Icon
                      cursor="pointer"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => deleteDiscount(discount.id)}
                    >
                      <path
                        d="M8.33317 8.33333C8.67834 8.33333 8.95817 8.61317 8.95817 8.95833V13.5417C8.95817 13.8868 8.67834 14.1667 8.33317 14.1667C7.988 14.1667 7.70817 13.8868 7.70817 13.5417V8.95833C7.70817 8.61317 7.988 8.33333 8.33317 8.33333Z"
                        fill="#D85639"
                      />
                      <path
                        d="M12.2915 8.95833C12.2915 8.61317 12.0117 8.33333 11.6665 8.33333C11.3213 8.33333 11.0415 8.61317 11.0415 8.95833V13.5417C11.0415 13.8868 11.3213 14.1667 11.6665 14.1667C12.0117 14.1667 12.2915 13.8868 12.2915 13.5417V8.95833Z"
                        fill="#D85639"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.24984 4.16667V2.91667C6.24984 1.99619 6.99603 1.25 7.9165 1.25H12.0832C13.0037 1.25 13.7498 1.99619 13.7498 2.91667V4.16667H17.7082C18.0533 4.16667 18.3332 4.44649 18.3332 4.79167C18.3332 5.13684 18.0533 5.41667 17.7082 5.41667H16.593L15.9254 16.7645C15.8737 17.6455 15.1441 18.3333 14.2617 18.3333H5.73801C4.85555 18.3333 4.12605 17.6455 4.07422 16.7645L3.4067 5.41667H2.2915C1.94633 5.41667 1.6665 5.13684 1.6665 4.79167C1.6665 4.44649 1.94633 4.16667 2.2915 4.16667H6.24984ZM7.9165 2.5H12.0832C12.3133 2.5 12.4998 2.68655 12.4998 2.91667V4.16667H7.49984V2.91667C7.49984 2.68655 7.68639 2.5 7.9165 2.5ZM4.65886 5.41667L5.32206 16.6912C5.33502 16.9113 5.5174 17.0833 5.73801 17.0833H14.2617C14.4823 17.0833 14.6647 16.9113 14.6776 16.6912L15.3408 5.41667H4.65886Z"
                        fill="#D85639"
                      />
                    </Icon>
                  </Stack>
                )}
              </EventTableItem>
            ))}
          </>
        }
      />
    </Box>
  );
};

const EventDetailDiscounts = () => {
  return (
    <EventDetailLoader>
      <EventDetailTabHandler />

      <Stack spacing="2rem">
        <DiscountSection />
      </Stack>
    </EventDetailLoader>
  );
};

export default EventDetailDiscounts;
