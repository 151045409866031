interface IOptions {
  scroll: (
    ref: React.RefObject<HTMLDivElement> | string,
    offset?: number,
    behavior?: ScrollBehavior
  ) => void;
}

const useScrollToId = (): IOptions => {
  return {
    scroll: (ref, offset = 0, behavior = "smooth") => {
      if (typeof ref === "string") {
        const yOffset = -100 + offset;
        const z =
          document.getElementById(ref)?.getBoundingClientRect()?.top ||
          0 + window.pageYOffset + yOffset;
        window.scrollTo({top: z, behavior});
        return;
      }
      // REF
      ref.current && ref.current.scrollIntoView({behavior});
    },
  };
};

export default useScrollToId;
