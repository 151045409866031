import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconTrash: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    cursor="pointer"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M8.33317 8.73177C8.67834 8.73177 8.95817 9.0116 8.95817 9.35677V13.9401C8.95817 14.2853 8.67834 14.5651 8.33317 14.5651C7.988 14.5651 7.70817 14.2853 7.70817 13.9401V9.35677C7.70817 9.0116 7.988 8.73177 8.33317 8.73177Z"
      fill="#D85639"
    />
    <path
      d="M12.2915 9.35677C12.2915 9.0116 12.0117 8.73177 11.6665 8.73177C11.3213 8.73177 11.0415 9.0116 11.0415 9.35677V13.9401C11.0415 14.2853 11.3213 14.5651 11.6665 14.5651C12.0117 14.5651 12.2915 14.2853 12.2915 13.9401V9.35677Z"
      fill="#D85639"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24984 4.5651V3.3151C6.24984 2.39463 6.99603 1.64844 7.9165 1.64844H12.0832C13.0037 1.64844 13.7498 2.39463 13.7498 3.3151V4.5651H17.7082C18.0533 4.5651 18.3332 4.84493 18.3332 5.1901C18.3332 5.53528 18.0533 5.8151 17.7082 5.8151H16.593L15.9254 17.1629C15.8737 18.0439 15.1441 18.7318 14.2617 18.7318H5.73801C4.85555 18.7318 4.12605 18.0439 4.07422 17.1629L3.4067 5.8151H2.2915C1.94633 5.8151 1.6665 5.53528 1.6665 5.1901C1.6665 4.84493 1.94633 4.5651 2.2915 4.5651H6.24984ZM7.9165 2.89844H12.0832C12.3133 2.89844 12.4998 3.08499 12.4998 3.3151V4.5651H7.49984V3.3151C7.49984 3.08499 7.68639 2.89844 7.9165 2.89844ZM4.65886 5.8151L5.32206 17.0896C5.33502 17.3098 5.5174 17.4818 5.73801 17.4818H14.2617C14.4823 17.4818 14.6647 17.3098 14.6776 17.0896L15.3408 5.8151H4.65886Z"
      fill="#D85639"
    />
  </Icon>
);

export default IconTrash;
