import {OrganizerStatsPeriod} from "@ef-org/api";
import {TypingSelect} from "@ef-org/components";
import useCookie from "apps/ef-org/src/hooks/useCookie";
import {format} from "date-fns";

import React, {useMemo} from "react";

import {Box, Button, Heading, Stack, Text} from "@chakra-ui/react";

const DashboardHeader: React.FC<{
  period: string;
  onPeriodChanged: (period: OrganizerStatsPeriod) => void;
}> = ({period, onPeriodChanged}) => {
  const greeting = useMemo(() => {
    const today = new Date();
    const hour = today.getHours();

    if (hour < 12) {
      return "Good morning";
    } else if (hour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  }, []);

  const {cookieValue: userName} = useCookie<string>("userName");

  return (
    <Stack
      direction={["column", "column", "row"]}
      align="flex-start"
      justify="space-between"
      spacing={["1rem", "1rem", "0"]}
    >
      <Stack spacing="0">
        <Text pb="1rem" color="primary-2">
          {format(new Date(), "d, MMMM")}
        </Text>
        <Heading pb="0.5rem">
          {greeting},{" "}
          <Text as="span" fontSize="inherit" color="primary-2">
            {userName}
          </Text>
        </Heading>

        <Stack direction="row" align="center" spacing="0">
          <Text as="span" color="primary-2" pr="0.5rem">
            Let&apos;s see update for the last
          </Text>
          <Stack
            cursor="pointer"
            _hover={{
              b: {textDecoration: "underline"},
            }}
            spacing="0"
            direction="row"
            align="center"
          >
            <TypingSelect
              hideTyping
              placeholder="the last 24 hours"
              customStyleConfig="CustomInputTransparent"
              color="primary-6"
              ml="-4px"
              fontWeight={500}
              w="120px"
              value={period}
              cursor="pointer"
              onSelected={(val) => onPeriodChanged(val as OrganizerStatsPeriod)}
              options={[
                {value: "day", name: "the last 24 hours"},
                {value: "week", name: "last week"},
                {value: "month", name: "last month"},
              ]}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* TODO: will be added later <Box display={["none", "none", "block"]}>
        <Button
          variant="neutral"
          bg="white"
          rightIcon={
            <Text
              mt="-35px"
              mr="-40px"
              boxSize="30px"
              lineHeight="30px"
              borderRadius="20px"
              pr="1px"
              bg="primary-2"
              color="white"
            >
              10
            </Text>
          }
        >
          Attendee activities
        </Button>
      </Box> */}
    </Stack>
  );
};

export default DashboardHeader;
