import {beNftOrgApi} from "@ef-org/api";
import {isEmpty} from "ramda";

import {useEffect} from "react";
import {useSelector} from "react-redux";

import {useRouter} from "next/router";

import {useNewNFTBuilder, useNFTBuilderSelector, WorkspaceType} from "../hooks/useNFTBuilder";

const useHandleNftStateWithUrl = () => {
  const {replace, query} = useRouter();
  const {setSelectWorkspace, setSelectStyle, setSelectImageType} = useNFTBuilderSelector(
    "setSelectWorkspace",
    "setSelectStyle",
    "setSelectImageType"
  );
  const {data} = useSelector(beNftOrgApi.endpoints.getNftappApiStyle.select());

  useEffect(() => {
    if (isEmpty(data?.items || [])) {
      return;
    }
    if ("space" in query && ["2d", "3d"].includes(query?.space?.toString())) {
      setSelectWorkspace(query?.space?.toString() as WorkspaceType);
    }

    if ("templateId" in query) {
      const style = data?.items?.find((x) => x.id === query?.templateId?.toString());

      if (style) {
        setSelectImageType("from-template");
        //@ts-ignore
        setSelectStyle(style);
      }
    }

    const unsub = useNewNFTBuilder.subscribe(
      (p) => ({selectedWorkspace: p.selectedWorkspace, selectedStyle: p.selectedStyle}),
      ({selectedStyle, selectedWorkspace}) => {
        replace(
          {
            query: {
              space: selectedWorkspace,
              ...(selectedStyle?.id && {templateId: selectedStyle.id}),
            },
          },
          undefined,
          {shallow: true}
        );
      }
    );

    return () => unsub();
  }, [data?.items]);
};

export default useHandleNftStateWithUrl;
