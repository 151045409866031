import {isValid, parse} from "date-fns";
import {enGB} from "date-fns/locale";
import Joi from "joi";

import {resolveTranslation} from "./localization";

export function parseDateFromInput(dateAsString: string) {
  const [year, month, day] = (dateAsString || "")?.split("-");
  return parse(`${day}/${month}/${year}`, "P", new Date(), {locale: enGB});
}
export function isValidDate(day, month, year) {
  const parsed = parse(`${day}/${month}/${year}`, "P", new Date(), {locale: enGB});
  return isValid(parsed);
}

export const JoiDateValidator = Joi.string().custom((value, helpers) => {
  try {
    const [year, month, day] = value.split("-");

    const parsedYear = parseInt(year);
    const parsedMonth = parseInt(month);
    const parsedDay = parseInt(day);

    if (isValidDate(parsedDay, parsedMonth, parsedYear)) {
      return value;
    }

    // @ts-expect-error fix
    return helpers.message(resolveTranslation("en", "date.invalid"));
  } catch (error) {
    // @ts-expect-error fix
    return helpers.message(resolveTranslation("en", "date.invalid"));
  }
});

export const JoiTimeValidator = Joi.string().custom((value, helpers) => {
  try {
    const [hour, minute] = value.split(":");

    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);

    if (parsedHour > 23 || parsedHour < 0 || parsedMinute > 59 || parsedMinute < 0) {
      // @ts-expect-error fix
      return helpers.message(resolveTranslation("en", "time.invalid"));
    }

    return value;
  } catch (error) {
    // @ts-expect-error fix
    return helpers.message(resolveTranslation("en", "time.invalid"));
  }
});
