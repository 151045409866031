import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconClock: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    cursor="pointer"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path d="M8.7998 4.75938C8.46844 4.75938 8.1998 5.02801 8.1998 5.35938V8.35938C8.1998 8.57378 8.3142 8.77178 8.4998 8.87897L11.4443 10.579C11.7312 10.7447 12.0982 10.6463 12.2639 10.3594C12.4296 10.0724 12.3312 9.70545 12.0443 9.53977L9.39981 8.01298V5.35938C9.39981 5.02801 9.13116 4.75938 8.7998 4.75938Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.7998 16.3594C13.218 16.3594 16.7998 12.7776 16.7998 8.35938C16.7998 3.9411 13.218 0.359375 8.7998 0.359375C4.38152 0.359375 0.799805 3.9411 0.799805 8.35938C0.799805 12.7776 4.38152 16.3594 8.7998 16.3594ZM8.7998 15.1594C12.5553 15.1594 15.5998 12.1149 15.5998 8.35938C15.5998 4.60384 12.5553 1.55938 8.7998 1.55938C5.04427 1.55938 1.9998 4.60384 1.9998 8.35938C1.9998 12.1149 5.04427 15.1594 8.7998 15.1594Z"
    />
  </Icon>
);

export default IconClock;
