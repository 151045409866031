import React, {useState} from "react";

import {useRouter} from "next/router";

import {CopyIcon} from "@chakra-ui/icons";
import {Flex, Heading, IconButton, Input, Text, Tooltip, useToast} from "@chakra-ui/react";

const EventEmbedLinks: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const {query} = useRouter();
  const toast = useToast();
  const eventID = query?.eventId?.toString();
  const eventLink = `${process.env.NEXT_PUBLIC_ATTENDEE_URL}/event/${eventID}`;
  const eventTicketsLink = `${process.env.NEXT_PUBLIC_ATTENDEE_URL}/event/${eventID}?embed=true&part=tickets`;
  const embedCode = `<iframe
    width="100%"
    height="1200px"
    src="${eventTicketsLink}"
    frameborder="0">
    </iframe>`;

  return (
    <>
      <Heading as="h3" fontSize="24px">
        Share&Embed
      </Heading>
      <Heading as="h3" fontSize="16px">
        Event page link
      </Heading>
      <Flex gap="10px" alignItems="center">
        <Input bg="white" w="100%" value={eventLink}></Input>
        <IconButton
          variant={"link"}
          width="40px"
          height="40px"
          bg="white"
          icon={<CopyIcon />}
          aria-label="copy"
          onClick={() => {
            toast({description: "Copied", colorScheme: "green"});
            navigator.clipboard.writeText(eventLink);
          }}
        />
      </Flex>
      <Heading as="h3" fontSize="16px">
        Event tickets link
      </Heading>
      <Flex gap="10px" alignItems="center">
        <Input bg="white" w="100%" value={eventTicketsLink}></Input>
        <IconButton
          variant={"link"}
          width="40px"
          height="40px"
          bg="white"
          icon={<CopyIcon />}
          aria-label="copy"
          onClick={() => {
            toast({description: "Copied", colorScheme: "green"});
            navigator.clipboard.writeText(eventTicketsLink);
          }}
        />
      </Flex>
      <Heading as="h3" fontSize="16px">
        Event tickets code
      </Heading>
      <Flex gap="10px" alignItems="center">
        <Input bg="white" w="100%" value={embedCode}></Input>
        <IconButton
          variant={"link"}
          width="40px"
          height="40px"
          bg="white"
          icon={<CopyIcon />}
          aria-label="copy"
          onClick={() => {
            toast({description: "Copied", colorScheme: "green"});
            navigator.clipboard.writeText(embedCode);
          }}
        />
      </Flex>
    </>
  );
};

export default EventEmbedLinks;
