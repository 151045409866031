import {
  useGetAvailablePlansSubscriptionsPlansGetQuery,
  useGetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetQuery,
  SubscriptionPlanRead,
} from "@ef-org/api";
import Cookies from "js-cookie";

import {useEffect} from "react";

import {useRouter} from "next/router";

import {useActivateSubscriptionModal} from "../modals/useActivateSubscriptionModal";

function usePlansAndSubscriptions() {
  const organizerId = Cookies.get("organizerId");
  const {query} = useRouter();
  const {openModal: openSubscriptionModal} = useActivateSubscriptionModal();

  const {data: plans, isLoading: isPlansLoading} = useGetAvailablePlansSubscriptionsPlansGetQuery();
  const {
    data: subscriptions,
    refetch: refetchSubscriptions,
    isLoading: isSubscriptionsLoading,
  } = useGetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetQuery({
    organizerId,
  });

  const isDataReady = plans && subscriptions && !isPlansLoading && !isSubscriptionsLoading;

  useEffect(() => {
    if (query?.ryftSetup === "true" && isDataReady) {
      openSubscriptionModal({
        plan: plans?.items.find((plan) => plan.id === query?.planId),
        onSuccess: refetchSubscriptions,
      });
    }
  }, [query, plans, isDataReady]);

  return {
    availablePlans: plans?.items || [],
    activePlan: subscriptions?.items[0]?.plan || null,
    refetch: refetchSubscriptions,
    isDataReady,
    openSubscriptionModal,
  };
}

export default usePlansAndSubscriptions;
