import React, {FC} from "react";

import {Image, ImageProps} from "@chakra-ui/react";

export const WalletImage: FC<{display?: ImageProps["display"]; maxW?: ImageProps["maxW"]}> = ({
  display,
  maxW = "310px",
}) => {
  return (
    <Image
      display={display}
      maxW={maxW}
      src="/images/illustrations/img.png"
      h="auto"
      alt="wallet"
    />
  );
};
