import {AspectRatio, Image} from "@chakra-ui/react";

const EventHeaderImage = (props: {src: string}) => {
  return (
    <AspectRatio w="100%" ratio={21 / 9}>
      <Image borderRadius="24px" w="100%" h="100%" src={props.src} alt="3d-own-upload" />
    </AspectRatio>
  );
};

export default EventHeaderImage;
