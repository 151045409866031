import {useGetEventOrganizersOrganizerIdEventsEventIdGetQuery} from "apps/ef-org/src/api/__generated__/beOrgApi.extended";
import useCookie from "apps/ef-org/src/hooks/useCookie";

import {useMemo} from "react";

import dynamic from "next/dynamic";
import {useRouter} from "next/router";

import {Heading, Stack} from "@chakra-ui/react";

import EventHeaderImage from "../../../EventHeaderImage";
import Details from "./components/Details";
import LocationAndDates from "./components/LocationAndDates";
import MerchPreview from "./components/MerchPreview";
import TicketProvider from "./components/TicketProvider";
import TicketsPreview from "./components/TicketsPreview";

const GoogleEventMap = dynamic(() => import("./components/GoogleEventMap"), {
  ssr: false,
});

const EventDetails = () => {
  const {query, push} = useRouter();
  const {cookieValue: organizerId} = useCookie<string>("organizerId");
  const {data, isLoading, isUninitialized} = useGetEventOrganizersOrganizerIdEventsEventIdGetQuery(
    {
      organizerId,
      eventId: query?.eventId?.toString(),
    },
    {refetchOnMountOrArgChange: true, skip: !query?.eventId}
  );
  const featured_image = useMemo(() => {
    return data?.images.find((img) => img.is_featured);
  }, [data]);

  if (isLoading) {
    return null;
  }

  if (!isUninitialized && !isLoading && !data) {
    push("/events");
    return null;
  }

  return (
    <Stack spacing="2rem" pb="5rem">
      {featured_image?.url && <EventHeaderImage src={featured_image.url} />}

      <Heading color="#616174" as="h1" fontSize="32px">
        {data?.name}
      </Heading>

      <LocationAndDates
        location={data?.location?.name}
        eventId={query?.eventId?.toString()}
        eventName={data?.name}
        endDate={data?.end_datetime}
        startDate={data?.start_datetime}
      />

      {data?.location?.name && <GoogleEventMap lat={data.location?.lat} lng={data.location?.lon} />}

      <Heading color="#616174" as="h2" fontSize="24px">
        Event details
      </Heading>

      <Details />

      <TicketsPreview />

      <MerchPreview />

      <TicketProvider />
    </Stack>
  );
};

export default EventDetails;
