import {beOrgApi} from "./beOrgApi.generated";

export const enhanceApis = beOrgApi.enhanceEndpoints({
  addTagTypes: [
    "nftMinting",
    "eventDetails",
    "eventDetailsImages",
    "organizerDetails",
    "subscriptions",
    "orders",
    "billingMethods",
    "integrations",
    "community",
    "eventList",
  ],
  endpoints: {
    getEventOrganizersOrganizerIdEventsEventIdGet: {
      providesTags: ["eventDetails"],
    },
    updateEventOrganizersOrganizerIdEventsEventIdPatch: {
      invalidatesTags: ["eventDetails"],
    },
    removeTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDelete: {
      invalidatesTags: ["eventDetails"],
    },
    removeDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDelete: {
      invalidatesTags: ["eventDetails"],
    },
    getEventImagesEventsEventIdImagesGet: {
      providesTags: ["eventDetailsImages"],
    },
    uploadEventImageEventsEventIdImagesPost: {
      invalidatesTags: ["eventDetailsImages"],
    },
    removeEventImageEventsEventIdImagesImageIdDelete: {
      invalidatesTags: ["eventDetailsImages"],
    },
    getOrganizerUsersUserIdOrganizersOrganizerIdGet: {
      providesTags: ["organizerDetails"],
    },
    updateOrganizerUsersUserIdOrganizersOrganizerIdPatch: {
      invalidatesTags: ["organizerDetails", "subscriptions", "eventDetails", "billingMethods"],
    },
    createOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPost: {
      invalidatesTags: ["billingMethods"],
    },
    getOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGet: {
      providesTags: ["subscriptions"],
    },
    updateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatch: {
      invalidatesTags: ["orders"],
    },
    getOrdersForOrganizerOrganizersOrganizerIdOrdersGet: {
      providesTags: ["orders"],
    },
    getOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGet: {
      providesTags: ["orders"],
    },
    getOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGet: {
      providesTags: ["billingMethods"],
    },
    updateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatch:
      {
        invalidatesTags: ["billingMethods", "subscriptions", "eventDetails"],
      },
    completeRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePost: {
      invalidatesTags: ["subscriptions", "organizerDetails", "eventDetails", "billingMethods"],
    },
    getOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGet: {
      providesTags: ["integrations"],
    },
    createOrganizersIntegrationOrganizersOrganizerIdIntegrationsPost: {
      invalidatesTags: ["integrations"],
    },
    deleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDelete: {
      invalidatesTags: ["integrations"],
    },
    getCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGet: {
      providesTags: ["community"],
    },
    sendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPost: {
      invalidatesTags: ["community"],
    },
    deleteEventOrganizersOrganizerIdEventsEventIdDelete: {
      invalidatesTags: ["eventList"],
    },
    getOrganizerEventsOrganizersOrganizerIdEventsGet: {
      providesTags: ["eventList"],
    },
    updateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPut: {
      invalidatesTags: ["eventDetails"],
    },
  },
});

export const {
  useRemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteMutation,
  useGetEventOrganizersOrganizerIdEventsEventIdGetQuery,
  useUpdateEventOrganizersOrganizerIdEventsEventIdPatchMutation,
  useRemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteMutation,
  useUploadEventImageEventsEventIdImagesPostMutation,
  useGetEventImagesEventsEventIdImagesGetQuery,
  useRemoveEventImageEventsEventIdImagesImageIdDeleteMutation,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery,
  useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation,
  useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation,
  useGetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetQuery,
  useUpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchMutation,
  useGetOrdersForOrganizerOrganizersOrganizerIdOrdersGetQuery,
  useGetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetQuery,
  useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery,
  useUpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchMutation,
  useCompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostMutation,
  useGetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetQuery,
  useCreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostMutation,
  useDeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteMutation,
  useGetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetQuery,
  useSendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostMutation,
  useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery,
  useUpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutMutation,
} = enhanceApis;
